import template from './vessel-access-group-list.html';
import events from 'App/events';

function ViewModel(params) {
    var self = this;

    self.accessGroups = params.accessGroups.getList();
    self.isLoading = params.accessGroups.isLoading;
    self.error = params.accessGroups.error;
}

ViewModel.prototype.editAccessGroupClick = function (accessGroup) {
    events.vesselAccessGroupEdit.dispatch(accessGroup);
}

ViewModel.prototype.renameAccessGroupClick = function (accessGroup) {
    events.vesselAccessGroupRename.dispatch(accessGroup);
}

ViewModel.prototype.addAccessGroupClick = function() {
    events.vesselAccessGroupAdd.dispatch();
}

ViewModel.prototype.deleteAccessGroupClick = function (accessGroup) {
    events.vesselAccessGroupDelete.dispatch(accessGroup);
}

export default { viewModel: ViewModel, template: template }