



















































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import tooltip from "@/components/knockoutWrappers/TooltipWrapper.vue";
import Sidebar from "@/components/Sidebar.vue";
import vVesselTitle from "@/components/VesselTitle.vue";
import store from "@/store";
import events from "App/events";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/clients/Auth.module";
import UserModule from "@/store/clients/User.module";
import AnnouncementModule from "@/store/clients/Announcement.module";
import VersionClient from "Clients/version-client";
import SnackbarModule from "@/store/clients/Snackbar.module";
import { SnackbarConfig } from "@/types/snackbarConfig";
import ShowAnnouncement from "@/components/Announcement/ShowAnnouncement.vue";
import IncidentsModule from "@/store/clients/Incidents.module";

const Auth = getModule(AuthModule, store);
const User = getModule(UserModule, store);
const Snackbar = getModule(SnackbarModule, store);
const Announcement = getModule(AnnouncementModule, store);
const Incidents = getModule(IncidentsModule, store);

@Component({
  components: {
    tooltip,
    Sidebar,
    vVesselTitle,
    ShowAnnouncement,
  },
})
export default class App extends Vue {
  showSidebar = true;
  showNewVersionNotification = false;
  lastUsedVersion = parseInt(Vue.$cookies.get("lastUsedVersion"));
  currentVersion!: number;
  errorEventBinding: any = null;

  ShowAnnouncementPopup = false;
  unreadAnnouncementCount = 0;
  unreadAnnouncementandNotification = 0;

  //store getters
  get isLoggedIn(): boolean {
    return Auth.isLoggedIn;
  }

  get isKognifai(): boolean {
    return User.isKognifai;
  }

  get userName(): string {
    return User.userName;
  }

  get userEmail(): string {
    return User.userEmail;
  }

  get userCompanyName(): string | null {
    return User.userCompanyName;
  }

  get notification(): SnackbarConfig {
    return Snackbar.message;
  }

  mounted(): void {
    this.$root.$on("announcementlistByUser", async () => {
      await this.announcementlistByUser();
    });
    this.errorEventBinding = events.errorEvent.add((error: any) => {
      this.$store.dispatch("Snackbar/showSnackbar", {
        text: error.responseJSON.message || "An error occurred",
      });
    });
  }

  async created(): Promise<void> {
    this.delayedRegisterKnockoutComponents();
    this.checkIfNewVersion();
    if (this.isLoggedIn) {
      const isTokenValid = await Auth.isTokenValid();
      if (!isTokenValid) this.logOut();
    }
    await this.announcementlistByUser();
  }

  beforeDestroy(): void {
    this.errorEventBinding.detach();
  }

  //We dynamically register the knockout-components after user has been loaded,
  //so that kognifai users get served the correct templates
  delayedRegisterKnockoutComponents(): void {
    const unwatchNameChanged = this.$watch("userName", newVal => {
      if (newVal) {
        import("@/registerDelayedKnockoutComponents");
        unwatchNameChanged();
      }
    });
  }

  async announcementlistByUser(): Promise<void> {
    if (!this.isLoggedIn) return;
    const response = await Announcement.announcementListByUser();
    const filterdata = response.filter((item: { isView: boolean }) => item.isView === false);
    this.unreadAnnouncementCount = filterdata.length;

    const openIncidents = await Incidents.getFleetLevelOpenIncidents();
    const unacknowledgedIncidents = openIncidents.filter(item => item.acknowledged != true);
    const unacknowledgedIncidentsCount = unacknowledgedIncidents.length;

    this.unreadAnnouncementandNotification = this.unreadAnnouncementCount + unacknowledgedIncidentsCount;
  }

  @Watch("isLoggedIn")
  async checkIfNewVersion(): Promise<void> {
    this.showNewVersionNotification = false;
    if (this.isLoggedIn === true) {
      this.currentVersion = await VersionClient.getVersion();
      if (!this.lastUsedVersion || this.currentVersion > this.lastUsedVersion) {
        this.showNewVersionNotification = true;
      }
    }
  }

  navigateToVersionHistory(): void {
    Vue.$cookies.set("lastUsedVersion", this.currentVersion, "1y");
    this.showNewVersionNotification = false;
    this.lastUsedVersion = this.currentVersion;
    this.$router.push("/Help/VersionHistory");
  }

  closeSnackbar(): void {
    this.$store.dispatch("Snackbar/close");
  }

  logOut(): void {
    this.showNewVersionNotification = false;
    Auth.signOut().then(() => {
      this.$router.push("/login");
      window.location.reload();
    });
  }
}
