
(function(factory) {
  "use strict";
	if (typeof require === "function" && typeof exports === "object" && typeof module === "object") {
		// CommonJS or Node: hard-coded dependency on "knockout"
		factory(require("knockout"), exports);
	} else if (typeof define === "function" && define["amd"]) {
		// AMD anonymous module with hard-coded dependency on "knockout"
		define(["knockout", "exports"], factory);
	} else {
		// <script> tag: use the global `ko` object
		factory(ko, {});
	}
}(function(ko, exports) {
  "use strict";
    ko.extenders.paging = function (target, pageSize) {
        var _pageSize = pageSize || 250;
        var _preferredSize = localStorage.getItem("preferredTableSize");
        if (_preferredSize)
            _pageSize = _preferredSize;

        var _currentPage = ko.observable(1);

        target._pageSize = ko.observable(_pageSize);
        //Save preference to localstorage
        target._pageSize.subscribe(function(newValue) {
            localStorage.setItem("preferredTableSize", newValue);
            target.moveFirst();
        });
        target.rowsToShow = ko.observableArray([25, 50, 100, 250]);

        target.pageSize = ko.computed({
            read: target._pageSize,
            write: function (newValue) {
                if (newValue > 0) {
                    target._pageSize(newValue);
                }
                else {
                    target._pageSize(10);
                }
            },
        });

        target.currentPage = ko.computed({
            read: _currentPage,
            write: function (newValue) {
                if (newValue > target.pageCount()) {
                    _currentPage(target.pageCount());
                }
                else if (newValue <= 0) {
                    _currentPage(1);
                }
                else {
                    _currentPage(newValue);
                }
            },
        });

        target.pageCount = ko.computed(function () {
            return Math.ceil(target().length / target.pageSize()) || 1;
        });

        target.currentPageData = ko.computed(function () {
            var pageSize = target._pageSize(),
                pageIndex = _currentPage(),
                startIndex = pageSize * (pageIndex - 1),
                endIndex = pageSize * pageIndex;

            return target().slice(startIndex, endIndex);
        });

        target.moveFirst = function () {
            target.currentPage(1);
        };
        target.movePrevious = function () {
            target.currentPage(target.currentPage() - 1);
        };
        target.moveNext = function () {
            target.currentPage(target.currentPage() + 1);
        };
        target.moveLast = function () {
            target.currentPage(target.pageCount());
        };
    };
}));
