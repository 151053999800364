import ko from "knockout";
import highcharts from "highcharts";

ko.bindingHandlers.highcharts = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var value = valueAccessor();
        var options = ko.utils.unwrapObservable(value.options);
        var chart = new highcharts.Chart(element, options);
        highcharts.AST.allowedAttributes.push("data-title");
        highcharts.AST.allowedReferences.push("data:");
        value.chart(chart);

        var optionsSubscription = value.options.subscribe(function (newValue) {
            chart = new highcharts.Chart(element, newValue);
            value.chart(chart);
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            optionsSubscription.dispose();
        });
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
    },
};