import template from "./vessel-feature.html";
import ko from "knockout";
import _ from "underscore";
import vesselsAdministrationsClient from "Clients/vessels-administrations-client";

function ViewModel(params) {
    var self = this;
    self.feature = params.feature;
    self.enabledFeatures = ko.utils.unwrapObservable(params.enabledFeatures);
    self.vesselId = ko.utils.unwrapObservable(params.vesselId);

    var isFeatureInitiallyEnabled = _.some(self.enabledFeatures, function(feature) {
        return feature.id === self.feature.id;
    });

    self.isFeatureEnabled = ko.observable(isFeatureInitiallyEnabled);

    self.isFeatureEnabledBinding = self.isFeatureEnabled.subscribe(function(isEnabled) {
        if (isEnabled) {
            self.activate();
        } else {
            self.deactivate();
        }
    });
}

ViewModel.prototype.dispose = function () {
    this.isFeatureEnabledBinding.dispose();
};

ViewModel.prototype.activate = function() {
    vesselsAdministrationsClient.activateFeature(this.vesselId, this.feature.id);
};

ViewModel.prototype.deactivate = function () {
    vesselsAdministrationsClient.deactivateFeature(this.vesselId, this.feature.id);
};

export default { viewModel: ViewModel, template: template };