import ko from "knockout";

var ViewModel = function Folder(folderName, folderPath, isTempFolder) {
    var self = this;

    self.elementType = "folder";
    self.folderName = ko.observable(folderName);
    self.folderPath = ko.observable(folderPath);
    self.isTempFolder = ko.observable(isTempFolder);

    self.isFileMenuVisible = ko.observable(false);
    self.newName = ko.observable(folderName);

    self.newNameErrorText = ko.pureComputed(function () {
        var newName = self.newName();
        return "A folder with the name " + newName + " already exists";
    });

    self.resetName = function () {
        var folderName = self.folderName();
        self.newName(folderName);
    };
};

ViewModel.prototype.showFileMenu = function (element) {
    var isFileMenuVisible = this.isFileMenuVisible();
    this.isFileMenuVisible(!isFileMenuVisible);
};

ViewModel.prototype.hideFileMenu = function() {
    this.isFileMenuVisible(false);
};

export default ViewModel;