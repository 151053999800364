














import { Component, Vue } from "vue-property-decorator";
import vPerformanceStatusIcon from "@/components/PerformanceStatusIcon.vue";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    vPerformanceStatusIcon,
  },
})
export default class VesselTitle extends Vue {
  get vesselName(): string | undefined {
    return Vessels.currentVessel?.name;
  }

  get isVesselPage(): string {
    return this.$route.params.vesselId;
  }

  get longTrendDateFormatted(): string | undefined {
    if (!Vessels.currentVessel?.isDiagnosticActivated) return;
    const vessel = Vessels.currentVessel;
    const minDate = formatDate(vessel.trendDataMinDate);
    const maxDate = formatDate(vessel.trendDataMaxDate);

    return minDate + " - " + maxDate;
  }
}
function formatDate(input: string): string {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const date = new Date(input);

  return `${date.getDate()}.${months[date.getMonth()]} ${date.getFullYear()}`;
}
