import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Incident, OpenIncident } from "@/types/incident";
import { Condition } from "@/types/condition";
import incidentsClient from "Clients/incidents-client";
import conditionsClient from "Clients/notification-conditions-client";
import _ from "underscore";

@Module({ namespaced: true, name: "Incidents" })
class Incidents extends VuexModule {
  _openIncidents: Incident[] = [];
  _allOpenIncidents: Incident[] = [];
  _allClosedIncidents: Incident[] = [];
  _conditions: Condition[] = [];
  private _incidentsExpired = true;

  @Mutation
  public UPDATE_OPEN_INCIDENTS(incidents: Incident[]): void {
    this._openIncidents = incidents;
  }

  @Mutation
  public SET_ALL_OPEN_INCIDENTS(incidents: Incident[]): void {
    this._allOpenIncidents = incidents;
  }

  @Mutation
  public SET_ALL_CLOSED_INCIDENTS(incidents: Incident[]): void {
    this._allClosedIncidents = incidents;
  }

  @Mutation
  public SET_CONDITIONS(conditions: Condition[]): void {
    this._conditions = conditions;
  }

  @Mutation
  public EXPIRE_DATA(): void {
    this._incidentsExpired = true;
  }

  @Action
  public async updateOpenIncidents(id = this.currentVesselId): Promise<void> {
    if (!id) return Promise.reject();

    try {
      const data = await incidentsClient.find(true, id);
      this.context.commit("UPDATE_OPEN_INCIDENTS", data);
      return data;
    } catch (error) {
      console.warn(error);
      return Promise.reject(`Failed to update open incidents: ${error}`);
    }
  }

  @Action
  public async getAllOpenIncidents(): Promise<void> {
    try {
      const data = await incidentsClient.find(true);
      this.context.commit("SET_ALL_OPEN_INCIDENTS", data);
    } catch (error) {
      console.warn(error);
    }
  }

  @Action({ rawError: true })
  public async getFleetLevelOpenIncidents(): Promise<OpenIncident[]> {
    try {
      const data = await incidentsClient.find(true);
      this.context.commit("SET_ALL_OPEN_INCIDENTS", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to return response", error });
    }
  }

  @Action
  public async getAllClosedIncidents(): Promise<void> {
    try {
      const data = await incidentsClient.find(false);
      this.context.commit("SET_ALL_CLOSED_INCIDENTS", data);
    } catch (error) {
      console.warn(error);
    }
  }

  @Action
  public async fetchConditionsByVesselId(vesselId: number): Promise<Condition[]> {
    try {
      const data = await conditionsClient.find(vesselId);
      return data;
    } catch (error) {
      throw ({ message: "Failed to load conditions", error });
    }
  }

  @Action
  public async fetchConditions(): Promise<Condition[]> {
    try {
      const data = await conditionsClient.find();
      this.context.commit("SET_CONDITIONS", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to load conditions", error });
    }
  }

  @Action
  public async createConditions(conditions: Condition[]): Promise<Condition[]> {
    try {
      const data = await conditionsClient.create(conditions);
      return data;
    } catch (error) {
      throw ({ message: "Failed to create conditions", error });
    }
  }

  @Action
  public async updateCondition(conditions: Condition[]): Promise<Condition[]> {
    try {
      const data = await conditionsClient.update(conditions);
      return data;
    } catch (error) {
      throw ({ message: "Failed to update condition", error });
    }
  }

  private get currentVesselId(): number | undefined {
    return this.context.rootGetters["Vessels/currentVessel"]?.id;
  }

  public get distinctOpenIncidents(): Incident[] {
    const groupedByConditionAndTargetKey = _.groupBy(this._openIncidents, function (incident) {
      return incident.conditionId + "-" + incident.conditionTargetKey;
    });

    return _.map(groupedByConditionAndTargetKey, function (group) {
      return _.sortBy(group, "severity")[0];
    });
  }

  public get distinctAllOpenIncidents(): Incident[] {
    const groupedByConditionAndTargetKey = _.groupBy(this._allOpenIncidents, function (incident) {
      return incident.conditionId + "-" + incident.conditionTargetKey;
    });

    return _.map(groupedByConditionAndTargetKey, function (group) {
      return _.sortBy(group, "severity")[0];
    });
  }

  public get openWarningIncidents(): Incident[] {
    return _.filter(this.distinctOpenIncidents, function (incident) {
      return incident.severity === "Warning";
    });
  }

  public get openCriticalIncidents(): Incident[] {
    return _.filter(this.distinctOpenIncidents, function (incident) {
      return incident.severity === "Critical";
    });
  }

  public get openCriticalIncidentsLength(): number {
    return this.openCriticalIncidents.length;
  }
  public get openWarningIncidentsLength(): number {
    return this.openWarningIncidents.length;
  }

  public get allOpenIncidents(): Incident[] {
    return this._allOpenIncidents;
  }

  public get allClosedIncidents(): Incident[] {
    return this._allClosedIncidents;
  }

  public get allIncidents(): Incident[] {
    return [...this._allClosedIncidents, ...this._allOpenIncidents];
  }

  public get conditions(): Condition[] {
    return this._conditions;
  }
}

export default Incidents;
