import regression from "regression";
import _ from "underscore";

function getRegressionData(dataPoints, curveFit, regressionOrder) {
    var precision = 20;
    var regressionData;

    curveFit = curveFit.toLowerCase();

    if (curveFit === "linear") {
        regressionData = regression.linear(dataPoints, { precision: precision });
    } else if (curveFit === "polynomial") {
        regressionData = regression.polynomial(dataPoints, { order: regressionOrder, precision: precision });
    } else if (curveFit === "power") {
        var dataPointsFilteredForPowerRegression = _.filter(dataPoints,
            function(point) {
                return point[0] > 0 && point[1] > 0;    //cannot use x and y values 0 or less for power regression
            });
        regressionData = regression.power(dataPointsFilteredForPowerRegression, { precision: precision });
    } else if (curveFit === "exponential") {
        regressionData = regression.exponential(dataPoints, { precision: precision });
    } else if (curveFit === "logarithmic") {
        var dataPointsFilteredForLogarithmicRegression = _.filter(dataPoints,
            function(point) {
                return point[0] > 0;                    //cannot use x values 0 or less for logarithmic regression
            });
        regressionData = regression.logarithmic(dataPointsFilteredForLogarithmicRegression, { precision: precision });
    }

    return regressionData;
}

function getPredictedPoint(x, regressionData) {
    return regressionData.predict(x);
}

function getPredictedValue(x, regressionData) {
    var predictedPoint = getPredictedPoint(x, regressionData);
    return predictedPoint[1];
}

function getPredictedPoints(data, regressionData) {
    var min = data[0][0];
    var max = data[0][0];

    for (var i = 0; i < data.length; i++) {
        var value = data[i][0];
        if (value < min) {
            min = value;
        }

        if (value > max) {
            max = value;
        }
    }
    var increment = max / 100;
    var upperLimit = max + increment;
    var graphPoints = [];
    var dataPoints = [];

    for (var i = min; i < upperLimit; i += increment) {
        var predictedPoint = getPredictedPoint(i, regressionData);
        if (isFinite(predictedPoint[1])) {
            graphPoints.push(predictedPoint);
        }
    }

    for (var i = 0; i < data.length; i++) {
        var predictedPoint = getPredictedPoint(data[i].x, regressionData);

        if (isFinite(predictedPoint[1])) {
            dataPoints.push(predictedPoint);
        }
    }

    sortAscending(graphPoints);
    sortAscending(dataPoints);

    return { graphPoints: graphPoints, dataPoints: dataPoints };
}

function getPredictionSerieData(regressionData, min, max) {
    var xMin = min;
    var xMax = max;
    if (!_.isFinite(min)) {
        xMin = _.min(regressionData.points, function(point) {
            return point[0];
        })[0];
    }

    if (!_.isFinite(max)) {
        xMax = _.max(regressionData.points, function(point) {
            return point[0];
        })[0];
    }
    var increment = Math.abs(xMax-xMin) / 100;
    var upperLimit = xMax + increment;
    var predictedPoints = [];

    for (var i = xMin; i < upperLimit; i += increment) {
        var predictedPoint = getPredictedPoint(i, regressionData);
        if (isFinite(predictedPoint[1])) {
            predictedPoints.push(predictedPoint);
        }
    }

    return _.sortBy(predictedPoints,
        function(point) {
            return point[0];
        });
}

function sortAscending(array) {
    array.sort(function (a, b) {
        if (a[0] > b[0]) { return 1; }
        if (a[0] < b[0]) { return -1; }
        return 0;
    });
}

function getDataArrayFromDataPointObjects(dataPoints) {
    var data = [];
    for (var i = 0; i < dataPoints.length; i++) {
        data[i] = [];
        if (!_.isUndefined(dataPoints[i].x)) {
            data[i][0] = dataPoints[i].x;
            data[i][1] = dataPoints[i].y;
        } else {
            data[i][0] = dataPoints[i][0];
            data[i][1] = dataPoints[i][0];
        }
    }
    return data;
}

function getStandardError(data, predictedData) {
    var SE = 0;
    var N = data.length;

    for (var i = 0; i < data.length; i++) {
        if (data[i][1] !== null & predictedData[i] !== null) {
            SE += Math.pow(data[i][1] - predictedData[i][1], 2);
        } else {
            N--;
        }
    }
    SE = Math.sqrt(SE / (N-2));

    return SE;
}

function getMean(dataArray) {
    return _.reduce(dataArray, function(memo, point)
    {
        return memo + point[1];
    }, 0) / dataArray.length;
}

function getStd(dataArray, mean) {
    var sumSquaredDifferences = _.reduce(dataArray, function(memo, point) {
        var diff = point[1] - mean;
        return memo + (diff * diff);
    }, 0);

    var variance = sumSquaredDifferences / dataArray.length;

    var standardDeviation = Math.sqrt(variance);

    return standardDeviation;
}

// https://stackoverflow.com/questions/14846767/std-normal-cdf-normal-cdf-or-error-function
function erf(x) {
    // save the sign of x
    var sign = (x >= 0) ? 1 : -1;
    x = Math.abs(x);

    // constants
    var a1 =  0.254829592;
    var a2 = -0.284496736;
    var a3 =  1.421413741;
    var a4 = -1.453152027;
    var a5 =  1.061405429;
    var p  =  0.3275911;

    // A&S formula 7.1.26
    var t = 1.0/(1.0 + p*x);
    var y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
    return sign * y; // erf(-x) = -erf(x);
}

function erfc(x) {
    return 1 - erf(x);
}

// test value against chauvenet's criterion to determine if it's an outlier or not.
// implementation based on:
// http://www.astro.rug.nl/software/kapteyn-beta/EXAMPLES/kmpfit_chauvenet.py
function isOutlier(value, mean, std, N) {
    var criterion = 1.0 / (2 * N);                  // Chauvenet's criterion

    var d = Math.abs(value - mean) / std;           // Distance of a value to mean, in std
    var threshold = d / (Math.pow(2.0, 0.5));       // The left and right tail threshold value

    var prob = erfc(threshold);                     // Area normal dist.
    var isOutlier = prob < criterion;               // outlier if probability is less than criterion
    return isOutlier;
}

export default {
    getRegressionData: function (dataPoints, curveFit, regressionOrder) {
        return getRegressionData(dataPoints, curveFit, regressionOrder);
    },
    predictRegressionValue: function (x, dataPoints, curveFit, regressionOrder) {
        var regressionData = getRegressionData(dataPoints, curveFit, regressionOrder);
        return getPredictedValue(x, regressionData);
    },
    getPredictedPoint: function(x, regressionData) {
        return getPredictedPoint(x, regressionData);
    },
    getPredictedPoints: function(data, regressionData) {
        return getPredictedPoints(data, regressionData);
    },
    getDataArrayFromDataPointObjects: function(dataPoints) {
        return getDataArrayFromDataPointObjects(dataPoints);
    },
    getPredictionSerieData: function(regressionData, min, max) {
        return getPredictionSerieData(regressionData, min, max);
    },
    getStandardError: function(data, predictedData) {
        return getStandardError(data, predictedData);
    },
    getMean: function(dataArray) {
        return getMean(dataArray);
    },
    getStd: function(dataArray, mean) {
        return getStd(dataArray, mean);
    },
    getPredictedDataArray: function(regressionData, dataArray) {
        return _.map(dataArray,
            function(point) {
                var predictedPoint = [];
                predictedPoint[0] = point[0];
                predictedPoint[1] = getPredictedValue(predictedPoint[0], regressionData);
                return predictedPoint;
            });
    },
    isOutlier: function(x, mean, stdv, N) {
        return isOutlier(x, mean, stdv, N);
    },
};