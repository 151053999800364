import net from "App/net";

var Client = function () {

};

Client.prototype.find = function (vesselId) {
  var filter = {
    vesselId: vesselId,
  };
  return net.get("/NotificationConditions/Find", filter);
};

Client.prototype.fetch = function () {
  return net.get("/NotificationConditions/Find");
};

Client.prototype.create = function (condition) {
  return net.post("/NotificationConditions", condition);
};

Client.prototype.update = function (conditions) {
  return net.put("/NotificationConditions", conditions);
};

Client.prototype.remove = function (condition) {
  return net.del("/NotificationConditions/" + condition.id);
};

export default new Client();
