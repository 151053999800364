import template from "./compare-vessels-long-trends-graph.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import Highcharts from "highcharts";
import highchartsHelper from "Utilities/highcharts-helper";
import dateHelper from "Utilities/date-helper";

function ViewModel(params) {
    var self = this;
    self.compareGroup = params.compareGroup;
    self.exportTitle = params.exportTitle;
    self.vessels = self.compareGroup.vessels;
    self.longTrends = self.compareGroup.longTrends;
    self.longTrendPeriods = self.compareGroup.longTrendPeriods;

    var firstLongTrend = self.longTrends[0];
    self.diagnosticType = firstLongTrend.diagnosticType;
    self.chart = ko.observable();
    self.isZoomedOut = ko.observable(true);
    self.zoomMin = ko.observable();
    self.zoomMax = ko.observable();

    self.chartInfo = ko.pureComputed(function() {
        var trendLinesSeries = highchartsHelper.getTrendLinesSeriesForCompareLongTrendPeriods(self.longTrendPeriods, self.longTrends, self.vessels);
        var plotBands = highchartsHelper.getPlotBandsForCompareLongTrendPeriods(self.longTrendPeriods, self.compareGroup);

        var firstLongTrendPeriod = _.first(self.longTrendPeriods);
        var firstPlotBand = _.first(plotBands);
        return {
            title: null,
            yUnit: firstLongTrendPeriod.unitName + " (" + firstLongTrendPeriod.unitCaption + ")",
            isRpm: firstLongTrendPeriod.isRpmDiagnostic,
            yMax: firstLongTrendPeriod.maximumValue,
            yMin: firstLongTrendPeriod.minimumValue,
            trendLinesSeries: trendLinesSeries,
            plotBands: plotBands,
            xMin: firstPlotBand ? firstPlotBand.from : null,
        };
    });

    self.highchartsOptions = ko.computed(function() {
        var chartInfo = self.chartInfo();
        if (!chartInfo) return null;

        var options = highchartsHelper.getDefaultDiagnosticSplineHighchartsOptions();
        options.title.text = chartInfo.title;
        options.yAxis.title.text = chartInfo.yUnit;
        options.xAxis.plotBands = chartInfo.plotBands;
        options.xAxis.min = chartInfo.xMin;
        options.xAxis.max = new Date().valueOf();
        options.series = chartInfo.trendLinesSeries;
        options.yAxis.max = chartInfo.isRpm ? 25 : 50;
        options.yAxis.min = chartInfo.isRpm ? -25 : -50;
        options.legend = {
            enabled: true,
        };
        options.exporting.filename = self.exportTitle;
        options.exporting.chartOptions.title = {
            text: self.exportTitle,
        };
        options.exporting.chartOptions.legend.enabled = true;

        options.xAxis.events.setExtremes = function(e) {
            var axis = this;
            var userOptions = axis.chart.userOptions;
            var isZoomedOut = (_.isUndefined(e.min) && _.isUndefined(e.max)) || (e.min === userOptions.xAxis.min && e.max === userOptions.xAxis.max);
            self.isZoomedOut(isZoomedOut);
            self.zoomMin(e.min);
            self.zoomMax(e.max);

            if (isZoomedOut) {
                axis.chart.yAxis[0].setExtremes(userOptions.yAxis.min, userOptions.yAxis.max);
            } else {
                var yAxis = axis.chart.yAxis[0];
                yAxis.setExtremes(yAxis.dataMin - Math.abs(yAxis.dataMin) * 0.1, yAxis.dataMax + Math.abs(yAxis.dataMax) * 0.1);
            }
        };

        options.tooltip = {
            useHTML: true,
            headerFormat: "{series.name}<br><small>{point.key}</small><br>",
            pointFormat: "Value: <strong>{point.y}</strong>",
            valueDecimals: 2,
            xDateFormat: "%d. %b, %Y",
            valueSuffix: "%",
        };

        return options;
    });

    self.zoomAllHelpText = ko.pureComputed(function() {
        var zoomMin = self.zoomMin();
        var zoomMax = self.zoomMax();
        var zoomMinText = dateHelper.getFormatedDateTimeString(zoomMin);
        var zoomMaxText = dateHelper.getFormatedDateTimeString(zoomMax);
        var helpText = "This will zoom all graphs to the current graphs zoom level: ";
        helpText += zoomMinText;
        helpText += " - ";
        helpText += zoomMaxText;
        return helpText;
    });
    self.isZoomAllHelpOpen = ko.observable(false);

    self.closeTooltipBinding = events.closeTooltip.add(function() {
        self.isZoomAllHelpOpen(false);
    });
}

ViewModel.prototype.zoomAllGraphs = function() {
    var self = this;
    Highcharts.charts.forEach(function(chart) {
        chart.xAxis[0].setExtremes(self.zoomMin(), self.zoomMax());
        if (!chart.resetZoomButton) {
            chart.showResetZoom();
        }
    });
};

ViewModel.prototype.dispose = function() {
    this.closeTooltipBinding.detach();
};

ViewModel.prototype.toggleZoomAllHelpTooltip = function(vm, e) {
    var isZoomAllHelpOpen = this.isZoomAllHelpOpen();
    if (!isZoomAllHelpOpen) {
        var element = e.currentTarget;
        events.showTooltip.dispatch("tooltip-text", { text: this.zoomAllHelpText(), isWide: true }, element);
        this.isZoomAllHelpOpen(true);
    } else {
        events.closeTooltip.dispatch();
    }
};

ViewModel.prototype.hideZoomAllHelpTooltip = function() {
    if (this.isZoomAllHelpOpen()) {
        this.isZoomAllHelpOpen(false);
        events.closeTooltip.dispatch();
    }
};

export default { viewModel: ViewModel, template: template };