function getVesselEventTypeText(type) {
    switch (type) {
    case "TrendEvent":
        return "Trend";
    case "InfoEvent":
        return "Info";
    default:
        return "";
    }
}

function getVesselEventSourceText(source) {
    switch (source) {
    case "Ship":
        return "Created onboard";
    case "Office":
        return "Created in office";
    default:
        return "";
    }
}

export default {
    getVesselEventTypeText: function (vesselEvent) {
        var vesselEventType = vesselEvent.hasOwnProperty("type") ? vesselEvent.type : vesselEvent;
        return getVesselEventTypeText(vesselEventType);
    },
    getVesselEventTypeImageSrc: function (vesselEvent) {
        var iconName = this.getVesselEventTypeText(vesselEvent).toLowerCase();
        var url = require("@/assets/images/eventicon/" + iconName + "-ikon.png");
        return url;
    },
    getVesselEventSourceText: function (vesselEvent) {
        return getVesselEventSourceText(vesselEvent.source);
    },
    getVesselEventSourceImageSrc: function (vesselEvent) {
        var iconName = vesselEvent.source === "Ship" ? "vessel" : "office";
        var url = require("@/assets/images/eventicon/" + iconName + "-ikon.png");
        return url;
    },
};
