import template from "./voyage-vessel-event-cargos.html";
import ko from "knockout";
import _ from "underscore";
import VoyageVesselEventCargoDetail from "ViewModels/emission-report/voyageVesselEventCargoDetail";
import vesselEventCargoDetailsClient from "Clients/vessel-event-cargo-details-client";
import events from "App/events";
import configEmissionReportFactory from "App/config-emission-report";

function getVoyageVesselEventCargoDetails(cargoDetails, vesselCargoUnits, vesselEvent) {
    var configEmissionReport = configEmissionReportFactory.get();
    var cargoUnitIdsInUse = _.pluck(cargoDetails, "cargoUnitId");
    var vesselCargoUnitIds = _.pluck(vesselCargoUnits, "id");
    var cargoUnitsMap = configEmissionReport.cargoUnitsMap();

    var activeOrInUseCargoUnits = _.filter(cargoUnitsMap,
        function(cargoUnit) {
            return _.contains(cargoUnitIdsInUse, cargoUnit.id) || _.contains(vesselCargoUnitIds, cargoUnit.id);
        });

    return _.map(activeOrInUseCargoUnits,
        function(cargoUnit) {
            return new VoyageVesselEventCargoDetail(vesselEvent, cargoUnit, cargoDetails);
        });
}

function ViewModel(params) {
    var self = this;
    self.vessel = params.vessel;
    self.voyageVesselEvent = params.voyageVesselEvent;
    self.vesselEvent = params.voyageVesselEvent.vesselEvent;
    self.isAllCargoDetailsCreatedOutput = params.isAllCargoDetailsCreated;

    self.cargoDetails = ko.observableArray([]);
    self.voyageVesselEventCargoDetails = ko.observableArray([]);

    self.isLoadingCargoDetails = ko.observable(true);
    vesselEventCargoDetailsClient.find(self.voyageVesselEvent.id)
        .done(function(cargoDetails) {
            var vesselCargoUnits = self.vessel().cargoUnits;
            var voyageVesselEventCargoDetails = getVoyageVesselEventCargoDetails(cargoDetails, vesselCargoUnits, self.voyageVesselEvent.vesselEvent);
            self.voyageVesselEventCargoDetails(voyageVesselEventCargoDetails);
        })
        .always(function() {
            self.isLoadingCargoDetails(false);
        });

    self.forceShowCargoDetails = ko.observable(false);

    self.isAllCargoDetailsCreated = ko.pureComputed(function() {
        var voyageVesselEventCargoDetails = self.voyageVesselEventCargoDetails();
        return _.every(voyageVesselEventCargoDetails,
            function(voyageVesselEventCargoDetail) {
                return voyageVesselEventCargoDetail.isCreated();
            });
    });

    self.showCargoDetails = ko.pureComputed(function() {
        var forceShowCargoDetails = self.forceShowCargoDetails();
        var vesselEvent = self.voyageVesselEvent.vesselEvent();
        var voyageVesselEventCargoDetails = self.voyageVesselEventCargoDetails();

        var createdCargoDetails = _.find(voyageVesselEventCargoDetails,
            function(voyageVesselEventCargoDetail) {
                return voyageVesselEventCargoDetail.isCreated();
            });

        return forceShowCargoDetails || vesselEvent.type === "Arrival" || createdCargoDetails;
    });

    self.vesselEventCargoDetailDeletedBinding = events.vesselEventCargoDetailDeleted.add(function() {
        self.forceShowCargoDetails(false);
    });

    self.isAllCargoDetailsCreatedOutput(self.isAllCargoDetailsCreated());
    self.isAllCargoDetailsCreatedBinding = self.isAllCargoDetailsCreated.subscribe(function(isAllCargoDetailsCreated) {
        self.isAllCargoDetailsCreatedOutput(isAllCargoDetailsCreated);
    });
}

ViewModel.prototype.dispose = function() {
    _.each(this.voyageVesselEventCargoDetails(),
        function(voyageVesselEventCargoDetail) {
            voyageVesselEventCargoDetail.dispose();
        });
    this.vesselEventCargoDetailDeletedBinding.detach();
};

ViewModel.prototype.toggleForceShowCargoDetails = function() {
    this.forceShowCargoDetails(!this.forceShowCargoDetails());
};

export default { viewModel: ViewModel, template: template };