import _ from "underscore";
import TaxonVM from "ViewModels/taxonomy/taxon";

var ViewModel = function(name, taxons, highlightedLogVariable) {
    this.name = name;
    var orderedTaxons = _.sortBy(taxons, "name");
    this.taxons = _.map(orderedTaxons, function(taxon) {
        return new TaxonVM(taxon, highlightedLogVariable);
    });
};

export default ViewModel;