import ko from "knockout";
import _ from "underscore";
import curvesClient from "Clients/curves-client";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

var ViewModel = function(curve) {
    var self = this;
    self.curve = ko.observable(curve);
    self.name = ko.pureComputed(function() {
        var curve = self.curve();
        if (!curve) return "";
        return curve.name;
    });
};

ViewModel.prototype.save = function(newCurve) {
    var self = this;
    var originalCurve = _.clone(self.curve()) || {};
    var curve = _.extend(originalCurve, newCurve);

    var promise;
    if (curve.id) {
        promise = curvesClient.update(curve);
    } else {
        curve.vesselId = Vessels.currentVessel.id;
        promise = curvesClient.create(curve);
    }
    promise.done(function(data) {
        self.curve(data);
    });
    return promise;
};

ViewModel.prototype.remove = function() {
    return curvesClient.remove(this.curve());
};

export default ViewModel;