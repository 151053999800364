import template from "./geo-coordinates-edit.html";
import ko from "knockout";
import locationHelper from "Utilities/location-helper";
import numberHelper from "Utilities/number-helper";

function ViewModel(params) {
    var self = this;
    self.inputLatitudeDegrees = ko.observable();
    self.inputLatitudeMinutes = ko.observable();
    self.inputLatitudeDirection = ko.observable();
    self.inputLongitudeDegrees = ko.observable();
    self.inputLongitudeMinutes = ko.observable();
    self.inputLongitudeDirection = ko.observable();
    self.geoCoordinate = params.geoCoordinate;
    self.initialLocation = ko.utils.unwrapObservable(params.initialLocation);

    self.validationErrors = ko.validation.group([self], { deep: true });

    if (self.initialLocation) {
        var latitudeParts = locationHelper.getCoordinateParts(self.initialLocation.geoCoordinate.latitude, "latitude");
        var longitudeParts = locationHelper.getCoordinateParts(self.initialLocation.geoCoordinate.longitude, "longitude");

        self.inputLatitudeDegrees(latitudeParts.degrees);
        self.inputLatitudeMinutes(numberHelper.round(latitudeParts.minutes, 4));
        self.inputLatitudeDirection(latitudeParts.direction);
        self.inputLongitudeDegrees(longitudeParts.degrees);
        self.inputLongitudeMinutes(numberHelper.round(longitudeParts.minutes, 4));
        self.inputLongitudeDirection(longitudeParts.direction);
    }

    self.currentGeoCoordinate = ko.computed(function() {
        var validationErrors = self.validationErrors();
        if (validationErrors.length > 0) {
            self.geoCoordinate(null);
            return;
        }

        var inputLatitudeDegrees = self.inputLatitudeDegrees();
        var inputLatitudeMinutes = self.inputLatitudeMinutes();
        var inputLatitudeDirection = self.inputLatitudeDirection();
        var inputLongitudeDegrees = self.inputLongitudeDegrees();
        var inputLongitudeMinutes = self.inputLongitudeMinutes();
        var inputLongitudeDirection = self.inputLongitudeDirection();

        var latitudeMultiplier = inputLatitudeDirection === "S" ? -1 : 1;
        var longitudeMultiplier = inputLongitudeDirection === "W" ? -1 : 1;
        var latitude = (parseInt(inputLatitudeDegrees) + parseFloat(inputLatitudeMinutes) / 60) * latitudeMultiplier;
        var longitude = (parseInt(inputLongitudeDegrees) + parseFloat(inputLongitudeMinutes) / 60) * longitudeMultiplier;

        var geoCoordinate = {
            latitude: latitude,
            longitude: longitude,
        };
        self.geoCoordinate(geoCoordinate);
    });
}

export default { viewModel: ViewModel, template: template };