import net from "App/net";

var CiiClient = function () {

};

CiiClient.prototype.getDays = function(query) {
  return net.get(`/Cii/Day`, query);
};

CiiClient.prototype.getYtd = function(query) {
  return net.get(`/Cii/Ytd`, query);
};

CiiClient.prototype.getYear = function(query) {
  return net.get(`/Cii/Year`, query);
};

export default new CiiClient();