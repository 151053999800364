import ko from "knockout";
import _ from "underscore";
import GraphVariable from "ViewModels/graphVariable";

var GraphVariableGroup = function (parent, id, name, variables, selectedVariables, variableSelectionUpdateTrigger) {
    var self = this;

    var maxVariables = {
        distinct: 10,
        group: 5,
    };

    self.name = name;
    self.graphVariables = ko.observableArray([]);

    _.each(variables, function (variable) {
        var isSelected = false;
        var variableId = variable.id ? variable.id : variable.id;
        var variableName = variable.name ? variable.name : variable.description;

        if (_.contains(selectedVariables, variableId.toString())) {
            isSelected = true;
        }

        self.graphVariables.push(new GraphVariable(variableName, variableId, isSelected, selectedVariables, variableSelectionUpdateTrigger, variable.onboardSystem, variable.displayName));
    });

    self.graphVariables(_.sortBy(self.graphVariables(), function (variable) { return variable.name; }));

    self.selectedGraphVariables = ko.pureComputed(function () {
        var selectedGraphVariables = _.filter(self.graphVariables(), function (variable) {
            return variable.isSelected();
        });
        return _.sortBy(selectedGraphVariables, "queue");
    });

    self.unselectedGraphVariables = ko.pureComputed(function () {
        return _.filter(self.graphVariables(), function (variable) {
            return !variable.isSelected();
        });
    });

    self.selectedIds = ko.pureComputed(function() {
        var selection = "";
        _.each(self.selectedGraphVariables(), function (item, index) {
            selection += item.id.toString();
            var seperator = id === 0 ? ";" : ",";
            if (index < self.selectedGraphVariables().length - 1) {
                selection += seperator;
            }
        });
        return selection;
    });

    self.count = ko.pureComputed(function () {
        return self.selectedGraphVariables().length;
    });

    self.maxVariables = ko.pureComputed(function () {
        var index = parent.currentGroupIndex();
        return index === 0 ? maxVariables.distinct : maxVariables.group;
    });

    self.clear = function() {
        _.each(self.graphVariables(), function (variable) {
            variable.unselect();
        });
    };
};

export default GraphVariableGroup;
