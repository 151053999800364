import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import vesselCategoriesClient from "Clients/vessel-categories-client";
import stringHelper from "Utilities/string-helper";

var ViewModel = function () {
    var self = this;
    self.items = ko.observableArray([]);
    self.isLoading = ko.observable(false);
    self.error = ko.observable();

    self.load();

    self.onCreatedBinding = events.vesselAccessGroupOnCreated.add(function (newItem) {
        self.items.push(newItem);
    });

    self.onUpdatedBinding = events.vesselAccessGroupOnUpdated.add(function (oldItem, updatedItem) {
        self.items.replace(oldItem, updatedItem);
    });

    self.onDeletedBinding = events.vesselAccessGroupOnDeleted.add(function (removedItem) {
        self.items.remove(function (item) {
            return item.id === removedItem.id;
        });
    });
};

ViewModel.prototype.dispose = function () {
    this.onCreatedBinding.detach();
    this.onUpdatedBinding.detach();
};

ViewModel.prototype.load = function () {
    var self = this;
    self.error(undefined);
    self.items([]);
    self.isLoading(true);

    vesselCategoriesClient.getAll()
        .done(function (data) {
            self.items(data);
        })
        .fail(function (error) {
            console.error(error);
            self.error("Failed to load list.");
        })
        .always(function() {
            self.isLoading(false);
        });
};

ViewModel.prototype.getList = function () {
    return this.items;
};

ViewModel.prototype.isLoading = function () {
    return this.isLoading;
};

ViewModel.prototype.exists = function (name) {
    var match = _.find(this.items(), function (o) {
        return stringHelper.equalsIgnoreCase(name, o.name);
    });
    return match !== undefined;
};

export default ViewModel;
