import net from "App/net";

var ReportTypesClient = function () {

};

ReportTypesClient.prototype.getAll = function () {
    return net.get("/ReportTypes");
};

export default new ReportTypesClient();