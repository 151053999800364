import template from "./graph-config-time-period.html";
import ko from "knockout";
import moment from "moment";
import dateHelper from "Utilities/date-helper";
import _ from "underscore";

function ViewModel(params) {
    var self = this;
    var inputErrorClass = "input-validation-error";
    var timeErrorMessage = "Please specify a valid time in the 00:00-23:59 range";
    var numberFormatErrorMessage = "The input field must be a positive number";
    var selectedTimeErrorMessage = "The total timespan must be greater than 0";
    var timeFormat = "HH:mm";

    self.showLastHours = params.showLastHours;
    self.showLastMinutes = params.showLastMinutes;
    self.showFromTime = params.showFromTime;
    self.showToTime = params.showToTime;

    self.selectedTimeInMinutes = params.selectedTimeInMinutes;
    self.selectedTimePeriod = params.selectedTimePeriod;
    self.lastDays = params.lastDays;
    self.lastHours = params.lastHours;
    self.lastMinutes = params.lastMinutes;
    self.fromDate = params.fromDate;
    self.toDate = params.toDate;
    self.fromTime = params.fromTime;
    self.toTime = params.toTime;
    self.lastVoyage = params.lastVoyage;
    self.isVoyagesLoading = params.isVoyagesLoading;

    self.timeError = ko.observable();
    self.lastPeriodError = ko.observable();

    self.lastPeriodValuesValid = params.lastPeriodValuesValid;
    self.setPeriodValuesValid = params.setPeriodValuesValid;

    self.disableLastVoyage = ko.pureComputed(function () {
        return !self.lastVoyage();
    });

    self.lastVoyageDateString = ko.pureComputed((function() {
        return self.lastVoyage() 
            ? `[${dateHelper.getFormatedDateString(self.lastVoyage().startDate)}  -  ${dateHelper.getFormatedDateString(self.lastVoyage().endDate)}]`
            : "[No voyage available]";
    }));

    self.isLastPeriodInputInvalid = function (observable) {
        var value = observable();
        setLastPeriodErrorMessage();

        return isValueInvalid(value);
    };

    self.isTimeInputInvalid = function (observable) {
        var from = moment(self.fromTime(), timeFormat, true);
        var to = moment(self.toTime(), timeFormat, true);
        var focused = moment(observable(), timeFormat, true);

        if (from.isValid() && to.isValid()) {
            self.setPeriodValuesValid(true);
            self.timeError("");
        } else {
            self.setPeriodValuesValid(false);
            self.timeError(timeErrorMessage);
        }

        if (self.selectedTimePeriod() === "SetPeriod" && self.selectedTimeInMinutes() <= 0) {
            self.timeError(selectedTimeErrorMessage);
        }

        return !focused.isValid();
    };

    self.selectedTimePeriodSubscriber = self.selectedTimePeriod.subscribe(function(value) {
        setSelectedTimeErrors();
    });

    self.selectedTimeInMinutesSubscriber = self.selectedTimeInMinutes.subscribe(function (value) {
        setSelectedTimeErrors();
    });

    function setSelectedTimeErrors() {
        if (self.selectedTimeInMinutes() <= 0) {
            if (self.selectedTimePeriod() === "LastPeriod") {
                self.lastPeriodError(selectedTimeErrorMessage);
                self.timeError("");
            } else if (self.selectedTimePeriod() === "SetPeriod") {
                self.lastPeriodError("");
                self.timeError(selectedTimeErrorMessage);
            }
        } else {
            self.lastPeriodError("");
            self.timeError("");
        }
    }

    function setLastPeriodErrorMessage() {
        var valid = true;

        if (isValueInvalid(self.lastDays())) {
            valid = false;
        }
        if (self.showLastHours && isValueInvalid(self.lastHours())) {
            valid = false;
        }
        if (self.showLastMinutes && isValueInvalid(self.lastMinutes())) {
            valid = false;
        }

        if (valid) {
            self.lastPeriodValuesValid(true);
            self.lastPeriodError("");
        } else {
            self.lastPeriodValuesValid(false);
            self.lastPeriodError(numberFormatErrorMessage);
        }

        if (self.selectedTimePeriod() === "LastPeriod" && self.selectedTimeInMinutes() === 0) {
            self.lastPeriodError(selectedTimeErrorMessage);
        } 
    }

    function isValueInvalid(value) {
        var empty = value === "";
        value = Number(value);
        return _.isNaN(value) || !_.isNumber(value) || value < 0 || empty;
    }
}

ViewModel.prototype.dispose = function () {
    this.selectedTimePeriodSubscriber.dispose();
    this.selectedTimeInMinutesSubscriber.dispose();
};

export default { viewModel: ViewModel, template: template };