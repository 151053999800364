import template from "./emission-report.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import configEmissionReportFactory from "App/config-emission-report";
import events from "App/events";
import urlHelper from "Utilities/url-helper";
import dateHelper from "Utilities/date-helper";
import emissionReportClient from "Clients/emission-report-client";
import emissionReportVesselEventsClient from "Clients/emission-report-vessel-events-client";
import logDataClient from "Clients/log-data-client";

function ViewModel(params) {
    var self = this;
    self.configEmissionReport = configEmissionReportFactory.get();
    self.vessel = self.configEmissionReport.vessel;
    self.gpsSpeedLogVariable = self.configEmissionReport.gpsSpeedLogVariable;
    self.isLoading = self.configEmissionReport.isLoading;
    self.isLoadingGpsSpeedLogVariable = self.configEmissionReport.isLoadingGpsSpeedLogVariable;

    self.isUploading = ko.observable(false);
    self.isVesselSettingsVisible = ko.observable(false);

    self.templateErrors = ko.observable();

    self.selectedDeparture = ko.observable();
    self.deselectDeparture = function() {
        self.selectedDeparture(null);
    }.bind(self);

    self.isLoadingVesselEvents = ko.observable(true);

    var now = moment.utc();
    var endOfMonth = moment.utc([now.year(), now.month(), 1]).add(1, "months");
    self.queryDates = ko.observable({
        fromDate: endOfMonth.clone().add(-2, "months"),
        toDate: endOfMonth,
    });

    self.inputQueryFromDate = ko.observable(dateHelper.getFormatedDateString(self.queryDates().fromDate));
    self.inputQueryToDate = ko.observable(dateHelper.getFormatedDateString(self.queryDates().toDate));

    self.allVesselEvents = ko.observableArray([]);
    var loadVesselEvents = function() {
        var queryDates = self.queryDates();

        self.isLoadingVesselEvents(true);
        emissionReportVesselEventsClient.find(self.configEmissionReport.currentVessel.id).done(self.allVesselEvents).always(function() {
            self.isLoadingVesselEvents(false);
        });
    };
    loadVesselEvents();

    self.allSortedVesselEvents = ko.pureComputed(function() {
        var allVesselEvents = self.allVesselEvents();
        return _.sortBy(allVesselEvents, "timestamp");
    });

    self.allDepartureVesselEvents = ko.pureComputed(function() {
        var allSortedVesselEvents = self.allSortedVesselEvents();
        return _.filter(allSortedVesselEvents,
            function(vesselEvent) {
                return vesselEvent.type === "Departure";
            });
    });

    self.filteredDepartureVesselEvents = ko.pureComputed(function() {
        var queryDates = self.queryDates();
        var vesselEvents = self.allDepartureVesselEvents();
        return _.filter(vesselEvents,
            function(vesselEvent) {
                var timestamp = moment.utc(vesselEvent.timestamp);
                return timestamp >= queryDates.fromDate && timestamp < queryDates.toDate;
            });
    });

    var getTimelineData = function(queryDates) {
        var timelineData = {
            data: {},
        };
        timelineData.data[queryDates.fromDate.valueOf()] = 0;
        timelineData.data[queryDates.toDate.valueOf()] = 0;
        return timelineData;
    };

    self.isLoadingGpsSpeedData = ko.observable(false);
    self.gpsSpeedData = ko.observable();
    var loadGpsSpeedData = function() {
        var queryDates = self.queryDates();
        var gpsSpeedLogVariable = self.gpsSpeedLogVariable();
        var isLoadingGpsSpeedLogVariable = self.isLoadingGpsSpeedLogVariable();
        var isLoadingGpsSpeedData = self.isLoadingGpsSpeedData();

        if (isLoadingGpsSpeedLogVariable || isLoadingGpsSpeedData) return;

        var fromDate = dateHelper.getSubmitDateString(queryDates.fromDate);
        var toDate = dateHelper.getSubmitDateString(queryDates.toDate);

        if (gpsSpeedLogVariable) {
            self.isLoadingGpsSpeedData(true);
            logDataClient.findLogData(gpsSpeedLogVariable.id, fromDate, toDate, "Hour", "UnixEpochMs")
                .done(function(gpsSpeedData) {
                    if (_.keys(gpsSpeedData.data).length > 0) {
                        self.gpsSpeedData(gpsSpeedData);
                    } else {
                        var timelineData = getTimelineData(queryDates);
                        self.gpsSpeedData(timelineData);
                    }
                })
                .always(function() {
                    self.isLoadingGpsSpeedData(false);
                });
        } else {
            var timelineData = getTimelineData(queryDates);
            self.gpsSpeedData(timelineData);
        }
    };

    self.vesselSettingsUrl = urlHelper.getVesselSettingsUrl();
    self.isGeneralVesselSettingsComplete = ko.pureComputed(function() {
        var vessel = self.vessel();
        if (!vessel) return false;

        return vessel.vesselTypeId &&
            vessel.portOfRegistry &&
            vessel.homePort &&
            vessel.ownerCompanyName &&
            vessel.ownerCompanyAddress &&
            vessel.operatorCompanyName &&
            vessel.operatorCompanyAddress &&
            vessel.emissionsContactPersonName &&
            vessel.emissionsContactPersonAddress &&
            vessel.emissionsContactPersonPhone &&
            vessel.emissionsContactPersonEmail;
    });

    self.hasFlowMeterOrFuelTank = ko.pureComputed(function() {
        var activeFlowMeters = self.configEmissionReport.activeFlowMeters();
        var activeFuelTanks = self.configEmissionReport.activeFuelTanks();
        return !(_.isEmpty(activeFlowMeters) && _.isEmpty(activeFuelTanks));
    });

    self.hasCargoUnits = ko.pureComputed(function() {
        var vessel = self.vessel();
        if (!vessel) return false;

        return vessel.cargoUnits.length > 0;
    });

    self.isMissingSettings = ko.pureComputed(function() {
        var isGeneralVesselSettingsComplete = self.isGeneralVesselSettingsComplete();
        var hasFlowMeterOrFuelTank = self.hasFlowMeterOrFuelTank();
        var hasCargoUnits = self.hasCargoUnits();
        return !isGeneralVesselSettingsComplete || !hasFlowMeterOrFuelTank || !hasCargoUnits;
    });

    self.voyageUpdatedBinding = events.voyageUpdated.add(function(updatedVoyage) {
        self.voyages.remove(function(voyage) {
            return voyage.id === updatedVoyage.id;
        });
        self.voyages.push(updatedVoyage);
    });

    self.vesselEventUpdatedBinding = events.vesselEventUpdated.add(function(updatedVesselEvent) {
        var allVesselEvents = self.allVesselEvents();
        var oldVesselEvent = _.find(allVesselEvents,
            function(vesselEvent) {
                return vesselEvent.id === updatedVesselEvent.id;
            });

        self.allVesselEvents.replace(oldVesselEvent, updatedVesselEvent);
    });

    self.vesselEventCreatedBinding = events.vesselEventCreated.add(function(createdVesselEvent) {
        self.allVesselEvents.push(createdVesselEvent);
    });

    self.vesselEventDeletedBinding = events.vesselEventDeleted.add(function(deletedVesselEvent) {
        self.allVesselEvents.remove(function(voyageVesselEvent) {
            return voyageVesselEvent.id === deletedVesselEvent.id;
        });
    });

    self.isLoadingGpsSpeedLogVariableBinding = self.isLoadingGpsSpeedLogVariable.subscribe(function() {
        loadGpsSpeedData();
    });

    self.queryDatesBinding = self.queryDates.subscribe(function() {
        loadGpsSpeedData();
    });

    self.inputQueryFromDateBinding = self.inputQueryFromDate.subscribe(function(newDate) {
        var queryDates = self.queryDates();
        queryDates.fromDate = dateHelper.getDate(newDate);
        self.queryDates(queryDates);
    });

    self.inputQueryToDateBinding = self.inputQueryToDate.subscribe(function(newDate) {
        var queryDates = self.queryDates();
        queryDates.toDate = dateHelper.getDate(newDate);
        self.queryDates(queryDates);
    });

    self.currentYear = ko.pureComputed(function() {
        var queryDates = self.queryDates();
        return queryDates.fromDate.year();
    });

    self.departureSelectedBinding = events.departureSelected.add(function(departure) {
        self.selectedDeparture(departure);
    });

    self.showDownloadAnnualEmissionReport = ko.pureComputed(function() {
        var vesselEvents = self.allDepartureVesselEvents();
        var currentYear = self.currentYear();
        var confirmedAnnualDepartureEvents = _.filter(vesselEvents,
            function(vesselEvent) {
                var timestamp = moment.utc(vesselEvent.timestamp);
                return currentYear === timestamp.year() && vesselEvent.voyage && vesselEvent.voyage.dataConfirmedAt;
            });

        return confirmedAnnualDepartureEvents.length > 0;
    });
}

ViewModel.prototype.uploadVoyageFromTemplate = function () {
    var self = this;
    self.isUploading(true);
    var vesselId = self.configEmissionReport.currentVessel.id;

    var fd = new FormData();
    var files = $("#voyageUploadFile")[0].files[0];
    fd.append("file", files);

    emissionReportClient.uploadVoyage(vesselId, fd)
        .done(function() {
            console.log("Upload successful");
        })
        .always(function() {
            self.isUploading(false);
        })
        .fail(function(response) {
            var responseText = response.responseText;
            var responseJson = JSON.parse("[" + responseText + "]");
            self.templateErrors(responseJson);
        });
};

ViewModel.prototype.downloadMrvAnnualReport = function () {
    var vesselId = this.configEmissionReport.currentVessel.id;
    var currentYear = this.currentYear();
    return emissionReportClient.mrvYearly(vesselId, currentYear);
};

ViewModel.prototype.downloadImoAnnualReport = function () {
    var vesselId = this.configEmissionReport.currentVessel.id;
    var currentYear = this.currentYear();
    return emissionReportClient.imoYearly(vesselId, currentYear);
};

ViewModel.prototype.toggleSettings = function () {
    var isVesselSettingsVisible = this.isVesselSettingsVisible();

    if (isVesselSettingsVisible) {
        this.configEmissionReport.load();
    }

    this.isVesselSettingsVisible(!isVesselSettingsVisible);
};

ViewModel.prototype.dispose = function() {
    this.voyageUpdatedBinding.detach();
    this.vesselEventUpdatedBinding.detach();
    this.vesselEventCreatedBinding.detach();
    this.vesselEventDeletedBinding.detach();
    this.departureSelectedBinding.detach();
    this.isLoadingGpsSpeedLogVariableBinding.dispose();
    this.queryDatesBinding.dispose();
    this.inputQueryFromDateBinding.dispose();
    this.inputQueryToDateBinding.dispose();
};

ViewModel.prototype.navigatePreviousMonth = function() {
    var queryDates = this.queryDates();
    queryDates.fromDate = queryDates.fromDate.add(-2, "months");
    queryDates.toDate = queryDates.toDate.add(-2, "months");
    this.queryDates(queryDates);
    this.inputQueryFromDate(dateHelper.getFormatedDateString(queryDates.fromDate));
    this.inputQueryToDate(dateHelper.getFormatedDateString(queryDates.toDate));
};

ViewModel.prototype.navigateNextMonth = function() {
    var queryDates = this.queryDates();
    queryDates.fromDate = queryDates.fromDate.add(2, "months");
    queryDates.toDate = queryDates.toDate.add(2, "months");
    this.queryDates(queryDates);
    this.inputQueryFromDate(dateHelper.getFormatedDateString(queryDates.fromDate));
    this.inputQueryToDate(dateHelper.getFormatedDateString(queryDates.toDate));
};

export default { viewModel: ViewModel, template: template };
