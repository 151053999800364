import stringHelper from "Utilities/string-helper";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

function getVesselBaseUrl(vessel) {
    var vesselUrlName = encodeURIComponent(vessel.name);

    var baseUrl = process.env.VUE_APP_BASE_URL + "Vessel/" + vessel.id + "/" + vesselUrlName + "/";
    return baseUrl;
}

export default {
    getVesselUrl: function(vessel) {
        return getVesselBaseUrl(vessel || Vessels.currentVessel);
    },
    getVesselSettingsUrl: function(vessel) {
        var baseUrl = getVesselBaseUrl(vessel || Vessels.currentVessel);
        return baseUrl + "VesselSettings";
    },
    getVesselNotificationsUrl: function(vessel) {
        var baseUrl = getVesselBaseUrl(vessel || Vessels.currentVessel);
        return baseUrl + "Notifications";
    },
    getVersionHistoryUrl: function(vessel) {
        return process.env.VUE_APP_BASE_URL + "Help/VersionHistory";
    },
    getHelpDiagnosticsUrl: function() {
        return process.env.VUE_APP_BASE_URL + "Help/Diagnostics";
    }
};
