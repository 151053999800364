import net from "App/net";

var ShapoliClient = function () {

};

ShapoliClient.prototype.getBreaches = function(query) {
  return net.get(`/Shapoli/Breaches`, query);
};

ShapoliClient.prototype.getBreachDetails = function(query) {
  return net.get(`/Breaches/Details`, query);
};

ShapoliClient.prototype.getEvents = function(query) {
  return net.get(`/Shapoli/Events`, query);
};

ShapoliClient.prototype.getShaftPowerLogVariables = function(query) {
  return net.get(`/Shapoli/PowerLogVariables`, query);
};

ShapoliClient.prototype.downloadBreachLogData = function(vesselId, dates) {
  net.fileDownload(`/Shapoli/Reports/Breach?vesselId=${vesselId}&breachStartDate=${dates.fromDate}&toDate=${dates.toDate}`, "GET");
};

ShapoliClient.prototype.downloadBreachesYearReport = function(vesselId, year) {
  net.fileDownload(`/Shapoli/Reports/Year?vesselId=${vesselId}&year=${year}`, "GET");
};

export default new ShapoliClient();
