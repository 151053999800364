import template from "./import-details.html";
import ko from "knockout";
import _ from "underscore";
import ImportStatistic from "ViewModels/dataImport/importStatistic";

function ViewModel(params) {
    var self = this;
    self.importDetailsVisible = params.importDetailsVisible;
    self.importEntity = params.importEntity;

    self.importStatistics = ko.pureComputed(function () {
        var importEntity = self.importEntity();

        if (importEntity) {
            var importStatistics = importEntity.importStatistics();
            return _.map(importStatistics, function (importStatistic) {
                return new ImportStatistic(importStatistic);
            });
        }
    });
}

ViewModel.prototype.returnToStatusPage = function() {
    this.importDetailsVisible(false);
};

ViewModel.prototype.dispose = function() {
};

export default { viewModel: ViewModel, template: template };
