import net from "App/net";
import events from "App/events";

var Client = function() {

};

Client.prototype.getAll = function() {
    return net.get("/VesselGroups");
};

Client.prototype.create = function(vesselGroup) {
    return net.post("/VesselGroups", vesselGroup).done(function(data) {
        events.vesselGroupCreated.dispatch(data);
    });
};

Client.prototype.update = function (vesselGroup) {
    return net.put("/VesselGroups/" + vesselGroup.id, vesselGroup).done(function(data) {
        events.vesselGroupUpdated.dispatch(data);
    });
};

Client.prototype.remove = function(vesselGroup) {
    return net.del("/VesselGroups/" + vesselGroup.id).done(function() {
        events.vesselGroupDeleted.dispatch(vesselGroup);
    });
};

Client.prototype.addVesselToGroup = function(vessel, vesselGroup) {
    var cmd = {
        vesselId: vessel.id,
        vesselGroupId: vesselGroup.id,
    };
    return net.post("/VesselGroups/AddVessel", cmd).done(function(data) {
        events.vesselGroupUpdated.dispatch(data);
    });
};

Client.prototype.removeVesselFromGroup = function(vessel, vesselGroup) {
    var cmd = {
        vesselId: vessel.id,
        vesselGroupId: vesselGroup.id,
    };
    return net.post("/VesselGroups/RemoveVessel", cmd).done(function(data) {
        events.vesselGroupUpdated.dispatch(data);
    });
};
export default new Client();