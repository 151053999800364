import net from "App/net";

var ReportsClient = function () {

};

ReportsClient.prototype.getAll = function (vesselId) {
    var filter = {
        vesselId: vesselId,
    };

    return net.get("/Reports", filter);
};

ReportsClient.prototype.getReportUrl = function (reportId) {
    var filter = {
        onlyUrl: true,
    };

    return net.get("/Reports/" + reportId, filter);
};

ReportsClient.prototype.downloadReport = function (reportId) {
    var url = "/Reports/" + reportId;
    return net.fileDownload(url, "GET");
};

export default new ReportsClient();