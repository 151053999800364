import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import performanceStatusWidgetClient from "Clients/performance-status-widget-client";
import { PerformanceStatusConfig } from "@/types/widgetPerformanceStatus";


@Module({ namespaced: true, name: "PerformanceStatusWidget" })
class PerformanceStatusWidget extends VuexModule {
  private _configsExpired = true;
  private _configs: PerformanceStatusConfig[] = [];


  @Mutation
  public SET_WIDGET_CONFIGS(configs: PerformanceStatusConfig[]): void {
    this._configs = configs;
    this._configsExpired = false;
  }

  @Mutation
  public SET_WIDGET_CONFIG(config: PerformanceStatusConfig): void {
    const index = this._configs.findIndex(savedConfig =>  savedConfig.id === config.id);

    if (index >= 0) {
      this._configs[index] = config;
      this._configsExpired = true;
    }
  }

  @Mutation
  public ADD_WIDGET_CONFIG(config: PerformanceStatusConfig): void {
    this._configs.push(config);
    this._configsExpired = true;
  }

  @Mutation
  public DELETE_WIDGET_CONFIG(id: number): void {
    const index = this._configs.findIndex(config => config.id === id);

    if (index >= 0) {
      this._configs.splice(index, 1);
      this._configsExpired = true;
    }
  }

  @Action({ rawError: true })
  public async refreshConfigs(): Promise<void> {
    try {
      const configs: PerformanceStatusConfig[] = await performanceStatusWidgetClient.getAll();
      this.context.commit("SET_WIDGET_CONFIGS", configs);
    } catch (error) {
      throw ({ message: "Failed to refresh widget config", error });
    }
  }

  @Action({ rawError: true })
  public async updateConfig(config: PerformanceStatusConfig): Promise<void> {
    try {
      await performanceStatusWidgetClient.update(config);
      this.context.commit("SET_WIDGET_CONFIG", config);
    } catch (error) {
      throw ({ message: "Failed to update widget config", error });
    }
  }

  @Action({ rawError: true })
  public async addNewConfig(): Promise<PerformanceStatusConfig> {
    const emptyConfig = { vessels: [] };

    try {
      const createdConfig = await performanceStatusWidgetClient.new(emptyConfig);
      this.context.commit("ADD_WIDGET_CONFIG", createdConfig);
      return createdConfig;
    } catch (error) {
      throw ({ message: "Failed to add widget config", error });
    }
  }

  @Action({ rawError: true })
  public async deleteWidgetConfig(id: number): Promise<void> {
    try {
      await performanceStatusWidgetClient.delete(id);
      this.context.commit("DELETE_WIDGET_CONFIG", id);
    } catch (error) {
      throw ({ message: "Failed to delete widget config", error });
    }
  }

  @Action({ rawError: true })
  public async getConfigById(id: number): Promise<PerformanceStatusConfig> {
    if (this._configsExpired) {
      await this.context.dispatch("refreshConfigs");
    }

    const config = this._configs.find(config =>  config.id === id);

    if (config) {
      for (let i = 0; i < config.vessels.length; i++) {
        const vessel = await this.context.dispatch("Vessels/get", config.vessels[i].id, { root: true });
        if (vessel) config.vessels[i] = vessel;
      }

      return config;
    }

    const sharedConfig: PerformanceStatusConfig = await performanceStatusWidgetClient.get(id);
    if (sharedConfig) {
      for (let i = 0; i < sharedConfig.vessels.length; i++) {
        sharedConfig.vessels[i] = await this.context.dispatch("Vessels/get", sharedConfig.vessels[i].id, { root: true });
      }

      return sharedConfig;
    }

    else throw ({ message: "Could not find config" });
  }

  get configs(): PerformanceStatusConfig[] {
    return this._configs;
  }

  get configsExpired(): boolean {
    return this._configsExpired;
  }
}

export default PerformanceStatusWidget;
