import net from "App/net";
import events from "App/events";

var Client = function() {

};

Client.prototype.find = function(vesselEventId) {
    var filter = {
        vesselEventId: vesselEventId,
    };

    return net.get("/FlowMeterFuelConsumptions/Find", filter);
};

Client.prototype.create = function(voyageFuelConsumption) {
    return net.post("/FlowMeterFuelConsumptions", voyageFuelConsumption);
};

Client.prototype.update = function(voyageFuelConsumption) {
    return net.put("/FlowMeterFuelConsumptions/" + voyageFuelConsumption.id, voyageFuelConsumption);
};

Client.prototype.remove = function(voyageFuelConsumption) {
    return net.del("/FlowMeterFuelConsumptions/" + voyageFuelConsumption.id).done(function() {
        events.flowMeterFuelConsumptionDeleted.dispatch(voyageFuelConsumption);
    });
};

export default new Client();