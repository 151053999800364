




















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class DefaultContainer extends Vue {
  @Prop({ type: String, default: "1300px" }) readonly maxWidth!: string;
  @Prop({ type: String, default: "align-self-start" }) readonly cardClass!: string;
  @Prop({ type: String }) readonly containerClass!: string;
}
