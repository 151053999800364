import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Channel } from "@/types/condition";
import ChannelsClient from "Clients/notification-channels-client";


@Module({ namespaced: true, name: "Channels" })
class Channels extends VuexModule {
  private _channels: Channel[] = [];

  @Mutation
  public SET_CHANNELS(channels: Channel[]): void {
    this._channels = channels;
  }

  @Mutation
  public ADD_CHANNEL(channel: Channel): void {
    this._channels.push(channel);
  }

  @Mutation
  public DELETE_CHANNEL(channel: Channel): void {
    const chnlIndex = this._channels.findIndex(chnl => channel.email === chnl.email);
    if (chnlIndex !== -1) this._channels.splice(chnlIndex, 1);
  }


  @Action({ rawError: true })
  public async fetchChannels(): Promise<void> {
    try {
      const channels = await ChannelsClient.getAll();
      this.context.commit("SET_CHANNELS", channels);
    } catch (error) {
      throw ({ message: "Failed to fetch channels", error });
    }
  }

  @Action({ rawError: true })
  public async deleteChannel(id: number): Promise<void> {
    try {
      await ChannelsClient.remove(id);
    } catch (error) {
      throw ({ message: "Failed to delete channel", error });
    }
  }

  get channels(): Channel[] {
    return this._channels;
  }
  get emailChannels(): Channel[] {
    return this._channels.filter(ch => ch.email && ch.userId === null);
  }
  public get getChannelByEmail() {
    return (email: string | null): Channel | undefined => {
      return this._channels.find(chnl => chnl.email === email);
    };
  }
}

export default Channels;
