import stringHelper from "Utilities/string-helper";

function formatValueWithCaption(logVariable, value) {
    var precision = logVariable != null ? logVariable.displayPrecision : 5;

    var unit = logVariable != null ? logVariable.unit : null;
    return addCaption(value, precision, unit);
}

function formatMapLocation(latitude, longitude) {
    if (latitude < -90 || latitude > 90) {
        latitude = 0;
    }

    if (longitude < -180 || longitude > 180) {
        longitude = 0;
    }

    return latitude.toFixed(5) + "," + longitude.toFixed(5);
}

function addCaption(value, displayPrecision, unit) {
    var unitCaption = "";

    if (unit != null) {
        unitCaption = unit.caption != null ? unit.caption : "";
    }

    return value.toFixed(displayPrecision) + " " + unitCaption;
}

function formatLogVariableDisplayName(logVariable, duplicateLogVariableNames) {
    var dupLogVariableNamesArray = [];
    if (Array.isArray(duplicateLogVariableNames)) {
      dupLogVariableNamesArray = duplicateLogVariableNames;
    }
    if (typeof duplicateLogVariableNames === "function") {
      // Support for knockout
      dupLogVariableNamesArray = duplicateLogVariableNames();
    }
    var name = logVariable.name;
    if (logVariable.onboardSystem && dupLogVariableNamesArray.some(x => x == name)) {
        name += ` (${logVariable.onboardSystem})`;
    }
    return name;
}

function getDuplicateLogVariableNames(logVariables) {
    var logVariablesArray = [];
    if (Array.isArray(logVariablesArray)) {
      logVariablesArray = logVariables;
    }
    if (typeof logVariables === "function") {
      // Support for knockout
      logVariablesArray = logVariables();
    }
    if (!logVariablesArray) {
        return [];
    }

    var countOfNames = logVariablesArray.reduce((acc, curr, idx, arr) => {
        if (!acc[curr.name]) {
            acc[curr.name] = 0;
        }
        acc[curr.name]++;
        return acc;
    }, {});
    return Object.entries(countOfNames).filter(([key, value]) => value > 1).map(([key, value]) => key);
}

export default {
    formatValueWithCaption: function (logVariable, value) {
        return formatValueWithCaption(logVariable, value);
    },
    formatMapLocation: function (latitude, longitude) {
        return formatMapLocation(latitude, longitude);
    },
    formatLogVariableDisplayName: function (logVariable, duplicateLogVariableNames) {
        return formatLogVariableDisplayName(logVariable, duplicateLogVariableNames);
    },
    getDuplicateLogVariableNames: function (logVariables) {
        return getDuplicateLogVariableNames(logVariables);
    }
};
