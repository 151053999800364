import net from "App/net";
import events from "App/events";
import Store from "@/store";

var CurvesClient = function() {

};

CurvesClient.prototype.find = function(vesselId) {
    var url = Store.getters["User/isKognifai"] ? "/Kognifai/Curves/Find" : "/Curves/Find";
    var filter = {
        vesselId: vesselId,
    };

    return net.get(url, filter);
};

CurvesClient.prototype.create = function(curve) {
    var url = Store.getters["User/isKognifai"] ? "/Kognifai/Curves" : "/Curves";
    return net.post(url, curve).done(function(data) {
        events.curveCreated.dispatch(data);
    });
};

CurvesClient.prototype.update = function(curve) {
    var url = Store.getters["User/isKognifai"] ? "/Kognifai/Curves/" : "/Curves/";

    return net.put(url + curve.id, curve).done(function(data) {
        events.curveUpdated.dispatch(data);
    });
};

CurvesClient.prototype.remove = function(curve) {
    var url = Store.getters["User/isKognifai"] ? "/Kognifai/Curves/" : "/Curves/";

    return net.del(url + curve.id).done(function() {
        events.curveDeleted.dispatch(curve);
    });
};

CurvesClient.prototype.downloadExcelReport = function(curvePlotResult) {
    return net.fileDownload("/Curves/Report", "POST", curvePlotResult);
};

export default new CurvesClient();
