import net from "App/net";

var LongTrendsClient = function () {

};

LongTrendsClient.prototype.find = function (longTrendId) {
    var filter = {
        longTrendId: longTrendId,
    };

    return net.get("/LongTrendPeriods/Find", filter);
};

LongTrendsClient.prototype.findMany = function (longTrendIds) {
    var filter = {
        longTrendIds: longTrendIds,
    };

    return net.get("/LongTrendPeriods/Find", filter);
};

LongTrendsClient.prototype.meta = function(query) {
  //query needs { startDate, endDate, vesselId, longTrendId }
  return net.get("/LongTrendPeriods/Meta", query);
};

LongTrendsClient.prototype.longTrendPeriods = function(query) {
  return net.get("/LongTrendPeriods", query);
};

LongTrendsClient.prototype.statistics = function(query) {
  return net.get("/LongTrendPeriods/Statistics", query);
};
LongTrendsClient.prototype.trendLimits = function(id) {
  return net.get("/TrendLimits", {vesselId: id});
};

export default new LongTrendsClient();
