import net from "App/net";

var ISO19030Client = function() {};

//  Events
ISO19030Client.prototype.All = function() {
  return net.get("/Iso19030Events");
};

ISO19030Client.prototype.AllByVesselId = function(vesselId) {
  return net.get(`/Iso19030Events/Vessel/${vesselId}`);
};

ISO19030Client.prototype.EventById = function(eventId) {
  return net.get(`/Iso19030Events/${eventId}`);
};

ISO19030Client.prototype.CreateEvent = function(eventModel) {
  return net.post("/Iso19030Events", eventModel);
};

ISO19030Client.prototype.UpdateEvent = function(eventModel) {
  return net.patch(`/Iso19030Events/${eventModel.id}`, eventModel);
};

ISO19030Client.prototype.DeleteEvent = function(eventId) {
  return net.del(`/Iso19030Events/${eventId}`);
};
//  PerformanceIndicators
ISO19030Client.prototype.PerformanceIndicators = function(vesselId) {
  return net.get(`/Iso19030PerformanceIndicators/${vesselId}`);
};

export default new ISO19030Client();
