












































import { Component, Vue } from "vue-property-decorator";

@Component
export default class ConfirmDialog extends Vue {
  dialog = false;
  resolve!: any;
  reject!: any;
  message: string | null = null;
  title: string | null = null;
  options = {
    color: "accentDarker110",
    width: 400,
    zIndex: 200,
    noconfirm: false,
  };

  open(title: string, message: string, options: unknown): Promise<unknown> {
    this.dialog = true;
    this.title = title;
    this.message = message;
    this.options = Object.assign(this.options, options);
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  confirm(): void {
    this.resolve(true);
    this.dialog = false;
  }

  cancel(): void {
    this.resolve(false);
    this.dialog = false;
  }
}
