import template from "./voyage.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import VoyageVesselEvent from "ViewModels/emission-report/voyageVesselEvent";
import configEmissionReportFactory from "App/config-emission-report";
import events from "App/events";
import emissionReportClient from "Clients/emission-report-client";
import emissionReportVesselEventsClient from "Clients/emission-report-vessel-events-client";

function ViewModel(params) {
    var self = this;
    self.configEmissionReport = configEmissionReportFactory.get();
    self.vessel = params.vessel;
    self.gpsSpeedLogVariable = params.gpsSpeedLogVariable;
    self.departure = ko.observable(params.departure);
    self.vesselEvents = params.allSortedVesselEvents;

    self.fuelTanks = _.values(self.configEmissionReport.fuelTanksMap());

    self.isConfirmingVoyageInformation = ko.observable(false);
    self.isUnconfirmingVoyageInformation = ko.observable(false);
    self.isConfirmingVoyageData = ko.observable(false);
    self.isUnconfirmingVoyageData = ko.observable(false);
    self.isSendingEmissionReportToDnvGl = ko.observable(false);

    self.departureTimestampMoment = ko.pureComputed(function() {
        var departure = self.departure();
        return moment.utc(departure.timestamp);
    });

    self.isVoyageInformationConfirmed = ko.pureComputed(function() {
        var departure = self.departure();
        return departure.voyage && departure.voyage.informationConfirmedAt;
    });

    self.isVoyageDataConfirmed = ko.pureComputed(function() {
        var departure = self.departure();
        return departure.voyage && departure.voyage.dataConfirmedAt;
    });

    self.hasVeracityKey = ko.pureComputed(function() {
        var vessel = self.vessel();
        return vessel.hasVeracityKey;
    });

    self.hasEsgFeature = ko.pureComputed(function() {
        var vessel = self.vessel();
        return vessel.hasEsgFeature;
    });

    self.voyageVesselEvents = ko.observableArray([]);

    var initVoyageVesselEvents = function(vesselEvents) {
        var departure = self.departure();
        var departureTimestampMoment = self.departureTimestampMoment();
        var voyageVesselEvents = [];
        var isEndOfVoyage = false;

        _.each(vesselEvents,
            function(vesselEvent) {
                var timestampMoment = moment.utc(vesselEvent.timestamp);
                if (!isEndOfVoyage && departureTimestampMoment <= timestampMoment) {
                    var isStartOfVoyage = vesselEvent.id === departure.id;
                    isEndOfVoyage = !isStartOfVoyage && (vesselEvent.type === "Departure");

                    voyageVesselEvents.push(new VoyageVesselEvent(self.vessel,
                        vesselEvent,
                        self.vesselEvents,
                        isStartOfVoyage,
                        isEndOfVoyage,
                        self.isVoyageInformationConfirmed,
                        self.isVoyageDataConfirmed));
                }
            });
        self.voyageVesselEvents(voyageVesselEvents);
    };

    initVoyageVesselEvents(self.vesselEvents());

    self.vesselEventCreatedBinding = events.vesselEventCreated.add(function(createdVesselEvent) {
        var isEndOfVoyage = createdVesselEvent.type === "Departure";

        var newVoyageVesselEvent = new VoyageVesselEvent(self.vessel,
            createdVesselEvent,
            self.vesselEvents,
            false,
            isEndOfVoyage,
            self.isVoyageInformationConfirmed,
            self.isVoyageDataConfirmed);
        self.voyageVesselEvents.push(newVoyageVesselEvent);
        events.vesselEventSelected.dispatch(createdVesselEvent);
    });

    self.vesselEventDeletedBinding = events.vesselEventDeleted.add(function(deletedVesselEvent) {
        var selectedVoyageVesselEvent = self.selectedVoyageVesselEvent();
        if (selectedVoyageVesselEvent && selectedVoyageVesselEvent.id === deletedVesselEvent.id) {
            events.vesselEventSelected.dispatch(self.departure());
        }

        var voyageVesselEvents = self.voyageVesselEvents();
        var deletedVoyageVesselEvent = _.find(voyageVesselEvents,
            function(voyageVesselEvent) {
                return voyageVesselEvent.id === deletedVesselEvent.id;
            });
        deletedVoyageVesselEvent.dispose();
        self.voyageVesselEvents.remove(deletedVoyageVesselEvent);
    });

    self.isLoadingVoyageVesselEvents = ko.observable(false);
    var loadUpdatedVoyageVesselEvents = function() {
        self.isLoadingVoyageVesselEvents(true);
        emissionReportVesselEventsClient.findVoyageVesselEvents(self.configEmissionReport.currentVessel.id, self.departure().id)
            .done(function(vesselEvents) {
                _.each(vesselEvents,
                    function(vesselEvent) {
                        events.vesselEventUpdated.dispatch(vesselEvent);
                    });
            })
            .always(function() {
                self.isLoadingVoyageVesselEvents(false);
            });
    };

    self.isVoyageInformationConfirmedBinding = self.isVoyageInformationConfirmed.subscribe(function(isVoyageInformationConfirmed) {
        loadUpdatedVoyageVesselEvents();
    });

    self.vesselEventUpdatedBinding = events.vesselEventUpdated.add(function(updatedVesselEvent) {
        var departure = self.departure();
        if (departure.id === updatedVesselEvent.id) {
            self.departure(updatedVesselEvent);
        }
    });

    self.isVoyageVesselEventsConfirmed = ko.pureComputed(function() {
        var voyageVesselEvents = self.voyageVesselEvents();
        return _.every(voyageVesselEvents,
            function(voyageVesselEvent) {
                return voyageVesselEvent.isConfirmed();
            });
    });

    self.endOfVoyageVesselEvent = ko.pureComputed(function() {
        var voyageVesselEvents = self.voyageVesselEvents();
        return _.find(voyageVesselEvents,
            function(voyageVesselEvent) {
                return voyageVesselEvent.isEndOfVoyage;
            });
    });

    self.arrivalVoyageVesselEvent = ko.pureComputed(function() {
        var voyageVesselEvents = self.voyageVesselEvents();
        return _.find(voyageVesselEvents,
            function(voyageVesselEvent) {
                return voyageVesselEvent.vesselEvent().type === "Arrival";
            });
    });

    self.departureVoyageVesselEvent = ko.pureComputed(function() {
        var voyageVesselEvents = self.voyageVesselEvents();
        var departure = self.departure();
        return _.find(voyageVesselEvents,
            function(voyageVesselEvent) {
                return voyageVesselEvent.id === departure.id;
            });
    });

    self.selectedVoyageVesselEvent = ko.observable(self.departureVoyageVesselEvent());
    self.vesselEventSelectedBinding = events.vesselEventSelected.add(function(vesselEvent) {
        var voyageVesselEvents = self.voyageVesselEvents();
        var selectedVoyageVesselEvent = _.find(voyageVesselEvents,
            function(voyageVesselEvent) {
                return voyageVesselEvent.id === vesselEvent.id;
            });
        self.selectedVoyageVesselEvent(selectedVoyageVesselEvent);
    });

    self.voyageVesselEventsByTimestampDescending = ko.pureComputed(function() {
        var voyageVesselEvents = self.voyageVesselEvents();
        var sorted = _.sortBy(voyageVesselEvents,
            function(voyageVesselEvent) {
                return voyageVesselEvent.vesselEvent().timestamp;
            });
        return sorted.reverse();
    });

    self.vesselEventTypesCount = ko.pureComputed(function() {
        var voyageVesselEvents = self.voyageVesselEventsByTimestampDescending();

        var groupedByType = _.groupBy(voyageVesselEvents,
            function(voyageVesselEvent) {
                return voyageVesselEvent.vesselEvent().type;
            });

        var vesselEventTypesCount = {
            "Departure": 0,
            "Arrival": 0,
            "StartAnchoring": 0,
            "EndAnchoring": 0,
            "StartDrifting": 0,
            "EndDrifting": 0,
            "StartBunkering": 0,
            "EndBunkering": 0,
        };
        _.each(groupedByType,
            function(item, key) {
                vesselEventTypesCount[key] = item.length;
            });
        return vesselEventTypesCount;
    });

    self.requireEndBunkering = ko.pureComputed(function() {
        var vesselEventTypesCount = self.vesselEventTypesCount();
        return vesselEventTypesCount["StartBunkering"] > vesselEventTypesCount["EndBunkering"];
    });

    self.requireEndAnchoring = ko.pureComputed(function() {
        var vesselEventTypesCount = self.vesselEventTypesCount();
        return vesselEventTypesCount["StartAnchoring"] > vesselEventTypesCount["EndAnchoring"];
    });

    self.requireEndDrifting = ko.pureComputed(function() {
        var vesselEventTypesCount = self.vesselEventTypesCount();
        return vesselEventTypesCount["StartDrifting"] > vesselEventTypesCount["EndDrifting"];
    });

    self.requireStartBunkering = ko.pureComputed(function() {
        var vesselEventTypesCount = self.vesselEventTypesCount();
        return vesselEventTypesCount["EndBunkering"] > vesselEventTypesCount["StartBunkering"];
    });

    self.requireStartAnchoring = ko.pureComputed(function() {
        var vesselEventTypesCount = self.vesselEventTypesCount();
        return vesselEventTypesCount["EndAnchoring"] > vesselEventTypesCount["StartAnchoring"];
    });

    self.requireStartDrifting = ko.pureComputed(function() {
        var vesselEventTypesCount = self.vesselEventTypesCount();
        return vesselEventTypesCount["EndDrifting"] > vesselEventTypesCount["StartDrifting"];
    });

    self.canConfirmVoyageInformation = ko.pureComputed(function() {
        var isVoyageVesselEventsConfirmed = self.isVoyageVesselEventsConfirmed();
        var departureVoyageVesselEvent = self.departureVoyageVesselEvent();
        var endOfVoyageVesselEvent = self.endOfVoyageVesselEvent();
        var arrivalVoyageVesselEvent = self.arrivalVoyageVesselEvent();

        var requireEndBunkering = self.requireEndBunkering();
        var requireEndAnchoring = self.requireEndAnchoring();
        var requireEndDrifting = self.requireEndDrifting();

        var departureHasCompleteInformation = departureVoyageVesselEvent.hasCompleteInformation();

        return isVoyageVesselEventsConfirmed &&
            endOfVoyageVesselEvent &&
            arrivalVoyageVesselEvent &&
            departureHasCompleteInformation &&
            !requireEndBunkering &&
            !requireEndAnchoring &&
            !requireEndDrifting;
    });

    self.canConfirmVoyageData = ko.pureComputed(function() {
        var isVoyageInformationConfirmed = self.isVoyageInformationConfirmed();
        var isVoyageDataConfirmed = self.isVoyageDataConfirmed();
        var voyageVesselEvents = self.voyageVesselEvents();
        var departure = self.departure();

        if (!isVoyageInformationConfirmed || isVoyageDataConfirmed) return false;
        if (voyageVesselEvents.length === 1) return false;

        var hasMissingFlowMeterFuelConsumptionOrDistance = _.find(voyageVesselEvents,
            function(voyageVesselEvent) {
                if (voyageVesselEvent.id === departure.id) return false;
                var hasMissingFlowMeterFuelConsumption = voyageVesselEvent.voyageVesselEventData.hasMissingFlowMeterFuelConsumption();
                var hasMissingDistance = voyageVesselEvent.voyageVesselEventData.hasMissingDistance();

                return hasMissingFlowMeterFuelConsumption || hasMissingDistance;
            });
        return !hasMissingFlowMeterFuelConsumptionOrDistance;
    });

    self.isMrvVoyage = ko.pureComputed(function() {
        var arrivalVoyageVesselEvent = self.arrivalVoyageVesselEvent();
        var departureVoyageVesselEvent = self.departureVoyageVesselEvent();

        return arrivalVoyageVesselEvent.vesselEvent().port.isMrvPort || departureVoyageVesselEvent.vesselEvent().port.isMrvPort;
    });

    self.downloadEmissionReportUrl = ko.pureComputed(function() {
        var departure = self.departure();
        return self.configEmissionReport.baseUrl + "EmissionReport/MrvVoyage?departureId=" + departure.id;
    });

    self.downloadImoEmissionReportUrl = ko.pureComputed(function() {
        var departure = self.departure();
        return self.configEmissionReport.baseUrl + "EmissionReport/ImoVoyage?departureId=" + departure.id;
    });

    self.downloadDnvGlEmissionReportUrl  = ko.pureComputed(function() {
        var departure = self.departure();
        return self.configEmissionReport.baseUrl + "EmissionReport/VoyageDnvGl?departureId=" + departure.id;
    });

    self.tabs = ko.pureComputed(function() {
        var selectedVoyageVesselEvent = self.selectedVoyageVesselEvent();
        if (!selectedVoyageVesselEvent) return [];

        var isVoyageInformationConfirmed = self.isVoyageInformationConfirmed();
        var isLoadingVoyageVesselEvents = self.isLoadingVoyageVesselEvents();
        var isStartOfVoyage = selectedVoyageVesselEvent.isStartOfVoyage;

        var tabs = ["Event info"];

        var showDistanceAndFuelTabs = isVoyageInformationConfirmed && !isLoadingVoyageVesselEvents && !isStartOfVoyage;
        if (showDistanceAndFuelTabs) {
            tabs.push("Distance");

            var activeOrInUseFlowMeters = selectedVoyageVesselEvent.activeOrInUseFlowMeters();
            var activeFuelTanks = selectedVoyageVesselEvent.activeFuelTanks();

            if (activeOrInUseFlowMeters.length > 0) {
                tabs.push("Flow meters");
            }

            if (activeFuelTanks.length > 0) {
                tabs.push("Fuel tanks");
            }
        }

        return tabs;
    });

    var getNewSelectedTab = function(selectedVoyageVesselEvent, previousSelectedTab) {
        var isVoyageInformationConfirmed = self.isVoyageInformationConfirmed();
        var isLoadingVoyageVesselEvents = self.isLoadingVoyageVesselEvents();
        var tabs = self.tabs();
        if (!selectedVoyageVesselEvent || selectedVoyageVesselEvent.isStartOfVoyage || !isVoyageInformationConfirmed || isLoadingVoyageVesselEvents) {
            return tabs[0];
        }
        var hasMissingDistance = selectedVoyageVesselEvent.voyageVesselEventData.hasMissingDistance();
        if (hasMissingDistance) {
            return "Distance";
        }

        var hasMissingFlowMeterFuelConsumption = selectedVoyageVesselEvent.voyageVesselEventData.hasMissingFlowMeterFuelConsumption();
        if (hasMissingFlowMeterFuelConsumption) {
            return "Flow meters";
        }

        return previousSelectedTab;
    };
    var initialSelectedTab = getNewSelectedTab(self.selectedVoyageVesselEvent());
    self.selectedTab = ko.observable(initialSelectedTab);

    self.tabsBinding = self.tabs.subscribe(function() {
        var previousSelectedTab = self.selectedTab();
        var selectedVoyageVesselEvent = self.selectedVoyageVesselEvent();
        var newSelectedTab = getNewSelectedTab(selectedVoyageVesselEvent, previousSelectedTab);
        self.selectedTab(newSelectedTab);
    });

    self.selectTab = function(tab) {
        self.selectedTab(tab);
    }.bind(self);
}

ViewModel.prototype.dispose = function() {
    this.vesselEventUpdatedBinding.detach();
    this.vesselEventSelectedBinding.detach();
    this.vesselEventCreatedBinding.detach();
    this.vesselEventDeletedBinding.detach();
    this.isVoyageInformationConfirmedBinding.dispose();
    this.tabsBinding.dispose();

    _.each(this.voyageVesselEvents(),
        function(voyageVesselEvent) {
            voyageVesselEvent.dispose();
        });
};

ViewModel.prototype.toggleCollapsed = function() {
    this.isCollapsed(!this.isCollapsed());
};

ViewModel.prototype.toggleEditVoyageInformation = function() {
    this.isEditingVoyageInformation(!this.isEditingVoyageInformation());
};

ViewModel.prototype.confirmVoyageInformation = function() {
    var self = this;
    var departure = self.departure();

    self.isConfirmingVoyageInformation(true);
    emissionReportVesselEventsClient.confirmVoyageInformation(departure).done(function(data) {
        self.departure(data);
    }).always(function() {
        self.isConfirmingVoyageInformation(false);
    });
};

ViewModel.prototype.unconfirmVoyageInformation = function() {
    var self = this;
    var departure = self.departure();

    self.isUnconfirmingVoyageInformation(true);
    emissionReportVesselEventsClient.unconfirmVoyageInformation(departure).done(function(data) {
        self.departure(data);
    }).always(function() {
        self.isUnconfirmingVoyageInformation(false);
    });
};

ViewModel.prototype.confirmVoyageData = function() {
    var self = this;
    var departure = self.departure();

    self.isConfirmingVoyageData(true);
    emissionReportVesselEventsClient.confirmVoyageData(departure).done(function(data) {
        self.departure(data);
    }).always(function() {
        self.isConfirmingVoyageData(false);
    });
};

ViewModel.prototype.unconfirmVoyageData = function() {
    var self = this;
    var departure = self.departure();

    self.isUnconfirmingVoyageData(true);
    emissionReportVesselEventsClient.unconfirmVoyageData(departure).done(function(data) {
        self.departure(data);
    }).always(function() {
        self.isUnconfirmingVoyageData(false);
    });
};

ViewModel.prototype.downloadMrvVoyageReport = function () {
    var departure = this.departure();
    return emissionReportClient.mrvVoyage(departure.id);
};

ViewModel.prototype.downloadImoVoyageReport = function () {
    var departure = this.departure();
    return emissionReportClient.imoVoyage(departure.id);
};

ViewModel.prototype.downloadDnvGlVoyageReport = function () {
    var departure = this.departure();
    return emissionReportClient.voyageDnvGl(departure.id);
};

ViewModel.prototype.sendEmissionReportToDnvGl = function() {
    var self = this;
    var departure = self.departure();

    self.isSendingEmissionReportToDnvGl(true);
    emissionReportVesselEventsClient.sendEmissionReportToDnvGl(departure).always(function() {
        self.isSendingEmissionReportToDnvGl(false);
    });
};

export default { viewModel: ViewModel, template: template };