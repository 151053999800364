import locationHelper from "Utilities/location-helper";
import logVariableHelper from "Utilities/log-variable-helper";
import { LogVariable } from "@/types/logVariable";

function prettyLatitude(latitude: number): string {
  return latitude !== null ? locationHelper.formatLatLongValue(latitude, "latitude") : "N/A";
}

function prettyLongitude(longitude: number): string {
  return longitude !== null ? locationHelper.formatLatLongValue(longitude, "longitude") : "N/A";
}

function prettyLogVariable(value: number, logVariable: LogVariable): string {
  return value !== null ? logVariableHelper.formatValueWithCaption(logVariable, value) : "N/A";
}


export { prettyLatitude, prettyLongitude, prettyLogVariable };
