import template from "./vessel-event-icon.html";
import ko from "knockout";
import vesselEventHelper from "Utilities/vessel-event-helper";
import events from "App/events";

function ViewModel(params) {
    var self = this;
    var type = ko.utils.unwrapObservable(params.type);
    self.name = ko.utils.unwrapObservable(params.name);
    self.showText = ko.utils.unwrapObservable(params.showText);
    self.typeText = vesselEventHelper.getVesselEventTypeText(type);
    self.typeImageSrc = vesselEventHelper.getVesselEventTypeImageSrc(type);
}

ViewModel.prototype.dispose = function() {
};

ViewModel.prototype.showNameToopltip = function(vm, e) {
    if (!this.name) return;
    var element = e.currentTarget;
    events.showTooltip.dispatch("tooltip-text", { text: this.name }, element);
};

ViewModel.prototype.closeTooltip = function() {
    if (!this.name) return;
    events.closeTooltip.dispatch();
};

export default { viewModel: ViewModel, template: template };