import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import foulingOverviewWidgetClient from "Clients/fouling-overview-widget-client";
import FoulingClient from "Clients/fouling-client";
import { FoulingOverviewWidgetConfig } from "@/types/widgetFoulingOverview";
import { HullCoating } from "@/types/HullCoating";
import { SpeedLossStatistic } from "@/types/SpeedLossStatistic";
import { FoulingAddedConsumption } from "@/types/FoulingAddedConsumption";
import { FoulingChartConfig } from "@/types/FoulingChartConfig";

@Module({ namespaced: true, name: "FoulingOverviewWidgetConfiguration" })
class FoulingOverviewWidget extends VuexModule {
  private _configsExpired = true;
  private _configs: FoulingOverviewWidgetConfig[] = [];
  private _hullCoatingVessels: Record<number, HullCoating> = [];
  private _latestSpeedLossStatisticsForVessels: Record<number, SpeedLossStatistic> = {};
  private _addedFuelConsumptionForVessels: Record<number, FoulingAddedConsumption[]> = {};
  private _foulingConfigs: Record<number, FoulingChartConfig> = [];

  public get hullCoatingVessels(): Record<number, HullCoating> {
    return this._hullCoatingVessels;
  }

  public get latestSpeedLossStatisticsForVessels(): Record<number, SpeedLossStatistic> {
    return this._latestSpeedLossStatisticsForVessels;
  }

  public get addedFuelConsumptionForVessels(): Record<number, FoulingAddedConsumption[]> {
    return this._addedFuelConsumptionForVessels;
  }

  public get foulingConfigs(): Record<number, FoulingChartConfig> {
    return this._foulingConfigs;
  }

  @Action({ rawError: true })
  public async addNewConfig(): Promise<FoulingOverviewWidgetConfig> {
    try {
      const config = await foulingOverviewWidgetClient.new({ vessels: [] });
      this.context.commit("ADD_WIDGET_CONFIG", config);
      return config;
    } catch (error) {
      throw { message: "Failed to add widget config", error };
    }
  }

  @Action({ rawError: true })
  public async updateConfig(
    config: FoulingOverviewWidgetConfig | undefined
  ): Promise<void> {
    if (!config) return;
    try {
      const updatedConfig = await foulingOverviewWidgetClient.update(config);
      this.context.commit("SET_WIDGET_CONFIG", updatedConfig);
    } catch (error) {
      throw { message: "Failed to update widget config", error };
    }
  }

  @Action({ rawError: true })
  public async deleteWidgetConfig(id: number): Promise<void> {
    try {
      await foulingOverviewWidgetClient.delete(id);
      this.context.commit("DELETE_WIDGET_CONFIG", id);
    } catch (error) {
      throw { message: "Failed to delete widget config", error };
    }
  }

  @Action({ rawError: true })
  public async getConfigById(id: number): Promise<FoulingOverviewWidgetConfig> {
    if (this._configsExpired) {
      await this.context.dispatch("refreshConfigs");
    }

    const config = this._configs.find((config) => config.id === id);

    if (config) {
      for (let i = 0; i < config.vessels.length; i++) {
        const vessel = await this.context.dispatch(
          "Vessels/get",
          config.vessels[i].id,
          { root: true }
        );
        if (vessel) config.vessels[i] = vessel;
      }

      return config;
    } else throw { message: "Could not find config" };
  }

  @Action({ rawError: true })
  public async refreshConfigs(): Promise<void> {
    try {
      const configs: FoulingOverviewWidgetConfig[] =
        await foulingOverviewWidgetClient.getAll();
      this.context.commit("SET_WIDGET_CONFIGS", configs);
    } catch (error) {
      throw { message: "Failed to refresh widget config", error };
    }
  }

  @Action({ rawError: true })
  public async fetchHullCoatingForVessels(vesselIds: number[]): Promise<Record<number, HullCoating>> {
    try {
      const result: HullCoating[] = await FoulingClient.hullCoatingVessels(vesselIds);
      const data: Record<number, HullCoating> = vesselIds.map((vesselId) => (
        {
          [vesselId]: result.filter((item) => item.vesselId === vesselId)[0]
        })).reduce((acc, val) => ({ ...acc, ...val }), {});
      this.context.commit("SET_HULL_COATING_FOR_VESSELS", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch Hull Coatings", error });
    }
  }

  @Action({ rawError: true })
  public async fetchLatestSpeedLossStatisticsForVessels(vesselIds: number[]): Promise<Record<number, SpeedLossStatistic>> {
    try {
      const data: Record<number, SpeedLossStatistic> = await FoulingClient.getLatestSpeedLossStatistics(vesselIds);
      this.context.commit("SET_LATEST_SPEED_LOSS_STATISTICS_FOR_VESSELS", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch Latest Speed Loss Statistics", error });
    }
  }

  @Action({ rawError: true })
  public async fetchAddedFuelConsumptionForVessels(vesselIds: number[]): Promise<Record<number, FoulingAddedConsumption[]>> {
    try {
      const data: Record<number, FoulingAddedConsumption[]> = await FoulingClient.getAddedFuelConsumptions(vesselIds);
      this.context.commit("SET_ADDED_FUEL_CONSUMPTION_FOR_VESSELS", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch Added Fuel Consumption", error });
    }
  }

  @Action({ rawError: true })
  public async fetchFoulingConfigs(vesselIds: number[]): Promise<Record<number, FoulingChartConfig>> {
    try {
      const data: Record<number, FoulingChartConfig> = await FoulingClient.foulingChartConfigs(vesselIds);
      this.context.commit("SET_FOULING_CONFIGS", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to fetch Fouling configurations", error });
    }
  }

  @Mutation
  public ADD_WIDGET_CONFIG(config: FoulingOverviewWidgetConfig): void {
    this._configs.push(config);
    this._configsExpired = true;
  }

  @Mutation
  public SET_WIDGET_CONFIG(config: FoulingOverviewWidgetConfig): void {
    const index = this._configs.findIndex(
      (savedConfig) => savedConfig.id === config.id
    );

    if (index >= 0) {
      this._configs[index] = config;
      this._configsExpired = true;
    }
  }

  @Mutation
  public DELETE_WIDGET_CONFIG(id: number): void {
    const index = this._configs.findIndex((config) => config.id === id);

    if (index >= 0) {
      this._configs.splice(index, 1);
      this._configsExpired = true;
    }
  }

  @Mutation
  public SET_WIDGET_CONFIGS(configs: FoulingOverviewWidgetConfig[]): void {
    this._configs = configs;
    this._configsExpired = false;
  }

  @Mutation
  public SET_HULL_COATING_FOR_VESSELS(data: Record<number, HullCoating>): void {
    this._hullCoatingVessels = { ...this._hullCoatingVessels, ...data };
  }

  @Mutation
  public SET_LATEST_SPEED_LOSS_STATISTICS_FOR_VESSELS(data: Record<number, SpeedLossStatistic>): void {
    this._latestSpeedLossStatisticsForVessels = { ...this._latestSpeedLossStatisticsForVessels, ...data };
  }

  @Mutation
  public SET_ADDED_FUEL_CONSUMPTION_FOR_VESSELS(data: Record<number, FoulingAddedConsumption[]>): void {
    Object.keys(data).forEach(key => {
      const numberKey = Number(key);
      this._addedFuelConsumptionForVessels[numberKey] = data[numberKey];
    });
  }

  @Mutation
  public SET_FOULING_CONFIGS(data: Record<number, FoulingChartConfig>): void {
    this._foulingConfigs = { ...this._foulingConfigs, ...data };
  }
}

export default FoulingOverviewWidget;
