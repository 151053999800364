import template from "./curve-graph.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import highchartsHelper from "Utilities/highcharts-helper";
import curvePredictionHelper from "Utilities/curve-prediction-helper";

function ViewModel(params) {
    var self = this;

    self.selectedCurve = ko.utils.unwrapObservable(params.selectedCurve);
    self.availableVariables = ko.utils.unwrapObservable(params.availableVariables);
    self.curveDataPoints = ko.utils.unwrapObservable(params.curveDataPoints);
    self.referenceCurveDataPoints = params.referenceCurveDataPoints;
    self.graphSeries = params.graphSeries;
    self.chart = ko.observable();

    self.curveDataArray = params.curveDataArray || [];
    self.curveDataStatistics = params.curveDataStatistics;
    self.curveDataXMin = params.curveDataXMin;
    self.curveDataXMax = params.curveDataXMax;
    self.referenceCurveXMin = params.referenceCurveXMin;
    self.referenceCurveXMax = params.referenceCurveXMax;
    self.XMin = params.XMin;
    self.XMax = params.XMax;
    self.regressionOrder = params.regressionOrder;
    self.curveRegressionData = params.curveRegressionData;

    self.curveFit = ko.pureComputed(function() {
        if (self.selectedCurve) {
            return self.selectedCurve.curve().curveFit;
        } else if (params.curveFit) {
            return params.curveFit();
        }
    });

    self.forceTonDayUnit = ko.pureComputed(function() {
        var selectedCurve = self.selectedCurve;
        if (!selectedCurve) return false;

        var curve = selectedCurve.curve();
        if (!curve) return false;

        var curveMode = curve.curveMode;
        return curveMode === "TimeCharter" || curveMode === "SpotMarket";
    });

    self.xAxisLogVariableId = ko.pureComputed(function () {
        if (self.selectedCurve) {
            return self.selectedCurve.curve().xAxisLogVariableId;
        } else if (params.xAxisLogVariableId) {
            return params.xAxisLogVariableId();
        }
    });

    self.yAxisLogVariableId = ko.pureComputed(function () {
        if (self.selectedCurve) {
            return self.selectedCurve.curve().yAxisLogVariableId;
        } else if (params.yAxisLogVariableId) {
            return params.yAxisLogVariableId();
        }
    });


    self.xAxisVariable = ko.pureComputed(function () {
        var xAxisLogVariableId = self.xAxisLogVariableId();
        if (!xAxisLogVariableId || xAxisLogVariableId === null) {
            return;
        }
        var availableVariable = _.find(self.availableVariables, function (availableVariable) {
            if (availableVariable.variable) {
                return xAxisLogVariableId === availableVariable.variable.id;
            } else {
                return xAxisLogVariableId === availableVariable.id;
            }
        });

        if (!availableVariable) {
            return;
        }

        return availableVariable.variable ? availableVariable.variable : availableVariable;
    });

    self.yAxisVariable = ko.pureComputed(function () {
        var yAxisLogVariableId = self.yAxisLogVariableId();
        var availableVariable = _.find(self.availableVariables, function (availableVariable) {
            if (availableVariable.variable) {
                return yAxisLogVariableId === availableVariable.variable.id;
            } else {
                return yAxisLogVariableId === availableVariable.id;
            }
        });
        if (availableVariable) {
            return availableVariable.variable ? availableVariable.variable : availableVariable;
        }
        
    });

    self.chartInfo = ko.pureComputed(function() {
        var selectedCurve = self.selectedCurve ? self.selectedCurve.curve() : {};
        var xAxisVariable = self.xAxisVariable();
        var yAxisVariable = self.yAxisVariable();
        var curveFit = self.curveFit().toLowerCase();
        var curveDataPoints = self.curveDataPoints;
        var referenceCurveDataPoints = self.referenceCurveDataPoints();
        var order = self.regressionOrder();
        var series = [];

        var curveDataArray = self.curveDataArray();
        var curveDataXMin = self.curveDataXMin();
        var curveDataXMax = self.curveDataXMax();
        var referenceCurveXMin = self.referenceCurveXMin();
        var referenceCurveXMax = self.referenceCurveXMax();
        var XMin = self.XMin();
        var XMax = self.XMax();
        var curveRegressionData = self.curveRegressionData();
        var curveDataStatistics = self.curveDataStatistics();

        if (referenceCurveDataPoints.length > 1 && selectedCurve.curveMode !== "SpotMarket") {
            var referenceCurveRegressionData = curvePredictionHelper.getRegressionData(referenceCurveDataPoints, curveFit, order);
            var referenceCurveSerieData = curvePredictionHelper.getPredictionSerieData(referenceCurveRegressionData, referenceCurveXMin, referenceCurveXMax);
            var referenceCurveSerie = highchartsHelper.getRegressionSerie(referenceCurveSerieData,
                {
                    name: "Reference curve",
                    color: "rgba(223, 83, 83, .9)",
                    showInLegend: true,
                });
            series.push(referenceCurveSerie);

            if (XMin < referenceCurveXMin || XMax > referenceCurveXMax) {
                var extendedReferenceCurveSerieData = curvePredictionHelper.getPredictionSerieData(referenceCurveRegressionData, XMin, XMax);
                var extendedReferenceCurveSerie = highchartsHelper.getRegressionSerie(extendedReferenceCurveSerieData,
                    {
                        name: "Forecast (reference)",
                        showInLegend: true,
                        color: "rgba(223, 83, 83, .9)",
                        dashStyle: "dash",
                        lineWidth: 1,
                        zIndex: 0,
                        visible: false,
                    });
                series.push(extendedReferenceCurveSerie);
            }
        }

        if (curveDataPoints) {
            var partitionedForOutliers = _.partition(curveDataPoints,
                function(point) {
                    return curvePredictionHelper.isOutlier(point.y,
                        curveDataStatistics.mean,
                        curveDataStatistics.std,
                        curveDataStatistics.N);
                });

            var filteredDataPoints = partitionedForOutliers[1];
            var curveDataSerie = highchartsHelper.getScatterDataSerie(filteredDataPoints,
                {
                    name: "Data points",
                });
            series.push(curveDataSerie);

            var outliers = partitionedForOutliers[0];
            if (outliers.length > 0) {
                var outliersSerie = highchartsHelper.getScatterDataSerie(outliers,
                    {
                        name: "Outliers",
                        color: "red",
                        marker: {
                            symbol: "triangle",
                        },
                    });
                series.push(outliersSerie);
            }

            if (curveDataArray && curveDataArray.length > 1 && curveRegressionData) {
                var bestFitSerieData = curvePredictionHelper.getPredictionSerieData(curveRegressionData, curveDataXMin, curveDataXMax);

                var predictionsSerieData = curvePredictionHelper.getPredictionSerieData(curveRegressionData, XMin, XMax);
                var predictedDataArray = curvePredictionHelper.getPredictedDataArray(curveRegressionData, curveDataArray);
                var standardError = curvePredictionHelper.getStandardError(curveDataArray, predictedDataArray);

                var bestFitSerie = highchartsHelper.getRegressionSerie(bestFitSerieData,
                    {
                        name: "Best fit curve (SE: " + standardError.toFixed(2) +")",
                        showInLegend: true,
                    });
                series.push(bestFitSerie);

                if (XMin < curveDataXMin || XMax > curveDataXMax) {
                    var predictionsSerie = highchartsHelper.getRegressionSerie(predictionsSerieData,
                        {
                            name: "Forecast (best fit)",
                            showInLegend: true,
                            dashStyle: "dash",
                            lineWidth: 1,
                            zIndex: 0,
                            visible: false,
                        });
                    series.push(predictionsSerie);
                }
            }
        }
        var yAxisUnitCaption = !yAxisVariable ? "" : self.forceTonDayUnit() ? "ton/day" : yAxisVariable.unit.caption;

        var chartInfo = {
            title: selectedCurve.name,
            xAxis: {
                title: {
                    text: xAxisVariable ? xAxisVariable.displayName + " (" + xAxisVariable.unit.caption + ")" : "",
                },
                type: !xAxisVariable && yAxisVariable ? "datetime" : "",
            },
            yAxis: {
                title: {
                    text: yAxisVariable ? yAxisVariable.displayName + " (" + yAxisUnitCaption + ")" : "",
                },
                plotLines: [
                    {
                        value: 0,
                        width: 1,
                        color: "#808080",
                    },
                ],
            },
            tooltip: {
                useHtml: true,
                formatter: function() {
                    return self.getTooltipText(this);
                },
            },
            legend: {
                enabled: true,
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
            },
            series: series,
        };

        return chartInfo;
    });

    self.highchartsOptions = ko.computed(function () {
        var chartInfo = self.chartInfo();
        var options = highchartsHelper.getDefaultGenericLineChartOptions();

        options.title.text = chartInfo.title;
        options.series = chartInfo.series;
        options.xAxis = chartInfo.xAxis;
        options.yAxis = chartInfo.yAxis;
        options.plotLines = chartInfo.plotLines;
        options.tooltip = chartInfo.tooltip;
        options.legend.enabled = true;

        return options;
    });

    self.chartBinding = self.chart.subscribe(function(value) {
        var series = value.series;

        var graphSeries = _.map(series, function (serie) {
            return { name: serie.name, x: serie.processedXData, y: serie.processedYData };
        });

        self.graphSeries(graphSeries);
    });

    self.referenceCurveDataPointsBinding = self.referenceCurveDataPoints.subscribe(function (value) {
        var chart = self.chart();
        if (chart.series.length > 0) {
            chart.series[0].setData(value);
        }
    });

    self.curvePredictionUpdatedBinding = events.curvePredictionUpdated.add(function (point) {
        var pointX = point[0][0];
        var pointY = point[0][1];
        var isValidPoint = !_.isNaN(pointX) && !_.isNaN(pointY);

        var seriesName = "Predicted value";
        var predictionSerieName = "Forecast (prediction)";
        var chart = self.chart();
        var series = _.find(chart.series, function (serie) { return serie.name === seriesName; });
        var predictedSerie = _.find(chart.series, function (serie) { return serie.name === predictionSerieName; });

        if (!isValidPoint) {
            if (series) {
                series.remove();
            }
            if (predictedSerie) {
                predictedSerie.remove();
            }
            return;
        }

        var predictionSerieColor = "#f7a35c";
        if (series) {
            series.setData(point);
        } else {
            chart.addSeries({ name: seriesName, data: point, type: "scatter", color: predictionSerieColor, marker: { symbol: "circle" } });
        }

        var curveRegressionData = self.curveRegressionData();
        var XMax = self.XMax();
        var XMin = self.XMin();
        var predictionsSerieData = null;

        if (pointX > XMax) {
            predictionsSerieData = curvePredictionHelper.getPredictionSerieData(curveRegressionData, XMax, pointX);
        } else if (pointX < XMin) {
            predictionsSerieData = curvePredictionHelper.getPredictionSerieData(curveRegressionData, pointX, XMin);
        }

        if (predictedSerie && !predictionsSerieData) {
            predictedSerie.remove();
        }

        if (!predictionsSerieData) {
            return;
        }

        if (predictedSerie) {
            predictedSerie.setData(predictionsSerieData);
        } else {
            var predictionsSerie = highchartsHelper.getRegressionSerie(predictionsSerieData,
                {
                    name: predictionSerieName,
                    showInLegend: true,
                    dashStyle: "dash",
                    lineWidth: 1,
                    zIndex: 0,
                    linkedTo: ":previous",
                    color: predictionSerieColor,
                });
            chart.addSeries(predictionsSerie);
        }
    });
}

ViewModel.prototype.getTooltipText = function (curvePoint) {
    var self = this;
    var text = "";

    if (curvePoint.series.name === "Predicted value") {
        var xAxisTitle = curvePoint.series.xAxis.axisTitle.textStr;
        var yAxisTitle = curvePoint.series.yAxis.axisTitle.textStr;

        return xAxisTitle +  ": " + curvePoint.point.x.toFixed(2) + "<br/>" + yAxisTitle + ": " + curvePoint.point.y.toFixed(2);
    }

    var timePeriod = curvePoint.point.timePeriod;
    var variableDataList = timePeriod.variableDataList();

    text += "<b>" + timePeriod.formatedStartDate() + " - " + timePeriod.formatedEndDate() + "</b><br/>";

    var yAxisLogVariableId = self.yAxisLogVariableId();
    var forceTonDayUnit = self.forceTonDayUnit();

    _.each(variableDataList, function (variableDataElement) {
        var availableVariable = _.find(self.availableVariables, function (availableVariable) { return variableDataElement.id === availableVariable.variable.id; });

        var isYAxis = variableDataElement.id === yAxisLogVariableId;
        var unitCaption = isYAxis && forceTonDayUnit ? "ton/day" : availableVariable.variable.unit.caption;

        text += "<span>" + variableDataElement.name + ": </span>" + "<span class=\"curveTooltipSampleTime\">" + variableDataElement.average.toFixed(2) +
            " " + unitCaption + "</span><br/>";
    });

    return text;
};

ViewModel.prototype.dispose = function () {
    this.referenceCurveDataPointsBinding.dispose();
    this.chartBinding.dispose();
    this.curvePredictionUpdatedBinding.detach();
};

export default {viewModel: ViewModel, template: template};