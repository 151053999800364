import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import companiesClient from "Clients/companies-client";
import stringHelper from "Utilities/string-helper";

var ViewModel = function () {
    var self = this;
    self.companyList = ko.observableArray([]);
    self.isLoading = ko.observable(false);

    self.load();

    self.companyOnCreatedBinding = events.companyOnCreated.add(function (newCompany) {
        self.companyList.push(newCompany);
    });

    self.companyOnUpdatedBinding = events.companyOnUpdated.add(function (oldCompany, updatedCompany) {
        self.companyList.replace(oldCompany, updatedCompany);
    });

    self.companyOnRemovedBinding = events.companyOnRemoved.add(function (removedCompany) {
        self.companyList.remove(function (company) {
            return company.id === removedCompany.id;
        });
    });
};

ViewModel.prototype.dispose = function () {
    this.companyOnUpdatedBinding.detach();
    this.companyOnCreatedBinding.detach();
    this.companyOnRemovedBinding.detach();
};

ViewModel.prototype.load = function () {
    var self = this;
    self.companyList([]);
    self.isLoading(true);

    companiesClient.getAll().done(function (data) {
        self.companyList(data);
    }).always(function () {
        self.isLoading(false);
    });
};

ViewModel.prototype.getList = function () {
    return this.companyList;
};

ViewModel.prototype.isLoading = function () {
    return this.isLoading;
};

ViewModel.prototype.exists = function (name) {
    var self = this;

    var match = _.find(this.companyList(), function (o) {
        return stringHelper.equalsIgnoreCase(name, o.name);
    });

    return match !== undefined;
};

export default ViewModel;