import net from "App/net";
import events from "App/events";

var Client = function() {

};

Client.prototype.find = function(flowMeterId, fromDate, toDate) {
    var filter = {
        flowMeterId: flowMeterId,
        fromDate: fromDate,
        toDate: toDate,
    };

    return net.get("/FlowMeterOperations/Find", filter);
};

Client.prototype.create = function(flowMeterOperation) {
    return net.post("/FlowMeterOperations", flowMeterOperation);
};

Client.prototype.remove = function(flowMeterOperation) {
    return net.del("/FlowMeterOperations/" + flowMeterOperation.id).done(function() {
        events.flowMeterOperationDeleted.dispatch(flowMeterOperation);
    });
};

export default new Client();