import template from "./voyage-vessel-event-voyage.html";
import ko from "knockout";
import _ from "underscore";
import configEmissionReportFactory from "App/config-emission-report";
import emissionReportVesselEventsClient from "Clients/emission-report-vessel-events-client";

function ViewModel(params) {
    var self = this;
    self.configEmissionReport = configEmissionReportFactory.get();
    self.vesselEvent = params.vesselEvent;
    self.voyageTypes = self.configEmissionReport.voyageTypes;
    self.voyage = ko.pureComputed(function() {
        var vesselEvent = self.vesselEvent();
        return vesselEvent.voyage;
    });

    var voyage = self.voyage();
    self.inputVoyageName = ko.observable(voyage ? voyage.name : "");
    self.inputVoyageType = ko.observable(voyage ? voyage.type : "");
    self.inputIsNonCommercial = ko.observable(voyage ? voyage.isNonCommercial : false);

    self.isEditing = ko.observable(false);
}

ViewModel.prototype.dispose = function() {
};

ViewModel.prototype.startEditing = function(vm, e) {
    var $wrapper = $(e.currentTarget).closest(".edit-wrapper");
    this.isEditing(true);

    var $input = $wrapper.find("input[type=text]");
    $input.focus();
    $input.select();
};

ViewModel.prototype.cancelEditing = function(vm, e) {
    this.isEditing(false);
};

ViewModel.prototype.save = function() {
    var self = this;
    var vesselEvent = _.clone(self.vesselEvent());
    vesselEvent.voyage = vesselEvent.voyage || {};
    vesselEvent.voyage.name = self.inputVoyageName();
    vesselEvent.voyage.type = self.inputVoyageType();
    vesselEvent.voyage.isNonCommercial = self.inputIsNonCommercial();

    emissionReportVesselEventsClient.update(vesselEvent).done(function(updatedVesselEvent) {
        self.vesselEvent(updatedVesselEvent);
        self.cancelEditing();
    });
};

export default { viewModel: ViewModel, template: template };
