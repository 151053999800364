import moment from "moment";

var dateFormat = "DD. MMM, YYYY";
var timeFormat = "HH:mm";
var submitFormat = "YYYY-MM-DD";

function toSubmitString(date, time) {
    if (!date) return null;
    var submitDate = moment.utc(date, dateFormat).format(submitFormat);
    if (time) {
        submitDate += "T" + time;
    }
    return submitDate + "Z";
}

function formatDate(date, format) {
    if (!date) return "";
    return moment.utc(date).format(format);
}

function toDateTime(date, time) {
    var dateTimeString = date + " " + time;
    return moment.utc(dateTimeString, dateFormat + timeFormat);
}

function toDate(date) {
    return moment.utc(date, dateFormat);
}

function formatDateTime(dateTime, timeFormatter = timeFormat) {
    return moment.utc(dateTime).format(`${dateFormat} ${timeFormatter}`);
}

function dateInMilliseconds(date) {
    var dateInMilliseconds = moment.utc(date, `${dateFormat} ${timeFormat}`).toDate().getTime();

    return dateInMilliseconds;
}

function datesDiffAsHours(from, to) {
    const fromDate = moment.utc(from);
    const toDate = moment.utc(to);

    return moment.duration(moment(toDate).diff(fromDate)).asHours();
}

function durationBetweenDates(from, to) {
    const fromDate = moment(from);
    const toDate = moment(to);
    const diff_s = fromDate.diff(toDate, "seconds");
    const dur_ms = moment.duration(diff_s, "seconds").asMilliseconds();

    return moment.utc(dur_ms).format("HH [hours] mm [minutes] ss [seconds]");
}

function humanizeDuration(from, to) {
  const fromDate = moment.utc(from);
  const toDate = to ? moment.utc(to) : moment.utc();

  return moment.duration(toDate.diff(fromDate)).humanize();
}

export default {
  getHumanizeDuration: function (from, to) {
    return humanizeDuration(from, to);
},
    getDurationBetweenDates: function (from, to) {
        return durationBetweenDates(from, to);
    },
    getDatesDiffAsHours: function (from, to) {
        return datesDiffAsHours(from, to);
    },
    getSubmitDateString: function (date, time) {
        return toSubmitString(date, time);
    },
    getFormatedDateString: function (date) {
        return formatDate(date, dateFormat);
    },
    getFormatedTimeString: function (dateTime) {
        return formatDate(dateTime, timeFormat);
    },
    getFormatedDateTimeString: function (dateTime, timeFormatter) {
        return formatDateTime(dateTime, timeFormatter);
    },
    getDateTime: function (date, time) {
        return toDateTime(date, time);
    },
    getDate: function (date) {
        return toDate(date);
    },
    getDateInMilliseconds: function (date) {
        return dateInMilliseconds(date);
    },
    getDateFormat: function () {
        return dateFormat;
    },
    getTimeFormat: function () {
        return timeFormat;
    },
    getJavascriptDate: function (date) {
        return toDate(date).toDate();
    },
    isValidTime: function (timeString) {
        return timeString.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
    },
};
