import template from "./fm-fuel-consumption.html";
import ko from "knockout";
import _ from "underscore";
import fuelConsumptionsClient from "Clients/fm-fuel-consumptions-client";
import events from "App/events";
import configEmissionReportFactory from "App/config-emission-report";

function getFlowMeterFuelConsumptionSourceColor(fuelConsumption) {
    var manualFuelConsumptionSourceColor = "#f7a35c";
    if (_.isNumber(fuelConsumption.correctedFuelConsumptionInTon)) {
        return manualFuelConsumptionSourceColor; //If corrected value, then it's manual input
    }
    switch (fuelConsumption.fuelConsumptionSource) {
        case "LogData":
            return "#28A5A8";
        case "Manual":
            return manualFuelConsumptionSourceColor;
        case "Regression":
            return "#75D1D5";
        case "ManualEstimate":
            return "#FFD3AD";
        default:
            return "";
    }
}

function ViewModel(params) {
    var self = this;
    self.configEmissionReport = configEmissionReportFactory.get();
    self.vesselEvent = params.vesselEvent;
    self.fuelConsumption = ko.observable(params.flowMeterFuelConsumption);
    self.isSelected = ko.observable(false);
    self.isOverlapped = ko.observable(false);
    self.isEditing = ko.observable(false);
    self.isRemoving = ko.observable(false);

    var fuelTypesMap = self.configEmissionReport.fuelTypesMap();
    self.fuelTypeName = params.flowMeterFuelConsumption.fuelTypeId ? fuelTypesMap[params.flowMeterFuelConsumption.fuelTypeId].name : "";


    self.hasCorrectedFuelConsumptionInTon = ko.pureComputed(function() {
        var fuelConsumption = self.fuelConsumption();
        return _.isNumber(fuelConsumption.correctedFuelConsumptionInTon);
    });

    self.inputFuelConsumptionInTon = ko.observable();

    self.flowMeterFuelConsumptionSelectedBinding = events.flowMeterFuelConsumptionSelected.add(function(selectedFuelConsumption) {
        var fuelConsumption = self.fuelConsumption();
        if (selectedFuelConsumption &&
            fuelConsumption.startAt === selectedFuelConsumption.startAt &&
            fuelConsumption.endAt === selectedFuelConsumption.endAt &&
            fuelConsumption.flowMeterId === selectedFuelConsumption.flowMeterId) {
            self.isSelected(true);
        } else {
            self.isSelected(false);
            self.isEditing(false);
        }
    });

    self.overlappingFlowMeterFuelConsumptionUpdatedBinding = events.overlappingFuelConsumptionUpdated.add(function(overlappingFuelConsumption) {
        var fuelConsumption = self.fuelConsumption();
        self.isOverlapped(overlappingFuelConsumption && overlappingFuelConsumption.id === fuelConsumption.id);
    });

    self.fuelConsumptionSourceColor = ko.pureComputed(function() {
        var fuelConsumption = self.fuelConsumption();
        return getFlowMeterFuelConsumptionSourceColor(fuelConsumption);
    });

    self.isRemoveable = ko.pureComputed(function() {
        var fuelConsumption = self.fuelConsumption();
        return fuelConsumption.fuelConsumptionSource === "Manual";
    });
}

ViewModel.prototype.dispose = function() {
    this.flowMeterFuelConsumptionSelectedBinding.detach();
    this.overlappingFlowMeterFuelConsumptionUpdatedBinding.detach();
};

ViewModel.prototype.startEdit = function(vm, e) {
    var self = this;
    var fuelConsumption = self.fuelConsumption();
    var initialInputFuelConsumptionInTon = self.hasCorrectedFuelConsumptionInTon()
        ? fuelConsumption.correctedFuelConsumptionInTon
        : fuelConsumption.fuelConsumptionInTon;
    initialInputFuelConsumptionInTon = _.isNumber(initialInputFuelConsumptionInTon) ? Math.round(initialInputFuelConsumptionInTon * 1000) / 1000 : "";
    self.inputFuelConsumptionInTon(initialInputFuelConsumptionInTon);

    self.isEditing(true);
    events.flowMeterFuelConsumptionSelected.dispatch(fuelConsumption);

    var $input = $(e.currentTarget).find("input");
    $input.focus();
    $input.select();

    $input.off("keyup");
    $input.on("keyup", function(e) {
        if (e.keyCode === 27) { // escape key
            events.flowMeterFuelConsumptionSelected.dispatch(null);
        }
    });

    $input.off("blur");
    $input.on("blur",
        function(e) {
            events.flowMeterFuelConsumptionSelected.dispatch(null);
        });
};

ViewModel.prototype.updateFuelConsumptionInTon = function() {
    var self = this;
    var fuelConsumption = _.clone(self.fuelConsumption());
    var inputFuelConsumptionInTon = self.inputFuelConsumptionInTon();
    var fuelConsumptionInTon = parseFloat(inputFuelConsumptionInTon);

    var vesselEvent = self.vesselEvent();
    fuelConsumption.vesselEventId = vesselEvent.id;

    if (fuelConsumption.fuelConsumptionSource === "LogData" ||
        fuelConsumption.fuelConsumptionSource === "Regression" ||
        fuelConsumption.fuelConsumptionSource === "ManualEstimate") {
        fuelConsumption.correctedFuelConsumptionInTon = fuelConsumptionInTon;
    } else {
        fuelConsumption.fuelConsumptionInTon = fuelConsumptionInTon;
    }

    if (!fuelConsumption.id) {
        fuelConsumptionsClient.create(fuelConsumption).done(function(data) {
            events.flowMeterFuelConsumptionSelected.dispatch(null);

            _.each(data, function(fuelConsumption) {
                events.flowMeterFuelConsumptionUpdated.dispatch(fuelConsumption);
            });
        });
    } else {
        fuelConsumptionsClient.update(fuelConsumption).done(function(data) {
            events.flowMeterFuelConsumptionSelected.dispatch(null);
            events.flowMeterFuelConsumptionUpdated.dispatch(data);
        });
    }
};

ViewModel.prototype.remove = function() {
    var self = this;
    self.isRemoving(true);
    fuelConsumptionsClient.remove(self.fuelConsumption()).always(function() {
        self.isRemoving(false);
    });
};

export default { viewModel: ViewModel, template: template };