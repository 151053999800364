import ko from "knockout";
import _ from "underscore";
import client from "Clients/users-client";
import stringHelper from "Utilities/string-helper";

var ViewModel = function (model, users) {
    var self = this;
    self.original = undefined;
    self.list = users;

    self.userId = ko.observable();
    self.userName = ko.observable().extend({
        required: true,
        email: true,
        validation: {
            async: true,
            validator: function (val, params, callback) {
                if ((self.original != null) && (stringHelper.equalsIgnoreCase(val, self.original.email))) {
                    // Same as "original name".
                    callback(true);
                } else {
                    callback(!self.list.exists(val));
                }
            },
            message: "A user with that email already exists.",
        },
    });
    self.name = ko.observable().extend({ required: true });
    self.title = ko.observable().extend({ required: true });
    self.phoneNumber = ko.observable();
    self.apiKey = ko.observable();
    self.role = ko.observable();
    self.kognifaiTenantId = ko.observable();
    self.kognifaiClientId = ko.observable();
    self.kognifaiClientSecret = ko.observable();

    self.setModel(model);

    //For accessing validation from the parent component to for example enable/disable "create/save" button.
    self.errors = ko.validation.group(self);
    self.isValid = ko.computed(function () {
        return self.errors().length === 0;
    });

    self.isNewUser = ko.pureComputed(function () {
        var userId = self.userId();
        return userId == null || userId === "00000000-0000-0000-0000-000000000000";
    });
};

ViewModel.prototype.setModel = function (model) {
    var self = this;
    self.original = model;

    if (model) {
        // Edit mode
        self.userId(model.id);
        self.userName(model.email);
        self.name(model.name);
        self.title(model.title);
        self.phoneNumber(model.phoneNumber);
        self.apiKey(model.apiKey);
        self.role(model.role);
        self.kognifaiTenantId(model.kognifaiTenantId);
        self.kognifaiClientId(model.kognifaiClientId);
        self.kognifaiClientSecret(model.kognifaiClientSecret);
    } else {
        // "New" mode
        self.userId(undefined);
        self.userName(undefined);
        self.name(undefined);
        self.title(undefined);
        self.phoneNumber(undefined);
        self.apiKey(undefined);
        self.role(undefined);
        self.kognifaiTenantId(undefined);
        self.kognifaiClientId(undefined);
        self.kognifaiClientSecret(undefined);
    }
};

ViewModel.prototype.getOriginalUser = function () {
    return this.original;
};

ViewModel.prototype.save = function () {
    var self = this;
    var user = _.clone(self.original) || {};

    user.name = self.name();
    user.title = self.title();
    user.phoneNumber = self.phoneNumber();
    user.role = self.role();
    user.kognifaiTenantId = self.kognifaiTenantId();
    user.kognifaiClientId = self.kognifaiClientId();
    user.kognifaiClientSecret = self.kognifaiClientSecret();

    var promise;
    if (self.isNewUser()) {
        user.email = self.userName();
        promise = client.add(user);
    } else {
        promise = client.update(user);
    }
    promise.done(function (data) {
        self.setModel(data);
    });
    return promise;
};

ViewModel.prototype.remove = function () {
    return client.remove(this.original);
};

ViewModel.prototype.generateApiKey = function () {
    var self = this;
    var userId = self.userId();

    var promise = client.generateApiKey(userId);
    promise.done(function (apiKey) {
        self.original.apiKey = apiKey;
        self.apiKey(apiKey);
    });
    return promise;
};

ViewModel.prototype.clearApiKey = function () {
    var self = this;
    var userId = self.userId();

    var promise = client.clearApiKey(userId);
    promise.done(function () {
        var apiKey = "";
        self.original.apiKey = apiKey;
        self.apiKey(apiKey);
    });
    return promise;
};

export default ViewModel;