import template from "./compare-vessels-long-trends-details-graph.html";
import ko from "knockout";
import _ from "underscore";
import highchartsHelper from "Utilities/highcharts-helper";

function ViewModel(params) {
    var self = this;
    self.compareGroup = params.compareGroup;
    self.exportTitle = params.exportTitle;
    self.showLastTrendPeriodOnly = params.showLastTrendPeriodOnly;
    self.showRelativeYAxis = params.showRelativeYAxis;

    self.vessels = self.compareGroup.vessels;
    self.longTrends = self.compareGroup.longTrends;
    self.longTrendPeriods = self.compareGroup.longTrendPeriods;
    var firstLongTrend = self.longTrends[0];
    self.diagnosticType = firstLongTrend.diagnosticType;
    self.chart = ko.observable();

    self.chartInfo = ko.pureComputed(function() {
        var showLastTrendPeriodOnly = self.showLastTrendPeriodOnly();
        var showRelativeYAxis = self.showRelativeYAxis();
        var exportTitle = self.exportTitle();
        var trendLinesSeries = null;
        if (showLastTrendPeriodOnly) {
            trendLinesSeries = highchartsHelper.getTrendLinesSeriesForCompareLastLongTrendPeriod(self.longTrendPeriods, self.longTrends, self.vessels, showRelativeYAxis);
        } else {
            trendLinesSeries = highchartsHelper.getTrendLinesSeriesForCompareLongTrendPeriods(self.longTrendPeriods, self.longTrends, self.vessels);
        }

        var firstLongTrendPeriod = _.first(self.longTrendPeriods);
        return {
            title: null,
            yUnit: showLastTrendPeriodOnly && showRelativeYAxis ? "Percent (%), relative" : firstLongTrendPeriod.unitName + " (" + firstLongTrendPeriod.unitCaption + ")",
            xUnit: showLastTrendPeriodOnly ? "Months since each vessel's last trend event." : null,
            isRpm: firstLongTrendPeriod.isRpmDiagnostic,
            yMax: firstLongTrendPeriod.maximumValue,
            yMin: firstLongTrendPeriod.minimumValue,
            trendLinesSeries: trendLinesSeries,
            axisType: showLastTrendPeriodOnly ? "linear" : "datetime",
            exportTitle: exportTitle,
        };
    });

    self.highchartsOptions = ko.computed(function() {
        var chartInfo = self.chartInfo();
        if (!chartInfo) return null;

        var options = highchartsHelper.getDefaultDiagnosticSplineHighchartsOptions();
        options.title.text = chartInfo.title;
        options.yAxis.title.text = chartInfo.yUnit;
        options.xAxis.title.text = chartInfo.xUnit;
        options.xAxis.type = chartInfo.axisType;
        options.series = chartInfo.trendLinesSeries;
        options.yAxis.max = chartInfo.isRpm ? 25 : 50;
        options.yAxis.min = chartInfo.isRpm ? -25 : -50;
        options.legend = {
            enabled: true,
        };
        options.exporting.filename = chartInfo.exportTitle;
        options.exporting.chartOptions.title = {
            text: chartInfo.exportTitle,
        };
        options.exporting.chartOptions.legend.enabled = true;

        options.xAxis.events.setExtremes = function(e) {
            var axis = this;
            var userOptions = axis.chart.userOptions;
            var isZoomedOut = (_.isUndefined(e.min) && _.isUndefined(e.max)) || (e.min === userOptions.xAxis.min && e.max === userOptions.xAxis.max);

            if (isZoomedOut) {
                axis.chart.yAxis[0].setExtremes(userOptions.yAxis.min, userOptions.yAxis.max);
            } else {
                var yAxis = axis.chart.yAxis[0];
                yAxis.setExtremes(yAxis.dataMin - Math.abs(yAxis.dataMin) * 0.1, yAxis.dataMax + Math.abs(yAxis.dataMax) * 0.1);
            }
        };

        options.tooltip = {
            useHTML: true,
            headerFormat: "{series.name}<br><small>{point.key}</small><br>",
            pointFormat: "Value: <strong>{point.y}</strong>",
            valueDecimals: 2,
            xDateFormat: "%d. %b, %Y",
            valueSuffix: "%",
        };

        return options;
    });
}


ViewModel.prototype.dispose = function() {
};

export default { viewModel: ViewModel, template: template };