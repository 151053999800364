import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { VesselGroup } from "@/types/vesselGroup";
import vesselGroupClient from "Clients/vessel-groups-client";


@Module({ namespaced: true, name: "VesselGroups" })
class VesselGroups extends VuexModule {
  private _vesselGroupsExpired = true;
  private _vesselGroups: VesselGroup[] = [];

  @Mutation
  public REFRESH_VESSEL_GROUPS(groups: VesselGroup[]): void {
    this._vesselGroupsExpired = false;
    this._vesselGroups = groups;
  }

  @Mutation
  public EXPIRE_DATA(): void {
    this._vesselGroupsExpired = true;
  }

  @Action({ rawError: true })
  public async refreshVesselGroups(): Promise<void> {
    let data = [];
    try {
      data = await vesselGroupClient.getAll();
    } catch (error) {
      throw ({ message: "Failed to refresh vessel groups", error });
    }
    this.context.commit("REFRESH_VESSEL_GROUPS", data);
  }

  @Action({ rawError: true })
  public async getAll(): Promise<VesselGroup[]> {
    if (!this._vesselGroupsExpired) {
      return this._vesselGroups;
    } else {
      await this.refreshVesselGroups();
      return this._vesselGroups;
    }
  }

  get vesselGroups(): VesselGroup[] {
    return this._vesselGroups;
  }
}

export default VesselGroups;
