import ko from "knockout";
import _ from "underscore";

var ViewModel = function(curvePoint, curveFit) {
    var self = this;

    self.curveFit = curveFit;

    self.x = ko.observable(curvePoint.x);
    self.y = ko.observable(curvePoint.y);

    self.isNegativeXValueAllowed = ko.pureComputed(function () {
        var curveFit = self.curveFit();

        return curveFit === "Linear" || curveFit === "Polynomial" || curveFit === "Exponential";
    });

    self.isNegativeYValueAllowed = ko.pureComputed(function () {
        var curveFit = self.curveFit();

        return curveFit === "Linear" || curveFit === "Polynomial" || curveFit === "Logarithmic";
    });

    self.dataPoint = ko.pureComputed(function() {
        var x = parseFloat(self.x());
        var y = parseFloat(self.y());
        return [x, y];
    });

    self.point = ko.pureComputed(function() {
        var x = parseFloat(self.x());
        var y = parseFloat(self.y());
        return {
            x: x,
            y: y,
        };
    });

    self.isXValid = ko.pureComputed(function() {
        var x = self.x();
        var curveFit = self.curveFit();
        var isNegativeXValueAllowed = self.isNegativeXValueAllowed();
        var isFinite = _.isFinite(x);

        if (!isNegativeXValueAllowed) {
            return isFinite && x > 0;
        }

        return isFinite;
    });

    self.isYValid = ko.pureComputed(function() {
        var y = self.y();

        var isNegativeYValueAllowed = self.isNegativeYValueAllowed();
        var isFinite = _.isFinite(y);

        if (!isNegativeYValueAllowed) {
            return isFinite && y > 0;
        }

        return isFinite;
    });

    self.isValid = ko.pureComputed(function() {
        var isXValid = self.isXValid();
        var isYValid = self.isYValid();
        return isXValid && isYValid;
    });
};

export default ViewModel;