import template from "./vessel-performance-status-item.html";
import ko from "knockout";
import _ from "underscore";
import longTrendsClient from "Clients/long-trends-client";
import events from "App/events";

function ViewModel(params) {
    var self = this;
    self.longTrend = ko.observable(params.longTrend);
    self.useInVesselStatus = ko.observable();
    self.isUpdating = ko.observable(false);
    self.updateError = ko.observable();

    self.isEditing = ko.observable(false);

    self.description = ko.pureComputed(function () {
        var longTrend = self.longTrend();
        return longTrend.descriptionLong;
    });

    self.performanceStatus = ko.pureComputed(function () {
        var longTrend = self.longTrend();
        return longTrend.performanceStatus;
    });

    self.useInVesselStatusTooltipText = ko.pureComputed(function () {
        var longTrend = self.longTrend();
        if (longTrend.useInVesselStatus) {
            return "Included in overall performance status";
        } else {
            return "Excluded from overall performance status";
        }
    });

    self.initialize();
}

ViewModel.prototype.toggleUseInVesselStatus = function (vm, e) {
    var self = this;
    self.isUpdating(true);

    var longTrend = _.clone(self.longTrend());
    longTrend.useInVesselStatus = self.useInVesselStatus();

    self.save(longTrend).always(function () {
        self.showUseInVesselStatusToopltip(vm, e);
    });

    return true;
};

ViewModel.prototype.save = function (longTrend) {
    var self = this;
    return longTrendsClient.update(longTrend).done(function (data) {
        self.longTrend(data);
    }).fail(function (e) {
        self.updateError("An error occured during update");
        self.isEditing(true);
    }).always(function () {
        self.initialize();
        self.isUpdating(false);
    });
};

ViewModel.prototype.initialize = function () {
    var longTrend = this.longTrend();
    this.useInVesselStatus(longTrend.useInVesselStatus);
};

ViewModel.prototype.showUseInVesselStatusToopltip = function (vm, e) {
    var element = e.currentTarget;
    events.showTooltip.dispatch("tooltip-text", { text: this.useInVesselStatusTooltipText() }, element);
};

ViewModel.prototype.closeToopltip = function () {
    events.closeTooltip.dispatch();
};

ViewModel.prototype.dispose = function () {
};

export default { viewModel: ViewModel, template: template };
