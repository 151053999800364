import template from "./fuel-tanks.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import fuelConsumptionSourcesClient from "Clients/fuel-consumption-sources-client";
import fuelTypesClient from "Clients/fuel-types-client";
import fuelTanksClient from "Clients/fuel-tanks-client";

import Store from "@/store";

function ViewModel(params) {
    var self = this;
    self.fuelTanks = ko.observableArray([]);
    self.fuelConsumptionSources = ko.observable();
    self.fuelTypes = ko.observable();
    self.massFlowRateLogVariables = ko.observable();
    self.isSaving = ko.observable(false);
    self.showNewFuelTank = ko.observable(false);

    self.isLoadingFuelTanks = ko.observable(true);
    self.isLoadingFuelConsumptionSources = ko.observable(true);
    self.isLoadingFuelTypes = ko.observable(true);

    self.isLoading = ko.pureComputed(function() {
        var isLoadingFuelTanks = self.isLoadingFuelTanks();
        var isLoadingFuelConsumptionSources = self.isLoadingFuelConsumptionSources();
        var isLoadingFuelTypes = self.isLoadingFuelTypes();
        return isLoadingFuelTanks ||
            isLoadingFuelConsumptionSources ||
            isLoadingFuelTypes;
    });

    fuelTanksClient.find(Store.getters["Vessels/currentVessel"].id)
        .done(self.fuelTanks)
        .always(function() {
            self.isLoadingFuelTanks(false);
        });

    fuelConsumptionSourcesClient.getAll()
        .done(self.fuelConsumptionSources)
        .always(function() {
            self.isLoadingFuelConsumptionSources(false);
        });

    fuelTypesClient.getAll()
        .done(self.fuelTypes)
        .always(function() {
            self.isLoadingFuelTypes(false);
        });

    self.activeFuelTypes = ko.pureComputed(function() {
        var fuelTypes = self.fuelTypes();
        return _.filter(fuelTypes,
            function(fuelType) {
                return !fuelType.isDeactivated;
            });
    });

    self.inputFuelConsumptionSources = ko.observableArray();
    self.inputName = ko.observable();
    self.inputFuelTypeId = ko.observable();

    self.newFuelTank = ko.pureComputed(function() {
        var inputFuelConsumptionSources = self.inputFuelConsumptionSources();
        var inputFuelTypeId = self.inputFuelTypeId();
        var inputName = self.inputName();

        var newFuelTank = {
            name: inputName,
            vesselId: Store.getters["Vessels/currentVessel"].id,
            fuelConsumptionSources: inputFuelConsumptionSources,
            fuelTypeId: inputFuelTypeId,
        };
        return newFuelTank;
    });

    self.isNewFuelTankValid = ko.pureComputed(function() {
        var newFuelTank = self.newFuelTank();
        var newFuelTankExists = self.newFuelTankExists();

        if (!(newFuelTank.fuelConsumptionSources.length > 0 && newFuelTank.fuelTypeId && newFuelTank.name) || newFuelTankExists) {
            return false;
        }

        return true;
    });

    self.fuelTankDeletedBinding = events.fuelTankDeleted.add(function(fuelTank) {
        self.fuelTanks.remove(function(item) {
            return item.id === fuelTank.id;
        });
    });

    self.hasActiveFuelTanks = ko.pureComputed(function() {
        var activeFuelTanks = self.activeFuelTanks();
        return activeFuelTanks.length > 0;
    });

    self.activeFuelTanks = ko.pureComputed(function() {
        var fuelTanks = self.fuelTanks();
        return _.filter(fuelTanks,
            function(fuelTank) {
                return !fuelTank.isDeactivated;
            });
    });

    self.newFuelTankExists = ko.pureComputed(function() {
        var activeFuelTanks = self.activeFuelTanks();
        var newFuelTankName = self.newFuelTank().name;

        if (!newFuelTankName) {
            return false;
        }

        return _.some(activeFuelTanks, function(activeFuelTank) {
            return activeFuelTank.name.toLowerCase() === newFuelTankName.toLowerCase();
        });
    });
}

ViewModel.prototype.dispose = function() {
    this.fuelTankDeletedBinding.detach();
};

ViewModel.prototype.addFuelTank = function() {
    var self = this;
    var newFuelTank = self.newFuelTank();
    self.isSaving(true);
    fuelTanksClient.create(newFuelTank).done(function(data) {
        self.fuelTanks.push(data);
        self.cancelNewFuelTank();
    }).always(function() {
        self.isSaving(false);
    });
};

ViewModel.prototype.clearNewFuelTank = function() {
    this.inputName("");
    this.inputFuelConsumptionSources([]);
    this.inputFuelTypeId("");
};

ViewModel.prototype.cancelNewFuelTank = function() {
    this.showNewFuelTank(false);
    this.clearNewFuelTank();
};

ViewModel.prototype.startNewFuelTank = function() {
    this.showNewFuelTank(true);
};

export default { viewModel: ViewModel, template: template };
