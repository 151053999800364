import net from "App/net";

var LoggingHistoryConfigurationsClient = function() {
        
};

LoggingHistoryConfigurationsClient.prototype.getByVesselId = function(vesselId) {
    var filter = {
        vesselId: vesselId,
    };

    var deferred = $.Deferred();
    net.get("/LoggingHistoryConfigurations/Find", filter).done(function (response) {
        deferred.resolve(response[0]);
    }).fail(function () {
        deferred.reject(arguments);
    });
    return deferred.promise();
};

LoggingHistoryConfigurationsClient.prototype.update = function(configuration) {
    return net.put("/LoggingHistoryConfigurations", configuration);
};

export default new LoggingHistoryConfigurationsClient();