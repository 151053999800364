import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import logVariablesClient from "Clients/log-variables-client";
import Vue from "vue";
import { LogVariable } from "@/types/logVariable";

interface VesselLogVariables {
  vesselId: number;
  logVariables: LogVariable[];
}

@Module({ namespaced: true, name: "LogVariables" })
class LogVariables extends VuexModule {
  private _vesselsLogVariables: VesselLogVariables[] = [];
  private _currentVesselLogVariables: LogVariable[] = [];
  private _currentVesselShaftPowerLogVariables: LogVariable[] = [];

  public get vesselsLogVariables(): VesselLogVariables[] {
    return this._vesselsLogVariables;
  }

  public get currentVesselLogVariables(): LogVariable[] {
    return this._currentVesselLogVariables;
  }

  public get currentVesselShaftPowerLogVariables(): LogVariable[] {
    return this._currentVesselShaftPowerLogVariables;
  }

  public get logVariableByName() {
    return (logVariableName: string): LogVariable => {
      return this._currentVesselLogVariables.find(variable => variable.name === logVariableName) ?? {} as LogVariable;
    };
  }

  public get logVariableById() {
    return (id: number): LogVariable => {
      return this._currentVesselLogVariables.find(variable => variable.id === id) ?? {} as LogVariable;
    };
  }

  public get logVariableByVesselId() {
    return (id: number): LogVariable[] | undefined => {
      return this._vesselsLogVariables.find(variable => variable.vesselId === id)?.logVariables;
    };
  }

  public get logVariableByNameAndVesselId() {
    return (vesselId: number, name: string): LogVariable | undefined => {
      const vesselVariables = this._vesselsLogVariables.find(variable => variable.vesselId === vesselId)?.logVariables;
      if (!vesselVariables?.length) return;
      return vesselVariables.find(variable => variable.displayName === name);
    };
  }

  @Action({ rawError: true })
  public async fetchAllLogVariablesByVesselId(vesselId: number): Promise<void> {
    try {
      const logVariables: LogVariable[] = await logVariablesClient.findAll(vesselId, undefined, true, true);
      const taxa = await logVariablesClient.findVesselLogVariablesTaxa(vesselId);
      logVariables.forEach(logVariable => {
        logVariable.taxa = taxa[logVariable.id];
      });
      this.context.commit("SET_VESSEL_LOG_VARIABLES", { logVariables: logVariables, vesselId: vesselId });
    } catch (error) {
      console.warn(error);
    }
  }

  @Action({ rawError: true })
  public async refreshCurrentVesselLogVariables(vesselId: number): Promise<void> {
    try {
      const data = await logVariablesClient.findAll(vesselId, null, null, true);
      this.context.commit("SET_CURRENT_VESSEL_LOG_VARIABLES", data);
    } catch (error) {
      console.warn(error);
    }
  }

  @Action({ rawError: true })
  public async fetchVesselShaftPowerLogVariables(vesselId: number): Promise<LogVariable[] | undefined> {
    try {
      const shaftPowerLogVariables: LogVariable[] = await logVariablesClient.findVesselLogVariablesShaftPower(vesselId);
      this.context.commit("SET_SHAFT_POWER_LOG_VARIABLES", shaftPowerLogVariables);
      return shaftPowerLogVariables;
    } catch (error) {
      console.warn(error);
    }
  }

  @Mutation
  public SET_VESSEL_LOG_VARIABLES(payload: VesselLogVariables): void {
    const existedVesselIndex = this._vesselsLogVariables.findIndex(vessel => vessel.vesselId === payload.vesselId);
    if (existedVesselIndex >= 0) Vue.set(this._vesselsLogVariables, existedVesselIndex, payload);
    else this._vesselsLogVariables.push(payload);
  }

  @Mutation
  public SET_CURRENT_VESSEL_LOG_VARIABLES(logVariables: LogVariable[]): void {
    this._currentVesselLogVariables = logVariables;
  }

  @Mutation
  public SET_SHAFT_POWER_LOG_VARIABLES(shaftPowerLogVariables: LogVariable[]): void {
    this._currentVesselShaftPowerLogVariables = shaftPowerLogVariables;
  }

}

export default LogVariables;
