import template from "./user-api-key.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";

function ViewModel(params) {
    var self = this;

    self.user = params.user;
    self.isGenerateApiKeyVisible = ko.observable(false);
    self.isClearApiKeyVisible = ko.observable(false);

    self.hasApiKey = ko.pureComputed(function () {
        var apiKey = self.user.apiKey();
        return apiKey;
    });

    self.generateApiKey = function () {
        var originalUser = self.user.getOriginalUser();
        self.user.generateApiKey().always(function () {
            self.isGenerateApiKeyVisible(false);
            events.userOnUpdated.dispatch(originalUser, self.user.getOriginalUser());
        });
    };

    self.clearApiKey = function () {
        var originalUser = _.clone(self.user.getOriginalUser());
        self.user.clearApiKey().always(function () {
            self.isClearApiKeyVisible(false);
            events.userOnUpdated.dispatch(originalUser, self.user.getOriginalUser());
        });
    };

    self.cancelGenerateApiKey = function () {
        self.isGenerateApiKeyVisible(false);
    };

    self.cancelClearApiKey = function () {
        self.isClearApiKeyVisible(false);
    };
}

ViewModel.prototype.showGenerateApiKeyDialog = function () {
    this.isGenerateApiKeyVisible(true);
};

ViewModel.prototype.showClearApiKeyDialog = function () {
    this.isClearApiKeyVisible(true);
};

ViewModel.prototype.clearApiKey = function () {
    this.isClearKeyVisible(true);
};

export default { viewModel: ViewModel, template: template };