import net from "App/net";

var FoulingClient = function () {

};

FoulingClient.prototype.getSpeedLossHistory = function (query) {
  return net.get(`/Fouling/SpeedLoss`, query);
};


FoulingClient.prototype.getSeaSurfaceTemperature = function (query) {
  return net.get(`/Fouling/OperationProfile`, query);
};

FoulingClient.prototype.getSpeedLossStatistics = function (vesselId) {
  return net.get(`/Fouling/Statistics`, {
    vesselId: vesselId
  });
}

FoulingClient.prototype.getLatestSpeedLossStatistics = function (vesselIds) {
  return net.post(`/Fouling/LatestStatistics`, vesselIds);
}

FoulingClient.prototype.getHullCoatingType = function () {
  return net.get(`/Fouling/HullCoatingType`);
};

FoulingClient.prototype.getHullCoatingManufacturer = function () {
  return net.get(`/Fouling/HullCoatingManufacturer`);
};

FoulingClient.prototype.postHullCoatingVessel = function (payload) {
  return net.post(`/Fouling/HullCoatingVessel`, payload);
};

FoulingClient.prototype.hullCoatingVessel = function (vesselId) {
  return net.get(`/Fouling/HullCoatingVessel/${vesselId}`);
};

FoulingClient.prototype.hullCoatingVessels = function (vesselIds) {
  return net.post(`/Fouling/HullCoatingVessels/`, vesselIds);
};

FoulingClient.prototype.foulingChartConfig = function (vesselId) {
  return net.get(`/Fouling/Config/${vesselId}`);
};

FoulingClient.prototype.foulingChartConfigs = function (vesselIds) {
  return net.post(`/Fouling/Configs`, vesselIds);
};

FoulingClient.prototype.updateFoulingChartConfig = function (payload) {
  return net.put(`/Fouling/Config`, payload);
};


FoulingClient.prototype.getPropulsionEfficency = function (query) {
  return net.get(`/Fouling/PropulsionEfficiencyData`, query);
};

FoulingClient.prototype.getPropulsionEfficencyCurve = function (query) {
  return net.get(`/Fouling/PropulsionEfficiencyCurve`, query);
};

FoulingClient.prototype.getAddedFuelConsumption = function (vesselId) {
  return net.get(`/Fouling/AddedFuelConsumption`, {
    vesselId: vesselId
  });
};

FoulingClient.prototype.getEventImpact = function (vesselId, eventDate) {
  return net.get(`/Fouling/Impact`, {
    vesselId: vesselId,
    eventDate: eventDate
  });
};

FoulingClient.prototype.getAddedFuelConsumptions = function (vesselIds) {
  return net.post(`/Fouling/AddedFuelConsumptions`, vesselIds);
};

export default new FoulingClient();
