import ko from "knockout";
import _ from "underscore";
import companiesClient from "Clients/companies-client";
import stringHelper from "Utilities/string-helper";

var ViewModel = function (model, companies) {
    var self = this;
    self.originalCompany = undefined;
    self.companyList = companies;
    self.id = ko.observable();
    self.name = ko.observable().extend({
        required: true,
        validation: {
            async: true,
            validator: function (val, params, callback) {
                if ((self.originalCompany != null) && (stringHelper.equalsIgnoreCase(val, self.originalCompany.name))) {
                    // Same as "original name".
                    callback(true);
                } else {
                    callback(!self.companyList.exists(val));
                }
            },
            message: "A company with that name already exists.",
        },
    });
    self.contactName = ko.observable();
    self.contactPhone = ko.observable();
    self.contactEmail = ko.observable().extend({ email: true });
    self.subscriptionStatus = ko.observable();
    self.dcMasterDataCompanyId = ko.observable();
    self.notes = ko.observable();

    self.setModel(model);

    //For accessing validation from the parent component to for example enable/disable "create/save" button.
    self.errors = ko.validation.group(self);
    self.isValid = ko.computed(function () {
        return self.errors().length === 0;
    });
};

ViewModel.prototype.setModel = function (model) {
    var self = this;

    self.originalCompany = model;

    if (model) {
        // Edit mode
        self.id(model.id);
        self.name(model.name);
        self.contactName(model.contactName);
        self.contactPhone(model.contactPhone);
        self.contactEmail(model.contactEmail);
        self.subscriptionStatus(model.subscriptionStatus);
        self.dcMasterDataCompanyId(model.dcMasterDataCompanyId);
        self.notes(model.notes);
    } else {
        // "New" mode
        self.id(undefined);
        self.name(undefined);
        self.contactName(undefined);
        self.contactPhone(undefined);
        self.contactEmail(undefined);
        self.subscriptionStatus(undefined);
        self.dcMasterDataCompanyId(undefined);
        self.notes(undefined);
    }
};

ViewModel.prototype.getOriginalCompany = function () {
    return this.originalCompany;
};

ViewModel.prototype.save = function () {
    var self = this;
    var company = _.clone(self.originalCompany) || {};
   
    company.name = self.name();
    company.contactName = self.contactName();
    company.contactPhone = self.contactPhone();
    company.contactEmail = self.contactEmail();
    company.subscriptionStatus = self.subscriptionStatus();
    company.dcMasterDataCompanyId = self.dcMasterDataCompanyId();
    company.notes = self.notes();

    var promise;
    if (company.id) {
        promise = companiesClient.update(company);
    } else {
        promise = companiesClient.create(company);
    }
    promise.done(function (data) {
        self.setModel(data);
    });
    return promise;
};

ViewModel.prototype.remove = function () {
    return companiesClient.remove(this.originalCompany);    
};

export default ViewModel;