import template from "./geo-coordinates.html";
import ko from "knockout";
import _ from "underscore";
import locationHelper from "Utilities/location-helper";
import dateHelper from "Utilities/date-helper";

function ViewModel(params) {
    var self = this;
    self.location = ko.utils.unwrapObservable(params.location);
    self.latitudeText = locationHelper.formatLatLongValue(self.location.geoCoordinate.latitude, "latitude");
    self.longitudeText = locationHelper.formatLatLongValue(self.location.geoCoordinate.longitude, "longitude");
    self.timestampText = dateHelper.getFormatedDateTimeString(self.location.timestamp);
}

export default { viewModel: ViewModel, template: template };