import { VuexModule, Module, Action } from "vuex-module-decorators";
import CiiClient from "Clients/cii-client";
import { CiiData } from "@/types/ciiData";

@Module({ namespaced: true, name: "Cii" })
class Cii extends VuexModule {

  @Action({ rawError: true })
  public async fetchDays(query: { vesselIds: string | number; start: string; end: string; }): Promise<CiiData[]> {
    try {
      return await CiiClient.getDays(query);
    } catch (error) {
      throw ({ message: "Failed to fetch calculations", error });
    }
  }

  @Action({ rawError: true })
  public async fetchYtd(query: { vesselIds: string }): Promise<CiiData[]> {
    try {
      return await CiiClient.getYtd(query);
    } catch (error) {
      throw ({ message: "Failed to fetch calculations", error });
    }
  }

  @Action({ rawError: true })
  public async fetchYear(query: { vesselIds: string; years: string }): Promise<CiiData[]> {
    try {
      return await CiiClient.getYear(query);
    } catch (error) {
      throw ({ message: "Failed to fetch calculations", error });
    }
  }
}

export default Cii;