import net from "App/net";

var VesselPerformanceStatusesClient = function () {

};

VesselPerformanceStatusesClient.prototype.get = function (vesselId) {
    return net.get("/VesselPerformanceStatuses/" + vesselId);
};

export default new VesselPerformanceStatusesClient();