



























































































































































import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/clients/Auth.module";
import { LoginData } from "@/types/loginData";

const Auth = getModule(AuthModule, store);

@Component
export default class Login extends Vue {
  //data to send
  user: LoginData = { username: "", password: "", persist: true };
  //login form state
  validLogin = true;
  //reset password state
  validResetPassword = true;
  //state loading login
  loading = false;
  //error message if login fails
  message = "";
  //toggle input type to reveal password
  showPassword = false;
  //state toggle between login and forgot password screens
  forgotPassword = false;
  //data to send in forgot email
  forgotPasswordEmail = "";
  //state submittet forgot passwor email
  submittedEmail = false;
  //state submitting forgot passwor email
  submittingEmail = false;
  //alert is either success or error
  forgotPasswordAlertType = "success";
  //alert message after submitting forgot password email, fetched from network response
  forgotPasswordResponse = "";
  isUsernameAutofilled = false;
  isPasswordAutofilled = false;
  //input validation
  usernameRules = {
    required: (value: string) => !!value || "Username required",
  };
  emailRules = {
    required: (value: string) => !!value || "Email required",
    valid: (value: string) => /^([\w\.\-\+]+)@([\w\-]+)((\.(\w){2,6})+)$/.test(value) || "Email must be valid",
  };
  passwordRules = {
    required: (value: string) => !!value || "Password required",
  };

  get isLoggedIn(): boolean {
    return Auth.isLoggedIn;
  }

  created(): void {
    //redirect to frontpage if already logged in
    if (this.isLoggedIn) {
      this.$router.push("/").catch(error => {});
    }
  }

  handleLogin(): void {
    this.loading = true;
    this.message = "";
    if (this.user.username && this.user.password) {
      Auth.login(this.user).then(
        data => {
          //we do the catch here to swallow an error thrown if user is redirected to kognifai
          this.$router.push("/").catch(error => {});
        },
        error => {
          this.loading = false;
          this.message = error;
        }
      );
    }
  }

  handleForgotPassword(): void {
    this.forgotPasswordResponse = "";
    this.forgotPasswordAlertType = "success";
    this.submittedEmail = false;
    this.submittingEmail = true;
    Auth.lostPassword(this.forgotPasswordEmail).then(
      response => {
        this.forgotPasswordResponse = response;
        this.submittedEmail = true;
        this.submittingEmail = false;
      },
      error => {
        this.forgotPasswordAlertType = "error";
        this.forgotPasswordResponse = error.responseJSON;
        this.submittedEmail = true;
        this.submittingEmail = false;
      }
    );
  }

  checkAutofilledInputs(): void {
    setTimeout(() => {
      const usernameInput = document.querySelector("input[type='email']:-webkit-autofill");
      const passwordInput = document.querySelector("input[type='password']:-webkit-autofill");
      if (usernameInput) this.isUsernameAutofilled = true;
      if (passwordInput) this.isPasswordAutofilled = true;
      this.validLogin = true;
    }, 300);
  }

  mounted(): void {
    this.checkAutofilledInputs();
  }
}
