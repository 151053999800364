import template from "./fuel-tank-management.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import dateHelper from "Utilities/date-helper";
import fuelTankOperationsClient from "Clients/ft-operations-client";
import events from "App/events";

var Tab = function(name, componentName) {
    this.name = name;
    this.componentName = componentName;
};

function ViewModel(params) {
    var self = this;
    self.departureVoyageVesselEvent = params.departureVoyageVesselEvent;
    self.endOfVoyageVesselEvent = params.endOfVoyageVesselEvent;
    self.selectedVoyageVesselEvent = params.selectedVoyageVesselEvent;
    self.fuelTanks = params.selectedVoyageVesselEvent.activeFuelTanks;
    self.isVoyageDataConfirmed = params.selectedVoyageVesselEvent.isVoyageDataConfirmed;

    self.selectedFuelTank = ko.observable(self.fuelTanks()[0]);

    self.tabs = [
        new Tab("Fuel consumption", "ft-fuel-consumptions"),
        new Tab("Bunkering", "ft-operations"),
        new Tab("Soundings", "ft-soundings"),
    ];
    self.selectedTab = ko.observable(self.tabs[0]);

    self.selectTab = function(tab) {
        var isMissingFuelOperations = self.isMissingFuelOperations();
        if (!isMissingFuelOperations) {
            self.selectedTab(tab);
        }
    }.bind(self);

    self.departureDate = ko.pureComputed(function() {
        var departureVesselEvent = self.departureVoyageVesselEvent().vesselEvent();
        return moment.utc(departureVesselEvent.timestamp);
    });

    self.minFuelOperationInputDate = ko.pureComputed(function() {
        var departureDate = self.departureDate();
        return moment.utc(departureDate).add(-12, "months");
    });

    self.isLoadingFuelTankOperations = ko.observable(false);
    self.fuelTankOperations = ko.observableArray([]);
    self.selectedFuelTankBinding = self.selectedFuelTank.subscribe(function(newFuelTank) {
        self.findFuelTankOperations();
    });

    self.sortedFuelTankOperations = ko.pureComputed(function() {
        var fuelTankOperations = self.fuelTankOperations();
        return _.sortBy(fuelTankOperations, "timestamp");
    });

    var endOfVoyageVesselEvent = self.endOfVoyageVesselEvent();
    self.fuelOperationsQueryDates = ko.observable({
        fromDate: self.minFuelOperationInputDate(),
        toDate: endOfVoyageVesselEvent ? moment(endOfVoyageVesselEvent.vesselEvent().timestamp).add(1, "days") : null,
    });

    self.fuelTankOperationDeletedBinding = events.fuelTankOperationDeleted.add(function(fuelTankOperation) {
        self.fuelTankOperations.remove(fuelTankOperation);
    });

    self.fuelTankOperationCreatedBinding = events.fuelTankOperationCreated.add(function(fuelTankOperation) {
        self.fuelTankOperations.push(fuelTankOperation);
    });

    self.isMissingFuelOperations = ko.pureComputed(function() {
        var isLoadingFuelTankOperations = self.isLoadingFuelTankOperations();
        var isVoyageDataConfirmed = self.isVoyageDataConfirmed();
        if (isLoadingFuelTankOperations || isVoyageDataConfirmed) return false;

        var sortedFuelTankOperations = self.sortedFuelTankOperations();
        if (sortedFuelTankOperations.length === 0) return true;

        var departureDate = self.departureDate();

        return moment.utc(sortedFuelTankOperations[0].timestamp) > departureDate;
    });

    self.isMissingFuelOperationsBinding = self.isMissingFuelOperations.subscribe(function(isMissingFuelOperations) {
        if (isMissingFuelOperations) {
            self.selectedTab(self.tabs[1]);
        }
    });

    self.findFuelTankOperations();
}

ViewModel.prototype.dispose = function() {
    this.selectedFuelTankBinding.dispose();
    this.fuelTankOperationDeletedBinding.detach();
    this.fuelTankOperationCreatedBinding.detach();
    this.isMissingFuelOperationsBinding.dispose();
};

ViewModel.prototype.findFuelTankOperations = function() {
    var self = this;
    var selectedFuelTank = self.selectedFuelTank();
    var queryDates = self.fuelOperationsQueryDates();
    var fromDate = dateHelper.getSubmitDateString(queryDates.fromDate);
    var toDate = dateHelper.getSubmitDateString(queryDates.toDate);

    self.isLoadingFuelTankOperations(true);
    fuelTankOperationsClient.find(selectedFuelTank.id, fromDate, toDate).done(function(data) {
        self.fuelTankOperations(data);
    }).always(function() {
        self.isLoadingFuelTankOperations(false);
    });
};

export default { viewModel: ViewModel, template: template };