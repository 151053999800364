import _ from "underscore";
import urlHelper from "Utilities/url-helper";
import dateHelper from "Utilities/date-helper";

function VesselCompareRowData(vessel, compareGroup) {
    var self = this;
    self.vessel = vessel;
    self.compareGroup = compareGroup;
    self.vesselIndex = _.indexOf(self.compareGroup.vessels, self.vessel);
    self.longTrends = self.compareGroup.longTrends;
    self.longTrendPeriods = self.compareGroup.longTrendPeriods;

    self.name = self.vessel.name;
    self.url = urlHelper.getVesselUrl(self.vessel);
    self.lastExportDateText = dateHelper.getFormatedDateTimeString(self.vessel.lastExportDate);

    self.vesselLongTrend = _.find(self.longTrends, function(longTrend) {
        return longTrend.vesselId === self.vessel.id;
    });

    self.vesselLongTrendIds = _.pluck(self.vesselLongTrends, "id");

    self.vesselLongTrendPeriods = _.filter(self.longTrendPeriods, function(longTrendPeriod) {
        return longTrendPeriod.longTrendId === self.vesselLongTrend.id;
    });

    self.sortedVesselLongTrendPeriods = _.sortBy(self.vesselLongTrendPeriods, "start");

    self.lastLongTrendPeriod = _.last(self.sortedVesselLongTrendPeriods);
    self.lastPeriodStartDate = self.lastLongTrendPeriod.start;
    self.lastPeriodStartDateText = dateHelper.getFormatedDateString(self.lastPeriodStartDate);
    self.isBenchmarking = self.vesselLongTrend.performanceStatus === "BenchMarking";

    if (!self.isBenchmarking) {
        self.changePerYear = self.lastLongTrendPeriod.trendline.coefficient * 365;
        self.benchmarkPointValue = self.lastLongTrendPeriod.benchmarkPoint.y;
        self.deviationFromBenchmark = self.lastLongTrendPeriod.trendline.end.y - self.lastLongTrendPeriod.benchmarkPoint.y;
    }
}

export default VesselCompareRowData;