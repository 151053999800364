import template from "./departure-list-item.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import emissionReportVesselEventsClient from "Clients/emission-report-vessel-events-client";
import vesselEventHelper from "Utilities/vessel-event-helper";

function ViewModel(params) {
    var self = this;
    self.vesselEvent = params.vesselEvent;
    self.allSortedVesselEvents = params.allSortedVesselEvents;

    self.sourceText = vesselEventHelper.getVesselEventSourceText(self.vesselEvent);
    self.sourceImageSrc = vesselEventHelper.getVesselEventSourceImageSrc(self.vesselEvent);

    self.isDeleting = ko.observable(false);
    self.canDelete = ko.pureComputed(function() {
        var allSortedVesselEvents = self.allSortedVesselEvents();
        var vesselEventIndex = _.indexOf(allSortedVesselEvents, self.vesselEvent);
        var left = allSortedVesselEvents[vesselEventIndex - 1];
        var right = allSortedVesselEvents[vesselEventIndex + 1];

        var departureType = "Departure";
        var isLeftOk = !left || left.type === departureType;
        var isRightOk = !right || right.type === departureType;

        return isLeftOk && isRightOk;
    });
}

ViewModel.prototype.dispose = function() {
};

ViewModel.prototype.notifySelect = function() {
    events.departureSelected.dispatch(this.vesselEvent);
};

ViewModel.prototype.deleteDeparture = function() {
    var self = this;

    self.isDeleting(true);
    emissionReportVesselEventsClient.remove(self.vesselEvent).fail(function() {
        self.isDeleting(false);
    });
};

export default { viewModel: ViewModel, template: template };
