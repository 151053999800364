import net from "App/net";
import _ from "underscore";
import Store from "@/store";
import logVariableHelper from "../utilities/log-variable-helper";

var LogVariablesClient = function () {

};

LogVariablesClient.prototype.findAll = function (vesselId, unitType, hasLogData, extended = false) {
    var url = Store.getters["User/isKognifai"] ? "/Kognifai/LogVariables" : "/LogVariables/Find";
    if (!Store.getters["User/isKognifai"] && extended) {
      url = "/LogVariables/Extended";
    }
    var filter = {
        vesselId: vesselId,
    };

    if (unitType) {
        filter.unitType = unitType;
    }

    //important that hasLogData is set on filter, otherwise the default value of API (true) is used.
    filter.hasLogData = !_.isUndefined(hasLogData) ? hasLogData : "";

    return net.get(url, filter)
      .done((variables) => {
        new Promise((resolve) => {
          var dupNames = logVariableHelper.getDuplicateLogVariableNames(variables);

          variables.forEach(variable => {
              variable['displayName'] = logVariableHelper.formatLogVariableDisplayName(variable, dupNames);
          });

          resolve(variables);
        })
    });
};

LogVariablesClient.prototype.findAvailableLogVariables = function (vesselId, unitType) {
    return this.findAll(vesselId, unitType, true, false);
};

LogVariablesClient.prototype.findAvailableLogVariablesExtended = function(vesselId, unitType) {
    return this.findAll(vesselId, unitType, true, true);
}

LogVariablesClient.prototype.findMassFlowRateVariables = function (vesselId) {
    return this.findAvailableLogVariables(vesselId, "MassFlowRate");
};

LogVariablesClient.prototype.findSpeedVariables = function (vesselId) {
    return this.findAvailableLogVariables(vesselId, "Speed");
};

LogVariablesClient.prototype.findGpsSpeedVariable = function (vesselId) {
    return net.get("/LogVariables/" + vesselId + "/GpsSpeed");
};

LogVariablesClient.prototype.findVesselsLogVariables = function (vesselIds) {
    var filter = {
        vesselIds: vesselIds,
    };

    return net.get("/LogVariables/", filter);
};

LogVariablesClient.prototype.findVesselLogVariablesTaxa = function (vesselId) {
    var filter = {
        vesselId: vesselId,
    };

    return net.get("/LogVariables/Taxa", filter);
};

LogVariablesClient.prototype.findVesselLogVariablesShaftPower = function (vesselId) {
    var filter = {
        vesselId: vesselId,
    };

    return net.get("/LogVariables/ShaftPower", filter);
};

export default new LogVariablesClient();
