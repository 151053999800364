import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import client from "Clients/users-client";
import stringHelper from "Utilities/string-helper";

var ViewModel = function () {
    var self = this;
    self.list = ko.observableArray([]);
    self.isLoading = ko.observable(false);

    self.load();

    self.onCreatedBinding = events.userOnCreated.add(function (newItem) {
        self.list.push(newItem);
    });

    self.onUpdatedBinding = events.userOnUpdated.add(function (oldItem, updatedItem) {
        self.list.remove(function (item) {
            return item.id === oldItem.id;
        });
        self.list.push(updatedItem);
    });

    self.onRemovedBinding = events.userOnRemoved.add(function (removedItem) {
        self.list.remove(function (item) {
            return item.id === removedItem.id;
        });
    });
};

ViewModel.prototype.dispose = function () {
    this.onUpdatedBinding.detach();
    this.onCreatedBinding.detach();
    this.onRemovedBinding.detach();
};

ViewModel.prototype.load = function () {
    var self = this;
    self.list([]);
    self.isLoading(true);

    client.getAll().done(function (data) {
        self.list(data);
    }).always(function () {
        self.isLoading(false);
    });
};

ViewModel.prototype.getList = function () {
    return this.list;
};

ViewModel.prototype.isLoading = function () {
    return this.isLoading;
};

ViewModel.prototype.exists = function (userName) {
    var match = _.find(this.list(), function (o) {
        return stringHelper.equalsIgnoreCase(userName, o.email);
    });

    return match !== undefined;
};

export default ViewModel;