import net from "App/net";
import events from "App/events";

var Client = function() {

};

Client.prototype.find = function(fuelTankId, departureId, vesselEventId) {
    var filter = {
        fuelTankId: fuelTankId,
        departureId: departureId,
        vesselEventId: vesselEventId,
    };

    return net.get("/FuelTankFuelConsumptions/Find", filter);
};

Client.prototype.create = function(fuelTankFuelConsumption) {
    return net.post("/FuelTankFuelConsumptions", fuelTankFuelConsumption);
};

Client.prototype.remove = function(fuelTankFuelConsumption) {
    return net.del("/FuelTankFuelConsumptions/" + fuelTankFuelConsumption.id).done(function() {
        events.fuelTankFuelConsumptionDeleted.dispatch(fuelTankFuelConsumption);
    });
};

export default new Client();