import template from "./cp-module-table.html";
import ko from "knockout";
import _ from "underscore";

function ViewModel(params) {
    var self = this;
    self.timePeriods = params.timePeriods;

    self.sumOfActualFuelConsumption = ko.pureComputed(function () {
        var sum = 0;
        var timePeriods = self.timePeriods();
        for (var i = 0; i < timePeriods.length; i++) {
            var timePeriod = timePeriods[i];
            var value = Number(timePeriod.cpModule().actualFuelConsumption());
            if (!_.isNaN(value) && _.isNumber(value)) {
                sum += value;
            }
        }
        return sum;
    });

    self.sumOfReferenceFuelConsumption = ko.pureComputed(function () {
        var sum = 0;
        var timePeriods = self.timePeriods();
        for (var i = 0; i < timePeriods.length; i++) {
            var timePeriod = timePeriods[i];
            var value = Number(timePeriod.cpModule().referenceFuelConsumption());
            if (!_.isNaN(value) && _.isNumber(value)) {
                sum += value;
            }
        }

        return sum;
    });

    self.sumOfDifferenceInFuelConsumption = ko.pureComputed(function() {
        var sum = 0;
        var timePeriods = self.timePeriods();
        for (var i = 0; i < timePeriods.length; i++) {
            var timePeriod = timePeriods[i];
            var value = Number(timePeriod.cpModule().differenceBetweenConsumptions());
            if (!_.isNaN(value) && _.isNumber(value)) {
                sum += value;
            }
        }
        return sum;
    });

    self.sumOfDistanceTravelled = ko.pureComputed(function() {
        var sum = 0;
        var timePeriods = self.timePeriods();
        for (var i = 0; i < timePeriods.length; i++) {
            var timePeriod = timePeriods[i];
            sum += timePeriod.cpModule().distanceTravelled();
        }

        return sum;
    });
}

export default {viewModel: ViewModel, template: template};