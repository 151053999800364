import template from "./ft-sounding.html";
import ko from "knockout";
import fuelTankSoundingsClient from "Clients/ft-soundings-client";

function ViewModel(params) {
    var self = this;
    self.fuelTankSounding = params.fuelTankSounding;
    self.isRemoving = ko.observable(false);
    self.isVoyageDataConfirmed = params.isVoyageDataConfirmed;
}

ViewModel.prototype.dispose = function() {
};

ViewModel.prototype.remove = function() {
    var self = this;
    self.isRemoving(true);
    fuelTankSoundingsClient.remove(self.fuelTankSounding).always(function() {
        self.isRemoving(false);
    });
};

export default { viewModel: ViewModel, template: template };