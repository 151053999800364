import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import ciiWidgetClient from "Clients/cii-widget-client";
import { CiiWidgetConfig } from "@/types/ciiWidgetConfig";

@Module({ namespaced: true, name: "CiiWidgetConfiguration" })
class CiiWidget extends VuexModule {
  private _configsExpired = true;
  private _configs: CiiWidgetConfig[] = [];

  @Mutation
  public SET_EMISSIONS_WIDGET_CONFIGS(ciiWidgetConfigs: CiiWidgetConfig[]): void {
    this._configsExpired = false;
    this._configs = ciiWidgetConfigs;
  }

  @Mutation
  public SET_CONFIGS_EXPIRED(value: boolean): void {
    this._configsExpired = value;
  }

  @Mutation
  public ADD_WIDGET_CONFIG(config: CiiWidgetConfig): void {
    this._configs.push(config);
    this._configsExpired = true;
  }

  @Action({ rawError: true })
  public async refreshCiiWidgetConfigs(): Promise<void> {
    let ciiWidgetConfigs = [];
    try {
      ciiWidgetConfigs = await ciiWidgetClient.getAll();
    } catch (error) {
      throw ({ message: "Failed to refresh widget configs", error });
    }
    this.context.commit("SET_EMISSIONS_WIDGET_CONFIGS", ciiWidgetConfigs);
  }

  @Action({ rawError: true })
  public async getCiiWidgetConfigs(): Promise<CiiWidgetConfig[]> {
    if (!this._configsExpired) {
      return this._configs;
    } else {
      await this.context.dispatch("refreshCiiWidgetConfigs");
      return this._configs;
    }
  }

  @Action({ rawError: true })
  public async getCiiWidgetConfigById(ciiWidgetId: number): Promise<CiiWidgetConfig> {
    if (this._configsExpired) {
      await this.context.dispatch("refreshCiiWidgetConfigs");
    }
    const config = this._configs.find(config => config.id === ciiWidgetId);
    if (config) {
      for (let i = 0; i < config.vessels.length; i++) {
        const vessel = await this.context.dispatch("Vessels/get", config.vessels[i].id, { root: true });
        if (vessel) config.vessels[i] = vessel;
      }

      return config;
    }

    else throw ({ message: "Failed to find widget config" });
  }

  @Action({ rawError: true })
  public async addNewConfig(): Promise<CiiWidgetConfig> {
    try {
      const config = await ciiWidgetClient.new({ vessels: [], ytd: true, showTable: true });
      this.context.commit("ADD_WIDGET_CONFIG", config);
      return config;
    } catch (error) {
      throw ({ message: "Failed to add widget config", error });
    }
  }

  @Action({ rawError: true })
  public async newCiiWidgetConfig(ciiWidgetConfig: CiiWidgetConfig): Promise<CiiWidgetConfig> {
    try {
      const response = await ciiWidgetClient.new(ciiWidgetConfig);
      await this.context.dispatch("expireConfig");
      this.context.dispatch("refreshCiiWidgetConfigs");
      return response;
    } catch (error) {
      throw ({ message: "Failed to add new widget config", error });
    }
  }

  @Action({ rawError: true })
  public async updateCiiWidgetConfig(ciiWidgetConfig: CiiWidgetConfig): Promise<void> {
    try {
      await ciiWidgetClient.update(ciiWidgetConfig);
      await this.context.dispatch("expireConfig");
    } catch (error) {
      throw ({ message: "Failed to update widget config", error });
    }
    this.context.dispatch("refreshCiiWidgetConfigs");
  }

  @Action({ rawError: true })
  public async deleteCiiWidgetConfig(ciiWidgetConfigId: number): Promise<void> {
    try {
      await ciiWidgetClient.delete(ciiWidgetConfigId);
      await this.context.dispatch("expireConfig");
    } catch (error) {
      throw ({ message: "Failed to delete widget config", error });
    }
    this.context.dispatch("refreshCiiWidgetConfigs");
  }

  @Action({ rawError: true })
  public expireConfig(): void {
    this.context.commit("SET_CONFIGS_EXPIRED", true);
  }

  get configs(): CiiWidgetConfig[] {
    return this._configs;
  }
}

export default CiiWidget;
