import numberHelper from "Utilities/number-helper";

function formatLatLongValue(value, latLongType) {
    var coordinateParts = getCoordinateParts(value, latLongType);
    return coordinateParts.degrees + "°" + coordinateParts.minutes + "'" + coordinateParts.direction;
}

function getCoordinateParts(value, latLongType) {
    var isLatitude = latLongType === "latitude";
    var latLongMinLimit = isLatitude ? -90 : -180;
    var latLongMaxLimit = isLatitude ? 90 : 180;

    if (value < latLongMinLimit || value > latLongMaxLimit) {
        value = 0;
    }

    var absoluteValue = Math.abs(value);
    var degrees = Math.floor(absoluteValue);
    var minuteFraction = absoluteValue - degrees;
    var minutes = minuteFraction * 60;

    var latLongPositiveSymbol = isLatitude ? "N" : "E";
    var latLongNegativeSymbol = isLatitude ? "S" : "W";

    var direction = value >= 0 ? latLongPositiveSymbol : latLongNegativeSymbol;

    return {
        value: value,
        degrees: degrees,
        minutes: numberHelper.round(minutes, 4),
        direction: direction,
    };
}

export default {
    formatLatLongValue: function (value, latLongType) {
        return formatLatLongValue(value, latLongType);
    },
    getCoordinateParts: function (value, latLongType) {
        return getCoordinateParts(value, latLongType);
    },
};