import net from "App/net";

class AuthService {
  login(username: string, password: string) {
    return net.post("/Authenticate", {
      username,
      password,
    });
  }

  logout() {
    localStorage.removeItem("ACCESS_TOKEN");
    localStorage.removeItem("TOKEN_EXPIRY_DATE");
  }

  lostPassword(Email: string) {
    return net.post("/Account/LostPassword", {
      Email,
    });
  }

  resetPassword(Username: string, Token: string, Password: string) {
    return net.post("/Account/ResetPassword", {
      Username,
      Token,
      Password,
    });
  }

  changePassword(Username: string, CurrentPassword: string, NewPassword: string) {
    return net.post("/Account/ChangePassword", {
      Username,
      CurrentPassword,
      NewPassword,
    });
  }

  register(username: string, email: string, password: string) {
    console.warn("TODO: implement signup");
  }
}

export default new AuthService();
