import net from "App/net";

var BenefitsClient = function() {

};

BenefitsClient.prototype.find = function(trendEventId, longTrendId) {
    var filter = {
        trendEventId: trendEventId,
        longTrendId: longTrendId,
    };

    return net.get("/Benefits/Find", filter);
};

export default new BenefitsClient();