import template from "./graph-config-variable-selection.html";
import ko from "knockout";
import _ from "underscore";
import GraphVariableGroup from "ViewModels/graphVariableGroup";

function ViewModel(params) {
    var self = this;

    self.maxGroups = 10;

    self.variableSelectionUpdateTrigger = ko.observable();
    self.currentGroupIndex = ko.observable(0);

    self.groups = ko.observableArray([]);

    self.variables = params.variables;
    self.selectedVariables = params.selectedVariables;

    var initialVariableSelection = initializeVariableSelection(self.selectedVariables());

    self.variableSelection = ko.computed(function () {
        self.variableSelectionUpdateTrigger();
        var selection = "";
        var selectionGroups = [];
        _.each(self.groups(), function (group, index) {
            var groupVariableIds = group.selectedIds();
            if (index == 0 && groupVariableIds.length === 0) {
                selection += "|";
            }

            if (groupVariableIds.length > 0) {
                selection += groupVariableIds;

                if (index == 0) {
                    selection += "|";
                } else {
                    selection += ";";
                }
            }
        });
        return self.selectedVariables(selection);
    });

    _.each(initialVariableSelection, function (item, index) {
        var groupName = index === 0 ? "Distinct" : "Group";
        self.groups.push(new GraphVariableGroup(self, index, groupName, self.variables(), item, self.variableSelectionUpdateTrigger));
    });

    self.currentGroup = ko.pureComputed(function () {
        var index = self.currentGroupIndex();
        return self.groups()[index];
    });

    self.addGroup = function () {
        self.groups.push(new GraphVariableGroup(self, self.groups().length, "Group", self.variables(), [], self.variableSelectionUpdateTrigger));
        self.currentGroupIndex(self.groups().length - 1);
    };

    self.clear = function() {
        self.groups.splice(1, self.groups().length);
        self.currentGroupIndex(0);
        self.groups()[0].clear();
    };
}

function initializeVariableSelection(variableSelectionString) {
    if (variableSelectionString === "") {
        variableSelectionString = "|";
    }

    var initialVariableStrings = variableSelectionString.split("|");

    initialVariableStrings = removeEmptyLastElementFromArray(initialVariableStrings);
    initialVariableStrings = getInitialVariableSelectionGroups(initialVariableStrings);

    return getInitialVariableSelectionIds(initialVariableStrings);
}

function removeEmptyLastElementFromArray(array) {
    if (array[array.length - 1] === "") {
        array.pop();
    }

    return array;
}

function getInitialVariableSelectionGroups(variableSelectionStrings) {
    if (variableSelectionStrings.length > 1) {
        var groups = variableSelectionStrings[1].split(";");
        variableSelectionStrings.pop();

        _.each(groups, function (group) {
            variableSelectionStrings.push(group);
        });
    }

    return variableSelectionStrings;
}

function getInitialVariableSelectionIds(array) {
    var initialIds = _.map(array, function (ids) {
        return ids.split(/[;,]/);
    });

    return initialIds;
}

ViewModel.prototype.dispose = function () {
    this.variableSelection.dispose();
};

export default { viewModel: ViewModel, template: template };