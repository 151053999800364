import ko from "knockout";
import $ from "jquery";
import dateHelper from "Utilities/date-helper";
import "pickadate/lib/picker.date";

function UpdatePickerDateProperty(picker, property, newDateValue) {
    if (newDateValue) {
        picker.set(property, dateHelper.getJavascriptDate(newDateValue));
    } else {
        picker.set(property, null);
    }
}

ko.bindingHandlers.datepicker = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var valueBinding = allBindingsAccessor.get("value");

        $(element).pickadate({
            format: "dd. mmm, yyyy",
            formatSubmit: "yyyy-mm-dd",
            selectYears: true,
            selectMonths: true,
            firstDay: 1,
            hiddenSuffix: "Value",
            onSet: function () {
                if (valueBinding) {
                    valueBinding(element.value);
                }
            },
            onClose: function() {
                $(element).blur();
            },
        });

        var picker = $(element).pickadate("picker");

        var minDate = valueAccessor().minDate;
        var minDateBinding = null;
        if (minDate) {
            var minDateValue = minDate();
            UpdatePickerDateProperty(picker, "min", minDateValue);

            minDateBinding = minDate.subscribe(function(newMinDate) {
                UpdatePickerDateProperty(picker, "min", newMinDate);

                if (newMinDate) {
                    var newMinDateMoment = dateHelper.getDate(newMinDate);
                    var currentDateMoment = dateHelper.getDate(valueBinding());
                    if (currentDateMoment < newMinDateMoment) {
                        valueBinding(dateHelper.getFormatedDateString(newMinDateMoment)); // change selected date to the new min date
                    }
                }
            });
        }

        var maxDate = valueAccessor().maxDate;
        var maxDateBinding = null;
        if (maxDate) {
            var maxDateValue = maxDate();
            UpdatePickerDateProperty(picker, "max", maxDateValue);

            maxDateBinding = maxDate.subscribe(function(newMaxDate) {
                UpdatePickerDateProperty(picker, "max", newMaxDate);

                if (newMaxDate) {
                    var newMaxDateMoment = dateHelper.getDate(newMaxDate);
                    var currentDateMoment = dateHelper.getDate(valueBinding());
                    if (currentDateMoment > newMaxDateMoment) {
                        valueBinding(dateHelper.getFormatedDateString(newMaxDateMoment));   // change selected date to the new max date
                    }
                }
            });
        }

        ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
            if (minDateBinding) minDateBinding.dispose();
            if (maxDateBinding) maxDateBinding.dispose();
        });
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
        var picker = $(element).pickadate("picker");
        var valueBinding = allBindingsAccessor.get("value");

        //Don't set date if value is empty (e.g. '').
        //Otherwise, the date will be set to today's date.
        var value = valueBinding();
        if (!value) return;

        picker.set("select",valueBinding(), {
            format: "dd. mmm, yyyy",
            formatSubmit: "yyyy-mm-dd",
            selectYears: true,
            selectMonths: true,
            firstDay: 1,
            hiddenSuffix: "Value",
        });
    },
};