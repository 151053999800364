import _ from "underscore";

function getPerformanceStatusText(performanceStatus) {
  switch (performanceStatus) {
    case "NotOk":
      return "Not ok";
    case "Observe":
      return "Observe";
    case "Ok":
      return "Ok";
    case "BenchMarking":
      return "Benchmarking";
    case "NoDiagnosticTypes":
      return "No performance parameters";
    case "NoEnabledLongTrends":
      return "Performance status disabled by user";
    case "DiagnosticsNotActivated":
      return "Diagnostics module not activated";
    default:
      return "";
  }
}

function getPerformanceStatusErrorText(performanceStatus) {
  switch (performanceStatus) {
    case "NoDiagnosticTypes":
      return "No performance parameters";
    case "NoEnabledLongTrends":
      return "Performance status disabled by user";
    case "DiagnosticsNotActivated":
      return "Diagnostics module not activated";
    default:
      return "";
  }
}

//Modified with require and new paths so new webpack config can find these files
function getPerformanceStatusImageSrc(performanceStatus) {
  switch (performanceStatus) {
    case "NotOk":
      return require("@/assets/images/status/notok.png");
    case "Observe":
      return require("@/assets/images/status/observe.png");
    case "Ok":
      return require("@/assets/images/status/ok.png");
    case "BenchMarking":
      return require("@/assets/images/status/benchmarking.png");
    case "NoDiagnosticTypes":
    case "NoEnabledLongTrends":
    case "DiagnosticsNotActivated":
      return require("@/assets/images/status/error.png");
    default:
      return require("@/assets/images/loading.gif");
  }
}

function getPerformanceStatus(vessel) {
  if (!vessel.isDiagnosticActivated) {
    return "DiagnosticsNotActivated";
  }

  var diagnosticLongTrends = _.filter(vessel.longTrends, function (longTrend) {
    return longTrend.diagnosticTypeId;
  });

  if (diagnosticLongTrends.length === 0) {
    return "NoDiagnosticTypes";
  }

  var enabledLongTrends = _.filter(diagnosticLongTrends, function (longTrend) {
    return longTrend.useInVesselStatus;
  });
  if (enabledLongTrends.length === 0) {
    return "NoEnabledLongTrends";
  }

  var longTrendStatuses = _.map(enabledLongTrends, function (longTrend) {
    return longTrend.performanceStatus;
  });

  if (_.contains(longTrendStatuses, "NotOk")) {
    return "NotOk";
  }
  if (_.contains(longTrendStatuses, "Observe")) {
    return "Observe";
  }
  if (_.contains(longTrendStatuses, "BenchMarking")) {
    return "BenchMarking";
  }
  if (_.contains(longTrendStatuses, "Ok")) {
    return "Ok";
  }

  throw "Unexepcted performance statuses: " + longTrendStatuses.join(",");
}

function getSortValue(performanceStatus) {
  switch (performanceStatus) {
    case "NotOk":
      return 0;
    case "Observe":
      return 1;
    case "Ok":
      return 2;
    case "BenchMarking":
      return 3;
    case "NoDiagnosticTypes":
      return 4;
    case "NoEnabledLongTrends":
      return 5;
    case "DiagnosticsNotActivated":
      return 6;
    default:
      throw "Unexpected performance status " + performanceStatus;
  }
}

export default {
  getPerformanceStatusText: function (performanceStatus) {
    return getPerformanceStatusText(performanceStatus);
  },
  getPerformanceStatusErrorText: function (performanceStatus) {
    return getPerformanceStatusErrorText(performanceStatus);
  },
  getPerformanceStatusImageSrc: function (performanceStatus) {
    return getPerformanceStatusImageSrc(performanceStatus);
  },
  getPerformanceStatusCssClass: function (performanceStatus) {
    return performanceStatus ? performanceStatus : "loading";
  },
  getPerformanceStatus: function (vessel) {
    return getPerformanceStatus(vessel);
  },
  getSortValue: function (vessel) {
    return getSortValue(getPerformanceStatus(vessel));
  },
  getSortValueByStatus: function (performanceStatus) {
    return getSortValue(performanceStatus);
  },
};
