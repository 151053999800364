import template from "./company-info.html";
import ko from "knockout";
import events from "App/events";

function ViewModel(params) {
    var self = this;

    self.company = params.company;
}

export default { viewModel: ViewModel, template: template };