import "./class-components-hooks";
import Vue from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import defaultContainer from "./components/DefaultContainer.vue";
import $ from "jquery";
import koValidation from "knockout.validation";
import VueCookies from "vue-cookies";
import VueGtag from "vue-gtag";

import "App/net-prefilter";
import "@/registerKnockoutComponents";
import "leaflet/dist/leaflet.css";
import "@/utilities/leafletIconFix.ts";
import "@/utilities/leaflet-marker-booster.js";

import "./filters";

// KnockoutJS validation extension: https://github.com/Knockout-Contrib/Knockout-Validation
koValidation.init({
  registerExtenders: true,
  messagesOnModified: true,
  insertMessages: true,
  parseInputAttributes: true,
  messageTemplate: undefined,
  decorateInputElement: true,
  errorElementClass: "input-validation-error",
});

//Cache busting for IE. Appends "_={timestamp}" to URL in all ajax GET requests
$.ajaxSetup({ cache: false });

Vue.config.productionTip = false;
//Enable this to use vue devtools in prod build
//Vue.config.devtools = true;

//Global components
Vue.component("default-container", defaultContainer);

Vue.use(VueCookies);

const hostnameParts = window.location.hostname.split(".");
const subdomain = hostnameParts.length > 2 ? hostnameParts[0] : "noSubdomain";

let googleAnalyticsId;
let isGoogleAnalyticsEnabled;

switch (subdomain) {
  case "kspoffice":
  case "noSubdomain": {
    googleAnalyticsId = "UA-109588643-1";
    isGoogleAnalyticsEnabled = true;
    break;
  }
  case "ksppreprod":
  case "preprod": {
    googleAnalyticsId = "UA-109588643-3";
    isGoogleAnalyticsEnabled = true;
    break;
  }
  case "ksptest":
  case "test": {
    googleAnalyticsId = "UA-109588643-2";
    isGoogleAnalyticsEnabled = true;
    break;
  }
  case "localhost":
  default: {
    googleAnalyticsId = "";
    isGoogleAnalyticsEnabled = false;
    break;
  }
}

Vue.use(VueGtag, {
  config: { id: googleAnalyticsId },
  enabled: isGoogleAnalyticsEnabled,
}, router);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
