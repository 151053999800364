import net from "App/net";

var FeaturesClient = function () {

};

FeaturesClient.prototype.getAll = function() {
    return net.get("/Features");
};

FeaturesClient.prototype.find = function(vesselId) {
    var filter = {
        vesselId: vesselId,
    };

    return net.get("/Features/Find", filter);
};

export default new FeaturesClient();
