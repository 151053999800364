import template from "./feature-not-available.html";

function getFeatureText(feature) {
    switch (feature) {
    case "CharterParty":
        return "Charter party";
    case "EmissionReport":
        return "Emission report";
    default:
        return feature;
    }
}

function ViewModel(params) {
    var self = this;

    self.featureText = getFeatureText(params.feature);
    self.vesselName = params.vesselName;
}

export default { viewModel: ViewModel, template: template };