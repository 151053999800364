import ko from "knockout";
import _ from "underscore";
import "leaflet";

ko.bindingHandlers.vesselMap = {
    init: function (element, valueAccessor, allBindingsAccessor) {
    },
    update: function (element, valueAccessor, allBindingsAccessor) {
        var value = valueAccessor();
        var map = ko.utils.unwrapObservable(value.mapObj);
        var markers = ko.utils.unwrapObservable(value.markers);
        var polylines = ko.utils.unwrapObservable(value.polylines);

        _.each(markers, function (marker) {
            if (marker) marker.addTo(map);
        });

        if (polylines) {
            _.each(polylines,
                function(polyline) {
                    polyline.addTo(map);
                });
        }
    },
};