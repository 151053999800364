import ko from "knockout";
import _ from "underscore";
import events from "App/events";
 
ko.bindingHandlers.vesselsTableSort = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
        var asc = true;
        var tableHeaderRow = document.querySelectorAll(".admin tr")[0];
        valueAccessor().array().sort(function (a, b) { return a.id() - b.id(); });
        element.onclick = function () {
            var array = valueAccessor().array;
            var column = valueAccessor().column;
            asc = !asc;

            array().sort(function(a, b) {
                var left = ko.utils.unwrapObservable(a[column]);
                var right = ko.utils.unwrapObservable(b[column]);

                return compareValues(asc, left, right);
            });

            highlightColumn(element, asc);
            events.vesselArraySorted.dispatch(array());
        };

        var menuItemClickEvent = events.menuItemClick.add(function() {
            _.each(tableHeaderRow.children, function (column) {
                if (column.className.includes("sortable sorted")) {
                    column.className = "sortable";
                }
            });
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            menuItemClickEvent.detach();
        });
    },
};

function compareValues(asc, left, right) {
    if (!isFinite(left - right)) {
        left = left.toLowerCase();
        right = right.toLowerCase();
        if (asc) {
            return left > right ? -1 : 1;
        } else {
            return left < right ? -1 : 1;
        }
    } else {
        return asc ? right - left : left - right;
    }
}

function highlightColumn(element, asc) {
    var tableRows = document.querySelectorAll(".admin tr");
    var elementIndex = _.indexOf(tableRows[0].children, element);

    _.each(tableRows, function (row, index) {
        var child = row.children[elementIndex];
        if (index === 0) {
            child.className = asc ? child.className = "sortable sorted down" : child.className = "sortable sorted up";
        } else {
            child.className += " sorted";
        }
        removeHighlighted(row, elementIndex);
    });
}

function removeHighlighted(row, currentIndex) {
    _.each(row.children, function (child, index) {
        if (index != currentIndex) {
            if (child.className !== "sortable" && child.className !== "") {
                child.className = "sortable";
            }
        }
    });
}