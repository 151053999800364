import { VuexModule, Module, Action } from "vuex-module-decorators";
import BenefitsClient from "Clients/benefits-client";
import { ITrendBenefit } from "@/types/trendBenefit";

@Module({ namespaced: true, name: "Benefits" })
class Benefits extends VuexModule {
  @Action({ rawError: true })
  public async fetchBenefit(data: { trendEventId: any, longTrendId: any }): Promise<ITrendBenefit> {
    try {
      const response: ITrendBenefit = await BenefitsClient.find(data.trendEventId, data.longTrendId);

      if (!response.consequences) return Promise.reject("Benchmarking");
      else return response;
    } catch (error) {
      return Promise.reject(`Failed to load benefit: ${error}`);
    }
  }
}

export default Benefits;
