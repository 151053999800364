import net from "App/net";

var Client = function () {

};

Client.prototype.getAll = function () {
  return net.get("/NotificationChannels");
};

Client.prototype.remove = function (id) {
  return net.del(`/NotificationChannels/${id}`);
};

export default new Client();
