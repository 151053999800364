import template from "./import-status.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import events from "App/events";
import importStatusClient from "Clients/import-status-client";
import queueClient from "Clients/import-queue-client";
import ImportEntity from "ViewModels/dataImport/importEntity";
import timeConverterHelper from "Utilities/time-converter-helper";

function ViewModel() {
    var self = this;
    self.poller;
    self.tableStorageDateFormat = "YYYY-MM-DD";
    self.datepickerFormat = "DD. MMM, YYYY";

    self.queueNames = { pendingImports: "pendingimports", receivedData: "receiveddata" };
    self.recentDateType = { day: 0, lastDays: 1, timeRange: 2 };

    self.importDetailsEntity = ko.observable();
    self.importDetailsVisible = ko.observable(false);
    self.successfulImportsLoading = ko.observable(true);
    self.failedImportsLoading = ko.observable(true);
    self.realtimePoll = ko.observable(false);

    self.selectedRecentDateType = ko.observable(self.recentDateType.day);
    self.datepickerValue = ko.observable(moment.utc().format(self.datepickerFormat));
    self.imoSearchValue = ko.observable("");

    self.recentDays = ko.observable(2);
    self.rowsPerPage = ko.observable(5);
    self.pollIntervall = ko.observable(2);
    self.receivedDataQueueLength = ko.observable(0);
    self.pendingImportsQueueLength = ko.observable(0);

    self.successfulImports = ko.observableArray([]);
    self.runningImports = ko.observableArray([]);
    self.failedImports = ko.observableArray([]);
    self.pollingOptions = ko.observableArray([1, 2, 5, 10, 20]);
    self.numberOfRows = ko.observableArray([1, 5, 10, 15, 20]);
    self.numberOfRecentDays = ko.observableArray(_.range(1, 31));

    self.isVesselImportStatesLoading = ko.observable(false);
    self.vesselImportStates = ko.observable();
    self.isRetryingAllFailedImports = ko.observable(false);

    self.loadStatus();

    self.vesselImportStateModels = ko.pureComputed(function() {
        var vesselImportStates = self.vesselImportStates();
        var activeVesselImportStates = _.filter(vesselImportStates, function(vesselImportState) {
            return vesselImportState.currentImport;
        });

        var vesselImportStateModels = _.map(activeVesselImportStates, function(vesselImportState) {
            var model = {
                imo: vesselImportState.imoNumber,
                currentImport: vesselImportState.currentImport,
                currentImportSetAt: vesselImportState.currentImportSetAt,
            };

            model.pendingImportPaths = vesselImportState.pendingImportPaths;
            return model;
        });

        return _.sortBy(vesselImportStateModels, function(vesselImportStateModel) {
            return vesselImportStateModel.pendingImportPaths.length;
        }).reverse();
    });

    self.recentImports = ko.pureComputed({
        read: function () {
            var successfulImports = self.successfulImports();
            var failedImports = self.recentlyFailedImports();
            var imoSearchValue = self.imoSearchValue();
            var imports = _.union(successfulImports, failedImports);

            if (imoSearchValue !== "") {
                imports = _.filter(imports,
                    function (importEntity) {
                        return importEntity.imoNumber().indexOf(imoSearchValue) !== -1;
                    });
            }

            self.sortByTimestamp(imports);

            return imports;
        },
        write: function (value) { },
    });

    self.recentlyFailedImports = ko.pureComputed(function () {
        var date = self.getFormattedDate();
        var failedImports = self.failedImports();

        return _.filter(failedImports, function (failedImport) {
            return failedImport.importEntity.partitionKey === date;
        });
    });

    self.retryImport = function (importEntity) {
        var queueNames = self.queueNames;
        var statuses = importEntity.statuses;
        var status = importEntity.status();
        var webJob = importEntity.webJob();
        var importPath = importEntity.importPath();

        var queueName = webJob == "Prepare" ? queueNames.receivedData : queueNames.pendingImports;

        if (status === statuses.error) {
            importStatusClient.deleteFailedImport(importEntity.importEntity).done(function () {
                self.failedImports.remove(importEntity);
                self.sendImportMessageOnQueue(queueName, importPath);
            });
        } else if (status === statuses.running) {
            importStatusClient.deleteRunningImport(importEntity.importEntity).done(function () {
                self.runningImports.remove(importEntity);
                self.sendImportMessageOnQueue(queueName, importPath);
            });
        }
    };

    self.removeFailedImport = function (importEntity) {
        importStatusClient.deleteFailedImport(importEntity.importEntity).done(function () {
            self.failedImports.remove(importEntity);
        });
    };

    self.pollFunction = function () {
        self.loadStatus();
    };

    self.retryImportEvent = events.retryImport.add(function (importEntity) {
        self.retryImport(importEntity);
    });

    self.removeImportEvent = events.removeImport.add(function (importEntity) {
        self.removeFailedImport(importEntity);
    });

    self.datePickerValueSubscription = self.datepickerValue.subscribe(function () {
        self.getSuccessfulImports();
    });

    self.selectedRecentDateTypeSubscription = self.selectedRecentDateType.subscribe(function () {
        self.getSuccessfulImports();
    });

    self.recentDaysSubscription = self.recentDays.subscribe(function () {
        var recentDateType = self.selectedRecentDateType();

        if (recentDateType === self.recentDateType.lastDays) {
            self.getSuccessfulImports();
        }
    });

    self.runningImportsBinding = self.runningImports.subscribe(function(runningImports) {
        var imoNumbers = _.map(runningImports, function(importEntity) {
            return importEntity.imoNumber();
        });
        self.getVesselImportStates(imoNumbers);
    });

    self.loggingHistoryItemsCount = ko.pureComputed(function() {
        var successfulImports = self.successfulImports();
        var loggingHistoryTableRowCounts = _.map(successfulImports, function(successfulImport) {
            var importStatistics = successfulImport.importStatistics();
            var loggingHistoryTableStats = _.find(importStatistics, function(importStatistic) {
                return importStatistic.TableName === "KSPDataTab_LoggingHistory";
            });
            return loggingHistoryTableStats ? loggingHistoryTableStats.RowCount : 0;
        });
        return _.reduce(loggingHistoryTableRowCounts, function(memo, num) { return memo + num; }, 0);
    });

    self.totalTimeSpentInHours = ko.pureComputed(function() {
        var successfulImports = self.successfulImports();
        var totalImportDurationTicks = _.reduce(successfulImports, function(memo, successfulImport) { return memo + successfulImport.importEntity.importDuration; }, 0);
        return timeConverterHelper.ticksToHours(totalImportDurationTicks);
    });

    self.logDataTotalTimeSpentInHours = ko.pureComputed(function() {
        var successfulImports = self.successfulImports();
        var loggingHistoryTableImportDurations = _.map(successfulImports, function(successfulImport) {
            var importStatistics = successfulImport.importStatistics();
            var loggingHistoryTableStats = _.find(importStatistics, function(importStatistic) {
                return importStatistic.TableName === "KSPDataTab_LoggingHistory";
            });
            return loggingHistoryTableStats ? loggingHistoryTableStats.ImportDuration : 0;
        });
        var totalImportDurationTicks = _.reduce(loggingHistoryTableImportDurations, function(memo, num) { return memo + num; }, 0);
        return timeConverterHelper.ticksToHours(totalImportDurationTicks);
    });
}

ViewModel.prototype.sortByTimestamp = function (imports) {
    imports.sort(function (a, b) {
        return Date.parse(b.timestamp()) - Date.parse(a.timestamp());
    });
};

ViewModel.prototype.getFormattedDate = function () {
    var self = this;
    var datepickerValue = self.datepickerValue();
    var formattedDate = moment(datepickerValue, self.datepickerFormat).format(self.tableStorageDateFormat);

    return formattedDate;
};

ViewModel.prototype.loadStatus = function () {
    var self = this;
    var queueNames = self.queueNames;

    self.getRunningImports();
    self.getFailedImports();
    self.getSuccessfulImports();

    self.getNumberOfMessagesInQueue(self.receivedDataQueueLength, queueNames.receivedData);
    self.getNumberOfMessagesInQueue(self.pendingImportsQueueLength, queueNames.pendingImports);
};

ViewModel.prototype.getNumberOfMessagesInQueue = function (queueObservable, queueName) {
    var self = this;
    queueClient.get(queueName).done(function (data) {
        queueObservable(data);
    });
};

ViewModel.prototype.sendImportMessageOnQueue = function (importQueue, importPath) {
    var self = this;
    var queueNames = self.queueNames;

    importQueue = _.isNull(importQueue) || _.isUndefined(importQueue) ? queueNames.pendingImports : importQueue;

    queueClient.post(importQueue, importPath).done(function (data) {
    });
};

ViewModel.prototype.getPreviousDatePartition = function () {
    var newDate = this.addDaysToDate(-1);
    this.datepickerValue(newDate);
    this.selectedRecentDateType(this.recentDateType.day);
};

ViewModel.prototype.getNextDatePartition = function () {
    var newDate = this.addDaysToDate(1);
    this.datepickerValue(newDate);
    this.selectedRecentDateType(this.recentDateType.day);
};

ViewModel.prototype.addDaysToDate = function (daysAdded) {
    var self = this;
    var currentDate = self.datepickerValue();
    var newDate = moment(currentDate, self.datepickerFormat);

    newDate = newDate.add(daysAdded, "days").format(self.datepickerFormat);
    return newDate;
};

ViewModel.prototype.toggleRealtimePoll = function () {
    var self = this;
    var poll = self.realtimePoll();
    var intervall = self.pollIntervall() * 1000;
    self.realtimePoll(!poll);

    if (!poll) {
        self.poller = setInterval(self.pollFunction, intervall);
    } else {
        clearInterval(self.poller);
    }
};

ViewModel.prototype.getRunningImports = function () {
    var self = this;

    importStatusClient.getRunningImports().done(function (data) {
        var runningImports = _.map(data, function (runningImport) {
            return new ImportEntity(runningImport, 2);
        });

        self.sortByTimestamp(runningImports);
        self.runningImports(runningImports);
    });
};

ViewModel.prototype.getFailedImports = function () {
    var self = this;
    importStatusClient.getFailedImports().done(function (data) {
        var failedImports = _.map(data, function (errorImport) {
            return new ImportEntity(errorImport, 1);
        });
        self.sortByTimestamp(failedImports);
        self.failedImports(failedImports);
    }).fail(function () {
    }).always(function () {
        self.failedImportsLoading(false);
    });
};

ViewModel.prototype.getSuccessfulImports = function () {
    var self = this;
    var selectedRecentDateType = self.selectedRecentDateType();
    var getImports = {};

    self.successfulImportsLoading(true);

    if (selectedRecentDateType === self.recentDateType.day) {
        var date = self.datepickerValue();
        date = moment(date, self.datepickerFormat).format(self.tableStorageDateFormat);
        getImports = importStatusClient.getSuccessfulImports(date);
    } else if (selectedRecentDateType === self.recentDateType.lastDays) {
        var recentDays = self.recentDays();
        getImports = importStatusClient.getLastNumberOfImports(recentDays);
    }

    getImports.done(function (data) {
        self.successfulImports(_.map(data, function (importEntity) {
            return new ImportEntity(importEntity, 0);
        }));
    }).fail(function () {
    }).always(function () {
        self.successfulImportsLoading(false);
    });
};

ViewModel.prototype.getVesselImportStates = function(imoNumbers) {
    var self = this;
    var getImports = {};

    self.isVesselImportStatesLoading(true);
    self.vesselImportStates([]);
    if (imoNumbers.length === 0) return;

    importStatusClient.getVesselImportStates(imoNumbers).done(function(data) {
        self.vesselImportStates(data);
    }).always(function() {
        self.isVesselImportStatesLoading(false);
    });
};

ViewModel.prototype.retryAllFailedImports = function() {
    var self = this;
    if (!confirm("Do you really want to retry ALL failed imports?")) {
        return;
    }

    self.isRetryingAllFailedImports(true);
    importStatusClient.retryAllFailedImports().done(function() {
        self.loadStatus();
    }).always(function() {
        self.isRetryingAllFailedImports(false);
    });
};

ViewModel.prototype.dispose = function () {
    this.retryImportEvent.detach();
    this.removeImportEvent.detach();
    this.datePickerValueSubscription.dispose();
    this.selectedRecentDateTypeSubscription.dispose();
    this.runningImportsBinding.dispose();
};

export default { viewModel: ViewModel, template: template };
