import template from "./veracity-key.html";
import ko from "knockout";
import _ from "underscore";
import vesselsAdministrationsClient from "Clients/vessels-administrations-client";
import usersClient from "Clients/users-client";
import Store from "@/store";

function ViewModel() {
    var self = this;

    self.isSuperUser = ko.observable(false);
    self.dialogState = ko.observable("");
    self.veracityKey = ko.observable();
    self.expirationDate = ko.observable();

    vesselsAdministrationsClient.getVeracityKeyExpirationDate(Store.getters["Vessels/currentVessel"].id)
    .done(self.expirationDate);

    usersClient.isSuperUser()
    .done(self.isSuperUser);
}

ViewModel.prototype.viewVeracityKey = function() {
    var self = this;
    
    if (self.isSuperUser() == false) {
        self.dialogState("DENIED");
    }
    else {
        vesselsAdministrationsClient.getVeracityKey(Store.getters["Vessels/currentVessel"].id)
        .done(self.veracityKey);

        self.dialogState("VIEW");
    }
};

ViewModel.prototype.editVeracityKey = function() {
    var self = this;

    self.dialogState("EDIT");
};

ViewModel.prototype.closeDialog = function() {
    var self = this;

    vesselsAdministrationsClient.getVeracityKeyExpirationDate(Store.getters["Vessels/currentVessel"].id)
    .done(self.expirationDate);

    self.dialogState("");
};

ViewModel.prototype.updateVeracityKey = function() {
    var self = this;

    var veracityKey = self.veracityKey();
    vesselsAdministrationsClient.updateVeracityKey(Store.getters["Vessels/currentVessel"].id, veracityKey);

    self.dialogState("VIEW");
};

export default { viewModel: ViewModel, template: template };