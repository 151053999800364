import net from "App/net";

var NotificationWidgetClient = function () {};

NotificationWidgetClient.prototype.getAll = function () {
  return net.get("/NotificationWidgets");
};

NotificationWidgetClient.prototype.get = function (notificationWidgetConfigId) {
  return net.get(`/NotificationWidgets/${notificationWidgetConfigId}`);
};

NotificationWidgetClient.prototype.new = function (notificationWidgetConfiguration) {
  return net.post("/NotificationWidgets", notificationWidgetConfiguration);
};

NotificationWidgetClient.prototype.update = function (notificationWidgetConfiguration) {
  return net.put(`/NotificationWidgets/${notificationWidgetConfiguration.id}`, notificationWidgetConfiguration);
};

NotificationWidgetClient.prototype.delete = function (notificationWidgetConfigId) {
  return net.del(`/NotificationWidgets/${notificationWidgetConfigId}`);
};

export default new NotificationWidgetClient();
