import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { ISO19030Event } from "@/types/ISO19030Event";
import { PerformanceIndicatorPeriod } from "@/types/PerformanceIndicatorPeriod";
import ISO19030Client from "Clients/ISO19030-client";

@Module({ namespaced: true, name: "ISO19030" })
class ISO19030 extends VuexModule {
  private _events: ISO19030Event[] = [];
  private _performanceIndicators: PerformanceIndicatorPeriod[] = [];

  get events(): ISO19030Event[] {
    return this._events;
  }

  get eventsByVesselId() {
    return (vesselId: number): ISO19030Event[] => {
      return this._events.filter(event => event.vesselId === vesselId);
    };
  }

  get performanceIndicators(): PerformanceIndicatorPeriod[] {
    return this._performanceIndicators;
  }

  //  Events < >
  @Action({ rawError: true })
  public async fetchEvents(): Promise<void> {
    try {
      const response = await ISO19030Client.All();
      this.context.commit("SET_ISO19030_EVENTS", response);
      return response;
    } catch (err) {
      console.warn(err);
      return Promise.reject(`Failed to fetch ISO19030 events: ${err}`);
    }
  }

  @Action({ rawError: true })
  public async createEvent(event: Partial<ISO19030Event>): Promise<void> {
    try {
      await ISO19030Client.CreateEvent(event);
      await this.context.dispatch("fetchEvents");
    } catch (err) {
      console.warn(err);
      return Promise.reject(`Failed to create event: ${err}`);
    }
  }

  @Action({ rawError: true })
  public async updateEvent(event: ISO19030Event): Promise<void> {
    try {
      await ISO19030Client.UpdateEvent(event);
      await this.context.dispatch("fetchEvents");
    } catch (err) {
      console.warn(err);
    }
  }

  @Action({ rawError: true })
  public async deleteEvent(eventId: number): Promise<void> {
    try {
      await ISO19030Client.DeleteEvent(eventId);
      await this.context.dispatch("fetchEvents");
    } catch (err) {
      console.warn(err);
    }
  }
  //  Events </>

  //  PerformanceIndicators < >
  @Action({ rawError: true })
  public async fetchPerformanceIndicators(vesselId: number | string): Promise<void> {
    try {
      const response = await ISO19030Client.PerformanceIndicators(vesselId);
      this.context.commit("SET_ISO19030_PERFORMANCE_INDICATORS", response);
      return response;
    } catch (err) {
      console.warn(err);
      return Promise.reject(`Failed to fetch ISO19030 performance indicators: ${err}`);
    }
  }
  //  PerformanceIndicators </>

  @Mutation
  public SET_ISO19030_EVENTS(response: ISO19030Event[]): void {
    this._events = response;
  }

  @Mutation
  public SET_ISO19030_PERFORMANCE_INDICATORS(response: PerformanceIndicatorPeriod[]): void {
    this._performanceIndicators = response;
  }
}

export default ISO19030;
