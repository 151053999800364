/* eslint-disable */
import net from "App/net";
import type { UserProfile } from "../types/userProfile";

class UserService {
  get() {
    return net.get("/Users/GetUser");
  }

  update(user: Partial<UserProfile>) {
    return net.put("/Users/Put", user);
  }

  generateApiKey(userId: string) {
    const command = {
      userId: userId,
    };
    return net.post("/Users/GenerateApiKey", command);
  }

  clearApiKey(userId: string) {
    const command = {
      userId: userId,
    };
    return net.post("/Users/ClearApiKey", command);
  }
}

export default new UserService();
