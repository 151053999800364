import template from "./taxonomy.html";
import ko from "knockout";
import _ from "underscore";
import CategoryVM from "ViewModels/taxonomy/category";
import store from "@/store";
import router from "@/router";

function ViewModel(param) {
    var self = this;
    self.isUploading = ko.observable(false);
    self.logVariablesMap = param.taxonomy;
    self.logVariables = _.sortBy(_.values(self.logVariablesMap), function(logVariable) {
        return logVariable.name.toLowerCase();
    });

    self.highlightLogVariableId = ko.observable();
    self.highlightedLogVariable = ko.pureComputed(function() {
        var highlightLogVariableId = self.highlightLogVariableId();
        if (!highlightLogVariableId) return null;

        return self.logVariablesMap.find(x => x.id === highlightLogVariableId);
    });

    var allTaxa = _.flatten(_.pluck(self.logVariables, "taxa"));

    var uniqueTaxa = _.uniq(allTaxa, function(taxon) {
        return taxon.name;
    });

    var orderedUniqueTaxa = _.sortBy(uniqueTaxa, "name");
    var groupedTaxa = _.groupBy(orderedUniqueTaxa, function(taxon) {
        return taxon.name.split(":")[0];
    });
    self.categories = _.map(groupedTaxa, function(taxons, name) {
        return new CategoryVM(name, taxons, self.highlightedLogVariable);
    });
}

ViewModel.prototype.downloadTaxonomy = function () {
    return store.dispatch("Taxonomy/downloadTaxonomy");
};

ViewModel.prototype.uploadTaxonomy = function () {
    var self = this;
    self.isUploading(true);

    var fd = new FormData();
    var files = $("#taxonomyUploadFile")[0].files[0];
    fd.append("file", files);

    store.dispatch("Taxonomy/uploadTaxonomy", fd)
    .then(function() {
        router.go();
    });
};

ViewModel.prototype.dispose = function () {

};

export default { viewModel: ViewModel, template: template };
