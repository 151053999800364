import template from "./user-list.html";
import ko from "knockout";
import events from "App/events";
import _ from "underscore";
import cookie from "js-cookie";
import moment from "moment";

function ViewModel(params) {
    var self = this;

    self.users = params.users.getList();
    self.isLoading = params.users.isLoading;

    var initialUserSortParameter = cookie.get("userSortParameter");
    if (!initialUserSortParameter) {
        cookie.set("userSortParameter", "name", { expires: 20 * 365 });
        initialUserSortParameter = "name";
    }

    var initialUserSortDirection = cookie.get("userSortDirection");
    if (!initialUserSortDirection) {
        cookie.set("userSortDirection", "asc", { expires: 20 * 365 });
        initialUserSortDirection = "asc";
    }

    self.userSortParameter = ko.observable(initialUserSortParameter);
    self.userSortDirection = ko.observable(initialUserSortDirection);

    self.sortedUsers = ko.pureComputed(function () {
        var users = self.users();
        var userSortParameter = self.userSortParameter();
        var userSortDirection = self.userSortDirection();

        var sorted = _.sortBy(users, function (user) {
            var value = self.getUserSortValue(user, userSortParameter);

            // 'Case insensitive' sorting
            if (typeof value === "string" || value instanceof String) {
                value = value.toUpperCase();
            }

            return value;
        });
        return userSortDirection === "asc" ? sorted : sorted.reverse();
    });
}

ViewModel.prototype.getUserSortValue = function(user, sortParameter) {
    switch (sortParameter) {
        case "name":
            return user.name;
        case "role":
            return user.role;
        case "email":
            return user.email;
        case "lastActivityDate":
            return user.lastActivityDate;
        case "hasApiKey":
            return user.apiKey != "";
        default:
            throw "Unexpected sort parameter: " + sortParameter;
    }
};

ViewModel.prototype.isUserOnline = function (lastActivityDate) {
  const tenMinutesAgo = moment().subtract(10, "minutes");
  return moment(lastActivityDate, "YYYY-MM-DD, H:mm:ss A").isAfter(tenMinutesAgo);
};

ViewModel.prototype.getHeaderSortCssClass = function (parameter) {
    if (parameter !== this.userSortParameter()) {
        return "sortable";
    }
    return this.userSortDirection() === "asc" ? "sortable sorted up" : "sortable sorted down";
};

ViewModel.prototype.sortBy = function (sortParameter) {
    var userSortParameter = this.userSortParameter();
    if (userSortParameter !== sortParameter) {
        this.userSortParameter(sortParameter);
        cookie.set("userSortParameter", sortParameter, { expires: 20 * 365 });
    } else {
        this.toggleSortDirection();
    }
};

ViewModel.prototype.toggleSortDirection = function () {
    var newUserSortDirection = this.userSortDirection() === "asc" ? "desc" : "asc";
    this.userSortDirection(newUserSortDirection);
    cookie.set("userSortDirection", newUserSortDirection, { expires: 20 * 365 });
};

ViewModel.prototype.viewUserClick = function (user) {
    events.userView.dispatch(user);
};

ViewModel.prototype.editUserClick = function (user) {
    events.userEdit.dispatch(user);
};

ViewModel.prototype.deleteUserClick = function (user) {
    events.userDelete.dispatch(user);
};

ViewModel.prototype.addUserClick = function () {
    events.userAdd.dispatch();
};

ViewModel.prototype.apiKeyClick = function (user) {
    events.userManageApiKey.dispatch(user);
};

export default { viewModel: ViewModel, template: template };
