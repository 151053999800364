import net from "App/net";

var Client = function() {

};

Client.prototype.find = function(vesselId) {
    var filter = {
        vesselId: vesselId,
    };
    return net.get("/Users/Find", filter);
};

Client.prototype.getAll = function () {
    return net.get("/Users/Get");
};

Client.prototype.getOnlineUsers = function () {
    return net.get("/Users/Online");
};

Client.prototype.update = function(user) {
    return net.put("/Users/Put", user);
};

Client.prototype.generateApiKey = function (userId) {
    var command = {
        userId: userId,
    };
    return net.post("/Users/GenerateApiKey", command);
};

Client.prototype.clearApiKey = function (userId) {
    var command = {
        userId: userId,
    };
    return net.post("/Users/ClearApiKey", command);
};

Client.prototype.add = function(user) {
    return net.post("/Users/Post", user);
};

Client.prototype.remove = function (user) {
    return net.del("/Users/" + user.id);
};

Client.prototype.setCompany = function (companyId) {
    return net.post("/Users/SetCompany/" + companyId);
};

Client.prototype.isSuperUser = function () {
    return net.get("/Users/IsSuperUser/");
};

export default new Client();
