import template from "./css-loader.html";
import ko from "knockout";
import events from "App/events";
import _ from "underscore";

function ViewModel(params) {
    var self = this;

    self.loaderText = ko.observable(_.isUndefined(params.loaderText) ? "Loading" : params.loaderText);

    self.loaderTextBinding = events.updateLoaderText.add(function(text) {
        self.loaderText(text);
    });
}

ViewModel.prototype.dispose = function() {
    this.loaderTextBinding.detach();
};

export default { viewModel: ViewModel, template: template };