import template from "./fuel-types.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import fuelTypesClient from "Clients/fuel-types-client";

function ViewModel(params) {
    var self = this;
    self.fuelTypes = ko.observableArray([]);
    self.isSaving = ko.observable(false);
    self.showNewFuelType = ko.observable(false);

    self.isLoadingFuelTypes = ko.observable(true);

    self.isLoading = ko.pureComputed(function() {
        var isLoadingFuelTypes = self.isLoadingFuelTypes();
        return isLoadingFuelTypes;
    });

    fuelTypesClient.getAll()
        .done(self.fuelTypes)
        .always(function() {
            self.isLoadingFuelTypes(false);
        });

    self.inputName = ko.observable();
    self.inputShortName = ko.observable();
    self.inputCarbonEmissionFactor = ko.observable();

    self.newFuelType = ko.pureComputed(function() {
        var inputName = self.inputName();
        var inputShortName = self.inputShortName();
        var inputCarbonEmissionFactor = self.inputCarbonEmissionFactor();

        var newFuelType = {
            name: inputName,
            shortName: inputShortName,
            carbonEmissionFactor: inputCarbonEmissionFactor,
        };

        return newFuelType;
    });

    self.isNewFuelTypeValid = ko.pureComputed(function() {
        var newFuelType = self.newFuelType();
        return newFuelType.name && newFuelType.shortName && _.isFinite(newFuelType.carbonEmissionFactor);
    });

    self.fuelTypeDeletedBinding = events.fuelTypeDeleted.add(function(fuelType) {
        self.fuelTypes.remove(function(item) {
            return item.id === fuelType.id;
        });
    });

    self.hasActiveFuelTypes = ko.pureComputed(function() {
        var activeFuelTypes = self.activeFuelTypes();
        return activeFuelTypes.length > 0;
    });

    self.activeFuelTypes = ko.pureComputed(function() {
        var fuelTypes = self.fuelTypes();
        return _.filter(fuelTypes,
            function(fuelType) {
                return !fuelType.isDeactivated;
            });
    });
}

ViewModel.prototype.dispose = function() {
    this.fuelTypeDeletedBinding.detach();
};

ViewModel.prototype.addFuelType = function() {
    var self = this;
    var newFuelType = self.newFuelType();
    self.isSaving(true);
    fuelTypesClient.create(newFuelType).done(function(data) {
        self.fuelTypes.push(data);
    }).always(function() {
        self.isSaving(false);
    });
};

ViewModel.prototype.cancelNewFuelType = function() {
    this.showNewFuelType(false);
};

ViewModel.prototype.startNewFuelType = function() {
    this.showNewFuelType(true);
};

export default { viewModel: ViewModel, template: template };