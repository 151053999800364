import template from "./voyages-chart.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import highchartsHelper from "Utilities/highcharts-helper";

function ViewModel(params) {
    var self = this;
    self.vessel = ko.utils.unwrapObservable(params.vessel);
    self.vesselEvents = params.vesselEvents;
    self.gpsSpeedData = params.gpsSpeedData;
    self.enablePlotLineDateSelect = false;
    self.chart = ko.observable();

    self.highchartsOptions = ko.computed(function() {
        var gpsSpeedData = self.gpsSpeedData();
        var vesselEvents = self.vesselEvents();
        if (!gpsSpeedData) return null;

        var points = _.map(gpsSpeedData.data,
            function(y, x) {
                return [parseInt(x), y];
            });
        var options = highchartsHelper.getDefaultGenericLineChartOptions();
        options.title.text = null;
        options.yAxis.title.text = gpsSpeedData.unit ? gpsSpeedData.unit.caption : "";
        options.xAxis.type = "datetime";
        options.chart.marginTop = 50;

        var exportTitle = "Emission report - " + self.vessel.name;
        options.exporting.filename = exportTitle;
        options.exporting.chartOptions.title = {
            text: exportTitle,
        };

        var firstPoint = _.first(points);
        var lastPoint = _.last(points);
        var isTimelineData = points.length === 2 && firstPoint[1] === 0 && lastPoint[1] === 0;

        options.series = [
            {
                name: isTimelineData ? "Timeline" : "Ship speed GPS",
                lineWidth: 1,
                marker: {
                    radius: 1,
                },
                data: points,
            },
        ];

        options.xAxis.plotLines = _.map(vesselEvents,
            function(vesselEvent) {
                return highchartsHelper.getPlotLineForVoyageEvent(vesselEvent.timestamp, vesselEvent.name);
            });

        return options;
    });

    self.dateSelectStartedBinding = events.dateSelectStarted.add(function() {
        self.enablePlotLineDateSelect = true;
    });

    self.dateSelectEndedBinding = events.dateSelectEnded.add(function() {
        self.enablePlotLineDateSelect = false;
        var chart = self.chart();
    });
}

ViewModel.prototype.dispose = function() {
    this.dateSelectStartedBinding.detach();
    this.dateSelectEndedBinding.detach();
};

ViewModel.prototype.mousedown = function(vm, e) {
    if (!this.enablePlotLineDateSelect) return;
    var chart = this.chart();
    highchartsHelper.plotLineDateSelectMousedown(chart, e);
};

export default { viewModel: ViewModel, template: template };