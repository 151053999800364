































































































































import { Component, Vue } from "vue-property-decorator";
import Utilities from "@/utilities/utilities";
import { FormattedError } from "@/types/formattedError";
import { ResetPasswordData } from "@/types/resetPasswordData";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/clients/Auth.module";

const Auth = getModule(AuthModule, store);

@Component
export default class ResetPassword extends Vue {
  //data to send
  payload: ResetPasswordData = { username: "", token: "", password: "" };
  //change this if we want to alter password minimum length frontend. Currently it is 6 on the server
  minimumPasswordLength = 6;
  //form state
  isFormValid = true;
  //true if user enters /ResetPassword without valid params in url
  incompleteLink = false;
  //toggle input type to reveal password
  showPassword = false;
  //holds the confirm password string
  repeatPassword = "";
  //state submitted
  submitted = false;
  //state submitting
  submitting = false;
  //state successfully changed password (removes inputs)
  submittedSuccess = false;
  //alert is either success or error
  alertType = "success";
  //response is string when success or object when error
  response = "";
  errorResponse!: FormattedError;
  //input validation
  passwordRules = {
    required: (value: string) => !!value || "Password required",
    valid: (value: string) => value.length >= this.minimumPasswordLength || `Password must be at least ${this.minimumPasswordLength} characters`,
  };

  //validate if passwords are equal
  get passwordsEqual(): boolean | string {
    return this.passwordsEqualBool || "Password mismatch";
  }

  get passwordsEqualBool(): boolean {
    return this.repeatPassword === this.payload.password;
  }

  created(): void {
    const query = this.$route.query;
    if (!query.username || !query.token) {
      this.incompleteLink = true;
      return;
    }
    this.payload.username = String(query.username);
    this.payload.token = String(query.token);
  }

  handleResetPassword(): void {
    this.response = "";
    this.alertType = "success";
    this.submitted = false;
    this.submitting = true;
    Auth.resetPassword(this.payload).then(
      response => {
        this.response = response;
        this.submitted = true;
        this.submitting = false;
        this.submittedSuccess = true;
      },
      error => {
        this.alertType = "error";
        this.errorResponse = Utilities.formatError(error);
        this.submitted = true;
        this.submitting = false;
      }
    );
  }
}
