import template from "./long-trend-config.html";
import ko from "knockout";
import longTrendConfigurationsClient from "Clients/long-trend-configurations-client";
import longTrendsClient from "Clients/long-trends-client";
import vesselsClient from "@/Scripts/clients/vessels-client";
import dateHelper from "Utilities/date-helper";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

function ViewModel() {
    var self = this;
    var vesselId = Vessels.currentVessel.id;

    self.showLastHours = false;
    self.showLastMinutes = false;
    self.showFromTime = false;
    self.showToTime = false;

    self.isVariablesLoading = ko.observable(true);
    self.isConfigLoading = ko.observable(true);
    self.isVoyagesLoading = ko.observable(true);
    self.config = ko.observable();
    self.isUpdateConfigurationLoading = ko.observable(false);
    self.isUpdateConfigurationError = ko.observable(false);
    self.submitError = ko.observable(false);

    self.variables = ko.observable();
    self.selectedVariables = ko.observable();

    self.selectedTimePeriod = ko.observable();
    self.lastDays = ko.observable();
    self.fromDate = ko.observable();
    self.toDate = ko.observable();
    self.lastPeriodValuesValid = ko.observable(true);
    self.voyages = ko.observable();

    self.isLoading = ko.pureComputed(function () {
        return self.isConfigLoading() || self.isVoyagesLoading() || self.isVariablesLoading();
    });

    self.lastVoyage = ko.pureComputed((function() {
        if (self.voyages().length < 1) return null;

        for (let i = self.voyages().length - 1; i >= 0; i--) {
            if (self.voyages()[i].startDate !== "0001-01-01T00:00:00" && self.voyages()[i].endDate !== "0001-01-01T00:00:00") {
                return self.voyages()[i];
            }
        }

        return null;
    }));

    self.selectedTimeInMinutes = ko.pureComputed(function () {
        var selectedTime = 0;

        if (self.selectedTimePeriod() === "AllValues") {
            selectedTime = 1;
        } else if (self.selectedTimePeriod() === "LastVoyage") {
            var from = dateHelper.getDateTime(dateHelper.getFormatedDateTimeString(self.lastVoyage().startDate));
            var to = dateHelper.getDateTime(dateHelper.getFormatedDateTimeString(self.lastVoyage().endDate));

            selectedTime = to.diff(from, "minutes")
        } else if (self.selectedTimePeriod() === "LastPeriod") {
            selectedTime = daysToMinutes(self.lastDays());
        } else if (self.selectedTimePeriod() === "SetPeriod") {
            var from = dateHelper.getDate(self.fromDate());
            var to = dateHelper.getDate(self.toDate());

            selectedTime = to.diff(from, "minutes");
        }

        if (selectedTime <= 0) {
            self.submitError(true);
        } else {
            self.submitError(false);
        }

        return selectedTime;
    });

    self.updateTimePeriod = function (fromDateTime, toDateTime) {
        self.fromDate(dateHelper.getFormatedDateString(fromDateTime));
        self.toDate(dateHelper.getFormatedDateString(toDateTime));
        self.selectedTimePeriod("SetPeriod");

        self.submit();
    };

    self.submit = function () {
        var selectedVariables = self.selectedVariables();
        var endChar = selectedVariables.charAt(selectedVariables.length - 1);

        if (endChar === ";") {
            selectedVariables = selectedVariables.slice(0, -1);
        }
        if (self.selectedTimePeriod() === "LastVoyage") {
            self.fromDate(dateHelper.getFormatedDateString(self.lastVoyage().startDate));
            self.toDate(dateHelper.getFormatedDateString(self.lastVoyage().endDate));
        }
        const config = {
          longTrendIds: selectedVariables,
          fromDateTime: dateHelper.getSubmitDateString(self.fromDate(), "00:00"),
          toDateTime: dateHelper.getSubmitDateString(self.toDate(), "00:00"),
          lastNumberDays: self.lastDays(),
          lastVoyage: self.lastVoyage(),
          timeConfigurationType: self.selectedTimePeriod(),
          id: self.config().id,
          userId: self.config().userId,
          vesselId: self.config().vesselId,
        };

        self.isUpdateConfigurationLoading(true);

        longTrendConfigurationsClient.update(config).done(function () {
            self.isUpdateConfigurationError(false);
            self.onSubmit(config);
        }).fail(function () {
            self.isUpdateConfigurationError(true);
        }).always(function () {
            self.isUpdateConfigurationLoading(false);
        });
    };

    self.disableSubmit = ko.pureComputed(function () {
        return self.selectedVariables() === "|" || !self.lastPeriodValuesValid() || self.isUpdateConfigurationLoading() || self.submitError();
    });

    self.displayEmptyVariableSelectionWarning = ko.pureComputed(function () {
        return self.selectedVariables() === "|";
    });

    longTrendsClient.findLongTrendVariables(vesselId).done(function (data) {
        self.variables(data);
    }).fail(function () {
        self.isLoadError(true);
    }).always(function () {
        self.isVariablesLoading(false);
    });

    longTrendConfigurationsClient.getByVesselId(vesselId).done(function (data) {
        self.config(data);
        self.selectedVariables(data.longTrendIds);
        self.selectedTimePeriod(data.timeConfigurationType);
        self.lastDays(data.lastNumberDays);
        self.fromDate(dateHelper.getFormatedDateString(data.fromDateTime));
        self.toDate(dateHelper.getFormatedDateString(data.toDateTime));
    }).fail(function () {
        self.isLoadError(true);
    }).always(function () {
        self.isConfigLoading(false);
    });

    vesselsClient.getVesselVoyages(vesselId).done(function (data) {
        self.voyages(data);
    }).fail(function () {
        self.isLoadError(true);
    }).always(function () {
        self.isVoyagesLoading(false);
    });
}

ViewModel.prototype.dispose = function () {
};

function daysToMinutes(days) {
    days = Number(days);
    return days * 24 * 60;
}

export default {viewModel: ViewModel, template: template};
