import net from "App/net";
import events from "App/events";

function vesselEventUpdatedCallback(vesselEvent) {
    events.vesselEventUpdated.dispatch(vesselEvent);
}

var Client = function() {

};

Client.prototype.find = function(vesselId) {
    var filter = {
        vesselId: vesselId,
    };

    return net.get("/EmissionReportVesselEvents/Find", filter);
};

Client.prototype.findVoyageVesselEvents = function(vesselId, departureId) {
    var filter = {
        vesselId: vesselId,
        departureId: departureId,
    };

    return net.get("/EmissionReportVesselEvents/FindVoyageVesselEvents", filter);
};

Client.prototype.create = function(vesselEvent) {
    return net.post("/EmissionReportVesselEvents", vesselEvent).done(function(data) {
        events.vesselEventCreated.dispatch(data);
    });
};

Client.prototype.update = function(vesselEvent) {
    return net.put("/EmissionReportVesselEvents/" + vesselEvent.id, vesselEvent).done(vesselEventUpdatedCallback);
};

Client.prototype.confirmVoyageInformation = function(vesselEvent) {
    return net.post("/EmissionReportVesselEvents/" + vesselEvent.id + "/ConfirmVoyageInformation").done(vesselEventUpdatedCallback);
};

Client.prototype.unconfirmVoyageInformation = function(vesselEvent) {
    return net.post("/EmissionReportVesselEvents/" + vesselEvent.id + "/UnconfirmVoyageInformation").done(vesselEventUpdatedCallback);
};

Client.prototype.confirmVoyageData = function(vesselEvent) {
    return net.post("/EmissionReportVesselEvents/" + vesselEvent.id + "/ConfirmVoyageData").done(vesselEventUpdatedCallback);
};

Client.prototype.unconfirmVoyageData = function(vesselEvent) {
    return net.post("/EmissionReportVesselEvents/" + vesselEvent.id + "/UnconfirmVoyageData").done(vesselEventUpdatedCallback);
};

Client.prototype.sendEmissionReportToDnvGl = function(vesselEvent) {
    return net.post("/EmissionReportVesselEvents/" + vesselEvent.id + "/UploadDnvGlVoyageData");
};

Client.prototype.remove = function(vesselEvent) {
    return net.del("/EmissionReportVesselEvents/" + vesselEvent.id).done(function() {
        events.vesselEventDeleted.dispatch(vesselEvent);
    });
};

export default new Client();