































































































































































































































import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
//  utilities
import moment from "moment";
import dateHelper from "Utilities/date-helper";
//  components
import DateTimeRangePicker from "@/components/widgets/DateTimeRangePicker.vue";
//  types
import { VesselDataWidgetColumn } from "@/types/VesselDataWidgetColumn";

@Component({
  components: {
    DateTimeRangePicker,
  },
})
export default class TimeSpanDialog extends Vue {
  @PropSync("value", { required: true }) isModalActive!: boolean;
  @PropSync("maxWidth", { default: 1000 }) dialogWidth!: number;
  @PropSync("column", { required: true }) syncColumn!: VesselDataWidgetColumn;
  @PropSync("isSaveButtonDisabled", { default: false }) syncIsSaveButtonDisabled!: boolean;

  lastHoursAverage = 24;
  lastHoursAccumulate = 24;
  selectedCalculationType = "";
  fromAndToDatesCalculationType = "Averaged";
  infoAccumulatedDialog = false;
  accumulatedAllowedUnits = ["rpm", "MT/hr", "MT/day", "knot", "kW", "kg/hr", "l/hr"];
  widgetDateRange: { fromDate: string | null; toDate: string | null } = {
    fromDate: moment().startOf("year").format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  };

  @Watch("fromAndToDatesCalculationType")
  onFromAndToDatesCalculationTypeChanged(): void {
    if (this.selectedCalculationType === "FromAndToDates") {
      Vue.set(this.syncColumn, "fromAndToDatesCalculationType", this.fromAndToDatesCalculationType);
      this.$emit("change", this.syncColumn);
    }
  }

  @Watch("selectedCalculationType")
  onCalculationTypeChanged(): void {
    this.setSelectedCalculationType();
    this.$emit("change", this.syncColumn);
  }

  @Watch("isModalActive")
  onModalActive(currentState: boolean): void {
    if (!currentState) return;
    this.selectedCalculationType = this.syncColumn.calculationType;
    if (!this.syncColumn.fromDate) return;

    const hours = dateHelper.getDatesDiffAsHours(this.syncColumn.fromDate, this.syncColumn.toDate);
    if (this.syncColumn.calculationType === "Averaged") {
      this.lastHoursAverage = hours;
      this.lastHoursAccumulate = 24;
    } else if (this.syncColumn.calculationType === "Accumulated") {
      this.lastHoursAccumulate = hours;
      this.lastHoursAverage = 24;
    } else if (this.syncColumn.calculationType === "FromAndToDates") {
      this.fromAndToDatesCalculationType = this.syncColumn.fromAndToDatesCalculationType ?? "Averaged";
      this.widgetDateRange = {
        fromDate: this.syncColumn.fromDate,
        toDate: this.syncColumn.toDate,
      };
    } else {
      this.lastHoursAverage = 24;
      this.lastHoursAccumulate = 24;
    }
  }

  get isLastHoursAverageValid(): boolean {
    return this.lastHoursAverage > 300 || this.lastHoursAverage < 1;
  }

  get isLastHoursAccumulateValid(): boolean {
    return this.lastHoursAccumulate > 300 || this.lastHoursAccumulate < 1;
  }

  get isTimeSpanHoursValid(): boolean {
    return !(this.isLastHoursAverageValid || this.isLastHoursAccumulateValid);
  }

  get errorMessage(): string {
    if ((this.isLastHoursAverageValid && this.selectedCalculationType === "Averaged") || (this.isLastHoursAccumulateValid && this.selectedCalculationType === "Accumulated")) {
      return "Selected hours must be between 1 and 300.";
    }

    return "";
  }

  get saveButtonDisabled(): boolean {
    return (this.selectedCalculationType === "Averaged" && this.isLastHoursAverageValid) || (this.selectedCalculationType === "Accumulated" && this.isLastHoursAccumulateValid);
  }

  get hasNotAllowedUnit(): boolean {
    if (!this.syncColumn?.logVariables) return false;
    return this.syncColumn.logVariables.some(variable => !this.isUnitAccAllowed(variable.unit?.caption));
  }

  open(): void {
    this.isModalActive = true;
    this.$emit("open");
  }

  close(): void {
    this.isModalActive = false;
    this.selectedCalculationType = "";
    this.$emit("close");
  }

  save(): void {
    this.isModalActive = false;
    this.setSelectedCalculationType();
    if (this.selectedCalculationType === "Accumulated" || this.selectedCalculationType === "LastVoyageAccumulated") this.infoAccumulatedDialog = true;
    this.$emit("save");
  }

  isUnitAccAllowed(unit: string): boolean {
    return this.accumulatedAllowedUnits.includes(unit);
  }

  onWidgetDateRangeChanged(fromToDates: { fromDate: string; toDate: string }): void {
    this.widgetDateRange = fromToDates;
  }

  setSelectedCalculationType(): void {
    if (!this.isTimeSpanHoursValid) return;

    switch (this.selectedCalculationType) {
      case "LastReceived":
        this.setTimeSpan("LastReceived");
        break;
      case "LastVoyageAveraged":
        this.setTimeSpan("LastVoyageAveraged");
        break;
      case "LastVoyageAccumulated":
        this.setTimeSpan("LastVoyageAccumulated");
        break;
      case "Averaged":
        this.setTimeSpan(this.selectedCalculationType, this.lastHoursAverage);
        break;
      case "Accumulated":
        this.setTimeSpan(this.selectedCalculationType, this.lastHoursAccumulate);
        break;
      case "FromAndToDates":
        this.setTimeSpan(this.selectedCalculationType, this.widgetDateRange);
        break;
      default:
        break;
    }
  }

  setTimeSpan(calculationType: string, value?: number | object): void {
    if (typeof value === "string") value = parseInt(value);
    let timeSpan;
    if (typeof value === "number") {
      timeSpan = {
        fromDate: moment.utc(new Date(), "YYYY-MM-DD").subtract(value, "hours").format(),
        toDate: moment.utc(new Date(), "YYYY-MM-DD").format(),
        calculationType: calculationType,
        fromAndToDatesCalculationType: "",
      };
    } else if (typeof value === "object") {
      timeSpan = {
        fromDate: this.widgetDateRange.fromDate,
        toDate: this.widgetDateRange.toDate,
        calculationType: calculationType,
        fromAndToDatesCalculationType: this.fromAndToDatesCalculationType,
      };
    } else {
      timeSpan = { calculationType: calculationType, fromAndToDatesCalculationType: "" };
    }
    Object.assign(this.syncColumn, timeSpan);
  }

  isLastHoursHasError(value: number, calculationType: string): boolean {
    return (value < 1 || value > 300) && calculationType === this.selectedCalculationType;
  }
}
