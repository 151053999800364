import net from "App/net";

var ImportQueueClient = function () {

};

ImportQueueClient.prototype.get = function (queueName) {
    return net.get("/QueueAttributes/" + queueName);
};

ImportQueueClient.prototype.post = function (queueName, importPath) {
    return net.post("/Queue/" + queueName, importPath);
};

export default new ImportQueueClient();