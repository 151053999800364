import net from "App/net";
import events from "App/events";

var Client = function() {

};

Client.prototype.find = function(vesselEventId) {
    var filter = {
        vesselEventId: vesselEventId,
    };

    return net.get("/VesselEventCargoDetails/Find", filter);
};

Client.prototype.create = function(vesselEventCargoDetail) {
    return net.post("/VesselEventCargoDetails", vesselEventCargoDetail);
};

Client.prototype.remove = function(vesselEventCargoDetail) {
    return net.del("/VesselEventCargoDetails/" + vesselEventCargoDetail.vesselEventId + "/" + vesselEventCargoDetail.cargoUnitId).done(function() {
        events.vesselEventCargoDetailDeleted.dispatch(vesselEventCargoDetail);
    });
};

export default new Client();