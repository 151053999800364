import template from "./benefit-consequence.html";

function ViewModel(params) {
    var self = this;
    self.hasSFRBenchmarkingError = params.hasSFRBenchmarkingError;
    self.mcrDisplay = params.consequence.mcrDisplay;
    self.diagnosticTypeName = params.consequence.diagnosticTypeName;
    self.results = params.consequence.results;
}

ViewModel.prototype.dispose = function() {
};

export default { viewModel: ViewModel, template: template };