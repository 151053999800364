//get the proper marker urls. Webpack outputs base64 images here
const green = require("@/assets/images/vessels/green.png");
const orange = require("@/assets/images/vessels/orange.png");
const red = require("@/assets/images/vessels/red.png");
const blue = require("@/assets/images/vessels/blue.png");
const gray = require("@/assets/images/vessels/gray.png");
const gpsUp = require("@/assets/images/vessels/previous2.png");

export enum VesselStatusIcons {
  Ok = green,
  Observe = orange,
  NotOk = red,
  BenchMarking = blue,
  notAvailable = gray,
  PreviousPosition = gpsUp
}
