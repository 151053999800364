import net from "App/net";

var Client = function () {

};

Client.prototype.findLatest = function (longTrendIds) {
  var filter = {
    longTrendIds: longTrendIds,
  };

  return net.get("/Consequences/FindLatest", filter);
};

Client.prototype.find = function (vesselId, longTrendId, fromDate, toDate, benchmarkPeriod) {
  var filter = {
    vesselId: vesselId,
    longTrendId: longTrendId,
    fromDate: fromDate,
    toDate: toDate,
    benchmarkPeriod: benchmarkPeriod
  };

  return net.get("/Consequences", filter);
};

export default new Client();
