import net from "App/net";

var EmissionReportClient = function() {

};

EmissionReportClient.prototype.generateExcelTemplate = function(data) {
    return net.fileDownload("/EmissionReport/GenerateExcelTemplate", "POST", data);
};

EmissionReportClient.prototype.uploadVoyage = function(vesselId, file) {
    var url = "/EmissionReport/Upload/" + vesselId;
    return net.fileUpload(url, file);
};

EmissionReportClient.prototype.mrvVoyage = function(departureId) {
    var url = "/EmissionReport/MrvVoyage/" + departureId;
    return net.fileDownload(url, "GET");
};

EmissionReportClient.prototype.imoVoyage = function(departureId) {
    var url = "/EmissionReport/ImoVoyage/" + departureId;
    net.fileDownload(url, "GET");
};

EmissionReportClient.prototype.mrvYearly = function(vesselId, year) {
    var url = "/EmissionReport/MrvYearly/" + vesselId + "/" + year;
    net.fileDownload(url, "GET");
};

EmissionReportClient.prototype.imoYearly = function(vesselId, year) {
    var url = "/EmissionReport/ImoYearly/" + vesselId + "/" + year;
    net.fileDownload(url, "GET");
};

EmissionReportClient.prototype.voyageDnvGl = function(departureId) {
    var url = "/EmissionReport/VoyageDnvGl/" + departureId;
    return net.fileDownload(url, "GET");
};

export default new EmissionReportClient();