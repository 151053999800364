import template from "./compare-vessels-long-trends-details-table-header.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";

function getTooltipText(sortParameter) {
    switch (sortParameter) {
    case "lastPeriodStartDate":
        return "Start date of the current trend period";
    case "changePerYear":
        return "Change per year";
    case "benchmarkPointValue":
        return "Last Benchmark Level";
    case "deviationFromBenchmark":
        return "Deviation from Benchmark";
    default:
        return null;
    }
}

function ViewModel(params) {
    var self = this;
    self.text = params.text;
    self.sortParameter = params.sortParameter;
    self.vesselSortParameter = params.vesselSortParameter;
    self.vesselSortDirection = params.vesselSortDirection;

    self.headerSortCssClass = ko.pureComputed(function() {
        var vesselSortParameter = self.vesselSortParameter();
        var vesselSortDirection = self.vesselSortDirection();

        if (self.sortParameter !== vesselSortParameter) return "sortable";

        return vesselSortDirection === "asc" ? "sortable sorted up" : "sortable sorted down";
    });

    self.tooltipText = getTooltipText(self.sortParameter);
    self.isTooltipOpen = ko.observable(false);

    self.closeTooltipBinding = events.closeTooltip.add(function() {
        self.isTooltipOpen(false);
    });
}

ViewModel.prototype.dispose = function() {
    this.closeTooltipBinding.detach();
};

ViewModel.prototype.getHeaderSortCssClass = function(parameter) {
    if (parameter !== this.vesselSortParameter()) return "sortable";

    return this.vesselSortDirection() === "asc" ? "sortable sorted up" : "sortable sorted down";
};

ViewModel.prototype.sort = function() {
    var vesselSortParameter = this.vesselSortParameter();
    if (vesselSortParameter !== this.sortParameter) {
        this.vesselSortParameter(this.sortParameter);
    } else {
        this.toggleSortDirection();
    }
};

ViewModel.prototype.toggleSortDirection = function() {
    var newVesselSortDirection = this.vesselSortDirection() === "asc" ? "desc" : "asc";
    this.vesselSortDirection(newVesselSortDirection);
};

ViewModel.prototype.toggleHelpTooltip = function(vm, e) {
    var isTooltipOpen = this.isTooltipOpen();
    if (!isTooltipOpen) {
        var element = e.currentTarget;
        events.showTooltip.dispatch("tooltip-text", { text: this.tooltipText, isWide: false }, element);
        this.isTooltipOpen(true);
    } else {
        events.closeTooltip.dispatch();
    }
};

ViewModel.prototype.hideHelpTooltip = function () {
    if (this.isTooltipOpen()) {
        this.isTooltipOpen(false);
        events.closeTooltip.dispatch();
    }
};

export default { viewModel: ViewModel, template: template };