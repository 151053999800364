import net from "App/net";

var Client = function() {

};

Client.prototype.find = function(vesselId, fromDate, toDate) {
    var filter = {
        vesselId: vesselId,
        fromDate: fromDate,
        toDate: toDate,
    };

    return net.get("/VesselLocations/Find", filter);
};

export default new Client();