import template from "./vessel-event.html";
import ko from "knockout";
import events from "App/events";
import vesselEventHelper from "Utilities/vessel-event-helper";

function ViewModel(params) {
    var self = this;
    self.vesselEvent = ko.observable(params.vesselEvent);

    self.timestamp = ko.pureComputed(function () {
        var vesselEvent = self.vesselEvent();
        return vesselEvent.timestamp;
    });

    self.name = ko.pureComputed(function () {
        var vesselEvent = self.vesselEvent();
        return vesselEvent.name;
    });

    self.vesselEventType = ko.pureComputed(function () {
        var vesselEvent = self.vesselEvent();
        return vesselEvent.type;
    });

    self.sourceText = ko.pureComputed(function () {
        var vesselEvent = self.vesselEvent();
        return vesselEventHelper.getVesselEventSourceText(vesselEvent);
    });

    self.sourceImageSrc = ko.pureComputed(function () {
        var vesselEvent = self.vesselEvent();
        return vesselEventHelper.getVesselEventSourceImageSrc(vesselEvent);
    });

    self.editEvent = function () {
      events.editEvent.dispatch(self.vesselEvent());
    };

    self.deleteEvent = function () {
      events.deleteEvent.dispatch(self.vesselEvent());
    };
}

ViewModel.prototype.dispose = function () {
};

export default { viewModel: ViewModel, template: template };
