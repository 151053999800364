import template from "./ft-operations.html";
import ko from "knockout";
import events from "App/events";
import moment from "moment";
import dateHelper from "Utilities/date-helper";
import fuelTankOperationsClient from "Clients/ft-operations-client";
import configEmissionReportFactory from "App/config-emission-report";

function ViewModel(params) {
    var self = this;
    self.configEmissionReport = configEmissionReportFactory.get();
    self.activeFuelTypes = self.configEmissionReport.activeFuelTypes;
    self.selectedFuelTank = params.selectedFuelTank;
    self.departureVoyageVesselEvent = params.departureVoyageVesselEvent;
    self.endOfVoyageVesselEvent = params.endOfVoyageVesselEvent;
    self.selectedVoyageVesselEvent = params.selectedVoyageVesselEvent;
    self.isSaving = ko.observable(false);
    self.fuelTankOperations = params.fuelTankOperations;
    self.isVoyageDataConfirmed = params.selectedVoyageVesselEvent.isVoyageDataConfirmed;

    self.fuelAdjustmentTooltipText = ko.observable("For \"de-bunkering\" add a minus ( - ) before the number");
    self.inputTimestampDate = ko.observable();
    self.inputTimestampTime = ko.observable();
    self.inputFuelTypeId = ko.observable(self.selectedFuelTank().fuelTypeId);
    self.inputFuelDensity = ko.observable();
    self.inputFuelAdjustmentInTon = ko.observable();
    self.inputSulphurContent = ko.observable();

    self.newFuelTankOperation = ko.pureComputed(function() {
        var selectedFuelTank = self.selectedFuelTank();
        if (!selectedFuelTank) return null;

        var inputTimestampDate = self.inputTimestampDate();
        var inputTimestampTime = self.inputTimestampTime();
        var inputFuelTypeId = self.inputFuelTypeId();
        var inputFuelDensity = self.inputFuelDensity();
        var inputFuelAdjustmentInTon = self.inputFuelAdjustmentInTon();
        var inputSulphurContent = self.inputSulphurContent();

        var newFuelTankOperation = {
            timestamp: dateHelper.getSubmitDateString(inputTimestampDate, inputTimestampTime),
            fuelTypeId: inputFuelTypeId,
            fuelTankId: selectedFuelTank.id,
            fuelDensity: parseFloat(inputFuelDensity),
            fuelAdjustmentInTon: parseFloat(inputFuelAdjustmentInTon),
            sulphurContent: parseFloat(inputSulphurContent),
        };

        return newFuelTankOperation;
    });

    self.isNewFuelTankOperationValid = ko.pureComputed(function() {
        var newFuelTankOperation = self.newFuelTankOperation();
        return true;
    });

    self.departureDate = ko.pureComputed(function() {
        var departureVesselEvent = self.departureVoyageVesselEvent().vesselEvent();
        return moment.utc(departureVesselEvent.timestamp);
    });

    self.minInputDate = ko.pureComputed(function() {
        var departureDate = self.departureDate();
        return moment.utc(departureDate).add(-12, "months");
    });

    self.minInputTimestampDate = ko.pureComputed(function() {
        var minInputDate = self.minInputDate();
        return dateHelper.getFormatedDateString(minInputDate);
    });

    self.maxInputTimestamp = ko.pureComputed(function() {
        var endOfVoyageVesselEvent = self.endOfVoyageVesselEvent();
        return endOfVoyageVesselEvent ? endOfVoyageVesselEvent.vesselEvent().timestamp : null;
    });

    self.maxInputTimestampDate = ko.pureComputed(function() {
        var maxInputTimestamp = self.maxInputTimestamp();
        return dateHelper.getFormatedDateString(maxInputTimestamp);
    });

    self.selectedFuelTankBinding = self.selectedFuelTank.subscribe(function(newFuelTank) {
        self.inputFuelTypeId(newFuelTank.fuelTypeId);
    });
}

ViewModel.prototype.dispose = function() {
    this.selectedFuelTankBinding.dispose();
};

ViewModel.prototype.addFuelTankOperation = function() {
    var self = this;
    var newFuelTankOperation = self.newFuelTankOperation();
    self.isSaving(true);
    fuelTankOperationsClient.create(newFuelTankOperation).always(function() {
        self.isSaving(false);
    });
};

ViewModel.prototype.showFuelAdjustmentTooltip = function (vm, e) {
    var element = e.currentTarget;
    events.showTooltip.dispatch("tooltip-text", { text: this.fuelAdjustmentTooltipText() }, element);
};

ViewModel.prototype.closeTooltip = function () {
    events.closeTooltip.dispatch();
};

export default { viewModel: ViewModel, template: template };