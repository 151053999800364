import template from "./voyage-create.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import emissionReportVesselEventsClient from "Clients/emission-report-vessel-events-client";
import dateHelper from "Utilities/date-helper";
import events from "App/events";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

function ViewModel(params) {
    var self = this;
    self.allSortedVesselEvents = params.allSortedVesselEvents;
    self.queryDates = params.queryDates;
    self.isCreating = ko.observable(false);

    self.inputDate = ko.observable();
    self.inputTime = ko.observable();

    self.newVesselEvent = ko.pureComputed(function() {
        var vesselEvent = {};
        vesselEvent.vesselId = Vessels.currentVessel.id;
        vesselEvent.timestamp = dateHelper.getSubmitDateString(self.inputDate(), self.inputTime());
        vesselEvent.type = "Departure";
        return vesselEvent;
    });

    self.plotLineDateSelectedBinding = events.plotLineDateSelected.add(function(selectedDate) {
        self.inputDate(dateHelper.getFormatedDateString(selectedDate));
        self.inputTime(dateHelper.getFormatedTimeString(selectedDate));
    });

    self.isInputTimestampValid = ko.pureComputed(function() {
        var inputVesselEventDate = self.inputDate();
        var inputVesselEventTime = self.inputTime();
        var vesselEvents = self.allSortedVesselEvents();
        var queryDates = self.queryDates();
        var timestampMoment = dateHelper.getDateTime(inputVesselEventDate, inputVesselEventTime);
        if (!timestampMoment.isValid()) return false;

        var allowedFromMoment = queryDates.fromDate;

        var allowedToMoment = queryDates.toDate;

        if (timestampMoment < allowedFromMoment) {
            return false;
        }

        if (timestampMoment > allowedToMoment) {
            return false;
        }

        var sameTimestampVesselEvent = _.find(vesselEvents,
            function(vesselEvent) {
                var vesselEventTimestampMoment = moment.utc(vesselEvent.timestamp);
                return vesselEventTimestampMoment.isSame(timestampMoment);
            });
        if (sameTimestampVesselEvent) {
            return false;
        }

        var leftOfTimestampVesselEventIndex = _.findLastIndex(vesselEvents,
            function(vesselEvent) {
                var vesselEventTimestampMoment = moment.utc(vesselEvent.timestamp);
                return vesselEventTimestampMoment < timestampMoment;
            });
        var leftOfTimestampVesselEvent = vesselEvents[leftOfTimestampVesselEventIndex];

        var rightOfTimestampVesselEvent = _.find(vesselEvents,
            function(vesselEvent) {
                var vesselEventTimestampMoment = moment.utc(vesselEvent.timestamp);
                return vesselEventTimestampMoment > timestampMoment;
            });

        if (!leftOfTimestampVesselEvent || !rightOfTimestampVesselEvent) {
            return true;
        }

        if (leftOfTimestampVesselEvent.type === "Departure" && (!leftOfTimestampVesselEvent.voyage || !leftOfTimestampVesselEvent.voyage.dataConfirmedAt)) {
            return true;
        }

        return false;
    });
}

ViewModel.prototype.dispose = function() {
    this.plotLineDateSelectedBinding.detach();
};

ViewModel.prototype.start = function() {
    this.isCreating(true);
    events.dateSelectStarted.dispatch();
};

ViewModel.prototype.cancel = function() {
    this.isCreating(false);
    events.dateSelectEnded.dispatch();
};

ViewModel.prototype.create = function() {
    var self = this;
    var newVesselEvent = self.newVesselEvent();

    emissionReportVesselEventsClient.create(newVesselEvent).done(function(createdVesselEvent) {
        self.isCreating(false);
    });
};

export default { viewModel: ViewModel, template: template };