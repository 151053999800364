import ko from "knockout";
import _ from "underscore";
import categoriesClient from "Clients/vessel-categories-client";
import stringHelper from "Utilities/string-helper";

var ViewModel = function (model, accessGroups) {
    var self = this;
    self.originalAccessGroup = undefined;
    self.accessGroups = accessGroups;
    self.id = ko.observable();
    self.name = ko.observable().extend({
        required: true,
        validation: {
            async: true,
            validator: function (val, params, callback) {
                if ((self.originalAccessGroup != null) && (stringHelper.equalsIgnoreCase(val, self.originalAccessGroup.name))) {
                    // Same as "original name".
                    callback(true);
                } else {
                    callback(!self.accessGroups.exists(val));
                }
            },
            message: "An access group with that name already exists.",
        },
    });
    self.vessels = ko.observableArray([]);
    self.users = ko.observableArray([]);

    self.setModel(model);

    //For accessing validation from the parent component to for example enable/disable "create/save" button.
    self.errors = ko.validation.group(self);
    self.isValid = ko.computed(function () {
        return self.errors().length === 0;
    });
};

ViewModel.prototype.setModel = function (model) {
    var self = this;
    self.originalAccessGroup = model;

    if (model) {
        // Edit mode
        self.id(model.id);
        self.name(model.name);
        self.vessels(model.vessels);
        self.users(model.users);
    } else {
        // "New" mode
        self.id(undefined);
        self.name(undefined);
        self.vessels([]);
        self.users([]);
    }
};

ViewModel.prototype.getOriginalAccessGroup = function () {
    return this.originalAccessGroup;
};

ViewModel.prototype.save = function () {
    var self = this;
    var accessGroup = _.clone(self.originalAccessGroup) || {};

    accessGroup.name = self.name();

    var promise;
    if (accessGroup.id) {
        promise = categoriesClient.update(accessGroup);
    } else {
        promise = categoriesClient.create(accessGroup);
    }
    promise.done(function (data) {
        self.setModel(data);
    });
    return promise;
};

ViewModel.prototype.delete = function () {
    return categoriesClient.delete(this.originalAccessGroup);
};

export default ViewModel;