import net from "App/net";
import events from "App/events";

var Client = function() {

};

Client.prototype.getAll = function() {
    return net.get("/FuelTypes");
};

Client.prototype.create = function(fuelType) {
    return net.post("/FuelTypes", fuelType);
};

Client.prototype.remove = function(fuelType) {
    return net.del("/FuelTypes/" + fuelType.id).done(function() {
        events.fuelTypeDeleted.dispatch(fuelType);
    });
};

export default new Client();