import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import events from "App/events";
import importStatusClient from "Clients/import-status-client";

var ImportEntity = function (importEntity, status) {
    var self = this;

    self.stackTraceSearchReplaces = [{ find: / in /g, repl: "<br\> in" }, { find: /\[Inner exception:/g, repl: "\n [Inner exception:" }];
    self.statuses = { imported: 0, error: 1, running: 2 };
    self.importEntity = importEntity;

    self.importDuration = ko.observable(importEntity.importDuration);
    self.importPath = ko.observable(importEntity.importPath);
    self.zipPath = ko.observable(importEntity.zipPath);
    self.timestamp = ko.observable(importEntity.timestamp);
    self.errorMessage = ko.observable(importEntity.errorMessage);
    self.stackTrace = ko.observable(importEntity.stackTrace);
    self.status = ko.observable(status);
    self.webJob = ko.observable(importEntity.webJob);

    self.isOpen = ko.observable(false);

    importEntity.importStatistics = typeof importEntity.importStatistics !== "string" ? "{}" : importEntity.importStatistics;
    self.importStatistics = ko.observable(JSON.parse(importEntity.importStatistics));

    self.progress = ko.observable(status === self.statuses.running ? importEntity.progress : 100);


    self.formattedStackTrace = ko.pureComputed(function () {
        var stackTrace = self.stackTrace();

        _.each(self.stackTraceSearchReplaces, function (item) {
            stackTrace = stackTrace.replace(item.find, item.repl);
        });

        return stackTrace;
    });

    self.importDurationInSeconds = ko.pureComputed(function () {
        var ticksInSeconds = 10000000;
        var ticks = self.importDuration();
        var importDuration = 0;

        if (_.isUndefined(ticks)) {
            var startTime = importEntity.startTime;

            if (!_.isUndefined(startTime)) {
                startTime = moment(startTime);
                var endTime = moment.utc();
                var diff = endTime.diff(startTime, "seconds", true);
                importDuration = diff;
            }

        } else {
            importDuration = ticks / ticksInSeconds;
        }
        return importDuration;
    });

    self.statusCss = ko.pureComputed(function () {
        var statusCss = "";
        var statuses = self.statuses;

        if (status === statuses.imported) {
            statusCss = "importStatusOk";
        } else if (status === statuses.error) {
            statusCss = "importStatusFailed";
        } else if (status === statuses.running) {
            statusCss = "importStatusRunning";
        }

        return statusCss;
    });

    self.statusText = ko.pureComputed(function () {
        var statusText = "";
        var statuses = self.statuses;

        if (status === statuses.imported) {
            statusText = "Imported";
        } else if (status === statuses.error) {
            statusText = "Failed";
        } else if (status === statuses.running) {
            statusText = "Running";
        }

        return statusText;
    });

    self.imoNumber = ko.pureComputed(function() {
        var importPath = self.importPath();

        return importPath.split("/")[0];
    });

    self.importPathDownloadUrl = ko.pureComputed(function () {
        var importPath = self.importPath();
        if (importPath.toLowerCase().endsWith(".zip")) {
            return "/ImportStatus/DownloadZipFile?path=" + importPath;
        }
        return "/ImportStatus/DownloadImportPackage?path=" + importPath;
    });

    self.zipPathDownloadUrl = ko.pureComputed(function () {
        var zipPath = self.zipPath();
        return "/ImportStatus/DownloadZipFile?path=" + zipPath;
    });
};

ImportEntity.prototype.retryImport = function () {
    var self = this;

    self.importEntity.importStatistics = _.isEmpty(self.importStatistics()) ? [] : self.importStatistics();
    events.retryImport.dispatch(self);
};

ImportEntity.prototype.removeImport = function () {
    var self = this;
    if (confirm("Do you really want to remove this?")) {
        events.removeImport.dispatch(self);
    }
};

ImportEntity.prototype.downloadImportPackage = function () {
    var self = this;
    importStatusClient.downloadImportPackage(self.importPath());
};

ImportEntity.prototype.downloadZipFile = function () {
    var self = this;
    importStatusClient.downloadZipFile(self.zipPath());
};

ImportEntity.prototype.toggleIsOpen = function () {
    this.isOpen(!this.isOpen());
};

export default ImportEntity;