import net from "App/net";

var Client = function() {

};

Client.prototype.find = function(isOpen, vesselId, limit, skip) {
    var query = {
        isOpen: isOpen,
    };

    if (vesselId) {
        query.vesselId = vesselId;
    }

    if (limit) {
        query.limit = limit;
    }

    if (skip) {
        query.skip = skip;
    }

    return net.get("/Incidents/Find", query);
};

Client.prototype.findOpen = function(vesselId) {
    return this.find(true);
};

Client.prototype.findClosed = function(vesselId, limit, skip) {
    return this.find(false, vesselId, limit, skip);
};

export default new Client();