import net from "App/net";

var CiiWidgetClient = function () {

};

CiiWidgetClient.prototype.getAll = function () {
    return net.get("/CiiWidgetConfigs");
};

CiiWidgetClient.prototype.new = function (ciiWidgetConfiguration) {
    return net.post("/CiiWidgetConfigs", ciiWidgetConfiguration);
};

CiiWidgetClient.prototype.update = function (ciiWidgetConfiguration) {
    return net.put(`/CiiWidgetConfigs`, ciiWidgetConfiguration);
};

CiiWidgetClient.prototype.delete = function (ciiWidgetConfigId) {
    return net.del(`/CiiWidgetConfigs/${ciiWidgetConfigId}`);
};

export default new CiiWidgetClient();