import template from "./voyage-vessel-event.html";
import ko from "knockout";

function ViewModel(params) {
    var self = this;
    self.vessel = params.vessel;
    self.voyageVesselEvent = params.voyageVesselEvent;
    self.departureVoyageVesselEvent = params.departureVoyageVesselEvent;
    self.endOfVoyageVesselEvent = params.endOfVoyageVesselEvent;
    self.selectedTab = params.selectedTab;

    self.isVoyageInformationConfirmed = params.voyageVesselEvent.isVoyageInformationConfirmed;
    self.isVoyageDataConfirmed = params.voyageVesselEvent.isVoyageDataConfirmed;

    self.vesselEvent = self.voyageVesselEvent.vesselEvent;
    self.requireAllCargoDetailsCreated = ko.pureComputed(function() {
        var vesselEvent = self.vesselEvent();
        return vesselEvent.type === "Arrival";
    });
    self.isAllCargoDetailsCreated = ko.observable(false);
}

ViewModel.prototype.dispose = function() {
};

export default { viewModel: ViewModel, template: template };