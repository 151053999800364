import template from "./ft-soundings.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import dateHelper from "Utilities/date-helper";
import fuelTankSoundingsClient from "Clients/ft-soundings-client";
import events from "App/events";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

function ViewModel(params) {
    var self = this;
    self.selectedFuelTank = params.selectedFuelTank;
    self.departureVoyageVesselEvent = params.departureVoyageVesselEvent;
    self.endOfVoyageVesselEvent = params.endOfVoyageVesselEvent;
    self.selectedVoyageVesselEvent = params.selectedVoyageVesselEvent;
    self.isSaving = ko.observable(false);
    self.isLoading = ko.observable(false);
    self.fuelTankSoundings = ko.observableArray([]);
    self.isVoyageDataConfirmed = params.selectedVoyageVesselEvent.isVoyageDataConfirmed;

    self.inputTimestampDate = ko.observable();
    self.inputTimestampTime = ko.observable("12:00");
    self.inputFuelAmountInTon = ko.observable();
    self.inputFuelAmountInCubicMetres = ko.observable();
    self.inputFuelTemperatureInDegreesCelcius = ko.observable();

    self.newFuelTankSounding = ko.pureComputed(function() {
        var selectedFuelTank = self.selectedFuelTank();
        if (!selectedFuelTank) return null;

        var inputTimestampDate = self.inputTimestampDate();
        var inputTimestampTime = self.inputTimestampTime();
        var inputFuelAmountInTon = self.inputFuelAmountInTon();
        var inputFuelAmountInCubicMetres = self.inputFuelAmountInCubicMetres();
        var inputFuelTemperatureInDegreesCelcius = self.inputFuelTemperatureInDegreesCelcius();

        var newFuelTankSounding = {
            vesselId: Vessels.currentVessel.id,
            timestamp: dateHelper.getSubmitDateString(inputTimestampDate, inputTimestampTime),
            FuelTankId: selectedFuelTank.id,
            fuelAmountInTon: parseFloat(inputFuelAmountInTon),
            fuelAmountInCubicMetres: parseFloat(inputFuelAmountInCubicMetres),
            fuelTemperatureInDegreesCelcius: parseFloat(inputFuelTemperatureInDegreesCelcius),
        };

        return newFuelTankSounding;
    });

    self.isNewFuelTankSoundingValid = ko.pureComputed(function() {
        var newFuelTankSounding = self.newFuelTankSounding();
        return true;
    });

    self.sortedFuelTankSoundings = ko.pureComputed(function() {
        var fuelTankSoundings = self.fuelTankSoundings();
        return _.sortBy(fuelTankSoundings, "timestamp");
    });

    self.departureDate = ko.pureComputed(function() {
        var departureVesselEvent = self.departureVoyageVesselEvent().vesselEvent();
        return moment.utc(departureVesselEvent.timestamp);
    });

    self.minInputDate = ko.pureComputed(function() {
        var departureDate = self.departureDate();
        return moment.utc(departureDate).add(-2, "days");
    });

    self.minInputTimestampDate = ko.pureComputed(function() {
        var minInputDate = self.minInputDate();
        return dateHelper.getFormatedDateString(minInputDate);
    });

    self.maxInputDate = ko.pureComputed(function() {
        var endOfVoyageVesselEvent = self.endOfVoyageVesselEvent();
        return endOfVoyageVesselEvent ? moment.utc(endOfVoyageVesselEvent.vesselEvent().timestamp).add(2, "days") : null;
    });

    self.maxInputTimestampDate = ko.pureComputed(function() {
        var maxInputDate = self.maxInputDate();
        return dateHelper.getFormatedDateString(maxInputDate);
    });

    self.selectedFuelTankBinding = self.selectedFuelTank.subscribe(function(newFuelTank) {
        self.search();
    });

    var endOfVoyageVesselEvent = self.endOfVoyageVesselEvent();
    self.queryDates = ko.observable({
        fromDate: self.minInputDate(),
        toDate: endOfVoyageVesselEvent ? moment.utc(endOfVoyageVesselEvent.vesselEvent().timestamp).add(2, "days") : null,
    });

    self.fuelTankSoundingDeletedBinding = events.fuelTankSoundingDeleted.add(function(fuelTankSounding) {
        self.fuelTankSoundings.remove(fuelTankSounding);
    });

    self.sortedFuelTankSoundingsBinding = self.sortedFuelTankSoundings.subscribe(function(sortedFuelTankSoundings) {
        var lastFuelTankSounding = sortedFuelTankSoundings.length > 0 ? sortedFuelTankSoundings[sortedFuelTankSoundings.length - 1] : null;

        var nextSuggestedTimestamp = null;

        if (!lastFuelTankSounding) {
            nextSuggestedTimestamp = moment.utc(self.departureDate());
        } else {
            nextSuggestedTimestamp = moment.utc(lastFuelTankSounding.timestamp).add(1, "days");
        }

        self.inputTimestampDate(dateHelper.getFormatedDateString(nextSuggestedTimestamp));
        self.inputTimestampTime(dateHelper.getFormatedTimeString(nextSuggestedTimestamp));
    });

    self.search();
}

ViewModel.prototype.dispose = function() {
    this.selectedFuelTankBinding.dispose();
    this.fuelTankSoundingDeletedBinding.detach();
    this.sortedFuelTankSoundingsBinding.dispose();
};

ViewModel.prototype.addFuelTankSounding = function() {
    var self = this;
    var newFuelTankSounding = self.newFuelTankSounding();
    self.isSaving(true);
    fuelTankSoundingsClient.create(newFuelTankSounding).done(function(data) {
        self.fuelTankSoundings.push(data);
    }).always(function() {
        self.isSaving(false);
    });
};

ViewModel.prototype.search = function() {
    var self = this;
    var selectedFuelTank = self.selectedFuelTank();
    var queryDates = self.queryDates();
    var fromDate = dateHelper.getSubmitDateString(dateHelper.getFormatedDateString(queryDates.fromDate), dateHelper.getFormatedTimeString(queryDates.fromDate));
    var toDate = dateHelper.getSubmitDateString(dateHelper.getFormatedDateString(queryDates.toDate), dateHelper.getFormatedTimeString(queryDates.toDate));

    self.isLoading(false);
    fuelTankSoundingsClient.find(selectedFuelTank.id, fromDate, toDate).done(function(data) {
        self.fuelTankSoundings(data);
    }).always(function() {
        self.isLoading(false);
    });
};

export default { viewModel: ViewModel, template: template };