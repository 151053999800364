import net from "App/net";

var Client = function() {

};

Client.prototype.getAll = function() {
    return net.get("/FuelConsumptionSources");
};

export default new Client();