import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { VesselEvent } from "@/types/vesselEvent";
import { TrendEventType } from "@/types/trendEventType";
import VesselEventsClient from "Clients/vessel-events-client";

@Module({ namespaced: true, name: "VesselEvents" })
class VesselEvents extends VuexModule {
  private _trendEventTypes: TrendEventType[] | null = null;
  private _infoEvents: VesselEvent[] = [];
  private _trendEvents: VesselEvent[] = [];

  @Mutation
  public SET_TREND_EVENT_TYPES(data: TrendEventType[]): void {
    this._trendEventTypes = data;
  }

  @Mutation
  public SET_INFO_EVENTS(data: VesselEvent[]): void {
    this._infoEvents = data;
  }

  @Mutation 
  public SET_TREND_EVENTS(data: VesselEvent[]): void {
    this._trendEvents = data;
  }

  @Mutation
  public CLEAR_INFO_EVENTS(): void {
    this._infoEvents = [];
  }

  @Action({ rawError: true })
  public async updateTrendEventTypes(): Promise<void> {
    let data = [];
    try {
      data = await VesselEventsClient.getTrendEventTypes();
    } catch (err) {
      console.warn(err);
    }
    this.context.commit("SET_TREND_EVENT_TYPES", data);
  }

  @Action({ rawError: true })
  public async deleteEvent(event: VesselEvent): Promise<void> {
    try {
      if (event.type === "InfoEvent") {
        await VesselEventsClient.DeleteInfoEvent(event.id);
        await this.context.dispatch("fetchInfoEvents", event.vesselId);
      } else {
        await VesselEventsClient.DeleteTrendEvent(event.id);
        await this.context.dispatch("fetchTrendEvents", event.vesselId);
      }
    } catch (err) {
      console.warn(err);
    }
  }

  @Action({ rawError: true })
  public async editEvent(event: VesselEvent): Promise<void> {
    try {
      if (event.type === "InfoEvent") {
        await VesselEventsClient.UpdateInfoEvent(event);
        await this.context.dispatch("fetchInfoEvents", event.vesselId);
      } else {
        await VesselEventsClient.UpdateTrendEvent(event);
        await this.context.dispatch("fetchTrendEvents", event.vesselId);
      }
    } catch (err) {
      console.warn(err);
    }
  }

  @Action({ rawError: true })
  public async createEvent(event: VesselEvent): Promise<void> {
    try {
      if (event.type === "InfoEvent") {
        await VesselEventsClient.CreateInfoEvent(event);
        await this.context.dispatch("fetchInfoEvents", event.vesselId);
      } else {
        await VesselEventsClient.CreateTrendEvent(event);
        await this.context.dispatch("fetchTrendEvents", event.vesselId);
      }
    } catch (err) {
      console.warn(err);
    }
  }

  @Action({ rawError: true })
  public async fetchInfoEvents(vesselId: number): Promise<void> {
    let data = [];
    try {
      data = await VesselEventsClient.find(vesselId, ["InfoEvent"]);
    } catch (err) {
      console.warn(err);
      return Promise.reject(`Failed to fetch info events: ${err}`);
    }
    this.context.commit("SET_INFO_EVENTS", data);
  }

  @Action({ rawError: true })
  public async fetchTrendEvents(vesselId: number): Promise<void> {
    let data = [];
    try {
      data = await VesselEventsClient.find(vesselId, ["TrendEvent"]);
    } catch (err) {
      console.warn(err);
      return Promise.reject(`Failed to fetch trend events: ${err}`);
    }
    this.context.commit("SET_TREND_EVENTS", data);
  }

  @Action({ rawError: true })
  public async fetchEvents(vesselId: number): Promise<void> {
    try {
      await Promise.all([
        this.context.dispatch("fetchInfoEvents", vesselId),
        this.context.dispatch("fetchTrendEvents", vesselId),
      ]);
    } catch (err) {
      console.warn(err);
      return Promise.reject(`Failed to fetch events: ${err}`);
    }
  }

  get trendEventTypes(): TrendEventType[] | null {
    return this._trendEventTypes;
  }
  get infoEvents(): VesselEvent[] {
    return this._infoEvents;
  }

  get trendEvents(): VesselEvent[] {
    return this._trendEvents;
  }

  get allEvents(): VesselEvent[] {
    return this._infoEvents.concat(this._trendEvents);
  }
}

export default VesselEvents;
