import template from "./voyage-vessel-event-list-item.html";
import ko from "knockout";
import events from "App/events";
import vesselEventHelper from "Utilities/vessel-event-helper";

function ViewModel(params) {
    var self = this;
    self.voyageVesselEvent = params.voyageVesselEvent;
    self.vesselEvent = params.voyageVesselEvent.vesselEvent;
    self.isVoyageInformationConfirmed = params.voyageVesselEvent.isVoyageInformationConfirmed;
    self.currentDistance = params.voyageVesselEvent.voyageVesselEventData.currentDistance;

    self.hasMissingFlowMeterFuelConsumption = params.voyageVesselEvent.voyageVesselEventData.hasMissingFlowMeterFuelConsumption;
    self.hasMissingDistance = params.voyageVesselEvent.voyageVesselEventData.hasMissingDistance;

    self.sourceText = ko.pureComputed(function () {
        var vesselEvent = self.vesselEvent();
        return vesselEventHelper.getVesselEventSourceText(vesselEvent);
    });

    self.sourceImageSrc = ko.pureComputed(function () {
        var vesselEvent = self.vesselEvent();
        return vesselEventHelper.getVesselEventSourceImageSrc(vesselEvent);
    });
}

ViewModel.prototype.dispose = function() {
};

ViewModel.prototype.notifySelect = function() {
    events.vesselEventSelected.dispatch(this.vesselEvent());
};

export default { viewModel: ViewModel, template: template };