import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import AuthService from "@/services/AuthService";
import { Tokens } from "@/types/tokens";
import { LoginData } from "@/types/loginData";
import { ResetPasswordData } from "@/types/resetPasswordData";
import { ChangePasswordData } from "@/types/changePasswordData";
import authHeader from "@/services/AuthHeader";

const storedAccessToken = localStorage.getItem("ACCESS_TOKEN");
const storedAccessTokenExpiryDate = localStorage.getItem("TOKEN_EXPIRY_DATE");
let storedRememberUser = localStorage.getItem("REMEMBER_USER");
if (storedRememberUser) {
  storedRememberUser = JSON.parse(storedRememberUser);
}

@Module({ namespaced: true, name: "Auth" })
class Auth extends VuexModule {
  public status = storedAccessToken ? { loggedIn: true } : { loggedIn: false };
  public accessToken = storedAccessToken ? storedAccessToken : null;
  public accessTokenExpiryDate: string | null = storedAccessTokenExpiryDate ? storedAccessTokenExpiryDate : null;
  public _isRefreshing = false;

  @Mutation
  public setAccountPersistence(state: boolean): void {
    localStorage.setItem("REMEMBER_USER", JSON.stringify(state));
  }

  @Mutation
  public loginSuccess(tokens: Tokens): void {
    this.status.loggedIn = true;
    this.accessToken = tokens.access;
    this.accessTokenExpiryDate = tokens.expiry;
    localStorage.setItem("ACCESS_TOKEN", tokens.access);
    localStorage.setItem("TOKEN_EXPIRY_DATE", tokens.expiry);

    this._isRefreshing = false;
  }

  @Mutation
  public loginFailure(): void {
    this.status.loggedIn = false;
    this.accessToken = null;
    this.accessTokenExpiryDate = null;
    this._isRefreshing = false;
  }

  @Mutation
  public loadingState(state: boolean): void {
    this._isRefreshing = state;

  }

  @Mutation
  public logout(): void {
    this.status.loggedIn = false;
    this.accessToken = null;
    this.accessTokenExpiryDate = null;
  }

  @Action({ rawError: true })
  public async login(data: LoginData): Promise<any> {
    this.context.commit("loadingState", true);
    return await AuthService.login(data.username, data.password).then(
      tokens => {
        this.context.commit("setAccountPersistence", data.persist);
        this.context.commit("loginSuccess", tokens);
        return Promise.resolve(tokens);
      },
      error => {
        console.error(error);
        this.context.commit("loginFailure");
        return Promise.reject(error.responseJSON);
      },
    );
  }

  @Action({ rawError: true })
  public async lostPassword(email: string): Promise<any> {
    return await AuthService.lostPassword(email).then(
      response => {
        return Promise.resolve(response);
      },
      error => {
        console.error(error);
        return Promise.reject(error);
      },
    );
  }

  @Action({ rawError: true })
  public async resetPassword(data: ResetPasswordData): Promise<any> {
    return await AuthService.resetPassword(data.username, data.token, data.password).then(
      response => {
        return Promise.resolve(response);
      },
      error => {
        console.error(error);
        return Promise.reject(error);
      },
    );
  }

  @Action({ rawError: true })
  public async changePassword(data: ChangePasswordData): Promise<any> {
    return await AuthService.changePassword(data.username, data.currentPassword, data.newPassword).then(
      response => {
        return Promise.resolve(response);
      },
      error => {
        console.error(error);
        return Promise.reject(error);
      },
    );
  }

  @Action
  public updateTokens(tokens: Tokens): void {
    this.context.commit("loginSuccess", tokens);
  }

  @Action
  public async isTokenValid(): Promise<boolean> {
    const response = await fetch(`${process.env.VUE_APP_API_BASE_URL + "/Authenticate/TokenCheck"}`, {
      headers: authHeader() as HeadersInit,
    });
    if (response.status !== 200) return false;
    return true;
  }

  @Action
  public async signOut(): Promise<void> {
    await AuthService.logout();
    this.context.commit("logout");
    this.context.commit("User/EXPIRE_USER", null, { root: true });
  }

  get isLoggedIn(): boolean {
    return this.status.loggedIn;
  }
  get isRefreshing(): boolean {
    return this._isRefreshing;
  }

  get getAccessTokenExpiryDate(): string | null {
    return this.accessTokenExpiryDate;
  }

  get getAccessToken(): string | null {
    return this.accessToken;
  }
}

export default Auth;
