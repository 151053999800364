import { VuexModule, Module } from "vuex-module-decorators";
import { WidgetType } from "@/types/widgetType";
const tableWidgetIllustration = require("@/assets/images/widgets/table-widget-illustration-1.png");
const graphWidgetIllustration = require("@/assets/images/widgets/graph-widget-illustration-1.png");
const mapWidgetIllustration = require("@/assets/images/widgets/map-widget-illustration-1.png");
const performanceStatusWidgetIllustration = require("@/assets/images/widgets/performance-status-widget-illustration-1.png");
const notificationWidgetIllustration = require("@/assets/images/widgets/notification-widget-illustration.png");
const ciiWidgetTableViewIllustration = require("@/assets/images/widgets/cii-widget-tableview.png");
const ciiWidgetChartViewIllustration = require("@/assets/images/widgets/cii-widget-chartview.png");
const foulingWidgetViewIllustration = require("@/assets/images/widgets/fouling-overview-widget-illustration.png");

@Module({ namespaced: true, name: "WidgetTypes" })
class WidgetTypes extends VuexModule {
  private _startup: WidgetType = {
    defaultName: "Welcome",
    template: "WelcomeWidget",
    defaultWidth: 20,
    defaultHeight: 12,
    icon: "mdi-human-greeting",
    multiple: true,
    description: "",
    images: [
      "https://images.pexels.com/photos/128299/pexels-photo-128299.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    ],
    usingConfig: false,
    enabled: true,
  };

  private _VesselDataTable: WidgetType = {
    defaultName: "Vessel data (table)",
    template: "VesselDataWidget",
    displayType: "VesselDataTable",
    defaultWidth: 12,
    defaultHeight: 6,
    icon: "mdi-table",
    multiple: true,
    description:
      "This widget allows comparisons to be made between vessels: select the vessels that you wish to compare, then add columns as required. In each column you can select one variable from each vessel. A timespan must be chosen for each column: The available options are last received value, last voyage average/accumulate, and last x hours average/accumulate. Note that if accumulate is chosen, only a restricted number of units are allowed. After clicking Save, the configuration will be stored, and the chosen data displayed as a table. Multiple instances of this widget may be created, each with their own configuration.",
    images: [tableWidgetIllustration],
    usingConfig: true,
    enabled: true,
  };

  private _VesselDataGraph: WidgetType = {
    defaultName: "Vessel data (graph)",
    template: "VesselDataWidget",
    displayType: "VesselDataGraph",
    defaultWidth: 12,
    defaultHeight: 6,
    icon: "mdi-chart-bar",
    multiple: true,
    description:
      "This widget allows comparisons to be made between vessels: select the vessels that you wish to compare. You can select one variable from each vessel. A timespan must be chosen for the comparison: The available options are last received value, last voyage average/accumulate, and last x hours average/accumulate. Note that if accumulate is chosen, only a restricted number of units are allowed. After clicking Save, the configuration will be stored, and the chosen data displayed as a graph. Multiple instances of this widget may be created, each with their own configuration.",
    images: [graphWidgetIllustration],
    usingConfig: true,
    enabled: true,
  };

  private _map: WidgetType = {
    defaultName: "Vessel map",
    template: "MapWidget",
    defaultWidth: 9,
    defaultHeight: 9,
    icon: "public",
    multiple: false,
    description:
      "This widget is a simple map. By default, the map displays all your vessels but you can choose to filter them based on vessel groups. The vessel details, such as name and tracking history, are usually hidden to avoid cluttering the display. If required, the vessel name and history can be made visible by clicking on the vessel. Clicking on the vessel name shown will display more detailed vessel information. You can only add one instance of this widget to your dashboard.",
    images: [mapWidgetIllustration],
    usingConfig: false,
    enabled: true,
  };

  private _performanceStatus: WidgetType = {
    defaultName: "Performance status",
    template: "PerformanceStatusWidget",
    defaultWidth: 8,
    defaultHeight: 6,
    icon: "mdi-format-list-bulleted",
    multiple: true,
    description:
      "This widget allows you to see the performance status of your vessels: select the vessels that you wish to see. But remember that performance status data is only available for vessels with the diagnostics feature enabled. After clicking save, the configuration will be stored, and the performance status, for each selected vessel, will show. Multiple instances of this widget may be created, each with their own configuration.",
    images: [performanceStatusWidgetIllustration],
    usingConfig: true,
    enabled: true,
  };

  private _notification: WidgetType = {
    defaultName: "Notifications",
    template: "NotificationWidget",
    defaultWidth: 8,
    defaultHeight: 6,
    icon: "mdi-bell",
    multiple: true,
    description:
      "This widget allows you to see notifications of incidents in your vessels: select the vessels that you wish to see. But remember that notifications are only available for vessels with the 'Notifications' feature enabled. After clicking save, the configuration will be stored, and the incidents, for each selected vessel, will show. Multiple instances of this widget may be created, each with their own configuration.",
    images: [notificationWidgetIllustration],
    usingConfig: true,
    enabled: true,
  };

  private _emissions: WidgetType = {
    defaultName: "Carbon Intensity Indicator (CII)",
    template: "CiiWidget",
    defaultWidth: 8,
    defaultHeight: 6,
    icon: "mdi-weather-fog",
    multiple: true,
    description:
      "This widget allows you to see the CII and related data for your vessels: select the vessels that you wish to see. After clicking save, the configuration will be stored, and the CII data, for each selected vessel, will show. Multiple instances of this widget may be created, each with their own configuration. <br /> Calculations are based on available fuel consumption sensors onboard, and should be used for indicative purposes only.",
    images: [ciiWidgetTableViewIllustration, ciiWidgetChartViewIllustration],
    usingConfig: true,
    enabled: true,
  };

  private _foulingOverview: WidgetType = {
    defaultName: "Fouling Overview",
    template: "FoulingOverviewWidget",
    defaultWidth: 12,
    defaultHeight: 6,
    icon: "mdi-ferry",
    multiple: true,
    description:
      "This widget allows you to view the current speed loss measurements across your fleet of vessels so that you can quickly see which ones require further action. Select the vessels you wish to include in this widget and then click save to store your configuration",
    images: [foulingWidgetViewIllustration],
    usingConfig: true,
    enabled: true,
  };

  private _allAddable: WidgetType[] = [
    this._VesselDataTable,
    this._VesselDataGraph,
    this._map,
    this._performanceStatus,
    this._notification,
    this._emissions,
    this._foulingOverview,
  ];

  public get getTypeByTemplateName(): (id: string) => unknown {
    return (id: string) => {
      switch (id) {
        case "WelcomeWidget":
          return this._startup;
        case "VesselDataTable":
          return this._VesselDataTable;
        case "VesselDataGraph":
          return this._VesselDataGraph;
        case "MapWidget":
          return this._map;
        case "PerformanceStatusWidget":
          return this._performanceStatus;
        case "NotificationWidget":
          return this._notification;
        case "CiiWidget":
          return this._emissions;
        case "FoulingOverviewWidget":
          return this._foulingOverview;
      }
    };
  }

  get startup(): WidgetType {
    return this._startup;
  }

  get VesselDataTable(): WidgetType {
    return this._VesselDataTable;
  }

  get VesselDataGraph(): WidgetType {
    return this._VesselDataGraph;
  }

  get map(): WidgetType {
    return this._map;
  }

  get performanceStatus(): WidgetType {
    return this._performanceStatus;
  }

  get notification(): WidgetType {
    return this._notification;
  }

  get emissions(): WidgetType {
    return this._emissions;
  }

  get allAddable(): WidgetType[] {
    return this._allAddable;
  }

  get foulingOverview(): WidgetType {
    return this._foulingOverview;
  }
}

export default WidgetTypes;
