var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"date-time-range-selection",class:{ 'disabled': _vm.disabled }},[_c('div',{staticClass:"row-wrapper mb-2"},[_c('div',{staticClass:"from-section mr-3"},[_c('fieldset',[_c('legend',{staticClass:"body-2 pb-1",attrs:{"align":"center"}},[_vm._v("From date")]),_c('div',{staticClass:"date-time d-flex align-center"},[_c('v-menu',{ref:"menuFromDate",attrs:{"close-on-content-click":false,"return-value":_vm.fromDate,"transition":"scale-transition","min-width":"auto"},on:{"update:returnValue":function($event){_vm.fromDate=$event},"update:return-value":function($event){_vm.fromDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"widget-date-selection pa-1 mr-1 d-flex align-center"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-calendar-range")]),_vm._v(" "+_vm._s(_vm.fromDate)+" ")],1)]}}]),model:{value:(_vm.isWidgetFromDateModalActive),callback:function ($$v) {_vm.isWidgetFromDateModalActive=$$v},expression:"isWidgetFromDateModalActive"}},[_c('v-date-picker',{on:{"input":function($event){_vm.$refs.menuFromDate.save(_vm.fromDate); _vm.save()}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1),_c('v-menu',{ref:"menuFromTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.fromTime,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.fromTime=$event},"update:return-value":function($event){_vm.fromTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"widget-time-selection pa-1 mr-1 d-flex align-center"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-clock-time-four-outline")]),_vm._v(" "+_vm._s(_vm.fromTime)+" ")],1)]}}]),model:{value:(_vm.isWidgetFromTimeModalActive),callback:function ($$v) {_vm.isWidgetFromTimeModalActive=$$v},expression:"isWidgetFromTimeModalActive"}},[(_vm.isWidgetFromTimeModalActive)?_c('v-time-picker',{attrs:{"format":"24hr"},model:{value:(_vm.fromTime),callback:function ($$v) {_vm.fromTime=$$v},expression:"fromTime"}},[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-semibold",attrs:{"color":"primary"},on:{"click":function($event){_vm.isWidgetFromTimeModalActive = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"font-weight-semibold",attrs:{"color":"primary"},on:{"click":function($event){_vm.$refs.menuFromTime.save(_vm.fromTime); _vm.save()}}},[_vm._v("OK")])],1):_vm._e()],1)],1)])]),_c('div',{staticClass:"to-section"},[_c('fieldset',[_c('legend',{staticClass:"body-2 pb-1",attrs:{"align":"center"}},[_vm._v("To date")]),_c('div',{staticClass:"date-time d-flex align-center"},[_c('v-menu',{ref:"menuToDate",attrs:{"close-on-content-click":false,"return-value":_vm.toDate,"transition":"scale-transition","min-width":"auto"},on:{"update:returnValue":function($event){_vm.toDate=$event},"update:return-value":function($event){_vm.toDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"widget-date-selection pa-1 mr-1 d-flex align-center"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-calendar-range")]),_vm._v(" "+_vm._s(_vm.toDate)+" ")],1)]}}]),model:{value:(_vm.isWidgetToDateModalActive),callback:function ($$v) {_vm.isWidgetToDateModalActive=$$v},expression:"isWidgetToDateModalActive"}},[_c('v-date-picker',{on:{"input":function($event){_vm.$refs.menuToDate.save(_vm.toDate); _vm.save()}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1),_c('v-menu',{ref:"menuToTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.toTime,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.toTime=$event},"update:return-value":function($event){_vm.toTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"widget-time-selection pa-1 mr-1 d-flex align-center"},'div',attrs,false),on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-clock-time-four-outline")]),_vm._v(" "+_vm._s(_vm.toTime)+" ")],1)]}}]),model:{value:(_vm.isWidgetToTimeModalActive),callback:function ($$v) {_vm.isWidgetToTimeModalActive=$$v},expression:"isWidgetToTimeModalActive"}},[(_vm.isWidgetToTimeModalActive)?_c('v-time-picker',{attrs:{"format":"24hr"},model:{value:(_vm.toTime),callback:function ($$v) {_vm.toTime=$$v},expression:"toTime"}},[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-semibold",attrs:{"color":"primary"},on:{"click":function($event){_vm.isWidgetToTimeModalActive = false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"font-weight-semibold",attrs:{"color":"primary"},on:{"click":function($event){_vm.$refs.menuToTime.save(_vm.toTime); _vm.save();}}},[_vm._v("OK")])],1):_vm._e()],1)],1)])])]),_vm._t("additional-actions")],2)}
var staticRenderFns = []

export { render, staticRenderFns }