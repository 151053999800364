import net from "App/net";

var AnnouncementClient = function () {

};

AnnouncementClient.prototype.find = function () {

  return net.get("/Announcement");
};

AnnouncementClient.prototype.new = function (newAnnouncement) {
  return net.post("/Announcement", newAnnouncement);
};

AnnouncementClient.prototype.findByUser = function () {
  return net.get("/Announcement/GetByUser");
}

AnnouncementClient.prototype.readByUser = function (announcementDetails) {
  return net.post("/Announcement/ReadByUser", announcementDetails);
}

AnnouncementClient.prototype.deleteAnnounement = function (announcementId) {
  return net.del("/Announcement/" + announcementId);
}

AnnouncementClient.prototype.sendEmail = function (newAnnouncement) {
  return net.post("/Announcement/SendEmailToOnlineUser", newAnnouncement)
}

AnnouncementClient.prototype.AcknowledgeIncident = function (id) {
  return net.put("/Incidents/" + id);
}

AnnouncementClient.prototype.AcknowledgeAllIncidents = function (openIncidents) {
  return net.post("/Incidents", openIncidents)
}

export default new AnnouncementClient();
