import template from "./flow-meter.html";
import ko from "knockout";
import _ from "underscore";
import flowMetersClient from "Clients/flow-meters-client";

function ViewModel(params) {
    var self = this;
    self.flowMeter = params.flowMeter;
    self.massFlowRateLogVariables = params.massFlowRateLogVariables;
    self.fuelTypes = params.fuelTypes;
    self.isRemoving = ko.observable(false);

    self.fuelTypeName = ko.pureComputed(function() {
        var fuelTypes = self.fuelTypes();
        var fuelType = _.find(fuelTypes,
            function(item) {
                return item.id === self.flowMeter.fuelTypeId;
            });
        return fuelType.name;
    });

    self.fuelMassFlowRateLogVariableName = ko.pureComputed(function() {
        var massFlowRateLogVariables = self.massFlowRateLogVariables();
        var massFlowRateLogVariable = _.find(massFlowRateLogVariables,
            function(item) {
                return item.id === self.flowMeter.fuelMassFlowRateLogVariableId;
            });
        return massFlowRateLogVariable ? massFlowRateLogVariable.name : "Manual input";
    });
}

ViewModel.prototype.dispose = function() {
};

ViewModel.prototype.remove = function() {
    var self = this;
    self.isRemoving(true);
    flowMetersClient.remove(self.flowMeter).always(function() {
        self.isRemoving(false);
    });
};

export default { viewModel: ViewModel, template: template };