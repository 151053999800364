import ko from "knockout";
import _ from "underscore";
import vesselEventCargoDetailsClient from "Clients/vessel-event-cargo-details-client";

var ViewModel = function(vesselEvent, cargoUnit, cargoDetails) {
    var self = this;
    self.vesselEvent = vesselEvent;
    self.vesselEventId = vesselEvent().id;
    self.cargoUnit = cargoUnit;

    self.isCreated = ko.observable();
    self.cargoDetail = ko.observable();
    self.inputCargoCarried = ko.observable();
    self.isEditing = ko.observable();

    var existingCargoDetail = _.find(cargoDetails,
        function(cargoDetail) {
            return cargoDetail.cargoUnitId === cargoUnit.id;
        });

    self.hasCargoCarried = ko.pureComputed(function() {
        var cargoDetail = self.cargoDetail();
        return _.isFinite(cargoDetail.cargoCarried);
    });

    self.initialize(existingCargoDetail);
};

ViewModel.prototype.initialize = function(existingCargoDetail) {
    var self = this;

    var cargoDetail = {
        vesselEventId: self.vesselEventId,
        cargoUnitId: self.cargoUnit.id,
        cargoCarried: existingCargoDetail ? existingCargoDetail.cargoCarried : null,
    };

    self.isCreated(existingCargoDetail ? true : false);
    self.cargoDetail(cargoDetail);
    self.inputCargoCarried(cargoDetail.cargoCarried);
    var isVesselEventConfirmed = self.vesselEvent().isConfirmed;
    self.isEditing(!_.isFinite(cargoDetail.cargoCarried) && !isVesselEventConfirmed);
};

ViewModel.prototype.dispose = function(newCurve) {
};

ViewModel.prototype.startEditing = function(vm, e) {
    var $wrapper = $(e.currentTarget).closest(".edit-wrapper");
    this.isEditing(true);

    var $input = $wrapper.find("input[type=text]");
    $input.focus();
    $input.select();
};

ViewModel.prototype.cancelEditing = function(vm, e) {
    this.isEditing(false);
};

ViewModel.prototype.save = function() {
    var self = this;
    var cargoDetail = _.clone(self.cargoDetail());
    var inputCargoCarried = self.inputCargoCarried();
    if (inputCargoCarried) {
        cargoDetail.cargoCarried = parseFloat(inputCargoCarried);
        if (!_.isFinite(cargoDetail.cargoCarried)) {
            cargoDetail.cargoCarried = 0;
        }
        vesselEventCargoDetailsClient.create(cargoDetail).done(function(data) {
            self.initialize(data);
        });
    } else if (self.isCreated()) {
        vesselEventCargoDetailsClient.remove(cargoDetail).done(function() {
            self.initialize(null);
        });
    }
};

export default ViewModel;