import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Company } from "@/types/company";
import companiesClient from "Clients/companies-client";


@Module({ namespaced: true, name: "Companies" })
class Companies extends VuexModule {
  private _CompaniesExpired = true;
  private _companies: Company[] = [];

  @Mutation
  public REFRESH_COMPANIES(companies: Company[]): void {
    this._CompaniesExpired = false;
    this._companies = companies;
  }

  @Mutation
  public EXPIRE_DATA(): void {
    this._CompaniesExpired = true;
  }

  @Action({ rawError: true })
  public async refreshCompanies(): Promise<void> {
    let data = [];
    try {
      data = await companiesClient.getAll();
    } catch (error) {
      console.warn(error);
    }
    this.context.commit("REFRESH_COMPANIES", data);
  }

  @Action({ rawError: true })
  public async getAll(): Promise<Company[]> {
    if (!this._CompaniesExpired) {
      return this._companies;
    } else {
      await this.refreshCompanies();
      return this._companies;
    }
  }

  get companies(): Company[] {
    return this._companies;
  }
  get companiesCount(): number {
    return this._companies.length;
  }

  get companyById() {
    return (id: number): Company | undefined => {
      return this._companies.find(company => company.id === id);
    };
  }
}

export default Companies;
