import ko from "knockout";

var ImportStatistic = function(importStatistic) {
    var self = this;

    self.tableName = ko.observable(importStatistic.TableName);
    self.importDuration = ko.observable(importStatistic.ImportDuration);
    self.rowCount = ko.observable(importStatistic.RowCount);

    self.importDurationText = ko.pureComputed(function() {
        var ticksPerMs = 10000;
        var ticksPerSecond = 10000000;
        var importDuration = self.importDuration();
        var importDurationInSeconds = importDuration / ticksPerSecond;
        var importDurationInMs = importDuration / ticksPerMs;


        if (importDurationInMs > 999) {
            return importDurationInSeconds.toFixed(3) + " s";
        }

        return importDurationInMs + " ms";
    });
};

export default ImportStatistic;