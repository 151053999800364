import net from "App/net";

var Client = function() {

};

Client.prototype.find = function(vesselId, eventTypes) {
    var filter = {
        vesselId: vesselId,
        eventTypes: eventTypes,
    };

    return net.get("/VesselEvents/Find", filter);
};

Client.prototype.getTrendEventTypes = function() {
  return net.get("/DiagnosticEvents/Trend/Types");
};

Client.prototype.update = function(vesselEvent) {
    return net.put("/VesselEvents/" + vesselEvent.id, vesselEvent);
};

Client.prototype.CreateInfoEvent = function(vesselInfoEventModel) {
  return net.post("/DiagnosticEvents/Info", vesselInfoEventModel);
};

Client.prototype.CreateTrendEvent = function(vesselTrendEventModel) {
  return net.post("/DiagnosticEvents/Trend", vesselTrendEventModel);
};

Client.prototype.UpdateInfoEvent = function(vesselInfoEventModel) {
  return net.put("/DiagnosticEvents/Info/" + vesselInfoEventModel.id, vesselInfoEventModel);
};

Client.prototype.UpdateTrendEvent = function(vesselTrendEventModel) {
  return net.put("/DiagnosticEvents/Trend/" + vesselTrendEventModel.id, vesselTrendEventModel);
};

Client.prototype.DeleteInfoEvent = function(id) {
  return net.del("/DiagnosticEvents/Info/" + id);
};

Client.prototype.DeleteTrendEvent = function(id) {
  return net.del("/DiagnosticEvents/Trend/" + id);
};

export default new Client();
