import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { SnackbarConfig } from "@/types/snackbarConfig";

@Module({ namespaced: true, name: "Snackbar" })
class Snackbar extends VuexModule {
  private _config = {} as SnackbarConfig;
  private _defaultConfig: SnackbarConfig = {
    text: "An error occurred.",
    color: "error",
    timeout: 5000,
    show: true,
    closeButton: false,
  };

  @Action
  public showSnackbar(config: Partial<SnackbarConfig>): void {
    //need to copy the default config, or the show variable will be overwritten when the snackbar is closed.
    const defaultConfigCopy = JSON.parse(JSON.stringify(this._defaultConfig));
    const snackbarConfig = Object.assign(defaultConfigCopy, config);
    
    this.context.commit("CLEAR_SNACKBAR_CONFIG");
    setTimeout(() => this.context.commit("SET_SNACKBAR_CONFIG", snackbarConfig));
  }

  @Action
  public close(): void {
    this.context.commit("CLEAR_SNACKBAR_CONFIG");
  }

  @Mutation
  public SET_SNACKBAR_CONFIG(config: SnackbarConfig): void {
    this._config = config;
  }

  @Mutation
  public CLEAR_SNACKBAR_CONFIG(): void {
    this._config = { show: false, timeout: -1 } as SnackbarConfig;
  }

  public get message(): SnackbarConfig {
    return this._config;
  }
}

export default Snackbar;
