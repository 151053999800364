import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import Store from "@/store/index";
import LongTrendsClient from "Clients/long-trends-client";
import LongTrendPeriodsClient from "Clients/long-trend-periods-client";
import { ILongTrend } from "@/types/longTrend";
import { IVesselLongTrends, IVesselLongTrend } from "@/types/vesselLongTrends";
import { ILongTrendPeriod } from "@/types/longTrendPeriod";

@Module({ namespaced: true, name: "LongTrends" })
class LongTrends extends VuexModule {
  private $_vesselLongTrends: IVesselLongTrends = {};

  @Mutation
  public SET_VESSEL_LONG_TRENDS(longTrends: ILongTrend[]): void {
    const vesselLongTrends: IVesselLongTrends = {};

    longTrends.forEach(longTrend => {
      vesselLongTrends[longTrend.name] = { longTrend };
    });
    
    this.$_vesselLongTrends = vesselLongTrends;
  }

  @Mutation
  public UPDATE_VESSEL_LONG_TRENDS(vesselLongTrend: IVesselLongTrend): void {
    this.$_vesselLongTrends[vesselLongTrend.longTrend.name] = vesselLongTrend;
  }

  @Action
  public async fetchVesselLongTrends(vesselId: number): Promise<void> {
    try {
      const longTrends =  await LongTrendsClient.findLongTrends(vesselId);
      const currentVessel = Store.getters["Vessels/currentVessel"];
      
      if (!currentVessel || currentVessel.id !== vesselId) return;
      else this.context.commit("SET_VESSEL_LONG_TRENDS", longTrends);
    } catch (error) {
      return Promise.reject(`Failed to find vessel long trends: ${error}`);
    }
  }

  @Action
  public async fetchVesselLongTrendPeriods(data: { vesselId: number, longTrend: ILongTrend }): Promise<void> { 
    if (!this.$_vesselLongTrends) return Promise.reject();     
      
    try {
      const longTrendPeriods: ILongTrendPeriod[] = await LongTrendPeriodsClient.find(data.longTrend.id);
      const currentVessel = Store.getters["Vessels/currentVessel"];
      
      if (!currentVessel || currentVessel.id !== data.vesselId) return;
      else this.context.commit("UPDATE_VESSEL_LONG_TRENDS", { longTrend: data.longTrend, longTrendPeriods });
    } catch (error) {
      return Promise.reject(`Failed to fetch long trends: ${error}`);
    }
  }

  @Action
  public async updateVesselLongTrend(data: { vesselId: number, longTrend: ILongTrend }): Promise<void> {
    try {
      const updatedLongTrend: ILongTrend = await LongTrendsClient.update(data.longTrend);
      const currentVessel = Store.getters["Vessels/currentVessel"];
      
      if (!currentVessel || currentVessel.id !== data.vesselId) return;
      else this.context.dispatch("fetchVesselLongTrendPeriods", { vesselId: data.vesselId, longTrend: updatedLongTrend });
    } catch (error) {
      return Promise.reject(`Failed to update long trend: ${error}`);
    }
  }

  get vesselLongTrends(): IVesselLongTrends {
    return this.$_vesselLongTrends;
  }

  get vesselLongTrendByName() {
    return (name: string): any => {
      return this.$_vesselLongTrends[name];
    };
  }
}

export default LongTrends;
