import ko from "knockout";
import _ from "underscore";

ko.bindingHandlers.numberFormat = {
  update: function (element, valueAccessor) {
    var options = ko.utils.unwrapObservable(valueAccessor());
    var value = ko.utils.unwrapObservable(options.value);
    var precision = ko.utils.unwrapObservable(options.precision);
    precision = _.isNumber(precision) ? precision : ko.bindingHandlers.numberFormat.defaultPrecision;
    var formattedValue = _.isNumber(value) ? value.toFixed(precision) : "";

    ko.bindingHandlers.text.update(element, function () { return formattedValue; });
  },
  defaultPrecision: 2,
};

ko.bindingHandlers.undefinedIfEmpty = {
  init: function (element, valueAccessor) {
    var observable = valueAccessor();
    ko.utils.registerEventHandler(element, "change", function () {
      var value = element.value;
      // Convert value to number if it's not empty, otherwise set to undefined
      observable(value === "" ? undefined : isNaN(Number(value)) ? value : Number(value));
    });
  },
  update: function (element, valueAccessor) {
    var value = ko.utils.unwrapObservable(valueAccessor());
    // Keep the value as is if it's undefined, otherwise convert to string for display
    element.value = value === undefined || value === null ? "" : String(value);
  }
};
