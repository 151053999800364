import template from "./compare-vessels-long-trends.html";
import ko from "knockout";
import _ from "underscore";
import $ from "jquery";
import events from "App/events";
import diagnosticTypesHelper from "Utilities/diagnostic-types-helper";

function ViewModel(params) {
    var self = this;
    self.compareGroup = params.compareGroup;
    self.vessels = self.compareGroup.vessels;
    self.longTrendName = self.compareGroup.longTrendName;
    self.longTrends = self.compareGroup.longTrends;
    self.longTrendPeriods = self.compareGroup.longTrendPeriods;

    var firstLongTrend = self.longTrends[0];
    self.headingText = firstLongTrend.descriptionLong || firstLongTrend.description;

    self.diagnosticTypeHelpText = diagnosticTypesHelper.getHelpText(firstLongTrend.diagnosticType);
    self.isDiagnosticTypeHelpOpen = ko.observable(false);

    self.closeTooltipBinding = events.closeTooltip.add(function() {
        self.isDiagnosticTypeHelpOpen(false);
    });
}

ViewModel.prototype.dispose = function() {
    this.closeTooltipBinding.detach();
};

ViewModel.prototype.toggleDiagnosticTypeHelpTooltip = function(vm, e) {
    var isDiagnosticTypeHelpOpen = this.isDiagnosticTypeHelpOpen();
    if (!isDiagnosticTypeHelpOpen) {
        var element = e.currentTarget;
        events.showTooltip.dispatch("tooltip-text", { text: this.diagnosticTypeHelpText, isWide: true }, element);
        this.isDiagnosticTypeHelpOpen(true);
    } else {
        events.closeTooltip.dispatch();
    }
};

ViewModel.prototype.hideDiagnosticTypeHelpTooltip = function () {
    if (this.isDiagnosticTypeHelpOpen()) {
        this.isDiagnosticTypeHelpOpen(false);
        events.closeTooltip.dispatch();
    }
};

export default { viewModel: ViewModel, template: template };