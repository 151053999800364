






































































































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import htmlEncoder from "Utilities/htmlencode-helper";
//  types
import { DashboardI } from "@/types/dashboard";
//  components
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import draggable from "vuedraggable";
//  modules
import DashboardModule from "@/store/clients/Dashboard.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
import UsersModule from "@/store/clients/Users.module";
import UserModule from "@/store/clients/User.module";
import { UserProfile } from "@/types/userProfile";

const Dash = getModule(DashboardModule, store);
const Snackbar = getModule(SnackbarModule, store);
const Users = getModule(UsersModule, store);
const User = getModule(UserModule, store);

interface DashboardNameHandler {
  dashboard: DashboardI;
  editing: boolean;
  newName: string;
  sequence?: number | undefined;
}

@Component({
  components: {
    ConfirmDialog,
    draggable,
  },
})
export default class ManageDashboardsDialog extends Vue {
  @Ref("confirmDelete") confirmDelete!: any;

  dialog = false;
  dashboardNameHandlers: DashboardNameHandler[] = [];
  panel: number[] = [];

  get personalDashboardNameHandlers(): DashboardNameHandler[] {
    const personalDahboards = this.dashboardNameHandlers.filter(d => d.dashboard.userId === User.userId);

    // get saved dashboards sequence array
    const stringifiedDashboardListSequence: string | null = localStorage.getItem("dashboardListSequence");
    //  if not null or undefined
    if (stringifiedDashboardListSequence != null) {
      const dashboardListSequence: { id: number; sequence: number }[] = JSON.parse(stringifiedDashboardListSequence);
      personalDahboards.forEach(d => (d.sequence = dashboardListSequence.find(item => item.id === d.dashboard.id)?.sequence));
      return personalDahboards.sort((a: any, b: any) => a.sequence - b.sequence);
    } else {
      return personalDahboards;
    }
  }

  set personalDashboardNameHandlers(updatedPersonalDashboardNameHandlers: DashboardNameHandler[]) {
    this.dashboardNameHandlers = updatedPersonalDashboardNameHandlers;
    const dashboardListSequence = this.dashboardNameHandlers.map((d, i) => ({ id: d.dashboard.id, sequence: i }));
    this.$emit("onSequenceChanged", dashboardListSequence);
    localStorage.setItem("dashboardListSequence", JSON.stringify(dashboardListSequence));
  }

  get dashboards(): DashboardI[] {
    return Dash.dashboards;
  }

  get activeDashboard(): DashboardI {
    return Dash.activeDashboard;
  }

  get isLastDashboard(): boolean {
    return this.dashboardNameHandlers.length === 1;
  }

  get isCurrentDashboard() {
    return (dashboardId: number): boolean => {
      return dashboardId === this.activeDashboard.id;
    };
  }

  get usersInSameCompany(): UserProfile[] {
    return Users.users.filter(u => u.id !== User.userId);
  }

  open(): void {
    Users.refreshUsers();
    this.refreshDashboardsList();
    this.dialog = true;
  }

  close(): void {
    this.dialog = false;
    this.panel = [];
  }

  refreshDashboardsList(): void {
    const dashboardNameHandlers: DashboardNameHandler[] = [];

    this.dashboards.forEach(dashboard => {
      dashboardNameHandlers.push({ dashboard: dashboard, editing: false, newName: dashboard.name });
    });

    this.dashboardNameHandlers = dashboardNameHandlers;
  }

  async createNewDashboard(): Promise<void> {
    const newDashboardName = "New dashboard " + (this.dashboards.length + 1);

    try {
      const newDashboard = await Dash.createNewDashboard(newDashboardName);
      this.setActiveDashboard(newDashboard.id);

      Snackbar.showSnackbar({
        text: `New dashboard <b>'${htmlEncoder.htmlEncode(newDashboardName)}'</b> was <b>created</b>`,
        color: "success",
      });

      this.refreshDashboardsList();
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to create dashboard" });
    }
  }

  setActiveDashboard(dashboardId: number): void {
    Dash.setActiveDashboard(dashboardId);
  }

  async deleteDashboard(dashboardNameHandler: DashboardNameHandler): Promise<void> {
    const confirmed = await this.confirmDelete.open(
      "Delete dashboard",
      `Are you sure you want to delete [${htmlEncoder.htmlEncode(dashboardNameHandler.dashboard.name)}]? Deleting a dashboard will also delete the widgets, and can not be undone.`
    );

    if (!confirmed) return;

    try {
      await Dash.deleteDashboard(dashboardNameHandler.dashboard.id);

      Snackbar.showSnackbar({
        text: `Dashboard <b>'${htmlEncoder.htmlEncode(dashboardNameHandler.dashboard.name)}'</b> was <b>deleted</b>`,
        color: "success",
      });

      this.refreshDashboardsList();
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to delete dashboard" });
    }
  }

  async updateDashboardName(dashboardNameHandler: DashboardNameHandler): Promise<void> {
    if (!dashboardNameHandler.newName.length) return;

    try {
      const dashboardToUpdate = Dash.dashboardById(dashboardNameHandler.dashboard.id);
      dashboardToUpdate.name = dashboardNameHandler.newName;
      await Dash.updateDashboard(dashboardToUpdate!);

      Snackbar.showSnackbar({
        text: "Dashboard was <b>renamed</b>",
        color: "success",
      });

      dashboardNameHandler.editing = false;
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to rename dashboard" });
      console.error("Failed to update dashboard name: ", error);
    }
  }

  cancelEditDashboardName(dashboardNameHandler: DashboardNameHandler): void {
    dashboardNameHandler.newName = dashboardNameHandler.dashboard.name;
    dashboardNameHandler.editing = false;
  }

  async onDashboardSharingChange(dashboard: DashboardI, user: UserProfile): Promise<void> {
    try {
      await Dash.updateDashboard(dashboard!);
      await Dash.refreshDashboards();
      this.refreshDashboardsList();
      var updatedDashboard = Dash.dashboardById(dashboard.id);

      var index = updatedDashboard.userIdsSharedWith?.findIndex(id => id === user.id);
      if (index !== undefined && index >= 0) {
        Snackbar.showSnackbar({
          text: `Dashboard was shared with <b>${htmlEncoder.htmlEncode(user.name)}</b>`,
          color: "success",
        });
      } else {
        Snackbar.showSnackbar({
          text: `Dashboard is no longer shared with <b>${htmlEncoder.htmlEncode(user.name)}</b>`,
          color: "success",
        });
      }
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to update dashboard sharing settings" });
      console.error("Failed to update dashboard sharing settings: ", error);
    }
  }
}
