import net from "App/net";

var VesselsAdministrationsClient = function () {

};

VesselsAdministrationsClient.prototype.get = function(vesselId) {
    return net.get("/VesselAdministrations/" + vesselId);
};

VesselsAdministrationsClient.prototype.getVessels = function () {
    return net.get("/VesselAdministrations");
};

VesselsAdministrationsClient.prototype.createVessel = function (vessel) {
    return net.post("/VesselAdministrations", vessel);
};

VesselsAdministrationsClient.prototype.getDataReceiverByImoNumber = function (imoNumber) {
    var filter = {
        isDataReceiver: true,
        imoNumber: imoNumber,
    };

    var deferred = $.Deferred();

    net.get("/VesselAdministrations/Find", filter).done(function (response) {
        deferred.resolve(response[0]);
    }).fail(function () {
        deferred.reject(arguments);
    });

    return deferred.promise();
};

VesselsAdministrationsClient.prototype.assign = function (vesselId, companyId) {
    var command = {
        vesselId: vesselId,
        companyId: companyId,
    };

    return net.post("/VesselAdministrations/" + vesselId + "/Assign", command);
};

VesselsAdministrationsClient.prototype.unassign = function(vesselId) {
    return net.post("/VesselAdministrations/" + vesselId + "/Unassign");
};

VesselsAdministrationsClient.prototype.update = function (vessel) {
    return net.put("/VesselAdministrations/" + vessel.id, vessel);
};

VesselsAdministrationsClient.prototype.setAsDataReceiver = function(vesselId) {
    return net.post("/VesselAdministrations/" + vesselId + "/SetDataReceiver");
};

VesselsAdministrationsClient.prototype.unsetAsDataReceiver = function(vesselId) {
    return net.post("/VesselAdministrations/" + vesselId + "/UnsetDataReceiver");
};

VesselsAdministrationsClient.prototype.archiveVessel = function(vesselId) {
    return net.post("/VesselAdministrations/" + vesselId + "/ArchiveVessel");
};

VesselsAdministrationsClient.prototype.activateVessel = function(vesselId) {
    return net.post("/VesselAdministrations/" + vesselId + "/ActivateVessel");
};

VesselsAdministrationsClient.prototype.deactivateVessel = function(vesselId) {
    return net.post("/VesselAdministrations/" + vesselId + "/DeactivateVessel");
};

VesselsAdministrationsClient.prototype.deleteVessel = function (vesselId) {
    return net.del("/VesselAdministrations/" + vesselId);
};

VesselsAdministrationsClient.prototype.activateFeature = function (vesselId, featureId) {
    var command = {
        vesselId: vesselId,
        featureId: featureId,
    };
    return net.post("/VesselAdministrations/" + vesselId + "/ActivateFeature", command);
};

VesselsAdministrationsClient.prototype.deactivateFeature = function (vesselId, featureId) {
    var command = {
        vesselId: vesselId,
        featureId: featureId,
    };
    return net.post("/VesselAdministrations/" + vesselId + "/DeactivateFeature", command);
};

VesselsAdministrationsClient.prototype.addCargoUnit = function(vesselId, cargoUnitId) {
    var command = {
        vesselId: vesselId,
        cargoUnitId: cargoUnitId,
    };
    return net.post("/VesselAdministrations/" + vesselId + "/AddCargoUnit", command);
};

VesselsAdministrationsClient.prototype.removeCargoUnit = function(vesselId, cargoUnitId) {
    var command = {
        vesselId: vesselId,
        cargoUnitId: cargoUnitId,
    };
    return net.post("/VesselAdministrations/" + vesselId + "/RemoveCargoUnit", command);
};

VesselsAdministrationsClient.prototype.getVeracityKey = function(vesselId) {
    return net.get("/VesselAdministrations/" + vesselId + "/GetVeracityKey");
};

VesselsAdministrationsClient.prototype.updateVeracityKey = function(vesselId, veracityKey) {
    var data = {
        vesselId: vesselId,
        veracityKey: veracityKey,
    };
    return net.put("/VesselAdministrations/UpdateVeracityKey", data);
};

VesselsAdministrationsClient.prototype.getVeracityKeyExpirationDate = function(vesselId) {
    return net.get("/VesselAdministrations/" + vesselId + "/GetVeracityKeyExpirationDate");
};

export default new VesselsAdministrationsClient();