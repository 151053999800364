import template from "./vessel-admin-table.html";
import ko from "knockout";
import events from "App/events";

function ViewModel(params) {
    var self = this;
    self.vessels = params.vessels;
    self.assignedVessels = params.assignedVessels;
    self.companies = params.companies;
    self.menuItem = ko.observable("unassignedVessels");

    self.menuItemClickEvent = events.menuItemClick.add(function (menuItem) {
        self.menuItem(menuItem);
    });

    self.editVessel = function (vessel) {
        events.menuItemClick.dispatch("editVessel", self.menuItem(), vessel);
    };
}

ViewModel.prototype.dispose = function () {
    this.menuItemClickEvent.detach();
};

export default { viewModel: ViewModel, template: template };