import template from "./assign-vessel.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import vesselsAdministrationsClient from "Clients/vessels-administrations-client";
import store from "@/store/index";

function ViewModel(params) {
    var self = this;

    self.vessel = params.vessel;
    self.companies = params.companies;
    self.company = params.company;
    self.assignedVessels = params.assignedVessels;
    self.displayAssignError = _.isUndefined(params.displayAssignError) ? true : params.displayAssignError;

    self.assignError = ko.observable(false);
    self.companySubscription = self.company.subscribe(function (newValue) {
        if (newValue) {
            var exists = self.companyContainVesselWithImoNumber(newValue, self.vessel);
            self.assignError(exists);
        } else {
            self.assignError(false);
        }
    });

    self.isVesselEmpty = ko.pureComputed(function () {
        if (self.vessel.id()) {
            return false;
        }
        return true;
    });

    if (self.company()) {
        var exists = self.companyContainVesselWithImoNumber(self.company(), self.vessel);
        self.assignError(exists);
    }
}

ViewModel.prototype.companyContainVesselWithImoNumber = function (selectedCompany, vessel) {
    var self = this;
    return _.some(self.assignedVessels(), function (assignedVessel) {
        var isImoNumberInCompany = self.isImoNumberInCompany(assignedVessel, selectedCompany, vessel);
        return isImoNumberInCompany && assignedVessel !== vessel;
    });
};

ViewModel.prototype.isImoNumberInCompany = function (assignedVessel, selectedCompany, vessel) {
    return assignedVessel.company() &&
        assignedVessel.company().id === selectedCompany.id &&
        assignedVessel.imoNumber() === Number(vessel.imoNumber());
};

ViewModel.prototype.assign = function (vessel) {
    var self = this;
    var promise;
    var company = this.company();
    var vesselId = this.vessel.id();

    if (company) {
        promise = vesselsAdministrationsClient.assign(vesselId, company.id);
    } else {
        promise = vesselsAdministrationsClient.unassign(vesselId);
    }
    promise.done(function (data) {
        self.vessel.activeStatus(data.activeStatus);
        events.vesselAdministrationUpdated.dispatch("assignVessel", data.name);
        store.dispatch("Vessels/refreshExtendedVessels");
    }).fail(function() {
    });
    return promise;
};

ViewModel.prototype.dispose = function () {
    this.companySubscription.dispose();
};

export default { viewModel: ViewModel, template: template };
