import template from "./vessels-menu.html";
import ko from "knockout";
import events from "App/events";

function ViewModel(params) {
    var self = this;
    self.selectedTab = ko.observable("unassignedVessels");

    self.menuItemClickEvent = events.menuItemClick.add(function (menuItem) {
        self.selectedTab(menuItem);
    });
}

ViewModel.prototype.isTabSelected = function (tab) {
    return tab === this.selectedTab();
};

ViewModel.prototype.updateMenuTab = function (viewModel, event) {
    var tab = event.target.id;
    if (!this.isTabSelected(tab)) {
        events.menuItemClick.dispatch(tab, this.selectedTab());
    }
};

ViewModel.prototype.dispose = function () {
    this.menuItemClickEvent.detach();
};

export default { viewModel: ViewModel, template: template };