import template from "./voyage-vessel-event-timestamp.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import dateHelper from "Utilities/date-helper";
import events from "App/events";
import emissionReportVesselEventsClient from "Clients/emission-report-vessel-events-client";

function ViewModel(params) {
    var self = this;
    self.voyageVesselEvent = params.voyageVesselEvent;
    self.vesselEvent = params.voyageVesselEvent.vesselEvent;
    self.isEditing = ko.observable(false);

    self.inputTimestampDate = ko.observable(dateHelper.getFormatedDateString(self.vesselEvent().timestamp));
    self.inputTimestampTime = ko.observable(dateHelper.getFormatedTimeString(self.vesselEvent().timestamp));

    self.plotLineDateSelectedBinding = events.plotLineDateSelected.add(function(selectedDate) {
        self.inputTimestampDate(dateHelper.getFormatedDateString(selectedDate));
        self.inputTimestampTime(dateHelper.getFormatedTimeString(selectedDate));
    });

    self.isInputTimestampValid = ko.pureComputed(function() {
        var inputTimestampDate = self.inputTimestampDate();
        var inputTimestampTime = self.inputTimestampTime();
        var timestampMoment = dateHelper.getDateTime(inputTimestampDate, inputTimestampTime);
        var vesselEvent = self.vesselEvent();

        var previousVesselEvent = self.voyageVesselEvent.previousVesselEvent();
        if (previousVesselEvent) {
            var previousVesselEventMoment = moment.utc(previousVesselEvent.timestamp);
            if (timestampMoment <= previousVesselEventMoment) return false;
        }

        var nextVesselEvent = self.voyageVesselEvent.nextVesselEvent();
        if (nextVesselEvent) {
            var nextVesselEventMoment = moment.utc(nextVesselEvent.timestamp);
            if (timestampMoment >= nextVesselEventMoment) return false;
        }

        return true;
    });
}

ViewModel.prototype.dispose = function() {
    this.plotLineDateSelectedBinding.detach();
};

ViewModel.prototype.startEditing = function(vm, e) {
    var $wrapper = $(e.currentTarget).closest(".edit-wrapper");
    this.isEditing(true);
    events.dateSelectStarted.dispatch();

    var $input = $wrapper.find("input.timeInput");
    $input.focus();
    $input.select();
};

ViewModel.prototype.cancelEditing = function(vm, e) {
    this.isEditing(false);
    events.dateSelectEnded.dispatch();
};

ViewModel.prototype.save = function() {
    var self = this;
    var vesselEvent = _.clone(self.vesselEvent());
    var date = self.inputTimestampDate();
    var time = self.inputTimestampTime();
    vesselEvent.timestamp = dateHelper.getSubmitDateString(date, time);

    emissionReportVesselEventsClient.update(vesselEvent).done(function() {
        self.cancelEditing();
    });
};

export default { viewModel: ViewModel, template: template };