import template from "./import-status-table.html";
import ko from "knockout";

function ViewModel(params) {
    var self = this;

    self.imports = params.imports;
    self.rowsPerPage = params.rowsPerPage;
    self.importDetailsEntity = params.importDetailsEntity;
    self.importDetailsVisible = params.importDetailsVisible;

    self.currentPage = ko.observable(1);
    self.ascSort = ko.observable(true);

    self.displayedImports = ko.pureComputed(function () {
        var imports = self.imports();
        var currentPage = self.currentPage();
        var rowsPerPage = self.rowsPerPage();
        var ascSort = self.ascSort();

        var endIndex = currentPage * rowsPerPage;
        var startIndex = endIndex - rowsPerPage;
                
        var list = imports.slice(startIndex, endIndex);
        return list;
    });

    self.numberOfPages = ko.pureComputed(function () {
        var imports = self.imports();

        return Math.ceil(imports.length / self.rowsPerPage());
    });

    self.pages = ko.pureComputed(function () {
        var numberOfPages = self.numberOfPages();
        var currentPage = self.currentPage();
        var list = [];

        for (var i = currentPage - 1; i < currentPage + 2; i++) {
            list.push(i);
        }

        return list;
    });

    self.changeCurrentPage = function (page) {
        self.currentPage(page);
    };

    self.rowsPerPageSubscription = self.rowsPerPage.subscribe(function() {
        self.goToFirst();
    });

    self.viewImportDetails = function (importEntity) {
        var importStatus = importEntity.status();
        if (importStatus === importEntity.statuses.imported) {
            self.importDetailsEntity(importEntity);
            self.importDetailsVisible(true);
        }
    };
}

ViewModel.prototype.sortByDuration = function() {
    var self = this;
    var imports = self.imports();
    var ascSort = self.ascSort();

    imports.sort(function (a, b) {
        var importDurationA = a.importDurationInSeconds();
        var importDurationB = b.importDurationInSeconds();

        if (ascSort) {
            return a.importDurationInSeconds() - b.importDurationInSeconds();
        } else {
            return b.importDurationInSeconds() - a.importDurationInSeconds();
        }
    });
    self.ascSort(!ascSort);
    self.imports(imports);
};

ViewModel.prototype.goToFirst = function () {
    this.currentPage(1);
};

ViewModel.prototype.goToLast = function () {
    var numberOfPages = this.numberOfPages();
    this.currentPage(numberOfPages);
};

ViewModel.prototype.nextPage = function () {
    var currentPage = this.currentPage();

    this.currentPage(currentPage + 1);
};

ViewModel.prototype.previousPage = function () {
    var currentPage = this.currentPage();
    this.currentPage(currentPage - 1);
};

ViewModel.prototype.dispose = function() {
    this.rowsPerPageSubscription.dispose();
};

export default { viewModel: ViewModel, template: template };