import template from "./performance-status-icon.html";
import ko from "knockout";
import performanceStatusHelper from "Utilities/performance-status-helper";
import events from "App/events";
import vesselPerformanceStatusesClient from "Clients/vessel-performance-statuses-client";

function ViewModel(params) {
    var self = this;
    self.performanceStatus = params.performanceStatus || ko.observable(params.initialPerformanceStatus);
    self.showText = ko.utils.unwrapObservable(params.showText);

    self.cssClass = ko.pureComputed(function () {
        return performanceStatusHelper.getPerformanceStatusCssClass(self.performanceStatus());
    });

    self.displayText = ko.pureComputed(function () {
        return performanceStatusHelper.getPerformanceStatusText(self.performanceStatus());
    });

    self.iconImageSrc = ko.pureComputed(function () {
        return performanceStatusHelper.getPerformanceStatusImageSrc(self.performanceStatus());
    });

    self.vesselId = params.vesselId;
    if (self.vesselId) {
        self.longTrendUpdatedBinding = events.longTrendUpdated.add(function (longTrend) {
            if (longTrend.vesselId !== self.vesselId) return;

            self.performanceStatus(null);
            vesselPerformanceStatusesClient.get(self.vesselId).done(function (vessel) {
                self.performanceStatus(vessel.performanceStatus);
            });
        });
    }
}

ViewModel.prototype.dispose = function () {
    if (this.longTrendUpdatedBinding) {
        this.longTrendUpdatedBinding.detach();
    }
};

export default { viewModel: ViewModel, template: template };