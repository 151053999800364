import template from "./voyage-chart.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import events from "App/events";
import highchartsHelper from "Utilities/highcharts-helper";
import dateHelper from "Utilities/date-helper";
import logDataClient from "Clients/log-data-client";

function ViewModel(params) {
    var self = this;
    self.gpsSpeedLogVariable = ko.utils.unwrapObservable(params.gpsSpeedLogVariable);
    self.chart = ko.observable();
    self.voyageVesselEvents = params.voyageVesselEvents;
    self.vessel = ko.utils.unwrapObservable(params.vessel);

    self.isLoadingGpsSpeedData = ko.observable(true);
    self.gpsSpeedData = ko.observable();

    var voyageVesselEvents = self.voyageVesselEvents();
    var extraQueryDateHours = voyageVesselEvents.length > 1 ? 12 : 168; //7 days if we only have 1 event (departure)
    self.queryDates = ko.observable({
        fromDate: moment.utc(voyageVesselEvents[0].vesselEvent().timestamp).add(-extraQueryDateHours, "hours"),
        toDate: moment.utc(voyageVesselEvents[voyageVesselEvents.length - 1].vesselEvent().timestamp).add(extraQueryDateHours, "hours"),
    });

    self.highchartsOptions = ko.computed(function() {
        var gpsSpeedData = self.gpsSpeedData();
        if (!gpsSpeedData) return null;

        var queryDates = self.queryDates();

        var points = _.map(gpsSpeedData.data,
            function(y, x) {
                return [parseInt(x), y];
            });
        var options = highchartsHelper.getDefaultGenericLineChartOptions();
        options.title.text = null;
        options.yAxis.title.text = gpsSpeedData.unit ? gpsSpeedData.unit.caption : "";
        options.xAxis.type = "datetime";
        options.chart.marginTop = 50;

        var exportTitle = "Emission report - " + self.vessel.name;
        options.exporting.filename = exportTitle;
        options.exporting.chartOptions.title = {
            text: exportTitle,
        };

        var firstPoint = _.first(points);
        var lastPoint = _.last(points);

        if (queryDates.fromDate < moment.utc(firstPoint[0])) {
            options.xAxis.min = queryDates.fromDate.valueOf();
        }
        if (queryDates.toDate > moment.utc(lastPoint[0])) {
            options.xAxis.max = queryDates.toDate.valueOf();
        }

        var isTimelineData = points.length === 2 && firstPoint[1] === 0 && lastPoint[1] === 0;

        options.series = [
            {
                name: isTimelineData ? "Timeline" : "Ship speed GPS",
                lineWidth: 1,
                marker: {
                    radius: 1,
                },
                data: points,
            },
        ];

        return options;
    });

    var getTimelineData = function(queryDates) {
        var timelineData = {
            data: {},
        };
        timelineData.data[queryDates.fromDate.valueOf()] = 0;
        timelineData.data[queryDates.toDate.valueOf()] = 0;
        return timelineData;
    };

    var loadGpsSpeedData = function() {
        var queryDates = self.queryDates();
        var fromDate = dateHelper.getSubmitDateString(dateHelper.getFormatedDateString(queryDates.fromDate), dateHelper.getFormatedTimeString(queryDates.fromDate));
        var toDate = dateHelper.getSubmitDateString(dateHelper.getFormatedDateString(queryDates.toDate), dateHelper.getFormatedTimeString(queryDates.toDate));

        if (self.gpsSpeedLogVariable) {
            self.isLoadingGpsSpeedData(true);
            logDataClient.findLogData(self.gpsSpeedLogVariable.id, fromDate, toDate, "QuarterHour", "UnixEpochMs")
                .done(function(gpsSpeedData) {
                    if (_.keys(gpsSpeedData.data).length > 0) {
                        self.gpsSpeedData(gpsSpeedData);
                    } else {
                        var timelineData = getTimelineData(queryDates);
                        self.gpsSpeedData(timelineData);
                    }
                })
                .always(function() {
                    self.isLoadingGpsSpeedData(false);
                });
        } else {
            var timelineData = getTimelineData(queryDates);
            self.gpsSpeedData(timelineData);
            self.isLoadingGpsSpeedData(false);
        }
    };

    var update = function() {
        var voyageVesselEvents = self.voyageVesselEvents();
        if (voyageVesselEvents.length === 0) return;

        var extraQueryDateHours = voyageVesselEvents.length > 1 ? 12 : 168; //7 days if we only have 1 event (departure)
        var newQueryDates = {
            fromDate: moment.utc(voyageVesselEvents[0].vesselEvent().timestamp).add(-extraQueryDateHours, "hours"),
            toDate: moment.utc(voyageVesselEvents[voyageVesselEvents.length - 1].vesselEvent().timestamp).add(extraQueryDateHours, "hours"),
        };
        var queryDates = self.queryDates();
        if (newQueryDates.fromDate < queryDates.fromDate || newQueryDates.toDate > queryDates.toDate) {
            self.queryDates(newQueryDates);
            loadGpsSpeedData();
        }
        self.drawPlotLines();
    };

    self.voyageVesselEventsBinding = self.voyageVesselEvents.subscribe(function(voyageVesselEvents) {
        update();
    });

    self.voyageVesselEventUpdatedBinding = events.voyageVesselEventUpdated.add(function() {
        update();
    });

    self.vesselEventDeletedBinding = events.vesselEventDeleted.add(function(deletedVesselEvent) {
        var chart = self.chart();
        if (!chart) return;
        var xAxis = chart.xAxis[0];
        var plotLine = highchartsHelper.getPlotLineForVoyageEvent(deletedVesselEvent.timestamp, deletedVesselEvent.name, deletedVesselEvent.id);
        xAxis.removePlotLine(plotLine.id);
    });

    self.enablePlotLineDateSelect = false;
    self.dateSelectStartedBinding = events.dateSelectStarted.add(function() {
        self.enablePlotLineDateSelect = true;
    });

    self.dateSelectEndedBinding = events.dateSelectEnded.add(function() {
        self.enablePlotLineDateSelect = false;
        var chart = self.chart();
        highchartsHelper.plotLineDateSelectCancel(chart);
    });

    self.chartBinding = self.chart.subscribe(function() {
        self.drawPlotLines();
    });

    loadGpsSpeedData();
}

ViewModel.prototype.dispose = function() {
    this.voyageVesselEventsBinding.dispose();
    this.voyageVesselEventUpdatedBinding.detach();
    this.vesselEventDeletedBinding.detach();
    this.dateSelectStartedBinding.detach();
    this.dateSelectEndedBinding.detach();
    this.chartBinding.dispose();
};

ViewModel.prototype.mousedown = function(vm, e) {
    if (!this.enablePlotLineDateSelect) return;
    var chart = this.chart();
    highchartsHelper.plotLineDateSelectMousedown(chart, e);
};

ViewModel.prototype.drawPlotLines = function() {
    var vesselEvents = _.map(this.voyageVesselEvents(), function(voyageVesselEvent) {
        return voyageVesselEvent.vesselEvent();
    });

    var chart = this.chart();
    if (!chart) return;
    var xAxis = chart.xAxis[0];

    _.each(vesselEvents, function(vesselEvent) {
        var plotLine = highchartsHelper.getPlotLineForVoyageEvent(vesselEvent.timestamp, vesselEvent.name, vesselEvent.id);
        xAxis.removePlotLine(plotLine.id);
        xAxis.addPlotLine(plotLine);
    });
};

export default { viewModel: ViewModel, template: template };