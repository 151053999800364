import template from "./vessel-info-map.html";
import ko from "knockout";
import _ from "underscore";
import vesselMapHelper from "Utilities/vessel-map-helper";
import moment from "moment";
import L from "leaflet";

L.RotatedMarker = L.Marker.extend({
    options: {
        angle: 0,
        rotationOrigin: "center",
    },
    _setPos: function (pos) {
        L.Marker.prototype._setPos.call(this, pos);
        this._icon.style[L.DomUtil.TRANSFORM+"Origin"] = this.options.rotationOrigin;

        if (L.DomUtil.TRANSFORM) {
            // use the CSS transform rule if available
            this._icon.style[L.DomUtil.TRANSFORM] += " rotate(" + this.options.angle + "deg)";
        } else if (L.Browser.ie) {
            // fallback for IE6, IE7, IE8
            var rad = this.options.angle * L.LatLng.DEG_TO_RAD,
                costheta = Math.cos(rad),
                sintheta = Math.sin(rad);
            this._icon.style.filter += " progid:DXImageTransform.Microsoft.Matrix(sizingMethod='auto expand', M11=" +
                costheta + ", M12=" + (-sintheta) + ", M21=" + sintheta + ", M22=" + costheta + ")";
        }
    },
});

L.rotatedMarker = function (pos, options) {
    return new L.RotatedMarker(pos, options);
};

function ViewModel(params) {
    var self = this;
    self.vesselHistory = params.vesselHistory;
    self.vesselHistories = params.vesselHistories;
    self.performanceStatus = params.performanceStatus;

    self.polylines = ko.observable();
    self.showVesselTrack = ko.observable(true);
    self.markers = ko.observableArray([]);

    self.vesselMap = ko.observable(vesselMapHelper.init());

    self.addVesselMarker();
    self.addVesselTrack();
    self.centerOnMarkers();

    self.showVesselTrackBinding = self.showVesselTrack.subscribe(function (value) {
        if (value) {
            self.addVesselTrack();
        } else {
            self.removeVesselTrack();
        }
    });

    self.vesselMap().on("zoomstart", function (event) {
        if (self.showVesselTrack()) {
            self.removeVesselTrack();
        }
    });

    self.vesselMap().on("zoomend", function (event) {
        if (self.showVesselTrack()) {            
            self.addVesselTrack();
        }
    });
}

ViewModel.prototype.addVesselTrack = function() {
    this.addPolyline();
    this.addPreviousPositionMarkers();
};

ViewModel.prototype.removeVesselTrack = function () {
    this.removeMarkers();
    this.removePolyline();
};

ViewModel.prototype.addPolyline = function () {
    var vesselHistories = this.vesselHistories();
    var polylines = vesselMapHelper.createPolylines(vesselHistories);
    this.polylines(polylines);
};

ViewModel.prototype.addPreviousPositionMarkers = function () {
    var self = this;
    var vesselHistories = self.vesselHistories();
    var currentVesselHistory = self.vesselHistory();
    var map = self.vesselMap();
    var minPixelDistance = 25;

    _.each(vesselHistories, function (vesselHistory) {
        if (currentVesselHistory.id() !== vesselHistory.id()) {
            var positionMarker = vesselMapHelper.createMarker(vesselHistory, "PreviousPosition");

            if (positionMarker) {
                var postitionMarkerLatLng = positionMarker.getLatLng();
                var positionMakerPoint = map.latLngToLayerPoint(postitionMarkerLatLng);

                var isInvalidMarker = _.some(self.markers(), function (marker) {
                    var markerLatLng = marker.getLatLng();
                    var markerPoint = map.latLngToLayerPoint(markerLatLng);

                    return markerPoint.distanceTo(positionMakerPoint) < minPixelDistance;
                });

                if (!isInvalidMarker) {
                    self.markers.push(positionMarker);
                }
            }
        }
    });
};

ViewModel.prototype.addVesselMarker = function () {
    var marker = vesselMapHelper.createMarker(this.vesselHistory(), this.performanceStatus);

    if (marker != null) {
        this.markers.push(marker);
    } else {
      document.getElementById("vesselInfoMapOverlay").classList.add("vessel-info-map-overlay");
    }
};

ViewModel.prototype.removeMarkers = function () {
    var map = this.vesselMap();
    var markers = this.markers();

    for (var i = 1; i < markers.length; i++) {//Dont remove vessel marker
        var marker = markers[i];
        marker.closePopup();
        map.removeLayer(marker);
    }

    this.markers([markers[0]]);
};

ViewModel.prototype.removePolyline = function () {
    var map = this.vesselMap();
    var polylines = this.polylines();

    _.each(polylines,
        function(polyline) {
            map.removeLayer(polyline);
        });
};

ViewModel.prototype.getVesselHistoriesInTimeIntervals = function () {
    var self = this;
    var timeBetweenVesselHistoriesInHours = 6;
    var vesselHistories = self.vesselHistories();
    var someVesselHistories = [];

    var latestVesselHistory = vesselHistories[0];
    var firstVesselHistory = vesselHistories[vesselHistories.length - 1];

    var startDate = moment(latestVesselHistory.exportDate());
    var endDate = moment(firstVesselHistory.exportDate());

    do {
        var vesselHistory = _.find(vesselHistories, function (vesselHistory) {
            var exportDate = moment(vesselHistory.exportDate());
            return exportDate < startDate.clone().subtract(timeBetweenVesselHistoriesInHours, "hours");
        });

        if (vesselHistory && !_.isUndefined(vesselHistory)) {
            someVesselHistories.push(vesselHistory);
        }

        startDate.subtract(timeBetweenVesselHistoriesInHours, "hours");

    } while (startDate > endDate);

    return someVesselHistories;
};

ViewModel.prototype.centerOnMarkers = function () {
    var markers = this.markers();
    var map = this.vesselMap();

    if (markers.length > 0) {
        map.fitBounds(vesselMapHelper.findMarkerBounds(markers), {
            padding: [100, 100],
            maxZoom: 6,
        });
    }
};

ViewModel.prototype.dispose = function () {
    this.showVesselTrackBinding.dispose();
};

export default { viewModel: ViewModel, template: template };