import template from "./logging-history-config.html";
import ko from "knockout";
import loggingHistoryConfigurationsClient from "Clients/logging-history-configurations-client";
import logVariablesClient from "Clients/log-variables-client";
import vesselsClient from "@/Scripts/clients/vessels-client";
import moment from "moment";
import dateHelper from "Utilities/date-helper";
import events from "App/events";

import Store from "@/store";

const filterMinMaxTooltipText = "If checked the plotted graph will filter data values that lay outside the min and max ranges, for each of the selected items.";
const filterOutliersTooltipText = "If checked the plotted graph will filter the data using Chauvenet's criterion.";

function ViewModel() {
    var self = this;
    self.currentVessel = Store.getters["Vessels/currentVessel"];

    self.showLastHours = true;
    self.showLastMinutes = true;
    self.showFromTime = true;
    self.showToTime = true;

    self.isConfigLoading = ko.observable(true);
    self.isVariablesLoading = ko.observable(true);
    self.isVoyagesLoading = ko.observable(true);
    self.isLoadError = ko.observable(false);
    self.isUpdateConfigurationError = ko.observable(false);
    self.isUpdateConfigurationLoading = ko.observable(false);
    self.isFilterMinMaxTooltipOpen = ko.observable(false);
    self.isFilterOutliersTooltipOpen = ko.observable(false);
    self.submitError = ko.observable(false);

    self.variables = ko.observableArray([]);

    self.selectedVariables = ko.observable();
    self.config = ko.observable();
    self.selectedTimePeriod = ko.observable();
    self.lastDays = ko.observable();
    self.lastHours = ko.observable();
    self.lastMinutes = ko.observable();
    self.fromDate = ko.observable();
    self.toDate = ko.observable();
    self.fromTime = ko.observable();
    self.toTime = ko.observable();
    self.voyages = ko.observable();
    self.selectedDataInterval = ko.observable();
    self.filterMinMax = ko.observable();
    self.filterOutliers = ko.observable();

    self.lastPeriodValuesValid = ko.observable();
    self.setPeriodValuesValid = ko.observable();

    self.isLoading = ko.pureComputed(function () {
        return self.isConfigLoading() || self.isVoyagesLoading() || self.isVariablesLoading();
    });

    self.lastVoyage = ko.pureComputed((function() {
        if (self.voyages().length < 1) return null;

        for (let i = self.voyages().length - 1; i > 0; i--) {
            if (self.voyages()[i].startDate !== "0001-01-01T00:00:00" && self.voyages()[i].endDate !== "0001-01-01T00:00:00") {
                return self.voyages()[i];
            }
        }

        return null;
    }));

    self.updateTimePeriod = function (fromDateTime, toDateTime) {
        self.fromDate(moment(fromDateTime).format("DD. MMM, YYYY"));
        self.fromTime(moment(fromDateTime).format("HH:mm"));
        self.toDate(moment(toDateTime).format("DD. MMM, YYYY"));
        self.toTime(moment(toDateTime).format("HH:mm"));
        self.selectedTimePeriod("SetPeriod");

        self.submit();
    };

    self.submit = function() {
        var selectedVariables = self.selectedVariables();
        var endChar = selectedVariables.charAt(selectedVariables.length - 1);
        var config = self.config();

        if (endChar === ";") {
            selectedVariables = selectedVariables.slice(0, -1);
        }

        config.logVariableIds = selectedVariables;
        config.fromDateTime = dateHelper.getSubmitDateString(self.fromDate(), self.fromTime());
        config.toDateTime = dateHelper.getSubmitDateString(self.toDate(), self.toTime());
        config.lastNumberDays = self.lastDays();
        config.lastNumberHours = self.lastHours();
        config.lastNumberMinutes = self.lastMinutes();
        config.timeConfigurationType = self.selectedTimePeriod();
        config.dataInterval = self.selectedDataInterval();
        config.lastVoyage = self.lastVoyage();
        config.filterMinMax = self.filterMinMax();
        config.filterOutliers = self.filterOutliers();

        self.isUpdateConfigurationLoading(true);

        self.onSubmit(config);

        loggingHistoryConfigurationsClient.update(config).done(function () {
            self.isUpdateConfigurationError(false);
        }).fail(function () {
            self.isUpdateConfigurationError(true);
        }).always(function () {
            self.isUpdateConfigurationLoading(false);
        });
    };

    self.disableSubmit = ko.pureComputed(function () {
        return self.selectedVariables() === "|" || !self.lastPeriodValuesValid() || !self.setPeriodValuesValid() || self.isUpdateConfigurationLoading()
            || self.submitError();
    });

    self.displayEmptyVariableSelectionWarning = ko.pureComputed(function() {
        return self.selectedVariables() === "|";
    });

    self.selectedTimeInMinutes = ko.pureComputed(function () {
        var minDate = moment.utc(self.currentVessel.logDataMinDate);
        var maxDate = moment.utc(self.currentVessel.logDataMaxDate);

        var maxMinutes = maxDate.diff(minDate, "minutes");

        var selectedTime = 0;

        if (self.selectedTimePeriod() === "AllValues") {
            selectedTime = maxMinutes;
        } else if (self.selectedTimePeriod() === "LastVoyage") {
            var from = dateHelper.getDateTime(dateHelper.getFormatedDateTimeString(self.lastVoyage().startDate));
            var to = dateHelper.getDateTime(dateHelper.getFormatedDateTimeString(self.lastVoyage().endDate));

            selectedTime = to.diff(from, "minutes");
        } else if (self.selectedTimePeriod() === "LastPeriod") {
            selectedTime = toMinutes(self.lastDays(), self.lastHours(), self.lastMinutes());
        } else if (self.selectedTimePeriod() === "SetPeriod") {
            var from = dateHelper.getDateTime(self.fromDate(), self.fromTime());
            var to = dateHelper.getDateTime(self.toDate(), self.toTime());

            selectedTime = to.diff(from, "minutes");
        }

        if (selectedTime <= 0) {
            self.submitError(true);
        } else {
            self.submitError(false);
        }

        return selectedTime;
    });

    function toMinutes(days, hours, minutes) {
        days = Number(days);
        hours = Number(hours);
        minutes = Number(minutes);

        return (days * 24 * 60) + (hours * 60) + minutes;
    }

    loggingHistoryConfigurationsClient.getByVesselId(self.currentVessel.id).done(function (data) {
        self.config(data);

        self.selectedVariables(data.logVariableIds);
        self.selectedDataInterval(data.dataInterval);

        self.selectedTimePeriod(data.timeConfigurationType);
        self.lastDays(data.lastNumberDays);
        self.lastHours(data.lastNumberHours);
        self.lastMinutes(data.lastNumberMinutes);
        self.filterMinMax(data.filterMinMax);
        self.filterOutliers(data.filterOutliers);

        self.fromDate(dateHelper.getFormatedDateString(data.fromDateTime));
        self.toDate(dateHelper.getFormatedDateString(data.toDateTime));
        self.fromTime(dateHelper.getFormatedTimeString(data.fromDateTime));
        self.toTime(dateHelper.getFormatedTimeString(data.toDateTime));
    }).fail(function () {
        self.isLoadError(true);
    }).always(function () {
        self.isConfigLoading(false);
    });

    logVariablesClient.findAvailableLogVariablesExtended(self.currentVessel.id).done(function (data) {
        self.variables(data);
    }).fail(function () {
        self.isLoadError(true);
    }).always(function () {
        self.isVariablesLoading(false);
    });

    vesselsClient.getVesselVoyages(self.currentVessel.id).done(function (data) {
        self.voyages(data);
    }).fail(function () {
        self.isLoadError(true);
    }).always(function () {
        self.isVoyagesLoading(false);
    });
}

ViewModel.prototype.toggleFilterMinMaxTooltip = function(vm, e) {
    var isFilterMinMaxTooltipOpen = this.isFilterMinMaxTooltipOpen();
    if (!isFilterMinMaxTooltipOpen) {
        var element = e.currentTarget;
        events.showTooltip.dispatch("tooltip-text", { text: filterMinMaxTooltipText, isWide: true, tooltipPosition: "right" }, element);
        this.isFilterMinMaxTooltipOpen(true);
    } else {
        this.isFilterMinMaxTooltipOpen(false);
        events.closeTooltip.dispatch();
    }
};

ViewModel.prototype.toggleFilterOutliersTooltip = function(vm, e) {
    var isFilterOutliersTooltipOpen = this.isFilterOutliersTooltipOpen();
    if (!isFilterOutliersTooltipOpen) {
        var element = e.currentTarget;
        events.showTooltip.dispatch("tooltip-text", { text: filterOutliersTooltipText, isWide: true, tooltipPosition: "right" }, element);
        this.isFilterOutliersTooltipOpen(true);
    } else {
        this.isFilterOutliersTooltipOpen(false);
        events.closeTooltip.dispatch();
    }
};

ViewModel.prototype.hideTooltips = function() {
    if (this.isFilterMinMaxTooltipOpen() || this.isFilterOutliersTooltipOpen()) {
        this.isFilterMinMaxTooltipOpen(false);
        this.isFilterOutliersTooltipOpen(false);
        events.closeTooltip.dispatch();
    }
};

ViewModel.prototype.dispose = function () {
};

export default { viewModel: ViewModel, template: template };
