//format date into '14 Jan 2021 15:09', used in 'last activity' column in users table under 'my profile'
function DateFilterDMYT(value: string | number): string {
  const date = new Date(value);
  return date.toLocaleDateString(["en-UK"], { month: "short", day: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" });
}

//format date into '14 Jan 2021'
function DateFilterDMY(value: string | number): string {
  const date = new Date(value);
  return date.toLocaleDateString(["en-UK"], { month: "short", day: "2-digit", year: "numeric" });
}

export { DateFilterDMYT, DateFilterDMY };
