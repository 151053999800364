import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import configEmissionReportFactory from "App/config-emission-report";

function getFlowMeterFuelConsumptionSourcePercent(fuelConsumptionSource, flowMeterFuelConsumptions, vesselEventDurationInSeconds) {
    var configEmissionReport = configEmissionReportFactory.get();
    var flowMeterFuelConsumptionDurationInSeconds = _.reduce(flowMeterFuelConsumptions,
        function(memo, flowMeterFuelConsumption) {
            var startAt = moment.utc(flowMeterFuelConsumption.startAt);
            var endAt = moment.utc(flowMeterFuelConsumption.endAt);

            var countCorrectedValues = fuelConsumptionSource === "Manual";
            var hasCorrectedValue = _.isNumber(flowMeterFuelConsumption.correctedFuelConsumptionInTon);

            if ((flowMeterFuelConsumption.fuelConsumptionSource === fuelConsumptionSource && !hasCorrectedValue) || (hasCorrectedValue && countCorrectedValues)) {
                return memo + endAt.diff(startAt, "seconds");
            }
            return memo;
        },
        0);

    var activeFlowMeters = configEmissionReport.activeFlowMeters();
    return flowMeterFuelConsumptionDurationInSeconds / vesselEventDurationInSeconds / _.values(activeFlowMeters).length;
}

var ViewModel = function(voyageVesselEvent) {
    var self = this;
    self.voyageVesselEvent = voyageVesselEvent;
    self.previousVesselEvent = self.voyageVesselEvent.previousVesselEvent;
    self.vesselEvent = self.voyageVesselEvent.vesselEvent;
    self.flowMeterFuelConsumptions = self.voyageVesselEvent.flowMeterFuelConsumptions;
    self.isVoyageInformationConfirmed = self.voyageVesselEvent.isVoyageInformationConfirmed;
    self.isVoyageDataConfirmed = self.voyageVesselEvent.isVoyageDataConfirmed;

    self.distance = ko.pureComputed(function() {
        var vesselEvent = self.vesselEvent();
        return vesselEvent.distanceSailed;
    });

    self.hasCorrectedDistance = ko.pureComputed(function() {
        var distance = self.distance();
        return distance && _.isNumber(distance.manualDistanceByGPS);
    });

    self.totalDistanceByGPS = ko.pureComputed(function() {
        var distance = self.distance();
        if (!distance) return "";

        var totalDistanceByGPS = null;
        if (_.isNumber(distance.loggedDistanceByGPS)) {
            totalDistanceByGPS = distance.loggedDistanceByGPS;
        }
        if (_.isNumber(distance.estimatedDistanceByGPS)) {
            totalDistanceByGPS += distance.estimatedDistanceByGPS;
        }

        return totalDistanceByGPS;
    });

    self.currentDistance = ko.pureComputed(function() {
        return self.hasCorrectedDistance() ? self.distance().manualDistanceByGPS : self.totalDistanceByGPS();
    });

    self.vesselEventDurationInSeconds = ko.pureComputed(function() {
        var previousVesselEvent = self.previousVesselEvent();
        if (!previousVesselEvent) return 0; //When deleting this vessel event, the previous vessel event will become null
        var vesselEvent = self.vesselEvent();

        var startAt = moment.utc(previousVesselEvent.timestamp);
        var endAt = moment.utc(vesselEvent.timestamp);
        return endAt.diff(startAt, "seconds");
    });

    self.flowMeterFuelConsumptionManualEstimatePercent = ko.pureComputed(function() {
        var flowMeterFuelConsumptions = self.flowMeterFuelConsumptions();
        var vesselEventDurationInSeconds = self.vesselEventDurationInSeconds();

        return getFlowMeterFuelConsumptionSourcePercent("ManualEstimate", flowMeterFuelConsumptions, vesselEventDurationInSeconds);
    });

    self.flowMeterFuelConsumptionRegressionPercent = ko.pureComputed(function() {
        var flowMeterFuelConsumptions = self.flowMeterFuelConsumptions();
        var vesselEventDurationInSeconds = self.vesselEventDurationInSeconds();

        return getFlowMeterFuelConsumptionSourcePercent("Regression", flowMeterFuelConsumptions, vesselEventDurationInSeconds);
    });

    self.flowMeterFuelConsumptionManualPercent = ko.pureComputed(function() {
        var flowMeterFuelConsumptions = self.flowMeterFuelConsumptions();
        var vesselEventDurationInSeconds = self.vesselEventDurationInSeconds();

        return getFlowMeterFuelConsumptionSourcePercent("Manual", flowMeterFuelConsumptions, vesselEventDurationInSeconds);
    });

    self.flowMeterFuelConsumptionLogDataPercent = ko.pureComputed(function() {
        var flowMeterFuelConsumptions = self.flowMeterFuelConsumptions();
        var vesselEventDurationInSeconds = self.vesselEventDurationInSeconds();

        return getFlowMeterFuelConsumptionSourcePercent("LogData", flowMeterFuelConsumptions, vesselEventDurationInSeconds);
    });

    self.missingFlowMeterFuelConsumptionPercent = ko.pureComputed(function() {
        var flowMeterFuelConsumptionLogDataPercent = self.flowMeterFuelConsumptionLogDataPercent();
        var flowMeterFuelConsumptionRegressionPercent = self.flowMeterFuelConsumptionRegressionPercent();
        var flowMeterFuelConsumptionManualPercent = self.flowMeterFuelConsumptionManualPercent();
        var flowMeterFuelConsumptionManualEstimatePercent = self.flowMeterFuelConsumptionManualEstimatePercent();

        var missingFlowMeterFuelConsumptionPercent = 1 -
            flowMeterFuelConsumptionLogDataPercent -
            flowMeterFuelConsumptionRegressionPercent -
            flowMeterFuelConsumptionManualPercent -
            flowMeterFuelConsumptionManualEstimatePercent;

        var roundedMissingFuelConsumptionPercent = Math.round(missingFlowMeterFuelConsumptionPercent * 100000) / 100000; // Round, to fix floating point inaccuracies
        return roundedMissingFuelConsumptionPercent;
    });

    self.missingDistancePercent = ko.pureComputed(function() {
        var hasCorrectedTotalDistanceSailed = self.hasCorrectedDistance();
        if (hasCorrectedTotalDistanceSailed) return 0;

        var distance = self.distance();
        if (!distance) return 1;

        var missingDistancePercent = 1 - distance.loggedDistanceByGPSPercent - distance.estimatedDistanceByGPSPercent;
        var roundedMissingDistancePercent = Math.round(missingDistancePercent * 100000) / 100000; // Round, to fix floating point inaccuracies
        return roundedMissingDistancePercent;
    });

    self.flowMeterFuelConsumptionPieSlices = ko.pureComputed(function() {
        var flowMeterFuelConsumptionLogDataPercent = self.flowMeterFuelConsumptionLogDataPercent() * 100;
        var flowMeterFuelConsumptionRegressionPercent = self.flowMeterFuelConsumptionRegressionPercent() * 100;
        var flowMeterFuelConsumptionManualPercent = self.flowMeterFuelConsumptionManualPercent() * 100;
        var flowMeterFuelConsumptionManualEstimatePercent = self.flowMeterFuelConsumptionManualEstimatePercent() * 100;
        var missingFlowMeterFuelConsumptionPercent = self.missingFlowMeterFuelConsumptionPercent() * 100;

        var pieSlices = [];
        if (flowMeterFuelConsumptionLogDataPercent > 0) {
            pieSlices.push({ name: "From<br/>log data", y: flowMeterFuelConsumptionLogDataPercent, color: "#28A5A8" });
        }
        if (flowMeterFuelConsumptionRegressionPercent > 0) {
            pieSlices.push({ name: "Estimated<br/><i>Kyma method</i>", y: flowMeterFuelConsumptionRegressionPercent, color: "#75D1D5" });
        }
        if (flowMeterFuelConsumptionManualPercent > 0) {
            pieSlices.push({ name: "From<br/>manual input:", y: flowMeterFuelConsumptionManualPercent, color: "#f7a35c" });
        }
        if (flowMeterFuelConsumptionManualEstimatePercent > 0) {
            pieSlices.push({ name: "Estimated<br/><i>manual input</i>", y: flowMeterFuelConsumptionManualEstimatePercent, color: "#FFD3AD" });
        }
        if (missingFlowMeterFuelConsumptionPercent > 0) {
            pieSlices.push({ name: "Missing data", y: missingFlowMeterFuelConsumptionPercent, color: "#f45b5b" });
        }
        return pieSlices;
    });

    self.distancePieSlices = ko.pureComputed(function() {
        var distance = self.distance();
        var loggedDistanceByGPSPercent = distance.loggedDistanceByGPSPercent * 100;
        var estimatedDistanceByGPSPercent = distance.estimatedDistanceByGPSPercent * 100;
        var hasCorrectedDistance = self.hasCorrectedDistance();
        var missingDistancePercent = self.missingDistancePercent() * 100;

        var pieSlices = [];
        if (!hasCorrectedDistance && loggedDistanceByGPSPercent > 0) {
            pieSlices.push({ name: "From<br/>log data", y: loggedDistanceByGPSPercent, color: "#28A5A8" });
        }
        if (!hasCorrectedDistance && estimatedDistanceByGPSPercent > 0) {
            pieSlices.push({ name: "Estimated<br/><i>Kyma method</i>", y: estimatedDistanceByGPSPercent, color: "#75D1D5" });
        }
        if (hasCorrectedDistance) {
            pieSlices.push({ name: "From<br/>manual input:", y: 100, color: "#f7a35c" });
        }
        if (missingDistancePercent > 0) {
            pieSlices.push({ name: "Missing data", y: missingDistancePercent, color: "#f45b5b" });
        }
        return pieSlices;
    });

    self.hasMissingFlowMeterFuelConsumption = ko.pureComputed(function() {
        if (self.voyageVesselEvent.isStartOfVoyage) return false;
        if (!self.isVoyageInformationConfirmed()) return false;

        var missingFlowMeterFuelConsumptionPercent = self.missingFlowMeterFuelConsumptionPercent();
        return missingFlowMeterFuelConsumptionPercent > 0;
    });

    self.hasMissingDistance = ko.pureComputed(function() {
        if (self.voyageVesselEvent.isStartOfVoyage) return false;
        if (!self.isVoyageInformationConfirmed()) return false;

        var missingDistancePercent = self.missingDistancePercent();
        return missingDistancePercent > 0;
    });
};

ViewModel.prototype.dispose = function(newCurve) {
};

export default ViewModel;