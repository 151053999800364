// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("@/assets/images/vessels/green.png");
var ___HTML_LOADER_IMPORT_1___ = require("@/assets/images/vessels/orange.png");
var ___HTML_LOADER_IMPORT_2___ = require("@/assets/images/vessels/red.png");
var ___HTML_LOADER_IMPORT_3___ = require("@/assets/images/vessels/blue.png");
var ___HTML_LOADER_IMPORT_4___ = require("@/assets/images/vessels/gray.png");
var ___HTML_LOADER_IMPORT_5___ = require("@/assets/images/gps-up.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACEMENT_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACEMENT_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACEMENT_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var code = "<div class=\"vessel-info-map-wrapper\">\r\n    <div id=\"vesselInfoMapOverlay\">\r\n    </div>\r\n    <div class=\"floatR\">\r\n        <input id=\"showVesselTrack\" type=\"checkbox\" data-bind=\"checked: showVesselTrack\">\r\n        <label for=\"showVesselTrack\">Show vessel track</label>\r\n    </div>\r\n    <div id=\"mapOuterWrap\">\r\n        <div id=\"map\" data-bind=\"vesselMap: {mapObj: vesselMap, markers: markers, polylines: polylines }\" style=\"clear: both;\"></div>\r\n        <dl id=\"maplegend\">\r\n            <dt>\r\n                <img alt=\"green\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\">\r\n            </dt>\r\n            <dd>Ok</dd>\r\n\r\n            <dt>\r\n                <img alt=\"Orange\" src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\">\r\n            </dt>\r\n            <dd>Observe</dd>\r\n\r\n            <dt>\r\n                <img alt=\"Red\" src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\">\r\n            </dt>\r\n            <dd>Not ok</dd>\r\n\r\n            <dt>\r\n                <img alt=\"Blue\" src=\"" + ___HTML_LOADER_REPLACEMENT_3___ + "\">\r\n            </dt>\r\n            <dd>Benchmarking</dd>\r\n\r\n            <dt>\r\n                <img alt=\"Gray\" src=\"" + ___HTML_LOADER_REPLACEMENT_4___ + "\">\r\n            </dt>\r\n            <dd>Performance status N/A</dd>\r\n\r\n            <dt>\r\n                <img alt=\"Black\" src=\"" + ___HTML_LOADER_REPLACEMENT_5___ + "\">\r\n            </dt>\r\n            <dd>Previous positions</dd>\r\n        </dl>\r\n    </div>\r\n\r\n</div>\r\n";
// Exports
module.exports = code;