import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import VesselHistoriesClient from "Clients/vessel-histories-client";
import { VesselHistories, VesselHistory, VesselHistoryAdditional } from "@/types/vesselHistory";
import moment from "moment";
import { Vessel } from "@/types/Vessel";

@Module({ namespaced: true, name: "VesselsHistories" })
class VesselsHistories extends VuexModule {
  //all stored vessel histories for all vessels
  private _vesselsHistories: VesselHistories[] = [];

  @Mutation
  public ADD_VESSEL_HISTORIES(vesselHistories: VesselHistories): void {
    this._vesselsHistories.push(vesselHistories);
  }

  //loads vessel histories from store if stored, else from database
  @Action({ rawError: true })
  public async getVesselHistories(input: { vessel: Vessel; timespan: number }): Promise<VesselHistories> {
    const vesselId = input.vessel.id;
    const timespan = input.timespan;

    let data = this._vesselsHistories.find(
      vesselHistories => vesselHistories.vesselId === vesselId,
    );

    if (!data) {
      const toDate = moment(input.vessel.lastExportDate);
      const fromDate = toDate.clone().subtract(timespan, "days");

      try {
        data = {
          vesselHistories: await VesselHistoriesClient.getVesselHistories(
            vesselId,
            fromDate.format("YYYY-MM-DDTHH:mm:ss[Z]"),
            toDate.format("YYYY-MM-DDTHH:mm:ss[Z]"),
          ),
          vesselId,
          timespan,
        };
      } catch (err) {
        data = {
          vesselHistories: [],
          vesselId,
          timespan,
        };

        console.warn((err as any).statusText);
      }

      this.context.commit("ADD_VESSEL_HISTORIES", data);
    }

    return data;
  }

  @Action({ rawError: true })
  public async getLatestVesselHistory(vesselId: number): Promise<VesselHistory> {
    try {
      return await VesselHistoriesClient.getLatestVesselHistory(vesselId);
    } catch (error) {
      return Promise.reject(`Failed to load latest vessel history: ${error}`);
    }
  }

  @Action({ rawError: true })
  public async getVesselHistoryAdditionals(vesselHistoryId: number): Promise<VesselHistoryAdditional[]> {
    try {
      const vesselHistoryAdditionals = await VesselHistoriesClient.getVesselHistoryAdditionals(vesselHistoryId);
      return vesselHistoryAdditionals;
    } catch (error) {
      return Promise.reject(`Failed to load vessel history additional information: ${error}`);
    }
  }

  public get vesselsHistories(): VesselHistories[] {
    return this._vesselsHistories;
  }
}

export default VesselsHistories;
