import ko from "knockout";
import events from "App/events";

ko.bindingHandlers.tooltipValidation = {
    update: function(element, valueAccessor) {
        var validationObservable = valueAccessor();
        var error = validationObservable.error();
        if (error && validationObservable.isModified()) {
            events.showTooltip.dispatch("tooltip-text", { text: error, customCssClass: "tooltip-validation-error" }, element);
        } else {
            events.closeTooltip.dispatch();
        }
    },
};