import net from "App/net";

var TaxonomyClient = function() {

};

TaxonomyClient.prototype.getTaxonomyLogVariables = function() {
    return net.get("/Taxonomy");
};

TaxonomyClient.prototype.getAllTaxa = function() {
    return net.get("/Taxonomy/Taxa");
};

TaxonomyClient.prototype.downloadTaxonomy = function() {
    var url = "/Taxonomy/Excel";
    return net.fileDownload(url, "GET");
};

TaxonomyClient.prototype.uploadTaxonomy = function(file) {
    var url = "/Taxonomy/Excel";
    return net.fileUpload(url, file);
};

export default new TaxonomyClient();
