import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import notificationWidgetClient from "Clients/notification-widget-client";
import { NotificationWidgetConfig } from "@/types/widgetNotification";


@Module({ namespaced: true, name: "NotificationWidgetConfiguration" })
class NotificationWidget extends VuexModule {
  private _configsExpired = true;
  private _configs: NotificationWidgetConfig[] = [];

  @Action({ rawError: true })
  public async addNewConfig(): Promise<NotificationWidgetConfig> {
    try {
      const config = await notificationWidgetClient.new({ vessels: [] });
      this.context.commit("ADD_WIDGET_CONFIG", config);
      return config;
    } catch (error) {
      throw ({ message: "Failed to add widget config", error });
    }
  }

  @Action({ rawError: true })
  public async updateConfig(config: NotificationWidgetConfig | undefined): Promise<void> {
    if (!config) return;
    try {
      const updatedConfig = await notificationWidgetClient.update(config);
      this.context.commit("SET_WIDGET_CONFIG", updatedConfig);
    } catch (error) {
      throw ({ message: "Failed to update widget config", error });
    }
  }

  @Action({ rawError: true })
  public async deleteWidgetConfig(id: number): Promise<void> {
    try {
      await notificationWidgetClient.delete(id);
      this.context.commit("DELETE_WIDGET_CONFIG", id);
    } catch (error) {
      throw ({ message: "Failed to delete widget config", error });
    }
  }

  @Action({ rawError: true })
  public async getConfigById(id: number): Promise<NotificationWidgetConfig> {
    if (this._configsExpired) {
      await this.context.dispatch("refreshConfigs");
    }

    const config = this._configs.find(config => config.id === id);

    if (config) {
      for (let i = 0; i < config.vessels.length; i++) {
        const vessel = await this.context.dispatch("Vessels/get", config.vessels[i].id, { root: true });
        if (vessel) config.vessels[i] = vessel;
      }

      return config;
    }
    else throw ({ message: "Could not find config" });
  }

  @Action({ rawError: true })
  public async refreshConfigs(): Promise<void> {
    try {
      const configs: NotificationWidgetConfig[] = await notificationWidgetClient.getAll();
      this.context.commit("SET_WIDGET_CONFIGS", configs);
    } catch (error) {
      throw ({ message: "Failed to refresh widget config", error });
    }
  }

  @Mutation
  public ADD_WIDGET_CONFIG(config: NotificationWidgetConfig): void {
    this._configs.push(config);
    this._configsExpired = true;
  }

  @Mutation
  public SET_WIDGET_CONFIG(config: NotificationWidgetConfig): void {
    const index = this._configs.findIndex(savedConfig => savedConfig.id === config.id);

    if (index >= 0) {
      this._configs[index] = config;
      this._configsExpired = true;
    }
  }

  @Mutation
  public DELETE_WIDGET_CONFIG(id: number): void {
    const index = this._configs.findIndex(config => config.id === id);

    if (index >= 0) {
      this._configs.splice(index, 1);
      this._configsExpired = true;
    }
  }

  @Mutation
  public SET_WIDGET_CONFIGS(configs: NotificationWidgetConfig[]): void {
    this._configs = configs;
    this._configsExpired = false;
  }
}

export default NotificationWidget;
