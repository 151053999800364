import ko from "knockout";

var GraphVariable = function (name, id, isSelected, selectedVariables, variableSelectionUpdateTrigger, onboardSystem, displayName) {
    var self = this;
    self.name = name;
    self.id = id;
    self.isSelected = ko.observable(isSelected);
    self.queue = selectedVariables.includes(id.toString()) ? selectedVariables.indexOf(id.toString()) : 0;
    self.onboardSystem = onboardSystem;
    self.displayName = displayName;

    self.select = function (variable) {
        self.queue = selectedVariables.length;
        self.isSelected(true);
        selectedVariables.push(variable.id.toString());
        if (variableSelectionUpdateTrigger) { variableSelectionUpdateTrigger.notifySubscribers(); }
    };

    self.unselect = function (isSelected) {
        self.isSelected(false);
        if (variableSelectionUpdateTrigger) { variableSelectionUpdateTrigger.notifySubscribers(); }
    };
};

export default GraphVariable;
