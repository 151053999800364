import template from "./departure-list.html";

function ViewModel(params) {
    var self = this;
    self.departureVesselEvents = params.departureVesselEvents;
    self.allSortedVesselEvents = params.allSortedVesselEvents;
}

ViewModel.prototype.dispose = function() {
};

export default { viewModel: ViewModel, template: template };