import template from "./vessel-notes.html";
import ko from "knockout";
import _ from "underscore";
import vesselsAdminClient from "Clients/vessels-administrations-client";

function ViewModel(params) {
    var self = this;

    self.vesselId = params.vesselId;
    self.vessel = undefined;
    self.notes = ko.observable();
    self.error = ko.observable(null);
    self.isLoading = ko.observable(false);
    self.isSaving = ko.observable(false);
    self.loadVesselNotes();
}

ViewModel.prototype.loadVesselNotes = function() {
    var self = this;
    self.clearError();

    self.isLoading(true);
    vesselsAdminClient.get(self.vesselId).done(function(data) {
        self.initialize(data);
    }).fail(function (data) {
        self.setError("Failed to load data..", data);
        self.initialize(undefined);
    }).always(function() {
        self.isLoading(false);
    });
};

ViewModel.prototype.saveVesselNotes = function () {
    var self = this;
    self.clearError();
    if (self.notes() !== undefined) {
        var vessel = _.clone(self.vessel);
        vessel.notes = self.notes();
        self.isSaving(true);
        vesselsAdminClient.update(vessel).done(function (data) {
            self.initialize(data);
        }).fail(function (data) {
            self.setError("Failed to save data..", data);
        }).always(function() {
            self.isSaving(false);
        });
    }
};

ViewModel.prototype.initialize = function (vessel) {
    var self = this;
    self.vessel = vessel;
    if (self.vessel) {
        self.notes(self.vessel.notes);
    } else {
        self.notes(undefined);
    }
};

ViewModel.prototype.clearError = function() {
    var self = this;
    self.error(null);
};

ViewModel.prototype.setError = function (errorText, error) {
    var self = this;
    self.error(errorText);
    console.error(errorText);
    console.error(error);
};

ViewModel.prototype.dispose = function () {
};

export default { viewModel: ViewModel, template: template };
