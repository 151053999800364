import ko from "knockout";
import moment from "moment";

ko.bindingHandlers.datetimeFormat = {
    update: function (element, valueAccessor) {
        var options = ko.utils.unwrapObservable(valueAccessor());
        var datetime = ko.utils.unwrapObservable(options.datetime);
        var format = ko.utils.unwrapObservable(options.format) || ko.bindingHandlers.datetimeFormat.defaultFormat;
        var formattedDateTime = datetime ? moment.utc(datetime).format(format) : "";

        ko.bindingHandlers.text.update(element, function () { return formattedDateTime; });
    },
    defaultFormat: "DD. MMM, YYYY HH:mm",
};