import template from "./flow-meters.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import logVariablesClient from "Clients/log-variables-client";
import flowMetersClient from "Clients/flow-meters-client";
import fuelConsumptionSourcesClient from "Clients/fuel-consumption-sources-client";
import fuelTypesClient from "Clients/fuel-types-client";

import Store from "@/store";

function ViewModel(params) {
    var self = this;
    self.flowMeters = ko.observableArray([]);
    self.fuelConsumptionSources = ko.observable();
    self.fuelTypes = ko.observable();
    self.massFlowRateLogVariables = ko.observable();
    self.isSaving = ko.observable(false);
    self.showNewFlowMeter = ko.observable(false);
    self.hasError = ko.observable(false);

    self.isLoadingFlowMeters = ko.observable(true);
    self.isLoadingFuelConsumptionSources = ko.observable(true);
    self.isLoadingFuelTypes = ko.observable(true);
    self.isLoadingMassFlowRateLogVariables = ko.observable(true);

    self.isLoading = ko.pureComputed(function() {
        var isLoadingFlowMeters = self.isLoadingFlowMeters();
        var isLoadingFuelConsumptionSources = self.isLoadingFuelConsumptionSources();
        var isLoadingFuelTypes = self.isLoadingFuelTypes();
        var isLoadingMassFlowRateLogVariables = self.isLoadingMassFlowRateLogVariables();
        return isLoadingFlowMeters ||
            isLoadingFuelConsumptionSources ||
            isLoadingFuelTypes ||
            isLoadingMassFlowRateLogVariables;
    });

    flowMetersClient.find(Store.getters["Vessels/currentVessel"].id)
        .done(self.flowMeters)
        .always(function() {
            self.isLoadingFlowMeters(false);
        });

    fuelConsumptionSourcesClient.getAll()
        .done(self.fuelConsumptionSources)
        .always(function() {
            self.isLoadingFuelConsumptionSources(false);
        });

    fuelTypesClient.getAll()
        .done(self.fuelTypes)
        .always(function() {
            self.isLoadingFuelTypes(false);
        });

    self.activeFuelTypes = ko.pureComputed(function() {
        var fuelTypes = self.fuelTypes();
        return _.filter(fuelTypes,
            function(fuelType) {
                return !fuelType.isDeactivated;
            });
    });

    logVariablesClient.findMassFlowRateVariables(Store.getters["Vessels/currentVessel"].id)
        .done(self.massFlowRateLogVariables)
        .always(function() {
            self.isLoadingMassFlowRateLogVariables(false);
        });

    self.inputFuelConsumptionSources = ko.observableArray();
    self.inputName = ko.observable();
    self.inputFuelMassFlowRateLogVariableId = ko.observable();
    self.inputFuelTypeId = ko.observable();

    self.newFlowMeter = ko.pureComputed(function() {
        var inputFuelConsumptionSources = self.inputFuelConsumptionSources();
        var inputFuelTypeId = self.inputFuelTypeId() || null;
        var inputName = self.inputName();
        var inputFuelMassFlowRateLogVariableId = self.inputFuelMassFlowRateLogVariableId();

        var newFlowMeter = {
            name: inputName,
            vesselId: Store.getters["Vessels/currentVessel"].id,
            fuelConsumptionSources: inputFuelConsumptionSources,
            fuelTypeId: inputFuelTypeId,
            fuelMassFlowRateLogVariableId: inputFuelMassFlowRateLogVariableId,
        };

        return newFlowMeter;
    });

    self.isNewFlowMeterValid = ko.pureComputed(function() {
        var newFlowMeter = self.newFlowMeter();
        var newFlowMeterExists = self.newFlowMeterExists();

        if (!(newFlowMeter.fuelConsumptionSources.length > 0 && newFlowMeter.fuelTypeId && newFlowMeter.name) || newFlowMeterExists) {
            return false;
        }

        return true;
    });

    self.newFlowMeterExists = ko.pureComputed(function() {
        var activeFlowMeters = self.activeFlowMeters();
        var newFlowMeterName = self.newFlowMeter().name;

        if (!newFlowMeterName) {
            return false;
        }

        return _.some(activeFlowMeters,
            function(activeFlowMeter) {
                return activeFlowMeter.name.toLowerCase() === newFlowMeterName.toLowerCase();
            });
    });

    self.flowMeterDeletedBinding = events.flowMeterDeleted.add(function(flowMeter) {
        self.flowMeters.remove(function(item) {
            return item.id === flowMeter.id;
        });
    });

    self.hasActiveFlowMeters = ko.pureComputed(function() {
        var activeFlowMeters = self.activeFlowMeters();
        return activeFlowMeters.length > 0;
    });

    self.activeFlowMeters = ko.pureComputed(function() {
        var flowMeters = self.flowMeters();
        return _.filter(flowMeters,
            function(flowMeter) {
                return !flowMeter.isDeactivated;
            });
    });

    self.availableMassFlowRateLogVariables = ko.pureComputed(function() {
        var activeFlowMeters = self.activeFlowMeters();
        var massFlowRateLogVariables = self.massFlowRateLogVariables();

        var inUseFuelMassFlowRateLogVariableId = _.pluck(activeFlowMeters, "fuelMassFlowRateLogVariableId");

        return _.filter(massFlowRateLogVariables,
            function(massFlowRateLogVariable) {
                return !_.contains(inUseFuelMassFlowRateLogVariableId, massFlowRateLogVariable.id);
            });
    });
}

ViewModel.prototype.dispose = function() {
    this.flowMeterDeletedBinding.detach();
};

ViewModel.prototype.addFlowMeter = function() {
    var self = this;
    var newFlowMeter = self.newFlowMeter();
    self.isSaving(true);
    flowMetersClient.create(newFlowMeter).done(function(data) {
        self.flowMeters.push(data);
        self.cancelNewFlowMeter();
    }).always(function() {
        self.isSaving(false);
    });
};

ViewModel.prototype.clearNewFlowMeter = function() {
    this.inputName("");
    this.inputFuelConsumptionSources([]);
    this.inputFuelMassFlowRateLogVariableId("");
    this.inputFuelTypeId("");
};

ViewModel.prototype.cancelNewFlowMeter = function() {
    this.clearNewFlowMeter();
    this.showNewFlowMeter(false);
};

ViewModel.prototype.startNewFlowMeter = function() {
    this.showNewFlowMeter(true);
};

export default { viewModel: ViewModel, template: template };
