import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { UserProfile } from "@/types/userProfile";
import usersClient from "Clients/users-client";
import store from "@/store/index";


@Module({ namespaced: true, name: "Users" })
class Users extends VuexModule {
  private _usersExpired = true;
  private _users: UserProfile[] = [];
  private _onlineUsers: UserProfile[] = [];

  @Mutation
  public REFRESH_USERS(users: UserProfile[]): void {
    this._usersExpired = false;
    this._users = users;
  }

  @Mutation
  public REFRESH_ONLINE_USERS(onlineUsers: UserProfile[]): void {
    this._onlineUsers = onlineUsers;
  }

  @Mutation
  public EXPIRE_DATA(): void {
    this._usersExpired = true;
  }

  @Action({ rawError: true })
  public async refreshUsers(): Promise<void> {
    let data = [];
    try {
      data = await usersClient.getAll();
    } catch (error) {
      console.warn(error);
    }
    this.context.commit("REFRESH_USERS", data);
  }

  @Action({ rawError: true })
  public async refreshOnlineUsers(): Promise<void> {
    let data = [];
    try {
      data = await usersClient.getOnlineUsers();
    } catch (error) {
      console.warn(error);
    }
    this.context.commit("REFRESH_ONLINE_USERS", data);
  }

  @Action({ rawError: true })
  public async getAll(): Promise<UserProfile[]> {
    if (!this._usersExpired) {
      return this._users;
    } else {
      await this.refreshUsers();
      return this._users;
    }
  }

  @Action({ rawError: true })
  public async viewAsCompany(companyId: number): Promise<void> {
    try {
      await usersClient.setCompany(companyId);
      store.commit("User/EXPIRE_USER", null, { root: true });
    } catch (error) {
      console.warn(error);
    }
  }

  get users(): UserProfile[] {
    return this._users;
  }
  public get getUserById() {
    return (id: string | null | number): UserProfile | undefined => {
      return this._users.find(usr => usr.id === id);
    };
  }
  get usersCount(): number {
    return this._users.length;
  }
  get onlineUsers(): UserProfile[] {
    return this._onlineUsers;
  }
}

export default Users;
