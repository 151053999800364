import template from "./general-settings.html";
import ko from "knockout";
import _ from "underscore";
import vesselsAdministrationsClient from "Clients/vessels-administrations-client";
import iceClassesClient from "Clients/ice-classes-client";
import vesselTypesClient from "Clients/vessel-types-client";
import cargoUnitsClient from "Clients/cargo-units-client";

function ViewModel(params) {
    var self = this;
    self.speedLogVariables = params.speedLogVariables;
    self.vessel = params.vessel;
    self.isSaving = ko.observable(false);
    self.isAddingCargoUnit = ko.observable(false);
    self.isRemovingCargoUnit = ko.observable(false);

    var vesselUnwrapped = ko.utils.unwrapObservable(params.vessel);
    self.inputCargoUnitId = ko.observable();
    self.inputVesselTypeId = ko.observable();
    self.inputIceClassId = ko.observable();

    self.cargoUnits = ko.observable();
    self.isLoadingCargoUnits = ko.observable(true);
    cargoUnitsClient.getAll()
        .done(function(data) {
            self.cargoUnits(data);
            self.inputCargoUnitId(vesselUnwrapped.cargoUnitId);
        })
        .always(function() {
            self.isLoadingCargoUnits(false);
        });

    self.vesselTypes = ko.observable();
    self.isLoadingVesselTypes = ko.observable(true);
    vesselTypesClient.getAll()
        .done(function(data) {
            self.vesselTypes(data);
            self.inputVesselTypeId(vesselUnwrapped.vesselTypeId);
        })
        .always(function() {
            self.isLoadingVesselTypes(false);
        });

    self.iceClasses = ko.observable();
    self.isLoadingIceClasses = ko.observable(true);
    iceClassesClient.getAll()
        .done(function(data) {
            self.iceClasses(data);
            self.inputIceClassId(vesselUnwrapped.iceClassId);
        })
        .always(function() {
            self.isLoadingIceClasses(false);
        });

    self.inputPortOfRegistry = ko.observable(vesselUnwrapped.portOfRegistry);
    self.inputHomePort = ko.observable(vesselUnwrapped.homePort);
    self.inputEnergyEfficiencyDesignIndex = ko.observable(vesselUnwrapped.energyEfficiencyDesignIndex);
    self.inputEstimatedIndexValue = ko.observable(vesselUnwrapped.estimatedIndexValue);
    self.inputAuxiliaryEnginesPower = ko.observable(vesselUnwrapped.auxiliaryEnginesPower);
    self.inputMainPropulsionPower = ko.observable(vesselUnwrapped.mainPropulsionPower);
    self.inputDeadweight = ko.observable(vesselUnwrapped.deadweight);
    self.inputNetTonnage = ko.observable(vesselUnwrapped.netTonnage);
    self.inputGrossTonnage = ko.observable(vesselUnwrapped.grossTonnage);

    self.inputOwnerCompanyName = ko.observable(vesselUnwrapped.ownerCompanyName);
    self.inputOwnerCompanyAddress = ko.observable(vesselUnwrapped.ownerCompanyAddress);
    self.inputOperatorCompanyName = ko.observable(vesselUnwrapped.operatorCompanyName);
    self.inputOperatorCompanyAddress = ko.observable(vesselUnwrapped.operatorCompanyAddress);

    self.inputEmissionsContactPersonName = ko.observable(vesselUnwrapped.emissionsContactPersonName);
    self.inputEmissionsContactPersonAddress = ko.observable(vesselUnwrapped.emissionsContactPersonAddress);
    self.inputEmissionsContactPersonPhone = ko.observable(vesselUnwrapped.emissionsContactPersonPhone);
    self.inputEmissionsContactPersonEmail = ko.observable(vesselUnwrapped.emissionsContactPersonEmail);

    self.hasVesselCargoUnits = ko.pureComputed(function() {
        var vessel = self.vessel();
        return vessel.cargoUnits.length > 0;
    });

    self.removeCargoUnit = function(cargoUnit) {
        var vessel = self.vessel();

        self.isRemovingCargoUnit(true);
        vesselsAdministrationsClient.removeCargoUnit(vessel.id, cargoUnit.id).done(self.vessel).always(function() {
            self.isRemovingCargoUnit(false);
        });
    }.bind(self);

    self.availableCargoUnits = ko.pureComputed(function() {
        var cargoUnits = self.cargoUnits();
        var vesselCargoUnits = self.vessel().cargoUnits;

        return _.filter(cargoUnits,
            function(cargoUnit) {
                return !_.find(vesselCargoUnits,
                    function(vesselCargoUnit) {
                        return vesselCargoUnit.id === cargoUnit.id;
                    });
            });
    });
}

ViewModel.prototype.dispose = function() {
};

ViewModel.prototype.save = function() {
    var self = this;
    var vessel = _.clone(self.vessel());
    vessel.vesselTypeId = self.inputVesselTypeId();
    vessel.iceClassId = self.inputIceClassId();
    vessel.portOfRegistry = self.inputPortOfRegistry();
    vessel.homePort = self.inputHomePort();
    vessel.energyEfficiencyDesignIndex = parseFloat(self.inputEnergyEfficiencyDesignIndex());
    vessel.estimatedIndexValue = parseFloat(self.inputEstimatedIndexValue());
    vessel.auxiliaryEnginesPower = parseFloat(self.inputAuxiliaryEnginesPower());
    vessel.mainPropulsionPower = parseFloat(self.inputMainPropulsionPower());
    vessel.deadweight = parseFloat(self.inputDeadweight());
    vessel.netTonnage = parseFloat(self.inputNetTonnage());
    vessel.grossTonnage = parseFloat(self.inputGrossTonnage());

    vessel.ownerCompanyName = self.inputOwnerCompanyName();
    vessel.ownerCompanyAddress = self.inputOwnerCompanyAddress();
    vessel.operatorCompanyName = self.inputOperatorCompanyName();
    vessel.operatorCompanyAddress = self.inputOperatorCompanyAddress();

    vessel.emissionsContactPersonName = self.inputEmissionsContactPersonName();
    vessel.emissionsContactPersonAddress = self.inputEmissionsContactPersonAddress();
    vessel.emissionsContactPersonPhone = self.inputEmissionsContactPersonPhone();
    vessel.emissionsContactPersonEmail = self.inputEmissionsContactPersonEmail();

    self.isSaving(true);
    vesselsAdministrationsClient.update(vessel).done(self.vessel).always(function() {
        self.isSaving(false);
    });
};

ViewModel.prototype.addCargoUnit = function() {
    var self = this;
    var vessel = self.vessel();
    var cargoUnitId = self.inputCargoUnitId();

    self.isAddingCargoUnit(true);
    vesselsAdministrationsClient.addCargoUnit(vessel.id, cargoUnitId).done(self.vessel).always(function() {
        self.isAddingCargoUnit(false);
    });
};

export default { viewModel: ViewModel, template: template };