import Color from "color";

var goldenRationConjugate = 0.618033988749895;

function colorFromHsv(hue, saturation, value) {
    var hi = parseInt(Math.floor(hue / 60)) % 6;
    var f = hue / 60 - Math.floor(hue / 60);

    value = value * 255;
    var v = parseInt(value);
    var p = parseInt(value * (1 - saturation));
    var q = parseInt(value * (1 - f * saturation));
    var t = parseInt(value * (1 - (1 - f) * saturation));

    switch (hi) {
    case 0:
        return Color({ r: v, g: t, b: p });
    case 1:
        return Color({ r: q, g: v, b: p });
    case 2:
        return Color({ r: p, g: v, b: t });
    case 3:
        return Color({ r: p, g: q, b: v });
    case 4:
        return Color({ r: t, g: p, b: v });
    default:
        return Color({ r: v, g: p, b: q });
    }
}

export default {
    getDistinctColor: function(index) {
        if (index === 0) return "#96BCD4"; //Nice blue Kyma color as first color.

        var h = parseFloat(index - 1) * goldenRationConjugate;
        var color = colorFromHsv(h * 360, 0.85, 0.95);
        return color.string();
    },
};