import template from "./benefit-consequence-result.html";
import ko from "knockout";
import events from "App/events";
import diagnosticTypesHelper from "Utilities/diagnostic-types-helper";

function ViewModel(params) {
    var self = this;
    self.diagnosticTypeName = params.diagnosticTypeName;
    self.displayValue = params.result.displayValue;
    self.isValid = params.result.isValid;
    self.hasSFRBenchmarkingError = params.hasSFRBenchmarkingError;
    self.value = params.result.value;
    self.isNaN = isNaN(self.value);
    self.isHelpTooltipOpen = ko.observable(false);
    self.closeTooltipBinding = events.closeTooltip.add(function() {
        self.isHelpTooltipOpen(false);
    });

    self.valueText = ko.pureComputed(function() {
        var displayValue = self.displayValue;
        var isNaN = self.isNaN;
        var hasSFRBenchmarkingError = self.hasSFRBenchmarkingError;
        var headerName = params.result.headerName.toLowerCase();

        if (headerName.indexOf("fuel oil impact") !== -1) {
            if (isNaN || hasSFRBenchmarkingError) return  "N/A **";
        }

        return displayValue;
    });
}

ViewModel.prototype.dispose = function () {
    this.closeTooltipBinding.detach();
};

ViewModel.prototype.toggleHelpTooltip = function (vm, e) {
    var isHelpTooltipOpen = this.isHelpTooltipOpen();
    if (!isHelpTooltipOpen) {
        var element = e.currentTarget;
        events.showTooltip.dispatch("tooltip-text", { text: diagnosticTypesHelper.getInvalidConsequenceHelpText(this.diagnosticTypeName) }, element);
        this.isHelpTooltipOpen(true);
    } else {
        events.closeTooltip.dispatch();
    }
};

ViewModel.prototype.hideHelpTooltip = function () {
    if (this.isHelpTooltipOpen()) {
        this.isHelpTooltipOpen(false);
        events.closeTooltip.dispatch();
    }
};

export default { viewModel: ViewModel, template: template };