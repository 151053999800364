import ko from "knockout";
import _ from "underscore";

function ViewModel(taxon, highlightedLogVariable) {
    var self = this;
    self.taxon = taxon;
    self.shortName = self.taxon.name.split(":")[1];

    self.isHighlighted = ko.pureComputed(function() {
        var logVariable = highlightedLogVariable();
        if (!logVariable) return false;

        return _.any(logVariable.taxa, function(logVariableTaxon) {
            return logVariableTaxon.name === self.taxon.name;
        });
    });
}

export default ViewModel;