import net from "App/net";

var FoulingOverviewWidgetClient = function () { };

FoulingOverviewWidgetClient.prototype.getAll = function () {
  return net.get("/FoulingOverviewWidget");
};

FoulingOverviewWidgetClient.prototype.get = function (foulingOverViewWidgetConfigId) {
  return net.get(`/FoulingOverviewWidget/${foulingOverViewWidgetConfigId}`);
};

FoulingOverviewWidgetClient.prototype.new = function (foulingOverViewWidgetConfigId) {
  return net.post("/FoulingOverviewWidget", foulingOverViewWidgetConfigId);
};

FoulingOverviewWidgetClient.prototype.update = function (foulingOverViewWidgetConfigId) {
  return net.put("/FoulingOverviewWidget", foulingOverViewWidgetConfigId);
};

FoulingOverviewWidgetClient.prototype.delete = function (foulingOverViewWidgetConfigId) {
  return net.del(`/FoulingOverviewWidget/${foulingOverViewWidgetConfigId}`);
};

export default new FoulingOverviewWidgetClient();
