import template from "./vessel-access-group.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import AccessGroupVM from "ViewModels/vessel-access-groups/vesselAccessGroup";
import AccessGroupListVM from "ViewModels/vessel-access-groups/vesselAccessGroupList";

function ViewModel() {
    var self = this;

    self.viewModel = null;
    self.error = ko.observable();
    self.dialogState = ko.observable("");
    self.isEditMode = ko.observable(false);
    self.accessGroupList = new AccessGroupListVM();

    self.vesselAccessGroupAddBinding = events.vesselAccessGroupAdd.add(function () {
        self.clearError();
        self.viewModel = new AccessGroupVM(null, self.accessGroupList);
        self.dialogState("ADD");
    });

    self.vesselAccessGroupRenameBinding = events.vesselAccessGroupRename.add(function (accessGroup) {
        self.clearError();
        self.viewModel = new AccessGroupVM(accessGroup, self.accessGroupList);
        self.dialogState("RENAME");
    });

    self.vesselAccessGroupEditBinding = events.vesselAccessGroupEdit.add(function (accessGroup) {
        self.clearError();
        self.viewModel = new AccessGroupVM(accessGroup, self.accessGroupList);
        self.isEditMode(true);
    });

    self.vesselAccessGroupDeleteBinding = events.vesselAccessGroupDelete.add(function (accessGroup) {
        self.clearError();
        self.viewModel = new AccessGroupVM(accessGroup, self.accessGroupList);
        self.dialogState("DELETE");
    });
}

ViewModel.prototype.dispose = function () {
    this.vesselAccessGroupAddBinding.detach();
    this.vesselAccessGroupRenameBinding.detach();
    this.vesselAccessGroupEditBinding.detach();
    this.vesselAccessGroupDeleteBinding.detach();
};

ViewModel.prototype.closeDialog = function () {
    this.dialogState("");
};

ViewModel.prototype.closeEdit = function () {
    this.isEditMode(false);
};

ViewModel.prototype.clearError = function () {
    this.error(null);
};

ViewModel.prototype.handleError = function (error) {
    if (error && error.responseJSON && error.responseJSON.message) {
        this.error(error.responseJSON.message);
    } else {
        this.error("An error occurred.");
    }
    events.errorEvent.dispatch(error);
};

ViewModel.prototype.createAccessGroup = function () {
    var self = this;
    self.viewModel.save()
        .done(function() {
            events.vesselAccessGroupOnCreated.dispatch(self.viewModel.originalAccessGroup);
            self.closeDialog();
        }).fail(function(error) {
            self.handleError(error);
        });
};

ViewModel.prototype.renameAccessGroup = function () {
    var self = this;
    var originalCompany = self.viewModel.originalAccessGroup;
    self.viewModel.save()
        .done(function (data) {
            events.vesselAccessGroupOnUpdated.dispatch(originalCompany, data);
            self.closeDialog();
        }).fail(function (error) {
            self.handleError(error);
        });
};

ViewModel.prototype.deleteAccessGroup = function () {
    var self = this;
    self.viewModel.delete()
        .done(function () {
            events.vesselAccessGroupOnDeleted.dispatch(self.viewModel.originalAccessGroup);
            self.closeDialog();
        }).fail(function (error) {
            self.handleError(error);
        });
};

export default { viewModel: ViewModel, template: template };
