import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import vesselFilesClient from "Clients/vessel-files-client";
import stringHelper from "Utilities/string-helper";

var ViewModel = function File(fileEntity) {
    var self = this;

    self.supportedExtensions = {
        excel: ["xlsx", "xls", "xlt", "xlm", "xlsm", "xltx", "xltm"],
        word: ["doc", "docx", "dot", "docm", "dotx", "dotm"],
        pdf: ["pdf"],
    };

    self.elementType = "file";
    self.id = ko.observable(fileEntity.id);
    self.fileName = ko.observable(fileEntity.fileName);
    self.uploadedTime = ko.observable(fileEntity.uploadedTime);
    self.vesselId = ko.observable(fileEntity.vesselId);
    self.folderId = ko.observable(fileEntity.folderId);
    self.binaryFileId = ko.observable(fileEntity.binaryFileId);
    self.uploadedBy = ko.observable(fileEntity.uploadedBy);
    self.fileSize = ko.observable(fileEntity.fileSize);
    self.isFileMenuVisible = ko.observable(false);
    self.newName = ko.observable();

    self.fileIcon = ko.pureComputed(function() {
        var fileExtension = self.fileExtension().toLowerCase();
        var supportedExtensions = self.supportedExtensions;

        if (_.some(supportedExtensions.excel, function(extension) { return extension === fileExtension; })) {
            return "fa fa-file-excel-o fa-2x excelIcon";
        } else if (_.some(supportedExtensions.word, function (extension) { return extension === fileExtension; })) {
            return "fa fa-file-word-o fa-2x wordIcon";
        } else if (_.some(supportedExtensions.pdf, function (extension) { return extension === fileExtension; })) {
            return "fa fa-file-pdf-o fa-2x pdfIcon";
        }

        return "";
    });

    self.fileExtension = ko.pureComputed(function () {
        var fileName = self.fileName();
        return fileName.split(".").pop();
    });

    self.formattedFileSize = ko.pureComputed(function () {
        var fileSize = self.fileSize();
        return stringHelper.getPrettyFileSize(fileSize);
    });

    self.uploadedText = ko.pureComputed(function () {
        var uploadedTime = self.uploadedTime();

        return moment(uploadedTime).format("DD. MMM, YYYY HH:mm");
    });

    self.newNameErrorText = ko.pureComputed(function () {
        var newName = self.newName() + "." + self.fileExtension();
        return "A file with the name " + newName + " already exists";
    });

    self.rename = function () {
        var newName = self.newName();
        var fileName = self.fileName();
        var fileNameWithoutPath = self.fileNameWithoutPath();

        if (newName === fileNameWithoutPath) {
            return;
        }

        var fileExtension = "." + fileName.split(".").pop();
        var newNameEndsWithExtension = stringHelper.endsWith(newName, fileExtension, false);
        newName += fileExtension;

        fileName = fileName.replace(fileNameWithoutPath, newName);
        self.fileName(fileName);

        vesselFilesClient.update(self.getFile());
    };

    self.fileNameWithoutPath = ko.pureComputed(function() {
        var fileName = self.fileName();

        if (fileName.indexOf("/") === -1) {
            return fileName;
        } else {
            return fileName.split("/").slice(-1)[0];
        }
    }); 

    self.fileNameWithoutExtension = ko.pureComputed(function() {
        var fileName = self.fileNameWithoutPath();
        var fileExtension = "." + self.fileExtension();
        return fileName.replace(fileExtension, "");
    });

    self.resetName = function () {
        var fileName = self.fileName();
        var nameWithoutExtension = fileName.substr(0, fileName.lastIndexOf("."));

        self.newName(nameWithoutExtension);
    };
};

ViewModel.prototype.getFileNameWithoutPath = function(fileName) {
    if (fileName.indexOf("/") === -1) {
        return fileName;
    } else {
        return fileName.split("/").slice(-1);
    }
};

ViewModel.prototype.showFileMenu = function () {
    var isFileMenuVisible = this.isFileMenuVisible();
    this.isFileMenuVisible(!isFileMenuVisible);
};

ViewModel.prototype.hideFileMenu = function (arg) {
    this.isFileMenuVisible(false);
};

ViewModel.prototype.getFile = function() {
    var self = this;

    var file = {
        id: self.id(),
        fileName: self.fileName(),
        fileSize: self.fileSize(),
        uploadedTime: self.uploadedTime(),
        uploadedBy: self.uploadedBy(),
        vesselId: self.vesselId(),
        binaryFileId: self.binaryFileId(),
    };

    return file;
};

export default ViewModel;