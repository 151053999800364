import "dropzone";

import ko from "knockout";
import _ from "underscore";
import $ from "jquery";
import events from "App/events";
import authHeader from "@/services/AuthHeader";

function updateTempFolders(file, fileName, tempFolders, parentFolders) {
    _.each(parentFolders(), function(parentFolder) {
        var tempFolder = _.find(tempFolders(), function (tempFolder) {
            return tempFolder.folderPath() === parentFolder.folderPath();
        });

        if (tempFolder) {
            tempFolders.remove(tempFolder);
        }
    });
}

ko.bindingHandlers.fileUpload = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var vesselId = valueAccessor().vesselId;
        var file = valueAccessor().file;
        var folder = valueAccessor().folder;
        var isValidFile = valueAccessor().isValidFile;
        var errorMessage = valueAccessor().errorMessage;
        var isUploading = valueAccessor().isUploading;
        var filesInCurrentFolder = valueAccessor().filesInCurrentFolder;
        var parentFolders = valueAccessor().parentFolders;
        var tempFolders = valueAccessor().tempFolders;

        $(element).dropzone({
            acceptedFiles: "application/pdf, .doc, .docx, .dot, .docm, .dotx, .dotm, .xlsx, .xls, .xlt, .xlm, .xlsm, .xltx, .xltm",
            dictInvalidFileType: "Files of this type are not supported, you can only upload PDF, Word or Excel files.",
            url: process.env.VUE_APP_API_BASE_URL + "/VesselFiles/" + vesselId,
            timeout: 6000000,
            headers: authHeader(),
            init: function () {
                this.on("success", function (file, vesselFile) {
                    updateTempFolders(vesselFile, file.name, tempFolders, parentFolders);
                    events.vesselFileUploaded.dispatch(vesselFile);
                    errorMessage("");
                }).on("error", function (file, response) {
                    var message = _.isUndefined(response.message) ? response : response.message;

                    errorMessage(message);
                    isValidFile(false);
                }).on("addedfile", function (file) {
                    var fileName = folder().folderPath() + file.name;

                    var fileExists = _.some(filesInCurrentFolder(), function (file) {
                        return file.fileName() === fileName;
                    });

                    file.upload.filename = fileName;

                    if (fileExists) {
                        if (!confirm(file.name + " already exists. Do you want to replace it?")) {
                            this.removeFile(file);
                        }
                    }
                }).on("sending", function () {
                    isUploading(true);
                }).on("complete", function () {
                    isUploading(false);
                });
            },
        });
    },
    update: function (element, valueAccessor, allBindingsAccessor) {

    },
};
