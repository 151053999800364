import router from "@/router";
import { FormattedError } from "@/types/formattedError";
class Utilities {

  /**
   * Clicking local urls from within static html/knockout templates are intercepted and handled by vue router
   * @param  {Event} e mouse or keyboard event
   */
  public interceptLocalUrl(e: any): void {
    let target = e.target;
    //return if target was not a link-element
    if (target.tagName !== "A") {
      //clicking a link with more elements inside
      if (target.parentElement?.tagName == "A") {
        target = target.parentElement;
      } else {
        return;
      }
    }

    const url: string = target.getAttribute("href");
    //return if url was not relative or if it was a local anchor
    if (url.indexOf("://") > 0 || url.startsWith("#"))
      return;

    //return if we are already on the url path
    if (router.currentRoute.path === url)
      return;

    //at this point we should be at a non-vue local link. Abort browser routing and use vue router
    e.preventDefault();
    e.stopPropagation();
    router.push({ path: url });
  }

  /* returns formattedError object containing title (string) and messages (Array<String>)
  There can be multiple properties within responseJSON.modelState, each containing an array of messages
  This function extracts all the messages into a flattened array*/
  public formatError(error: any): FormattedError {
    let title;
    let messages: any = [];

    if (error.responseJSON) {
      title = error.responseJSON.message;
      messages = Object.values(error.responseJSON.modelState).flat();
    } else {
      title = error.statusText;
    }

    const formattedError: FormattedError = {
      title,
      messages,
    };

    return formattedError;
  }

}

export default new Utilities();
