import template from "./vessel-access-group-editor.html";
import ko from "knockout";
import _ from "underscore";
import vesselCategoriesClient from "Clients/vessel-categories-client";
import vesselsClient from "Clients/vessels-client";
import usersClient from "Clients/users-client";

function ViewModel(params) {
    var self = this;

    self.accessGroup = params.accessGroup;

    self.isLoadingVessels = ko.observable(false);
    self.isLoadingUsers = ko.observable(false);
    self.error = ko.observable();
    self.isCriticalError = ko.observable(false);

    self.vessels = ko.observable();
    self.users = ko.observable();

    self.initialize();

    self.vesselsInAccessGroup = ko.pureComputed(function () {
        return _.sortBy(self.accessGroup.vessels(), "name");
    });

    self.vesselsAvailable = ko.pureComputed(function () {
        var vesselsInAccessGroup = self.vesselsInAccessGroup();
        var vesselsAvailable = _.filter(self.vessels(), function (vessel) {
            return !_.find(vesselsInAccessGroup, function(v) {
                    return v.id === vessel.id;
                });
        });
        return _.sortBy(vesselsAvailable, "name");
    });

    self.usersInAccessGroup = ko.pureComputed(function () {
        return _.sortBy(self.accessGroup.users(), "name");
    });

    self.usersAvailable = ko.pureComputed(function () {
        var usersInAccessGroup = self.usersInAccessGroup();
        var usersAvailable = _.filter(self.users(), function (user) {
            return !_.find(usersInAccessGroup, function (u) {
                    return u.id === user.id;
                });
        });

        return _.sortBy(usersAvailable, "name");
    });
}

ViewModel.prototype.initialize = function() {
    var self = this;

    self.error(undefined);
    self.isCriticalError(false);
    self.isLoadingVessels(true);
    self.isLoadingUsers(true);

    self.loadVessels();
    self.loadUsers();
};

ViewModel.prototype.loadVessels = function () {
    var self = this;
    self.isLoadingVessels(true);
    vesselsClient.getVessels()
        .done(function(data) {
            self.vessels(data);
        }).fail(function(error) {
            self.isCriticalError(true);
            self.handleError("Failed to load list of available vessels.", error);
        })
        .always(function() {
            self.isLoadingVessels(false);
        });
};

ViewModel.prototype.loadUsers = function () {
    var self = this;
    self.isLoadingUsers(true);
    usersClient.getAll()
        .done(function(data) {
            self.users(data);
        })
        .fail(function(error) {
            self.isCriticalError(true);
            self.handleError("Failed to load list of available users.", error);
        })
        .always(function() {
            self.isLoadingUsers(false);
        });
};

ViewModel.prototype.clearError = function() {
    this.error(undefined);
};

ViewModel.prototype.handleError = function(message, error) {
    this.error(message);
    console.error(error);
};

ViewModel.prototype.closeErrorDialog = function () {
    this.isCriticalError(false);
};

ViewModel.prototype.addVessel = function(vessel) {
    var self = this;
    self.clearError();
    vesselCategoriesClient.addVesselToCategory(self.accessGroup.originalAccessGroup, vessel)
        .done(function (data) {
            // Add to model list.
            self.accessGroup.vessels.push(vessel);
        }).fail(function(error) {
            self.handleError("Failed to add vessel.", error);
        });
};

ViewModel.prototype.removeVessel = function(vessel) {
    var self = this;
    self.clearError();
    vesselCategoriesClient.removeVesselFromCategory(self.accessGroup.originalAccessGroup, vessel)
        .done(function (data) {
            // Remove from model list.
            self.accessGroup.vessels.remove(function (i) {
                return i.id === vessel.id;
            });
        }).fail(function(error) {
            self.handleError("Failed to remove vessel.", error);
        });
};

ViewModel.prototype.addUser = function(user) {
    var self = this;
    self.clearError();
    vesselCategoriesClient.addUserToCategory(self.accessGroup.originalAccessGroup, user)
        .done(function(data) {
            // Add to model list.
            self.accessGroup.users.push(user);
        }).fail(function(error) {
            self.handleError("Failed to add user.", error);
        });
};

ViewModel.prototype.removeUser = function(user) {
    var self = this;
    self.clearError();
    vesselCategoriesClient.removeUserFromCategory(self.accessGroup.originalAccessGroup, user)
        .done(function(data) {
            // Remove from model list.
            self.accessGroup.users.remove(function (i) {
                return i.id === user.id;
            });
        })
        .fail(function(error) {
            self.handleError("Failed to remove user.", error);
        });
};

export default { viewModel: ViewModel, template: template };
