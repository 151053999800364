import net from "@/Scripts/app/net";
/* eslint-disable */
class DataQualityClient {
  constructor() {}
  getFleetOverview() {
    return [{
        vesselId: 556,
        overallStatus: 3,
        dataAvailability: 3,
        dataOutliers: 3,
      },
      {
        vesselId: 601,
        overallStatus: 1,
        dataAvailability: 1,
        dataOutliers: 1,
      },
      {
        vesselId: 602,
        overallStatus: 1,
        dataAvailability: 1,
        dataOutliers: 1,
      },
      {
        vesselId: 603,
        overallStatus: 0,
        dataAvailability: 0,
        dataOutliers: 0,
      },
      {
        vesselId: 606,
        overallStatus: 2,
        dataAvailability: 2,
        dataOutliers: 2,
      },
      {
        vesselId: 607,
        overallStatus: 1,
        dataAvailability: 1,
        dataOutliers: 1,
      }
    ];
    return net.get("/DataQuality/FleetOverview");
  }
  getDataOutage() {
    return net.get("/DataQuality/DataOutage");
  }
  getDataOutageConfig() {
    return net.get("/DataQuality/DataOutage/Configuration");
  }
  updateDataOutageConfig(config) {
    return net.put("/DataQuality/DataOutage/Configuration", config);
  }
  getDataOutliers() {
    return net.get("/DataQuality/DataOutliers");
  }
  getDataOutliersTakeAction() {
    return net.get("/DataQuality/DataOutliers/TakeAction");
  }
  updateDataOutliersTakeActionAcknowledge(tags) {
    return net.put("/DataQuality/DataOutliers/TakeAction/Acknowledge", tags);
  }
  updateDataOutliersTakeActionRemove(tags) {
    return net.put("/DataQuality/DataOutliers/TakeAction/Remove", tags);
  }
  getDataOutliersViewHistory() {
    return net.get("/DataQuality/DataOutliers/ViewHistory");
  }
  updateDataOutliersViewHistory(actions) {
    return net.put("/DataQuality/DataOutliers/ViewHistory", actions);
  }
}

export default new DataQualityClient();
