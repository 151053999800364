require(["knockout", "jquery", "leaflet", "underscore"], function(ko, $, L, _) {
    function findMarkerBounds(markers) {
        var n, e, s, w, sw, ne;
        var marker, lat, lng;

        for (var i = 0; i < markers.length; i++) {
            marker = markers[i];
            lat = marker.getLatLng().lat;
            lng = marker.getLatLng().lng;

            // find lowest and highest latitude
            if (lat > n || i == 0) n = lat;
            if (lat < s || i == 0) s = lat;

            // find lowest and highest longitude
            if (lng > e || i == 0) e = lng;
            if (lng < w || i == 0) w = lng;
        }

        sw = new L.LatLng(s, w);
        ne = new L.LatLng(n, e);

        return new L.LatLngBounds(sw, ne);
    }

    function updateMarkers(map, markers, markerPopupsIsOpen) {
        var openPopups = markerPopupsIsOpen;
        if (map.markersGroup) {
            openPopups = _.any(map.markers, function(marker) {
                return marker.isPopupOpen();
            });

            //clearing the layers further below doesn't remove the popups :/
            //so removing them here explicitly as a fix
            _.each(map.markers, function(marker) {
                marker.closePopup();
            });

            map.markersGroup.clearLayers();
        }

        map.markers = markers;
        map.markersGroup = L.layerGroup(map.markers).addTo(map);

        if (openPopups) {
            _.each(markers, function(marker) {
                marker.openPopup();
            });
        }

        if (map.markers.length > 0) {
            var markerBounds = findMarkerBounds(map.markers);
            map.fitBounds(markerBounds, {
                padding: [100, 100],
                maxZoom: 6,
            });
        }
    }

    ko.bindingHandlers.map = {
        init: function(element, valueAccessor, allBindingsAccessor) {
            var $el = $(element);
            var value = valueAccessor();
            var options = ko.utils.unwrapObservable(value.options);

            var layer = new L.TileLayer("https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.png", {
                attribution: "Esri",
            });
            var maxBounds = L.latLngBounds(L.latLng(-85, -200), L.latLng(85, 200));
            var map = L.map(element, { layers: layer, scrollWheelZoom: true, maxZoom: 14, minZoom: 2, zoom: 5, center: [0, 0], maxBounds: maxBounds, maxBoundsViscosity: 0.8 });

            value.map(map);

            updateMarkers(map, options.markers, options.initialMarkerPopupsIsOpen);
            var optionsSubscription = value.options.subscribe(function(newOptions) {
                updateMarkers(map, newOptions.markers);
            });

            ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
                optionsSubscription.dispose();
            });
        },
        update: function(element, valueAccessor, allBindingsAccessor) {},
    };
});