import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Vessel } from "@/types/Vessel";
import { ExtendedVessel } from "@/types/Vessel";
import vesselsClient from "Clients/vessels-client";
import vesselTypesClient from "Clients/vessel-types-client";
import vesselPerformanceStatusesClient from "Clients/vessel-performance-statuses-client";
import { VesselDraft } from "@/types/vesselDraft";
import performanceStatusHelper from "Utilities/performance-status-helper";
import Vue from "vue";

@Module({ namespaced: true, name: "Vessels" })
class Vessels extends VuexModule {
  private _currentVessel: ExtendedVessel | null = null;
  private _vessels: Vessel[] = [];
  private _extendedVessels: ExtendedVessel[] = [];
  private _vesselTypes: { id: number; name: string; }[] = [];
  private _vesselsExpired = true;
  private _extendedVesselsExpired = true;
  private _vesselsLoadingState = true;
  private _currentVesselLoading = true;

  @Mutation
  public UPDATE_CURRENT_VESSEL(vessel: ExtendedVessel): void {
    this._currentVessel = vessel;
  }

  @Mutation
  public SET_CURRENT_VESSEL_LOADING_STATE(loading: boolean): void {
    this._currentVesselLoading = loading;
  }

  @Mutation
  public EXPIRE_DATA(): void {
    this._vesselsExpired = true;
    this._extendedVesselsExpired = true;
  }

  @Mutation
  public REFRESH_VESSELS(vessels: Vessel[]): void {
    this._vesselsExpired = false;
    this._vessels = vessels;
  }

  @Mutation
  public REFRESH_EXTENDED_VESSELS(vessels: ExtendedVessel[]): void {
    this._extendedVesselsExpired = false;
    this._extendedVessels = vessels;
  }

  @Mutation
  public SET_VESSEL_PERFORMANCE_STATUS(status: { vesselId: number, performanceStatus: string }): void {
    const currentVesselIndex = this._extendedVessels.findIndex(vessel => vessel.id === status.vesselId);
    if (currentVesselIndex >= 0) Vue.set(this._extendedVessels[currentVesselIndex], "performance", status);
    if (status.vesselId === this._currentVessel?.id) Vue.set(this._currentVessel, "performance", status);
  }

  @Mutation
  public SET_LOADING_STATE(loading: boolean): void {
    this._vesselsLoadingState = loading;
  }

  @Mutation
  public SET_VESSEL_TYPES(types: { id: number, name: string }[]): void {
    this._vesselTypes = types;
  }

  @Action({ rawError: true })
  public updateCurrentVessel(vessel: ExtendedVessel): void {
    this.context.commit("UPDATE_CURRENT_VESSEL", vessel);
  }

  @Action({ rawError: true })
  public async updateCurrentVesselById(id: number): Promise<void> {
    try {
      let vessel = await this.context.dispatch("get", id);
      const vesselPerformance = await this.context.dispatch("getVesselPerformanceStatus", id);

      vessel = { ...vessel, vesselPerformance };
      this.context.commit("UPDATE_CURRENT_VESSEL", vessel);
    } catch (error) {
      throw ({ message: "Failed to update current vessel", error });
    }
  }

  @Action({ rawError: true })
  public async updateVessel(vessel: Partial<ExtendedVessel>): Promise<void> {
    try {
      await vesselsClient.updateVessel(vessel);
    } catch (error) {
      throw ({ message: "Failed to update vessel", error });
    }
  }

  @Action({ rawError: true })
  public async get(id: number): Promise<ExtendedVessel | undefined> {
    if (!this._extendedVesselsExpired) {
      return this.context.getters["getExtendedVesselById"](id);
    } else {
      await this.context.dispatch("refreshExtendedVessels");
      return this.context.getters["getExtendedVesselById"](id);
    }
  }

  @Action({ rawError: true })
  public async refreshVessels(): Promise<void> {
    let vessels = [];
    try {
      vessels = await vesselsClient.getVessels();
    } catch (error) {
      throw ({ message: "Failed to refresh vessels -> ", error });
    }
    this.context.commit("REFRESH_VESSELS", vessels);
  }

  @Action({ rawError: true })
  public async getVessels(): Promise<Vessel[]> {
    if (!this._vesselsExpired) {
      return this._vessels;
    } else {
      await this.context.dispatch("refreshVessels");
      return this._vessels;
    }
  }

  @Action({ rawError: true })
  public async refreshExtendedVessels(): Promise<void> {
    try {
      this.context.commit("SET_LOADING_STATE", true);
      const vessels: ExtendedVessel[] = await vesselsClient.getExtendedVessels();
      vessels.forEach(vessel => {
        Vue.set(vessel, "performance", { vesselId: vessel.id, performanceStatus: performanceStatusHelper.getPerformanceStatus(vessel) });
      });

      this.context.commit("REFRESH_EXTENDED_VESSELS", vessels);
      this.context.commit("SET_LOADING_STATE", false);
    } catch (error) {
      console.warn(error);
    }
  }

  @Action({ rawError: true })
  public async getExtendedVessels(): Promise<Vessel[]> {
    if (!this._extendedVesselsExpired) {
      return this._extendedVessels;
    } else {
      await this.context.dispatch("refreshExtendedVessels");
      return this._extendedVessels;
    }
  }

  /* @todo: add performance status to extended vessel in api */
  @Action({ rawError: true })
  public async getVesselPerformanceStatus(id: number): Promise<void> {
    try {
      const vesselPerformance = await vesselPerformanceStatusesClient.get(id);
      this.context.commit("SET_VESSEL_PERFORMANCE_STATUS", vesselPerformance);
      return vesselPerformance;
    } catch (error) {
      console.warn(error);
    }
  }

  @Action({ rawError: true })
  public async updateVesselDraft(data: VesselDraft): Promise<void> {
    try {
      await vesselsClient.updateVesselDraft(data.vesselId, data.draft);
    } catch (err) {
      console.warn(err);
    }
  }

  @Action({ rawError: true })
  public updateCurrentVesselLoadingState(loading: boolean): void {
    this.context.commit("SET_CURRENT_VESSEL_LOADING_STATE", loading);
  }

  @Action({ rawError: true })
  public async fetchVesselTypes(): Promise<void> {
    try {
      const types = await vesselTypesClient.getAll();
      this.context.commit("SET_VESSEL_TYPES", types);
    } catch (error) {
      throw ({ message: "Failed to fetch vessel types", error });
    }
  }

  public get getExtendedVesselById() {
    return (id: number): ExtendedVessel | undefined => {
      return this._extendedVessels.find(vessel => vessel.id === id);
    };
  }
  public get currentVessel(): ExtendedVessel | null {
    return this._currentVessel;
  }

  public get extendedVessels(): ExtendedVessel[] {
    return this._extendedVessels;
  }

  public get extendedVesselsExpired(): boolean {
    return this._extendedVesselsExpired;
  }

  public get vessels(): Vessel[] {
    return this._vessels;
  }

  public get vesselsLoadingState(): boolean {
    return this._vesselsLoadingState;
  }

  public get currentVesselLoading(): boolean {
    return this._currentVesselLoading;
  }

  public get vesselTypes(): { id: number; name: string; }[] {
    return this._vesselTypes;
  }
}

export default Vessels;
