import template from "./voyage-vessel-event-port.html";
import ko from "knockout";
import _ from "underscore";
import emissionReportVesselEventsClient from "Clients/emission-report-vessel-events-client";
import portsClient from "Clients/ports-client";

function ViewModel(params) {
    var self = this;
    self.voyageVesselEvent = params.voyageVesselEvent;
    self.vesselEvent = self.voyageVesselEvent.vesselEvent;

    self.port = ko.pureComputed(function() {
        var vesselEvent = self.vesselEvent();
        return vesselEvent.port;
    });

    self.loCode = ko.pureComputed(function() {
        var port = self.port();
        return port ? port.loCode : null;
    });


    var port = self.port();

    var eventType = self.vesselEvent().type;
    if (!port && eventType === "Arrival") {
        var nextVesselEvent = self.voyageVesselEvent.nextVesselEvent();
        if (nextVesselEvent && nextVesselEvent.port && nextVesselEvent.type === "Departure") {
            port = nextVesselEvent.port;
        }
    }

    if (!port && eventType === "Departure") {
        var previousVesselEvent = self.voyageVesselEvent.previousVesselEvent();
        if (previousVesselEvent && previousVesselEvent.port && previousVesselEvent.type === "Arrival") {
            port = previousVesselEvent.port;
        }
    }

    self.inputPortName = ko.observable(port ? port.name : "");
    self.inputPortIsMrvPort = ko.observable(port ? port.isMrvPort : false);
    self.inputLoCode = ko.observable(port ? port.loCode : null);

    self.isEditing = ko.observable(false);
    self.isFindingPorts = ko.observable(false);
    self.foundPorts = ko.observable([]);

    self.findPortsTimeout = null;
    self.inputPortNameSubscription = self.inputPortName.subscribe(function(portName) {
        clearTimeout(self.findPortsTimeout);
        self.inputLoCode(null);
        self.findPortsTimeout = setTimeout(self.findPorts.bind(self), 500);
    });

    self.selectFoundPort = function(port) {
        self.inputPortName(port.name);
        self.inputPortIsMrvPort(port.isMrvPort);
        self.inputLoCode(port.loCode);
        self.save();
    }.bind(self);

    if (!self.loCode()) {
        self.findPorts();
    }
}

ViewModel.prototype.dispose = function() {
    this.inputPortNameSubscription.dispose();
};

ViewModel.prototype.startEditing = function(vm, e) {
    var $wrapper = $(e.currentTarget).closest(".edit-wrapper");
    this.isEditing(true);

    var $input = $wrapper.find("input[type=text]");
    $input.focus();
    $input.select();
};

ViewModel.prototype.cancelEditing = function(vm, e) {
    this.isEditing(false);
};

ViewModel.prototype.save = function() {
    var self = this;
    var vesselEvent = _.clone(self.vesselEvent());
    vesselEvent.port = vesselEvent.port || {};
    vesselEvent.port.name = self.inputPortName();
    vesselEvent.port.isMrvPort = self.inputPortIsMrvPort();
    vesselEvent.port.loCode = self.inputLoCode();

    emissionReportVesselEventsClient.update(vesselEvent).done(function(updatedVesselEvent) {
        self.vesselEvent(updatedVesselEvent);
        self.cancelEditing();
    });
};

ViewModel.prototype.findPorts = function() {
    var self = this;
    var inputPortName = self.inputPortName();

    if (inputPortName.length < 3) {
        self.foundPorts([]);
        return;
    }

    self.isFindingPorts(true);
    portsClient.find(inputPortName)
        .done(self.foundPorts)
        .fail(function() {
            self.foundPorts([]);
        }).always(function() {
            self.isFindingPorts(false);
        });
};

export default { viewModel: ViewModel, template: template };