import ko from "knockout";

import vesselInfoMap from "Components/vessel-info/vessel-info-map/vessel-info-map";
import vesselPerformanceStatus from "Components/vessel-performance-status/vessel-performance-status";
import vesselPerformanceStatusItem from "Components/vessel-performance-status-item/vessel-performance-status-item";
import longTrendBenchmarkLevelIcon from "Components/long-trend-benchmark-level-icon/long-trend-benchmark-level-icon";
import performanceStatusIcon from "Components/performance-status-icon/performance-status-icon";
import componentLoading from "Components/component-loading/component-loading";
import vesselEvent from "Components/vessel-event/vessel-event";
import vesselEventIcon from "Components/vessel-event-icon/vessel-event-icon";
import benefitsTable from "Components/benefits-table/benefits-table";
import benefitConsequence from "Components/benefit-consequence/benefit-consequence";
import benefitConsequenceResult from "Components/benefit-consequence-result/benefit-consequence-result";
import longTrendBenchmarkLevelSettings from "Components/long-trend-benchmark-level-settings/long-trend-benchmark-level-settings";
import tooltip from "Components/tooltip/tooltip";
import tooltipText from "Components/tooltip-text/tooltip-text";
import loggingHistoryConfig from "Components/logging-history-config/logging-history-config";
import graphConfigVariableSelection from "Components/graph-config-variable-selection/graph-config-variable-selection";
import graphConfigTimePeriod from "Components/graph-config-time-period/graph-config-time-period";
import graphConfigDataInterval from "Components/graph-config-data-interval/graph-config-data-interval";
import curveEditGraph from "Components/curves/curve-edit-graph/curve-edit-graph";
import curveGraph from "Components/curves/curve-graph/curve-graph";
import cpModuleTable from "Components/curves/cp-module-table/cp-module-table";
import curvePredictionTable from "Components/curves/curve-prediction-table/curve-prediction-table";
import cssLoader from "Components/css-loader/css-loader";
import dataPie from "Components/data-pie/data-pie";
import emissionReport from "Components/emission-report/emission-report";
import fuelConsumptionSummary from "Components/fuel-consumption-summary/fuel-consumption-summary";
import voyagesChart from "Components/emission-report/voyages-chart/voyages-chart";
import departureList from "Components/emission-report/departure-list/departure-list";
import departureListItem from "Components/emission-report/departure-list/departure-list-item/departure-list-item";
import voyageCreate from "Components/emission-report/voyage-create/voyage-create";
import voyage from "Components/emission-report/voyage/voyage";
import voyageChart from "Components/emission-report/voyage/voyage-chart/voyage-chart";
import voyageVesselEventList from "Components/emission-report/voyage/voyage-vessel-event-list/voyage-vessel-event-list";
import voyageVesselEventListItem from "Components/emission-report/voyage/voyage-vessel-event-list/voyage-vessel-event-list-item/voyage-vessel-event-list-item";
import voyageVesselEvent from "Components/emission-report/voyage/voyage-vessel-event/voyage-vessel-event";
import voyageVesselEventTimeStamp from "Components/emission-report/voyage/voyage-vessel-event/voyage-vessel-event-timestamp/voyage-vessel-event-timestamp";
import voyageVesselEventVoyage from "Components/emission-report/voyage/voyage-vessel-event/voyage-vessel-event-voyage/voyage-vessel-event-voyage";
import voyageVesselEventPort from "Components/emission-report/voyage/voyage-vessel-event/voyage-vessel-event-port/voyage-vessel-event-port";
import voyageVesselEventCargos from "Components/emission-report/voyage/voyage-vessel-event/voyage-vessel-event-cargos/voyage-vessel-event-cargos";
import voyageVesselEventDistance from "Components/emission-report/voyage/voyage-vessel-event/voyage-vessel-event-distance/voyage-vessel-event-distance";
import voyageVesselEventLocation from "Components/emission-report/voyage/voyage-vessel-event/voyage-vessel-event-location/voyage-vessel-event-location";
import fuelTankManagement from "Components/fuel-tank-management/fuel-tank-management";
import ftFuelConsumptions from "Components/fuel-tank-management/ft-fuel-consumptions/ft-fuel-consumptions";
import ftFuelConsumption from "Components/fuel-tank-management/ft-fuel-consumptions/ft-fuel-consumption/ft-fuel-consumption";
import ftOperations from "Components/fuel-tank-management/ft-operations/ft-operations";
import ftOperation from "Components/fuel-tank-management/ft-operations/ft-operation/ft-operation";
import ftSoundings from "Components/fuel-tank-management/ft-soundings/ft-soundings";
import ftSounding from "Components/fuel-tank-management/ft-soundings/ft-sounding/ft-sounding";
import flowMeterManagement from "Components/flow-meter-management/flow-meter-management";
import fmFuelConsumptions from "Components/flow-meter-management/fm-fuel-consumptions/fm-fuel-consumptions";
import fmFuelConsumptionCreate from "Components/flow-meter-management/fm-fuel-consumptions/fm-fuel-consumption-create/fm-fuel-consumption-create";
import fmFuelConsumption from "Components/flow-meter-management/fm-fuel-consumptions/fm-fuel-consumption/fm-fuel-consumption";
import fmOperations from "Components/flow-meter-management/fm-operations/fm-operations";
import fmOperation from "Components/flow-meter-management/fm-operations/fm-operation/fm-operation";
import vesselSettings from "Components/vessel-settings/vessel-settings";
import veracityKey from "Components/vessel-settings/veracity-key/veracity-key";
import generalSettings from "Components/vessel-settings/general-settings/general-settings";
import emissionReportTemplate from "Components/vessel-settings/emission-report-template/emission-report-template";
import flowMeters from "Components/vessel-settings/flow-meters/flow-meters";
import flowMeter from "Components/vessel-settings/flow-meters/flow-meter/flow-meter";
import fuelTanks from "Components/vessel-settings/fuel-tanks/fuel-tanks";
import fuelTank from "Components/vessel-settings/fuel-tanks/fuel-tank/fuel-tank";
import fuelTypes from "Components/vessel-settings/fuel-types/fuel-types";
import fuelType from "Components/vessel-settings/fuel-types/fuel-type/fuel-type";
import vesselFiles from "Components/vessel-files/vessel-files";
import inputDialog from "Components/input-dialog/input-dialog";
import vesselAdmin from "Components/vessel-administration/vessel-admin/vessel-admin";
import vesselsMenu from "Components/vessel-administration/vessels-menu/vessels-menu";
import vesselAdminTable from "Components/vessel-administration/vessel-admin-table/vessel-admin-table";
import editVessel from "Components/vessel-administration/edit-vessel/edit-vessel";
import assignVessel from "Components/vessel-administration/assign-vessel/assign-vessel";
import vesselFeature from "Components/vessel-administration/vessel-feature/vessel-feature";
import featureNotAvailable from "Components/feature-not-available/feature-not-available";
import longTrendConfig from "Components/long-trend-config/long-trend-config";
import importStatus from "Components/data-import/import-status/import-status";
import importDetails from "Components/data-import/import-details/import-details";
import importStatusTable from "Components/data-import/import-status-table/import-status-table";
import taxonomy from "Components/taxonomy/taxonomy";


import users from "Components/profile/users/users";
import userForm from "Components/profile/user-form/user-form";
import userInfo from "Components/profile/user-info/user-info";
import userList from "Components/profile/user-list/user-list";
import userApiKey from "Components/profile/user-api-key/user-api-key";


import vesselGroups from "Components/vessel-groups/vessel-groups";
import compareVessels from "Components/compare-vessels/compare-vessels";
import compareVesselsLongTrends from "Components/compare-vessels/compare-vessels-long-trends/compare-vessels-long-trends";
import compareVesselsLongTrendsGraph from "Components/compare-vessels/compare-vessels-long-trends-graph/compare-vessels-long-trends-graph";
import compareVesselsLongTrendsDetails from "Components/compare-vessels/compare-vessels-long-trends-details/compare-vessels-long-trends-details";
import compareVesselsLongTrendsDetailsGraph from "Components/compare-vessels/compare-vessels-long-trends-details-graph/compare-vessels-long-trends-details-graph";
import compareVesselsLongTrendsDetailsTable from "Components/compare-vessels/compare-vessels-long-trends-details-table/compare-vessels-long-trends-details-table";
import compareVesselsLongTrendsDetailsTableRow from "Components/compare-vessels/compare-vessels-long-trends-details-table-row/compare-vessels-long-trends-details-table-row";
import compareVesselsLongTrendsDetailsTableHeader from "Components/compare-vessels/compare-vessels-long-trends-details-table-header/compare-vessels-long-trends-details-table-header";
import companyManager from "Components/company/company-manager/company-manager";
import companyTable from "Components/company/company-table/company-table";
import companyInfo from "Components/company/company-info/company-info";
import companyForm from "Components/company/company-form/company-form";
import vesselNotes from "Components/vessel-notes/vessel-notes";
import vesselAccessGroup from "Components/vessel-access-group/vessel-access-group";
import vesselAccessGroupList from "Components/vessel-access-group/vessel-access-group-list/vessel-access-group-list";
import vesselAccessGroupEditor from "Components/vessel-access-group/vessel-access-group-editor/vessel-access-group-editor";
import vesselAccessGroupName from "Components/vessel-access-group/vessel-access-group-name/vessel-access-group-name";

import geoCoordinates from "Components/geo-coordinates/geo-coordinates";
import geoCoordinatesEdit from "Components/geo-coordinates/geo-coordinates-edit/geo-coordinates-edit";

//Kognifai specific
import "Extensions/pagination";
import curvesFrame from "Components/curves/curves-frame/curves-frame";
import treeSelect from "Components/curves/tree-select/tree-select";
import curvesAbout from "Components/curves/curves-about/curves-about";
import curvesHelp from "Components/curves/curves-help/curves-help";
ko.components.register("curves-frame", curvesFrame);
ko.components.register("tree-select", treeSelect);
ko.components.register("curves-about", curvesAbout);
ko.components.register("curves-help", curvesHelp);
//end kognifai specific


import "Bindings/all-bindings";

ko.components.register("taxonomy", taxonomy);
ko.components.register("import-details", importDetails);
ko.components.register("import-status-table", importStatusTable);
ko.components.register("import-status", importStatus);
ko.components.register("long-trend-config", longTrendConfig);
ko.components.register("feature-not-available", featureNotAvailable);
ko.components.register("vessel-feature", vesselFeature);
ko.components.register("assign-vessel", assignVessel);
ko.components.register("edit-vessel", editVessel);
ko.components.register("vessel-admin-table", vesselAdminTable);
ko.components.register("vessels-menu", vesselsMenu);
ko.components.register("vessel-admin", vesselAdmin);
ko.components.register("input-dialog", inputDialog);
ko.components.register("vessel-files", vesselFiles);
ko.components.register("fuel-type", fuelType);
ko.components.register("fuel-types", fuelTypes);
ko.components.register("fuel-tank", fuelTank);
ko.components.register("fuel-tanks", fuelTanks);
ko.components.register("flow-meter", flowMeter);
ko.components.register("flow-meters", flowMeters);
ko.components.register("general-settings", generalSettings);
ko.components.register("emission-report-template", emissionReportTemplate);
ko.components.register("vessel-settings", vesselSettings);
ko.components.register("veracity-key", veracityKey);
ko.components.register("fm-operation", fmOperation);
ko.components.register("fm-operations", fmOperations);
ko.components.register("fm-fuel-consumption", fmFuelConsumption);
ko.components.register("fm-fuel-consumption-create", fmFuelConsumptionCreate);
ko.components.register("fm-fuel-consumptions", fmFuelConsumptions);
ko.components.register("flow-meter-management", flowMeterManagement);
ko.components.register("ft-sounding", ftSounding);
ko.components.register("ft-soundings", ftSoundings);
ko.components.register("ft-operation", ftOperation);
ko.components.register("ft-operations", ftOperations);
ko.components.register("ft-fuel-consumption", ftFuelConsumption);
ko.components.register("ft-fuel-consumptions", ftFuelConsumptions);
ko.components.register("fuel-tank-management", fuelTankManagement);
ko.components.register("voyage-vessel-event-location", voyageVesselEventLocation);
ko.components.register("voyage-vessel-event-distance", voyageVesselEventDistance);
ko.components.register("voyage-vessel-event-cargos", voyageVesselEventCargos);
ko.components.register("voyage-vessel-event-port", voyageVesselEventPort);
ko.components.register("voyage-vessel-event-voyage", voyageVesselEventVoyage);
ko.components.register("voyage-vessel-event-timestamp", voyageVesselEventTimeStamp);
ko.components.register("voyage-vessel-event", voyageVesselEvent);
ko.components.register("voyage-vessel-event-list-item", voyageVesselEventListItem);
ko.components.register("voyage-vessel-event-list", voyageVesselEventList);
ko.components.register("voyage-chart", voyageChart);
ko.components.register("voyage", voyage);
ko.components.register("voyage-create", voyageCreate);
ko.components.register("departure-list-item", departureListItem);
ko.components.register("departure-list", departureList);
ko.components.register("voyages-chart", voyagesChart);
ko.components.register("fuel-consumption-summary", fuelConsumptionSummary);
ko.components.register("emission-report", emissionReport);
ko.components.register("data-pie", dataPie);
ko.components.register("vessel-info-map", vesselInfoMap);
ko.components.register("vessel-performance-status", vesselPerformanceStatus);
ko.components.register("vessel-performance-status-item", vesselPerformanceStatusItem);
ko.components.register("long-trend-benchmark-level-icon", longTrendBenchmarkLevelIcon);
ko.components.register("performance-status-icon", performanceStatusIcon);
ko.components.register("component-loading", componentLoading);
ko.components.register("vessel-event", vesselEvent);
ko.components.register("vessel-event-icon", vesselEventIcon);
ko.components.register("benefits-table", benefitsTable);
ko.components.register("benefit-consequence", benefitConsequence);
ko.components.register("benefit-consequence-result", benefitConsequenceResult);
ko.components.register("long-trend-benchmark-level-settings", longTrendBenchmarkLevelSettings);
ko.components.register("tooltip", tooltip);
ko.components.register("tooltip-text", tooltipText);
ko.components.register("logging-history-config", loggingHistoryConfig);
ko.components.register("graph-config-variable-selection", graphConfigVariableSelection);
ko.components.register("graph-config-time-period", graphConfigTimePeriod);
ko.components.register("graph-config-data-interval", graphConfigDataInterval);
ko.components.register("curve-edit-graph", curveEditGraph);
ko.components.register("curve-graph", curveGraph);
ko.components.register("cp-module-table", cpModuleTable);
ko.components.register("curve-prediction-table", curvePredictionTable);
ko.components.register("css-loader", cssLoader);
ko.components.register("users", users);
ko.components.register("user-list", userList);
ko.components.register("user-form", userForm);
ko.components.register("user-info", userInfo);
ko.components.register("user-api-key", userApiKey);
ko.components.register("vessel-groups", vesselGroups);
ko.components.register("compare-vessels", compareVessels);
ko.components.register("compare-vessels-long-trends", compareVesselsLongTrends);
ko.components.register("compare-vessels-long-trends-graph", compareVesselsLongTrendsGraph);
ko.components.register("compare-vessels-long-trends-details", compareVesselsLongTrendsDetails);
ko.components.register("compare-vessels-long-trends-details-graph", compareVesselsLongTrendsDetailsGraph);
ko.components.register("compare-vessels-long-trends-details-table", compareVesselsLongTrendsDetailsTable);
ko.components.register("compare-vessels-long-trends-details-table-row", compareVesselsLongTrendsDetailsTableRow);
ko.components.register("compare-vessels-long-trends-details-table-header", compareVesselsLongTrendsDetailsTableHeader);
ko.components.register("company-manager", companyManager);
ko.components.register("company-table", companyTable);
ko.components.register("company-info", companyInfo);
ko.components.register("company-form", companyForm);
ko.components.register("vessel-notes", vesselNotes);
ko.components.register("vessel-access-group", vesselAccessGroup);
ko.components.register("vessel-access-group-list", vesselAccessGroupList);
ko.components.register("vessel-access-group-editor", vesselAccessGroupEditor);
ko.components.register("vessel-access-group-name", vesselAccessGroupName);
ko.components.register("geo-coordinates", geoCoordinates);
ko.components.register("geo-coordinates-edit", geoCoordinatesEdit);
