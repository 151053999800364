import _ from "underscore";

// See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegExp(str) {
    return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

function replaceAll(str, find, replace) {
    return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

function addSlashes(str) {
    return (str + "").replace(/[\\"']/g, "\\$&").replace(/\u0000/g, "\\0");
}

function isNullOrWhiteSpace(str) {
    if (!_.isString(str)) return true;

    return str.replace(/\s/g, "").length < 1;
}

function isLookingLikeAnEmail(str) {
    return /(.+)@(.+){2,}\.(.+){2,}/.test(str);
}

function stringContains(str, value) {
    return str.toLowerCase().indexOf(value) !== -1;

}

function getPrettyFileSize(bytes) {
    var sizes = ["B", "KB", "MB", "GB"];
    if (bytes == 0) return "0 B";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)));

    return (bytes / Math.pow(1000, i)).toFixed(2) + " " + sizes[i];
}

function endsWith(str, value, isCaseInsensitive) {
    if (isCaseInsensitive) {
        str = str.toLowerCase();
        value = value.toLowerCase();
    }

    return str.slice(-value.length) === value;
}

function getPrettyIMO(imoNumber) {
    var prettyIMO = ("0000000" + imoNumber).slice(-7);
    return "IMO" + prettyIMO;
}

function pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
}

function equalsIgnoreCase(str1, str2) {
    var areEqual = str1 === str2;
    if (areEqual) {
        return areEqual;
    }

    if ((str1 === null) || (str2 === null)) {
        return false;
    }

    return str1.toUpperCase().localeCompare(str2.toUpperCase()) === 0;
}

export default {
    replaceAll: function(str, find, replace) {
        return replaceAll(str, find, replace);
    },
    addSlashes: function(str) {
        return addSlashes(str);
    },
    isNullOrWhiteSpace: function(str) {
        return isNullOrWhiteSpace(str);
    },
    isLookingLikeAnEmail: function(str) {
        return isLookingLikeAnEmail(str);
    },
    stringContains: function(str, value) {
        return stringContains(str, value);
    },
    getPrettyFileSize: function(bytes) {
        return getPrettyFileSize(bytes);
    },
    endsWith: function(str, value, isCaseInsensitive) {
        return endsWith(str, value, isCaseInsensitive);
    },
    getPrettyIMO: function(imoNumber) {
        return getPrettyIMO(imoNumber);
    },
    pad: function(str, max) {
        return pad(str, max);
    },
    equalsIgnoreCase: function (str1, str2) {
        return equalsIgnoreCase(str1, str2);
    },
};