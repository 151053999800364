import template from "./fm-operations.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import flowMeterOperationsClient from "Clients/fm-operations-client";
import dateHelper from "Utilities/date-helper";
import events from "App/events";
import configEmissionReportFactory from "App/config-emission-report";

function ViewModel(params) {
    var self = this;
    self.configEmissionReport = configEmissionReportFactory.get();
    self.activeFuelTypes = self.configEmissionReport.activeFuelTypes;
    self.selectedFlowMeter = params.selectedFlowMeter;
    self.departureVoyageVesselEvent = params.departureVoyageVesselEvent;
    self.endOfVoyageVesselEvent = params.endOfVoyageVesselEvent;
    self.selectedVoyageVesselEvent = params.selectedVoyageVesselEvent;
    self.isVoyageDataConfirmed = params.selectedVoyageVesselEvent.isVoyageDataConfirmed;
    self.isSaving = ko.observable(false);
    self.isLoading = ko.observable(false);
    self.flowMeterOperations = ko.observableArray([]);

    self.inputTimestampDate = ko.observable();
    self.inputTimestampTime = ko.observable();
    self.inputFuelTypeId = ko.observable(self.selectedFlowMeter().fuelTypeId);
    self.inputEstimatedFuelMassFlowRateInTonDay = ko.observable();

    self.newFlowMeterOperation = ko.pureComputed(function() {
        var selectedFlowMeter = self.selectedFlowMeter();
        if (!selectedFlowMeter) return null;

        var inputTimestampDate = self.inputTimestampDate();
        var inputTimestampTime = self.inputTimestampTime();
        var inputFuelTypeId = self.inputFuelTypeId();
        var inputEstimatedFuelMassFlowRateInTonDay = self.inputEstimatedFuelMassFlowRateInTonDay();

        var newFlowMeterOperation = {
            timestamp: dateHelper.getSubmitDateString(inputTimestampDate, inputTimestampTime),
            fuelTypeId: inputFuelTypeId,
            flowMeterId: selectedFlowMeter.id,
            estimatedFuelMassFlowRateInTonDay: inputEstimatedFuelMassFlowRateInTonDay ? parseFloat(inputEstimatedFuelMassFlowRateInTonDay) : null,
        };

        return newFlowMeterOperation;
    });

    self.isNewFlowMeterOperationValid = ko.pureComputed(function() {
        var newFlowMeterOperation = self.newFlowMeterOperation();
        return true;
    });

    self.sortedFlowMeterOperations = ko.pureComputed(function() {
        var flowMeterOperations = self.flowMeterOperations();
        return _.sortBy(flowMeterOperations, "timestamp");
    });

    self.minInputTimestamp = ko.pureComputed(function() {
        var departureVesselEvent = self.departureVoyageVesselEvent().vesselEvent();
        return departureVesselEvent.timestamp;
    });

    self.minInputTimestampDate = ko.pureComputed(function() {
        var minInputTimestamp = self.minInputTimestamp();
        return dateHelper.getFormatedDateString(minInputTimestamp);
    });

    self.maxInputTimestamp = ko.pureComputed(function() {
        var endOfVoyageVesselEvent = self.endOfVoyageVesselEvent();
        return endOfVoyageVesselEvent ? endOfVoyageVesselEvent.vesselEvent().timestamp : null;
    });

    self.maxInputTimestampDate = ko.pureComputed(function() {
        var maxInputTimestamp = self.maxInputTimestamp();
        return dateHelper.getFormatedDateString(maxInputTimestamp);
    });

    self.selectedFlowMeterBinding = self.selectedFlowMeter.subscribe(function(newFlowMeter) {
        self.inputFuelTypeId(newFlowMeter.fuelTypeId);
        self.search();
    });

    var endOfVoyageVesselEvent = self.endOfVoyageVesselEvent();
    self.queryDates = ko.observable({
        fromDate: moment.utc(self.departureVoyageVesselEvent().vesselEvent().timestamp),
        toDate: endOfVoyageVesselEvent ? moment(endOfVoyageVesselEvent.vesselEvent().timestamp).add(1, "days") : null,
    });

    self.flowMeterOperationDeletedBinding = events.flowMeterOperationDeleted.add(function(flowMeterOperation) {
        self.flowMeterOperations.remove(flowMeterOperation);
    });

    self.search();
}

ViewModel.prototype.dispose = function() {
    this.selectedFlowMeterBinding.dispose();
    this.flowMeterOperationDeletedBinding.detach();
};

ViewModel.prototype.addFlowMeterOperation = function() {
    var self = this;
    var newFlowMeterOperation = self.newFlowMeterOperation();
    self.isSaving(true);
    flowMeterOperationsClient.create(newFlowMeterOperation).done(function(data) {
        self.flowMeterOperations.push(data);
    }).always(function() {
        self.isSaving(false);
    });
};

ViewModel.prototype.search = function() {
    var self = this;
    var selectedFlowMeter = self.selectedFlowMeter();
    var queryDates = self.queryDates();
    var fromDate = dateHelper.getSubmitDateString(queryDates.fromDate);
    var toDate = dateHelper.getSubmitDateString(queryDates.toDate);

    self.isLoading(false);
    flowMeterOperationsClient.find(selectedFlowMeter.id, fromDate, toDate).done(function(data) {
        self.flowMeterOperations(data);
    }).always(function() {
        self.isLoading(false);
    });
};

export default { viewModel: ViewModel, template: template };