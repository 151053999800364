import template from "./curves-frame.html";
import ko from "knockout";
import _ from "underscore";
import vesselsClient from "Clients/vessels-client";
import Router from "@/router";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/clients/Auth.module";
import UserModule from "@/store/clients/User.module";
import VesselsModule from "@/store/clients/Vessels.module";

const Auth = getModule(AuthModule, store);
const User = getModule(UserModule, store);
const Vessels = getModule(VesselsModule, store);

function ViewModel() {
    var self = this;
    self.vessels = ko.observableArray([]);
    self.showCurves = ko.observable(false);
    self.loading = ko.observable(false);
    self.showAbout = ko.observable(false);
    self.showHelp = ko.observable(false);
    self.showProfile = ko.observable(false);
    self.showExpiredKeyWarning = ko.observable(false);

    vesselsClient.getVessels().done(function(data) {
        self.vessels(data);
    }).fail(function(response) {
        if (response.status === 503) {
            self.showExpiredKeyWarning(true);
        }
    });

    self.sortedVessels = ko.pureComputed(function() {
        var vessels = self.vessels();
        var sorted = _.sortBy(vessels, function(vessel) {
            return vessel.name.toLowerCase();
        });
        return sorted;
    });

    self.userName = ko.pureComputed(function () {
        if (!User.getUser()) return "Username missing";
        return User.userName || "Username missing";
    });

    self.userEmail = ko.pureComputed(function () {
        if (!User.getUser()) return "Email missing";
        return User.userEmail || "Email missing";
    });

    self.navigateHome =  function() {
        self.showCurves(false);
        window.history.back();
    };

    window.onpopstate = function () {
        self.showCurves(false);
    };

    self.selectVessel =  function(data) {
        Vessels.updateCurrentVessel(data);
        self.showCurves(true);
        window.history.pushState(null, "", window.location.href);
    };

    self.toggleAbout =  function() {
        self.showAbout(!self.showAbout());
    };

    self.toggleHelp =  function() {
        self.showHelp(!self.showHelp());
    };
    self.toggleProfile =  function() {
        self.showProfile(!self.showProfile());
    };

    self.logOut =  function() {
      Auth.signOut().then(() => {
        Router.push("/login");
      });
  };
}

ViewModel.prototype.dispose = function() {
};

export default { viewModel: ViewModel, template: template };
