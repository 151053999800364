import template from "./ft-fuel-consumptions.html";
import ko from "knockout";
import _ from "underscore";
import fuelTankFuelConsumptionsClient from "Clients/ft-fuel-consumptions-client";

function ViewModel(params) {
    var self = this;
    self.fuelTanks = params.fuelTanks;
    self.selectedFuelTank = params.selectedFuelTank;
    self.departureVoyageVesselEvent = params.departureVoyageVesselEvent;
    self.endOfVoyageVesselEvent = params.endOfVoyageVesselEvent;
    self.selectedVoyageVesselEvent = params.selectedVoyageVesselEvent;
    self.isLoading = ko.observable(false);
    self.fuelTankFuelConsumptions = ko.observableArray([]);
    self.isVoyageDataConfirmed = params.selectedVoyageVesselEvent.isVoyageDataConfirmed;

    self.sortedFuelTankFuelConsumptions = ko.pureComputed(function() {
        var fuelTankFuelConsumptions = self.fuelTankFuelConsumptions();
        return _.sortBy(fuelTankFuelConsumptions, "timestamp");
    });

    self.selectedFuelTankBinding = self.selectedFuelTank.subscribe(function(newFuelTank) {
        self.search();
    });

    self.search();
}

ViewModel.prototype.dispose = function() {
    this.selectedFuelTankBinding.dispose();
};

ViewModel.prototype.search = function() {
    var self = this;
    var selectedFuelTank = self.selectedFuelTank();
    var departureVoyageVesselEvent = self.departureVoyageVesselEvent();
    var selectedVoyageVesselEvent = self.selectedVoyageVesselEvent;

    self.isLoading(false);
    fuelTankFuelConsumptionsClient.find(selectedFuelTank.id, departureVoyageVesselEvent.id, selectedVoyageVesselEvent.id).done(function(data) {
        self.fuelTankFuelConsumptions(data);
    }).always(function() {
        self.isLoading(false);
    });
};

export default { viewModel: ViewModel, template: template };