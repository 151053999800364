import $ from "jquery";
import authHeader from "@/services/AuthHeader";

var Net = function () {

};

Net.prototype.ajax = function (method, url, data) {
    return $.ajax({
        type: method,
        crossDomain: false,
        url: process.env.VUE_APP_API_BASE_URL + url,
        data: JSON.stringify(data),
        contentType: "application/json; charset=utf-8",
        processData: false,
        headers: authHeader(),
        traditional: true,
    });
};

Net.prototype.post = function (url, data) {
    return this.ajax("POST", url, data);
};

Net.prototype.put = function (url, data) {
    return this.ajax("PUT", url, data);
};

Net.prototype.del = function (url, data) {
    return this.ajax("DELETE", url, data);
};

Net.prototype.get = function (url, data) {
    return $.ajax({
        method: "GET",
        data: data,
        url: process.env.VUE_APP_API_BASE_URL + url,
        cache: false,
        contentType: "application/json; charset=utf-8",
        headers: authHeader(),
        traditional: true,
    });
};

Net.prototype.fileDownload = function (url, method, data) {
    $.ajax({
        type: method,
        url: process.env.VUE_APP_API_BASE_URL + url,
        data: method === "GET" ? data : JSON.stringify(data),
        contentType: "application/json; charset=utf-8",
        headers: authHeader(),
        xhrFields: {
            responseType: "blob",
        },
        success: function (response, status, xhr) {
            var filename = "";
            
            var header = xhr.getResponseHeader("Content-Disposition");

            //get filename from "Content-Disposition" header
            if (header && header.indexOf("attachment") !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(header);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, "");
                }
            }

            var windowUrl = window.URL || window.webkitURL;
            
            var url = windowUrl.createObjectURL(response);
            var anchor = document.createElement("a");

            anchor.href = url;
            anchor.download = filename;
            anchor.click();
            
            windowUrl.revokeObjectURL(url);
        },
        error: function (error) {
            console.log(error);
        },
    });
};

Net.prototype.fileUpload = function (url, file) {
    return $.ajax({
        url: process.env.VUE_APP_API_BASE_URL + url,
        method: "POST",
        data: file,
        contentType: false,//jQuery needs this set to false when uploading form data file
        processData: false,
        headers: authHeader(),
    });
};

export default new Net();
