import template from "./fuel-tank.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import events from "App/events";
import vesselsAdministrationsClient from "Clients/vessels-administrations-client";
import logVariablesClient from "Clients/log-variables-client";
import flowMetersClient from "Clients/flow-meters-client";
import fuelConsumptionSourcesClient from "Clients/fuel-consumption-sources-client";
import fuelTypesClient from "Clients/fuel-types-client";
import fuelTanksClient from "Clients/fuel-tanks-client";
import diagnosticTypesHelper from "Utilities/diagnostic-types-helper";
import CurvePoint from "ViewModels/curves/curvePoint";

function ViewModel(params) {
    var self = this;
    self.fuelTank = params.fuelTank;
    self.fuelTypes = params.fuelTypes;
    self.isRemoving = ko.observable(false);

    self.fuelTypeName = ko.pureComputed(function() {
        var fuelTypes = self.fuelTypes();
        var fuelType = _.find(fuelTypes,
            function(item) {
                return item.id === self.fuelTank.fuelTypeId;
            });
        return fuelType.name;
    });
}

ViewModel.prototype.dispose = function() {
};

ViewModel.prototype.remove = function() {
    var self = this;
    self.isRemoving(true);
    fuelTanksClient.remove(self.fuelTank).always(function() {
        self.isRemoving(false);
    });
};

export default { viewModel: ViewModel, template: template };