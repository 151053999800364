import ko from "knockout";
import _ from "underscore";

ko.bindingHandlers.vesselFilesSort = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
        var sortColumn = valueAccessor().sortColumn;
        var column = valueAccessor().column;
        var sortAscending = valueAccessor().sortAscending;
        var folders = valueAccessor().folders;

        element.onclick = function () {
            sortAscending(!sortAscending());
            sortColumn(column);

            var currentSortIcon = $(element).find(".fileHeaderSort")[0];
            var sortIcons = $(".filesHeader").find(".fa");

            _.each(sortIcons, function (icon) {
                icon.className = "fileHeaderSort";
            });

            var className = sortAscending() ? "fa fa-sort-asc " : " fa fa-sort-desc";
            currentSortIcon.className = className + " fileHeaderSort";

            if (column === "fileName") {
                var sortedFolders = folders();

                sortedFolders.sort(function (a, b) {
                    var left = a.folderName().toLowerCase();
                    var right = b.folderName().toLowerCase();
 
                    if (left < right) return sortAscending() ? -1 : 1;
                    if (left > right) return sortAscending() ? 1 : -1;
                    return 0;
                });

                folders(sortedFolders);
            }
        };
    },
};