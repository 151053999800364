import net from "App/net";

var VesselHistoriesClient = function() {
};

VesselHistoriesClient.prototype.getVesselHistoryAdditionals = function(vesselHistoryId) {
    return net.get("/VesselHistoryAdditional/Find/" + vesselHistoryId);
};

VesselHistoriesClient.prototype.getVesselHistories = function (vesselId, fromDate, toDate) {
    var filter = {
        vesselId: vesselId,
        fromDate: fromDate,
        toDate: toDate,
    };
    return net.get("/VesselHistories/Find", filter);
};

VesselHistoriesClient.prototype.getLatestVesselHistory = function (vesselId) {
    return net.get("/VesselHistories/Latest/" + vesselId);
};

export default new VesselHistoriesClient();