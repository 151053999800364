import net from "App/net";

var DashboardClient = function () {

};

DashboardClient.prototype.getAll = function () {
    return net.get("/Dashboard");
};

DashboardClient.prototype.new = function (dashboard) {
    return net.post("/Dashboard", dashboard);
};

DashboardClient.prototype.update = function (dashboard) {
    return net.put("/Dashboard", dashboard);
};

DashboardClient.prototype.delete = function (dashboardId) {
    return net.del("/Dashboard/" + dashboardId);
};

export default new DashboardClient();