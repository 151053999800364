

































































































































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
//  utilities
import moment from "moment";

@Component({
  components: {},
})
export default class DateTimeRangePicker extends Vue {
  @Prop() disabled!: boolean;
  @PropSync("maxWidth", { default: 1000 }) dialogWidth!: number;
  @PropSync("widgetDateRange", { required: true }) syncWidgetDateRange!: any;

  selectedCalculationType = "Averaged";
  isWidgetFromDateModalActive = false;
  isWidgetFromTimeModalActive = false;
  isWidgetToDateModalActive = false;
  isWidgetToTimeModalActive = false;
  fromDate: string = moment().startOf("year").format("YYYY-MM-DD");
  fromTime = "00:00";
  toDate: string = moment().format("YYYY-MM-DD");
  toTime = "00:00";

  @Watch("syncWidgetDateRange")
  setSyncWidgetDateRange(): void {
    this.fromDate = moment(this.syncWidgetDateRange.fromDate).format("YYYY-MM-DD");
    this.fromTime = moment(this.syncWidgetDateRange.fromDate).format("HH:mm");
    this.toDate = moment(this.syncWidgetDateRange.toDate).format("YYYY-MM-DD");
    this.toTime = moment(this.syncWidgetDateRange.toDate).format("HH:mm");
  }

  get fromToDates(): { fromDate: string; toDate: string } {
    return {
      fromDate: moment(`${this.fromDate} ${this.fromTime}`).format("YYYY-MM-DDTHH:mm"),
      toDate: moment(`${this.toDate} ${this.toTime}`).format("YYYY-MM-DDTHH:mm"),
    };
  }

  save(): void {
    this.$emit("change", this.fromToDates);
  }

  mounted(): void {
    this.setSyncWidgetDateRange();
  }
}
