import net from "@/Scripts/app/net";
import Store from "@/store";

var VesselsClient = function () {

};

VesselsClient.prototype.get = function (vesselId) {
  return net.get("/Vessels/" + vesselId);
};

VesselsClient.prototype.getVessels = function (ignoreCompany = false) {
  var url = Store.getters["User/isKognifai"] ? "/Kognifai/Vessels" : `/Vessels?IgnoreCompany=${ignoreCompany}`;
  return net.get(url);
};

VesselsClient.prototype.getVesselsByCompanyId = function (id) {
  return net.get(`/Vessels?companyId=${id}`);
};

VesselsClient.prototype.getExtendedVessels = function () {
  return net.get("/Vessels/Extended");
};

VesselsClient.prototype.updateVesselDraft = function (id, draft) {
  const draftMean = {
    draftMean: draft,
  };
  return net.put("/Vessels/" + id + "/Draft", draftMean);
};

VesselsClient.prototype.updateVessel = function (vessel) {
  return net.put(`/Vessels/${vessel.id}`, vessel);
};

VesselsClient.prototype.getVesselVoyages = function (id) {
  return net.get("/Vessel/" + id + "/Voyages");
}

export default new VesselsClient();
