import template from "./users.html";
import ko from "knockout";
import koValidation from "knockout.validation";
import events from "App/events";
import UserVM from "ViewModels/user/user";
import UsersVM from "ViewModels/user/userList";

function ViewModel() {
    var self = this;

    self.viewModel = null;
    self.dialogState = ko.observable("");
    self.users = new UsersVM();

    self.error = ko.observable();

    self.viewBinding = events.userView.add(function (user) {
        self.clearError();
        self.viewModel = new UserVM(user);
        self.dialogState("VIEW");
    });

    self.editBinding = events.userEdit.add(function (user) {
        self.clearError();
        self.viewModel = new UserVM(user, self.users);
        self.dialogState("EDIT");
    });

    self.addBinding = events.userAdd.add(function () {
        self.clearError();
        self.viewModel = new UserVM(null, self.users);
        self.dialogState("ADD");
    });

    self.apiKeyBinding = events.userManageApiKey.add(function (user) {
        self.clearError();
        self.viewModel = new UserVM(user, self.users);
        self.dialogState("API");
    });

    self.deleteBinding = events.userDelete.add(function (user) {
        self.clearError();
        self.viewModel = new UserVM(user);
        self.dialogState("REMOVE");
    });
}

ViewModel.prototype.dispose = function () {
    this.viewBinding.detach();
    this.editBinding.detach();
    this.deleteBinding.detach();
    this.addBinding.detach();
    this.apiKeyBinding.detach();
};

ViewModel.prototype.createUser = function () {
    var self = this;

    self.viewModel.save().done(function (newUser) {
        events.userOnCreated.dispatch(newUser);
        self.closeDialog();
    }).fail(function (error) {
        if (error.status === 409) {
            events.userOnCreatedAlreadyExist.dispatch();
        } else {
            events.userOnCreatedFailed.dispatch();
        }
        self.handleError(error);
    });
};

ViewModel.prototype.removeUser = function () {
    var self = this;
    var user = self.viewModel.getOriginalUser();
    self.viewModel.remove()
        .done(function() {
            events.userOnRemoved.dispatch(user);
            self.closeDialog();
        })
        .fail(function(error) {
            events.userOnFailed.dispatch(error);
            self.handleError(error);
        });
};

ViewModel.prototype.editUser = function () {
    var self = this;
    var originalUser = self.viewModel.getOriginalUser();
    self.viewModel.save().done(function (updatedUser) {
        events.userOnUpdated.dispatch(originalUser, updatedUser);
        self.closeDialog();
    }).fail(function (error) {
        events.userOnFailed.dispatch(error);
        self.handleError(error);
    });
};

ViewModel.prototype.closeDialog = function () {
    this.dialogState("");
};

ViewModel.prototype.clearError = function () {
    this.error(null);
};

ViewModel.prototype.handleError = function (error) {
    if (error && error.responseJSON && error.responseJSON.message) {
        this.error(error.responseJSON.message);
    } else {
        this.error("An error occured. See log for more details.");
    }
    console.error(error);
};

export default { viewModel: ViewModel, template: template };
