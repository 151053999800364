import template from "./fm-fuel-consumption-create.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import flowMeterFuelConsumptionsClient from "Clients/fm-fuel-consumptions-client";
import dateHelper from "Utilities/date-helper";
import events from "App/events";

function FlowMeterOption(id, name) {
    this.id = id;
    this.name = name;
}

function ViewModel(params) {
    var self = this;
    self.selectedFlowMeter = params.selectedFlowMeter;
    self.flowMeterFuelConsumptions = params.flowMeterFuelConsumptions;
    self.missingFlowMeterFuelConsumptions = params.missingFlowMeterFuelConsumptions;
    self.selectedVoyageVesselEvent = params.selectedVoyageVesselEvent;
    self.vesselEvent = params.selectedVoyageVesselEvent.vesselEvent;

    self.isSaving = ko.observable(false);
    self.showNewFlowMeterFuelConsumption = ko.observable(false);

    self.inputConsumptionStartDate = ko.observable();
    self.inputConsumptionStartTime = ko.observable();
    self.inputConsumptionEndDate = ko.observable();
    self.inputConsumptionEndTime = ko.observable();
    self.inputFuelConsumptionInTon = ko.observable();

    self.newFlowMeterFuelConsumption = ko.pureComputed(function() {
        var vesselEvent = self.vesselEvent();
        var selectedFlowMeter = self.selectedFlowMeter();
        var inputConsumptionStartDate = self.inputConsumptionStartDate();
        var inputConsumptionStartTime = self.inputConsumptionStartTime();
        var inputConsumptionEndDate = self.inputConsumptionEndDate();
        var inputConsumptionEndTime = self.inputConsumptionEndTime();
        var inputFuelConsumptionInTon = self.inputFuelConsumptionInTon();

        var newFlowMeterFuelConsumption = {
            vesselEventId: vesselEvent.id,
            flowMeterId: selectedFlowMeter.id,
            startAt: dateHelper.getSubmitDateString(inputConsumptionStartDate, inputConsumptionStartTime),
            endAt: dateHelper.getSubmitDateString(inputConsumptionEndDate, inputConsumptionEndTime),
            fuelConsumptionInTon: inputFuelConsumptionInTon,
        };

        return newFlowMeterFuelConsumption;
    });

    self.overlappingFlowMeterFuelConsumption = ko.pureComputed(function() {
        var newFlowMeterFuelConsumption = self.newFlowMeterFuelConsumption();
        var flowMeterFuelConsumptions = self.flowMeterFuelConsumptions();

        var startAt = moment.utc(newFlowMeterFuelConsumption.startAt);
        var endAt = moment.utc(newFlowMeterFuelConsumption.endAt);

        var overlappingFlowMeterFuelConsumption = _.find(flowMeterFuelConsumptions, function(flowMeterFuelConsumption) {
            var flowMeterFuelConsumptionStartAt = moment.utc(flowMeterFuelConsumption.startAt);
            var flowMeterFuelConsumptionEndAt = moment.utc(flowMeterFuelConsumption.endAt);

            return startAt < flowMeterFuelConsumptionEndAt && endAt > flowMeterFuelConsumptionStartAt;
        });

        return overlappingFlowMeterFuelConsumption;
    });

    self.validationErrorMessage = ko.pureComputed(function() {
        var overlappingFlowMeterFuelConsumption = self.overlappingFlowMeterFuelConsumption();
        var missingFlowMeterFuelConsumptions = self.missingFlowMeterFuelConsumptions();
        var newFlowMeterFuelConsumption = self.newFlowMeterFuelConsumption();
        var previousVesselEvent = self.selectedVoyageVesselEvent.previousVesselEvent();
        var vesselEvent = self.vesselEvent();

        if (overlappingFlowMeterFuelConsumption && missingFlowMeterFuelConsumptions.length) {
            return "The time span is overlapping an existing fuel consumption. Please adjust.";
        }

        var minDate = moment.utc(previousVesselEvent.timestamp);
        var maxDate = moment.utc(vesselEvent.timestamp);

        var startAt = moment.utc(newFlowMeterFuelConsumption.startAt);
        var endAt = moment.utc(newFlowMeterFuelConsumption.endAt);

        if (endAt <= startAt) {
            return "End must be ahead of start.";
        }

        if (startAt < minDate || endAt > maxDate) {
            return "Start and end must be between " + dateHelper.getFormatedDateTimeString(minDate) + " and " + dateHelper.getFormatedDateTimeString(maxDate) + ".";
        }

        return "";
    });

    var initializeDates = function() {
        var missingFlowMeterFuelConsumptions = self.missingFlowMeterFuelConsumptions();
        if (missingFlowMeterFuelConsumptions.length > 0) {
            var firstMissingFlowMeterFuelConsumptions = missingFlowMeterFuelConsumptions[0];
            self.inputConsumptionStartDate(dateHelper.getFormatedDateString(firstMissingFlowMeterFuelConsumptions.startAt));
            self.inputConsumptionStartTime(dateHelper.getFormatedTimeString(firstMissingFlowMeterFuelConsumptions.startAt));
            self.inputConsumptionEndDate(dateHelper.getFormatedDateString(firstMissingFlowMeterFuelConsumptions.endAt));
            self.inputConsumptionEndTime(dateHelper.getFormatedTimeString(firstMissingFlowMeterFuelConsumptions.endAt));
        }
    };

    self.missingFlowMeterFuelConsumptionsBinding = self.missingFlowMeterFuelConsumptions.subscribe(function(missingFlowMeterFuelConsumptions) {
        initializeDates();
    });
    initializeDates();
}

ViewModel.prototype.dispose = function() {
    this.missingFlowMeterFuelConsumptionsBinding.dispose();
};

ViewModel.prototype.addFlowMeterFuelConsumption = function() {
    var self = this;
    var validationErrorMessage = self.validationErrorMessage();
    if (validationErrorMessage) return;

    var newFlowMeterFuelConsumption = self.newFlowMeterFuelConsumption();
    self.isSaving(true);
    flowMeterFuelConsumptionsClient.create(newFlowMeterFuelConsumption).done(function(data) {
        _.each(data, function(fuelConsumption) {
            events.flowMeterFuelConsumptionUpdated.dispatch(fuelConsumption);
        });
    }).always(function() {
        self.isSaving(false);
    });
};

ViewModel.prototype.cancelNewFlowMeterFuelConsumption = function() {
    this.showNewFlowMeterFuelConsumption(false);
};

ViewModel.prototype.startNewFlowMeterFuelConsumption = function() {
    this.showNewFlowMeterFuelConsumption(true);
};


export default { viewModel: ViewModel, template: template };