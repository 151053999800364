import template from "./user-form.html";
import ko from "knockout";
import koValidation from "knockout.validation";
import _ from "underscore";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/clients/User.module";

const User = getModule(UserModule, store);

function ViewModel(params) {
    var self = this;

    self.user = params.user;
    self.error = params.error;

    self.selectedRole = self.user.role;
    self.roleAlternatives = ko.observableArray(["User"]);
    self.canEditRole = ko.observable(false);

    var isSuperUser = User.userRole === "SuperUser";
    var isAdministrator = User.userRole === "Administrator";

    if (isSuperUser || isAdministrator) {
        self.roleAlternatives.push("SuperUser");
        self.canEditRole(true);
    }

    // Only an administrator can set admin level.
    if (isAdministrator) {
        self.roleAlternatives.push("KognifaiUser");
        self.roleAlternatives.push("Administrator");
    }
}

export default { viewModel: ViewModel, template: template };