import net from "App/net";
import _ from "underscore";

var ImportStatusClient = function () {

};

ImportStatusClient.prototype.getSuccessfulImports = function(partition) {
    return net.get("/SuccessfulImports/Find/" + partition);
};

ImportStatusClient.prototype.getLastNumberOfImports = function(recentDays) {
    return net.get("/SuccessfulImports/GetAll/" + recentDays);
};

ImportStatusClient.prototype.getFailedImports = function(partition) {
    if (_.isUndefined(partition)) {
        return net.get("/FailedImports");
    } else {
        return net.get("/FailedImports/Find/" + partition);
    }
};

ImportStatusClient.prototype.getRunningImports = function () {
    return net.get("/RunningImports");
};

ImportStatusClient.prototype.deleteFailedImport = function(importEntity) {
    return net.del("/FailedImports", importEntity);
};

ImportStatusClient.prototype.deleteRunningImport = function(importEntity) {
    return net.del("/RunningImports", importEntity);
};

ImportStatusClient.prototype.getVesselImportStates = function(imoNumbers) {
    var filter = {
        imoNumbers: imoNumbers,
    };
    return net.get("/VesselImportStates", filter);
};

ImportStatusClient.prototype.retryAllFailedImports = function() {
    return net.post("/FailedImports/RetryAll");
};

ImportStatusClient.prototype.downloadImportPackage = function(path) {
    return net.fileDownload("/ImportStatus/DownloadImportPackage?path=" + path);
};

ImportStatusClient.prototype.downloadZipFile = function(path) {
    return net.fileDownload("/ImportStatus/DownloadZipFile?path=" + path);
};

export default new ImportStatusClient();