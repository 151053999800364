import moment from "moment";
import _ from "underscore";
import stringHelper from "Utilities/string-helper";
import events from "App/events";
import Highcharts from "highcharts";
import colorHelper from "Utilities/color-helper";
import merge from "merge";
import addExporting from "highcharts/modules/exporting";

var isInitialized = false;
var lastZoomTime = moment.utc();
var plotlineTempMarkerId = "plotline-temp-marker";
var plotlineHoverMarkerId = "plotline-hover-marker";

addExporting(Highcharts);

function initializeHighcharts() {
    Highcharts.setOptions({
        chart: {
            type: "line",
            zoomType: "x",
            spacingRight: 20,
            marginTop: 40,
            animation: false,
            events: {
                selection: function() {
                    lastZoomTime = moment.utc();
                },
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            gridLineWidth: 1,
            type: "datetime",
            title: {
                text: null,
            },
            labels: {
                y: 35,
            },
        },
        plotOptions: {
            series: {
                enableMouseTracking: true,
                marker: {
                    enabled: false,
                },
            },
        },
        tooltip: {
            valueDecimals: 2,
        },
        exporting: {
            sourceHeight: 400,
            sourceWidth: 1000,
        },
    });
    isInitialized = true;
}

function getDefaultGenericLineChartOptions() {
    return {
        chart: {

        },
        title: {
            text: "Default",
        },
        yAxis: {
            title: {
                text: "Default",
            },
            tickPixelInterval: 20,
        },
        xAxis: {
            plotBands: [],
            plotLines: [],
        },
        plotOptions: {
            series: {
                type: "line",
                zIndex: 2,
                marker: {
                    enabled: true,
                },
                animation: false,
            },
        },
        legend: {
            enabled: false,
        },
        exporting: {
            filename: "kyma-chart",
            chartOptions: {
                legend: {
                    enabled: true,
                },
            },
        },
    };
}

function getDefaultDiagnosticSplineHighchartsOptions() {
    return {
        chart: {
            zoomType: navigator.userAgent.match(/iPad/i) != null ? null : "x",
            events: {
            },
        },
        title: {
            text: "Default",
        },
        yAxis: {
            title: {
                text: "Default",
            },
            tickPixelInterval: 20,
        },
        xAxis: {
            plotBands: [],
            plotLines: [],
            events: {
            },
            title: {
                text: null,
            },
        },
        plotOptions: {
            series: {
                type: "line",
                color: "#008000",
                zIndex: 2,
            },
        },
        exporting: {
            filename: "kyma-diagnostics-chart",
            chartOptions: {
                legend: {
                    enabled: false,
                },
                title: {
                    text: "Default",
                    style: {
                        width: "450px",
                    },
                },
            },
        },
    };
}

function getTrendLinesSeriesForLongTrendPeriods(longTrendPeriods) {
    var series = [];
    _.each(longTrendPeriods, function(longTrendPeriod, i) {
        if (!longTrendPeriod.trendline) return;

        series.push({
            name: "Trendline" + i,
            data: [
                [moment.utc(longTrendPeriod.trendline.start.x).valueOf(), longTrendPeriod.trendline.start.y],
                [moment.utc(longTrendPeriod.trendline.end.x).valueOf(), longTrendPeriod.trendline.end.y],
            ],
        });
    });
    return series;
}

function getTrendLinesSeriesForCompareLongTrendPeriods(longTrendPeriods, longTrends, vessels) {

    var longTrendPeriodsGroupedByLongTrendId = _.groupBy(longTrendPeriods, "longTrendId");

    var series = [];
    _.each(longTrendPeriodsGroupedByLongTrendId, function(longTrendPeriodGroup, longTrendId) {
        var longTrend = _.find(longTrends, function(longTrend) {
            return longTrend.id === parseInt(longTrendId);
        });

        var vessel = _.find(vessels, function(vessel) {
            return vessel.id === longTrend.vesselId;
        });
        var vesselIndex = _.indexOf(vessels, vessel);

        var serie = {
            name: vessel.name,
            data: [],
            color: colorHelper.getDistinctColor(vesselIndex),
        };

        _.each(longTrendPeriodGroup, function(longTrendPeriod) {
            if (longTrendPeriod.trendline) {
                serie.data.push(
                    [moment.utc(longTrendPeriod.trendline.start.x).valueOf(), longTrendPeriod.trendline.start.y],
                    [moment.utc(longTrendPeriod.trendline.end.x).valueOf(), longTrendPeriod.trendline.end.y],
                    [moment.utc(longTrendPeriod.trendline.end.x).valueOf(), null],
                );
            }
        });

        if (serie.data.length === 0) {
            serie.name += " (in benchmarking and trendline n/a)";
        }

        series.push(serie);
    });
    return series;
}

function getTrendLinesSeriesForCompareLastLongTrendPeriod(longTrendPeriods, longTrends, vessels, showRelativeYAxis) {
    var longTrendPeriodsGroupedByLongTrendId = _.groupBy(longTrendPeriods, "longTrendId");

    var series = [];
    _.each(longTrendPeriodsGroupedByLongTrendId, function(longTrendPeriodGroup, longTrendId) {
        var longTrend = _.find(longTrends, function(longTrend) {
            return longTrend.id === parseInt(longTrendId);
        });

        var vessel = _.find(vessels, function(vessel) {
            return vessel.id === longTrend.vesselId;
        });
        var vesselIndex = _.indexOf(vessels, vessel);

        var serie = {
            name: vessel.name,
            data: [],
            color: colorHelper.getDistinctColor(vesselIndex),
        };

        var currentLongTrendPeriod = _.find(longTrendPeriodGroup, function(longTrendPeriod) {
            return longTrendPeriod.isCurrent;
        });

        if (longTrend.performanceStatus === "BenchMarking") {
            serie.name += " (in benchmarking and trendline n/a)";
        } else {
            var durationInMonths = moment.utc(currentLongTrendPeriod.trendline.end.x).diff(currentLongTrendPeriod.trendline.start.x, "months");
            var valueDifference = currentLongTrendPeriod.trendline.end.y - currentLongTrendPeriod.trendline.start.y;
            var startYValue = showRelativeYAxis ? 0 : currentLongTrendPeriod.trendline.start.y;
            var endYValue = showRelativeYAxis ? valueDifference : currentLongTrendPeriod.trendline.end.y;
            serie.data.push(
                [0, startYValue],
                [durationInMonths, endYValue],
            );
        }

        series.push(serie);
    });
    return series;
}

function getPlotBandsForLongTrendPeriods(longTrendPeriods) {
    var bands = [];
    _.each(longTrendPeriods, function(longTrendPeriod, i) {
        bands.push({
            color: "rgba(204, 217, 204, 0)",
            from: moment.utc(longTrendPeriod.start).valueOf(),
            to: moment.utc(longTrendPeriod.end).valueOf(),
            longTrendId: longTrendPeriod.longTrendId,
            zIndex: 4,
            events: {
                click: function() {
                    // Only handle click if time since last zoom is over 500ms
                    if (moment.utc().diff(lastZoomTime) > 500) {
                        const detailsData = {
                          longTrendId: this.options.longTrendId,
                          startDate: new Date(this.options.from).toISOString(),
                          endDate: new Date(this.options.to).toISOString(),
                        };

                        events.showDiagnosticsDetails.dispatch(detailsData);
                    }
                },
                mouseover: function(e) {
                    this.svgElem.attr("fill", "rgba(204, 217, 204, 0.3)");
                },
                mouseout: function(e) {
                    this.svgElem.attr("fill", this.options.color);
                },
            },
        });
    });
    return bands;
}

function getPlotBandsForCompareLongTrendPeriods(longTrendPeriods, compareGroup) {
    var orderedLongTrendPeriods = _.sortBy(longTrendPeriods, "start");
    var minStartLongTrend = _.min(orderedLongTrendPeriods, function(longTrendPeriod) {
        return moment.utc(longTrendPeriod.start).valueOf();
    });
    var maxEndLongTrend = _.max(orderedLongTrendPeriods, function(longTrendPeriod) {
        return moment.utc(longTrendPeriod.end).valueOf();
    });

    var bands = [{
        color: "rgba(204, 217, 204, 0)",
        from: moment.utc(minStartLongTrend.start).valueOf(),
        to: moment.utc(maxEndLongTrend.end).valueOf(),
        zIndex: 4,
        events: {
            click: function() {
                // Only handle click if time since last zoom is over 500ms
                if (moment.utc().diff(lastZoomTime) > 500) {
                    events.compareGroupClicked.dispatch(compareGroup);
                }
            },
            mouseover: function(e) {
                this.svgElem.attr("fill", "rgba(204, 217, 204, 0.3)");
            },
            mouseout: function(e) {
                this.svgElem.attr("fill", this.options.color);
            },
        },
    }];

    return bands;
}

function getDataSeriesForLongTrendPeriods(longTrendPeriods) {
    var series = [];
    _.each(longTrendPeriods, function(longTrendPeriod, i) {
        var data = _.map(longTrendPeriod.longTrendData, function(point) {
            return [moment.utc(point.x).valueOf(), point.y];
        });

        series.push({
            name: "LongTrend",
            type: "line",
            color: "#800000",
            lineWidth: 1,
            zIndex: 1,
            data: data,
        });
    });
    return series;
}

function getPlotLineForVesselEvent(vesselEvent) {
    var color = vesselEvent.type == "InfoEvent" ? "#0060fe" : "#008000";
    var id = "plot-line-" + vesselEvent.id;
    var value = parseInt(moment.utc(vesselEvent.timestamp).valueOf());
    var html = "<vessel-event-icon params=\"{ type: '" + vesselEvent.type + "',  name: '" + stringHelper.addSlashes(vesselEvent.name) + "'}\"></vessel-event-icon>";

    return {
        value: value,
        color: color,
        width: 2,
        id: id,
        label: {
            rotation: 0,
            style: {
                color: color,
            },
            text: html,
            useHTML: true,
            y: -10,
            x: -10,
        },
    };
}

function getPlotLinesForVesselEvents(vesselEvents) {
    var lines = [];
    _.each(vesselEvents, function(vesselEvent, i) {
        lines.push(getPlotLineForVesselEvent(vesselEvent));
    });
    return lines;
}

function getBenefitPlotLineForVesselEvent(vesselEvent) {
    return {
        id: "benefitplotline",
        color: "#20A020",
        width: 4,
        value: vesselEvent ? parseInt(moment.utc(vesselEvent.timestamp).valueOf()) : null,
    };
}

function getPlotLineForVoyageEvent(occuredAt, eventName, id) {
    var color = "#20A020";
    return {
        color: color,
        width: 2,
        value: parseInt(moment.utc(occuredAt).valueOf()),
        label: {
            rotation: 0,
            style: {
                color: color,
            },
            text: eventName,
            y: -10,
            x: -10,
        },
        id: id,
    };
}

function getDefaultGenericPieChartOptions(name, pieSlices) {
    return {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: "pie",
        },
        title: {
            text: name,
        },
        tooltip: {
            pointFormat: "<b>{point.y:.2f} %</b>",
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    format: "{point.name}:<br />{point.y:.2f} %",
                },
                animation: false,
            },
        },
        series: [
            {
                name: name,
                colorByPoint: true,
                data: pieSlices,
            },
        ],
    };
}

function plotLineDateSelectMousedown(chart, e) {
    e = chart.pointer.normalize(e);
    var xAxis = chart.xAxis[0];
    var value = xAxis.toValue(e.chartX);
    var date = moment.utc(value);

    events.plotLineDateSelected.dispatch(date);

    xAxis.removePlotLine(plotlineTempMarkerId);
    xAxis.addPlotLine({
        value: value,
        color: "#FFAAAA",
        width: 3,
        id: plotlineTempMarkerId,
    });
}

function plotLineDateSelectCancel(chart) {
    var xAxis = chart.xAxis[0];
    xAxis.removePlotLine(plotlineTempMarkerId);
}

function getRegressionSerie(data, options) {
    var defaultSerie = {
        data: data,
        lineWidth: 2,
        marker: { enabled: false },
        isRegressionLine: true,
        enableMouseTracking: false,
        type: "spline",
        showInLegend: false,
        color: "blue",
    };
    return merge(defaultSerie, options);
}

function getScatterDataSerie(data, options) {
    var defaultSerie = {
        type: "scatter",
        data: data,
        color: "#7cb5ec",
        marker: {
            symbol: "circle",
        },
    };
    return merge(defaultSerie, options);
}

export default {
getDefaultDiagnosticSplineHighchartsOptions: function (vesselEvent) {
    if (!isInitialized) initializeHighcharts();
    return getDefaultDiagnosticSplineHighchartsOptions();
},
getTrendLinesSeriesForLongTrendPeriods: function (longTrendPeriods) {
    return getTrendLinesSeriesForLongTrendPeriods(longTrendPeriods);
},
getTrendLinesSeriesForCompareLongTrendPeriods: function (longTrendPeriods, longTrends, vessels) {
    return getTrendLinesSeriesForCompareLongTrendPeriods(longTrendPeriods, longTrends, vessels);
},
getTrendLinesSeriesForCompareLastLongTrendPeriod: function (longTrendPeriods, longTrends, vessels, showRelativeYAxis) {
    return getTrendLinesSeriesForCompareLastLongTrendPeriod(longTrendPeriods, longTrends, vessels, showRelativeYAxis);
},
getPlotBandsForLongTrendPeriods: function (longTrendPeriods) {
    return getPlotBandsForLongTrendPeriods(longTrendPeriods);
},
getPlotBandsForCompareLongTrendPeriods: function (longTrendPeriods, compareGroup) {
    return getPlotBandsForCompareLongTrendPeriods(longTrendPeriods, compareGroup);
},
getDataSeriesForLongTrendPeriods: function (longTrendPeriods) {
    return getDataSeriesForLongTrendPeriods(longTrendPeriods);
},
getPlotLineForVesselEvent: function (vesselEvent) {
    return getPlotLineForVesselEvent(vesselEvent);
},
getPlotLinesForVesselEvents: function (vesselEvents) {
    return getPlotLinesForVesselEvents(vesselEvents);
},
getBenefitPlotLineForVesselEvent: function (vesselEvent) {
    return getBenefitPlotLineForVesselEvent(vesselEvent);
},
getPlotLineForVoyageEvent: function (occuredAt, eventName, id) {
    return getPlotLineForVoyageEvent(occuredAt, eventName, id);
},
getDefaultGenericLineChartOptions: function () {
    if (!isInitialized) initializeHighcharts();
    return getDefaultGenericLineChartOptions();
},
getDefaultGenericPieChartOptions: function (name, pieSlices) {
    if (!isInitialized) initializeHighcharts();
    return getDefaultGenericPieChartOptions(name, pieSlices);
},
plotLineDateSelectMousedown: function (chart, e) {
    return plotLineDateSelectMousedown(chart, e);
},
plotLineDateSelectCancel: function (chart) {
    return plotLineDateSelectCancel(chart);
},
getRegressionSerie: function(data, options) {
    return getRegressionSerie(data, options);
},
getScatterDataSerie: function(data, options) {
    return getScatterDataSerie(data, options);
},
};
