import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { VesselAccessGroup } from "@/types/vesselAccessGroup";
import vesselCategoriesClient from "Clients/vessel-categories-client";


@Module({ namespaced: true, name: "VesselAccessGroups" })
class VesselAccessGroups extends VuexModule {
  private _vesselAccessGroupsExpired = true;
  private _vesselAccessGroups: VesselAccessGroup[] = [];

  @Mutation
  public REFRESH_GROUPS(groups: VesselAccessGroup[]): void {
    this._vesselAccessGroupsExpired = false;
    this._vesselAccessGroups = groups;
  }

  @Mutation
  public EXPIRE_DATA(): void {
    this._vesselAccessGroupsExpired = true;
  }

  @Action({ rawError: true })
  public async refreshVesselAccessGroups(): Promise<void> {
    let data = [];
    try {
      data = await vesselCategoriesClient.getAll();
    } catch (error) {
      console.warn(error);
    }
    this.context.commit("REFRESH_GROUPS", data);
  }

  @Action({ rawError: true })
  public async getAll(): Promise<VesselAccessGroup[]> {
    if (!this._vesselAccessGroupsExpired) {
      return this._vesselAccessGroups;
    } else {
      await this.refreshVesselAccessGroups();
      return this._vesselAccessGroups;
    }
  }

  get vesselAccessGroups(): VesselAccessGroup[] {
    return this._vesselAccessGroups;
  }
  get vesselAccessGroupsCount(): number {
    return this._vesselAccessGroups.length;
  }
}

export default VesselAccessGroups;
