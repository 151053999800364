import template from "./curve-prediction-table.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import stringHelper from "Utilities/string-helper";
import curvePredictionHelper from "Utilities/curve-prediction-helper";
import CurveVM from "ViewModels/curves/curve";

function ViewModel(params) {
    var self = this;

    self.selectedCurve = params.selectedCurve;
    self.curveData = params.curveData;
    self.curveDataStatistics = params.curveDataStatistics;
    self.variableDataList = params.variableDataList;
    self.inputValue = ko.observable();
    self.prediction = ko.observable();
    self.isInputValid = ko.observable(true);

    self.predictions = ko.observableArray([]);

    self.curveMode = ko.pureComputed(function () {
        var curve = self.curve();

        return curve.curveMode;
    });

    self.curve = ko.pureComputed(function() {
        var curve = self.selectedCurve().curve();

        return curve;
    });

    self.xAxisLogVariable = ko.pureComputed(function () {
        var variableDataList = self.variableDataList();
        var xAxisLogVariableId = self.curve().xAxisLogVariableId;

        return _.find(variableDataList, function (variable) { return variable.id === xAxisLogVariableId; });
    });

    self.yAxisLogVariable = ko.pureComputed(function () {
        var variableDataList = self.variableDataList();
        var yAxisLogVariableId = self.curve().yAxisLogVariableId;

        return _.find(variableDataList, function (variable) { return variable.id === yAxisLogVariableId; });
    });

    self.curveFit = ko.pureComputed(function () {
        var curveFit = self.curve().curveFit.toLowerCase();
        return curveFit;
    });

    self.regressionOrder = ko.pureComputed(function () {
        var regressionOrder = self.curve().regressionOrder;
        return regressionOrder;
    });

    self.predictionText = ko.pureComputed(function () {
        var prediction = self.prediction();

        return !_.isUndefined(prediction) ? prediction.toFixed(2) : "";
    });

    self.inputValueBinding = self.inputValue.subscribe(function (value) {
        var curveData = self.curveData();
        var curveFit = self.curveFit();
        var regressionOrder = self.regressionOrder();
        var isNullOrWhiteSpace = stringHelper.isNullOrWhiteSpace(value);
        value = parseFloat(value);

        if (!isNullOrWhiteSpace && _.isNaN(value)) {
            self.isInputValid(false);
        } else {
            var curveDataArray = curvePredictionHelper.getDataArrayFromDataPointObjects(curveData);
            var regressionData = curvePredictionHelper.getRegressionData(curveDataArray, curveFit, regressionOrder);

            var predictionPoint = curvePredictionHelper.getPredictedPoint(value, regressionData);
            self.isInputValid(true);

            self.prediction(predictionPoint[1]);
            events.curvePredictionUpdated.dispatch([predictionPoint]);
        }
    });

    self.removePoint = function (point) {
        var curve = self.curve();
        self.predictions.remove(point);

        var indexOfCurvePoint = -1;
        for (var i = 0; i < curve.points.length; i++) {
            if (curve.points[i].x === point[0]) {
                indexOfCurvePoint = i;
                break;
            }
        }

        curve.points.splice(indexOfCurvePoint, 1);


        new CurveVM(curve).save(curve);
    };

    self.yAxisUnitCaption = ko.pureComputed(function () {
        var curveMode = self.curveMode();

        if (curveMode === "TimeCharter" || curveMode === "SpotMarket") {
            return "ton/day";
        }

        return self.yAxisLogVariable().unit.caption;
    });

    self.sortedPredictions = ko.pureComputed(function() {
        var predictions = self.predictions();

        var sorted = predictions.sort(function (a, b) { return a[0] - b[0]; });
        return sorted;
    });

    self.addPredictions();

    self.isPredictedValueOutlier = ko.pureComputed(function() {
        var predictedValue = self.prediction();
        if (_.isUndefined(predictedValue) || _.isNaN(predictedValue)) return false;

        return self.isOutlier(predictedValue);
    });

    self.hasAnyPredictedOutliers = ko.pureComputed(function() {
        var isPredictedValueOutlier = self.isPredictedValueOutlier();
        var predictions = self.predictions();

        return isPredictedValueOutlier ||
            _.any(predictions,
                function(prediction) {
                    return prediction[2];
                });
    });
}

ViewModel.prototype.addPoint = function () {
    var self = this;
    var value = self.inputValue();
    var floatValue = parseFloat(value);
    var curve = self.curve();
    var curveData = self.curveData();
    var curveFit = self.curveFit();
    var regressionOrder = self.regressionOrder();

    var pointExists = _.some(curve.points, function (point) { return point.x === floatValue; });

    if (!_.isNaN(floatValue) && !pointExists) {
        var curvePoint = { x: floatValue, y: 0 };
        var prediction = curvePredictionHelper.predictRegressionValue(floatValue, curveData, curveFit, regressionOrder);

        self.predictions.push([floatValue, prediction]);
        curve.points.push(curvePoint);

        new CurveVM(curve).save(curve);
    }
};

ViewModel.prototype.addPredictions = function () {
    var self = this;
    var points = self.curve().points;
    var curveFit = self.curveFit();
    var regressionOrder = self.regressionOrder();
    var curveData = self.curveData();
    var predictions = [];

    var dataArray = curvePredictionHelper.getDataArrayFromDataPointObjects(curveData);

    _.each(points, function (point) {
        var prediction = curvePredictionHelper.predictRegressionValue(point.x, dataArray, curveFit, regressionOrder);
        var isOutlier = self.isOutlier(prediction);
        predictions.push([point.x, prediction, isOutlier]);
    });

    self.predictions(predictions);
};

ViewModel.prototype.isOutlier = function(value) {
    var self = this;
    var curveDataStatistics = self.curveDataStatistics();

    var isOutlier = curvePredictionHelper.isOutlier(value,
        curveDataStatistics.mean,
        curveDataStatistics.std,
        curveDataStatistics.N);

    return isOutlier;
};

ViewModel.prototype.dispose = function () {
    this.inputValueBinding.dispose();
};

export default { viewModel: ViewModel, template: template };