import ko from "knockout";
import vesselsAdministrationsClient from "Clients/vessels-administrations-client";
import logVariablesClient from "Clients/log-variables-client";
import cargoUnitsClient from "Clients/cargo-units-client";
import fuelTypesClient from "Clients/fuel-types-client";
import fuelTanksClient from "Clients/fuel-tanks-client";
import flowMetersClient from "Clients/flow-meters-client";
import _ from "underscore";

import Store from "@/store";

function toIdMap(arrayOfObjectsWithId) {
    var map = {};
    _.each(arrayOfObjectsWithId,
        function(item) {
            map[item.id] = item;
        });
    return map;
}

var ConfigEmissionReport = function() {
    var self = this;
    self.baseUrl = process.env.VUE_APP_BASE_URL;
    self.apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
    self.currentVessel = Store.getters["Vessels/currentVessel"];

    self.voyageTypes = ["Laden", "Ballast"];
    self.vessel = ko.observable();
    self.gpsSpeedLogVariable = ko.observable();
    self.flowMetersMap = ko.observable();
    self.fuelTanksMap = ko.observable();
    self.fuelTypesMap = ko.observable();
    self.cargoUnitsMap = ko.observable();

    self.isLoadingVessel = ko.observable(true);
    self.isLoadingGpsSpeedLogVariable = ko.observable(true);
    self.isLoadingCargoUnits = ko.observable(true);
    self.isLoadingFlowMeters = ko.observable(true);
    self.isLoadingFuelTanks = ko.observable(true);
    self.isLoadingFuelTypes = ko.observable(true);

    self.activeFlowMeters = ko.pureComputed(function() {
        var flowMetersMap = self.flowMetersMap();
        return _.filter(flowMetersMap, function(flowMeter) {
            return !flowMeter.isDeactivated;
        });
    });

    self.activeFuelTanks = ko.pureComputed(function() {
        var fuelTanksMap = self.fuelTanksMap();
        return _.filter(fuelTanksMap, function(fuelTank) {
            return !fuelTank.isDeactivated;
        });
    });

    self.activeFuelTypes = ko.pureComputed(function() {
        var fuelTypesMap = self.fuelTypesMap();
        return _.filter(fuelTypesMap, function(fuelType) {
            return !fuelType.isDeactivated;
        });
    });

    self.isLoading = ko.pureComputed(function() {
        var isLoadingVessel = self.isLoadingVessel();
        var isLoadingGpsSpeedLogVariable = self.isLoadingGpsSpeedLogVariable();
        var isLoadingCargoUnits = self.isLoadingCargoUnits();
        var isLoadingFlowMeters = self.isLoadingFlowMeters();
        var isLoadingFuelTanks = self.isLoadingFuelTanks();
        var isLoadingFuelTypes = self.isLoadingFuelTypes();

        return isLoadingVessel ||
            isLoadingGpsSpeedLogVariable ||
            isLoadingCargoUnits ||
            isLoadingFlowMeters ||
            isLoadingFuelTanks ||
            isLoadingFuelTypes;
    });

    self.load();
};

ConfigEmissionReport.prototype.load = function() {
    this.loadVessel();
    this.loadGpsSpeedLogVariable();
    this.loadFlowMeters();
    this.loadFuelTanks();
    this.loadCargoUnits();
    this.loadFuelTypes();
};

ConfigEmissionReport.prototype.loadVessel = function() {
    var self = this;
    self.isLoadingVessel(true);

    vesselsAdministrationsClient.get(self.currentVessel.id)
        .done(self.vessel)
        .always(function() {
            self.isLoadingVessel(false);
        });
};

ConfigEmissionReport.prototype.loadGpsSpeedLogVariable = function () {
    var self = this;
    self.isLoadingGpsSpeedLogVariable(true);
    logVariablesClient.findGpsSpeedVariable(self.currentVessel.id)
        .done(self.gpsSpeedLogVariable)
        .always(function () {
            self.isLoadingGpsSpeedLogVariable(false);
        });
};

ConfigEmissionReport.prototype.loadFlowMeters = function() {
    var self = this;
    self.isLoadingFlowMeters(true);
    flowMetersClient.find(self.currentVessel.id)
        .done(function(data) {
            self.flowMetersMap(toIdMap(data));
        })
        .always(function() {
            self.isLoadingFlowMeters(false);
        });
};

ConfigEmissionReport.prototype.loadFuelTanks = function() {
    var self = this;
    self.isLoadingFuelTanks(true);
    fuelTanksClient.find(self.currentVessel.id)
        .done(function(data) {
            self.fuelTanksMap(toIdMap(data));
        })
        .always(function() {
            self.isLoadingFuelTanks(false);
        });
};

ConfigEmissionReport.prototype.loadCargoUnits = function() {
    var self = this;
    self.isLoadingCargoUnits(true);
    cargoUnitsClient.getAll()
        .done(function(data) {
            self.cargoUnitsMap(toIdMap(data));
        })
        .always(function() {
            self.isLoadingCargoUnits(false);
        });
};

ConfigEmissionReport.prototype.loadFuelTypes = function() {
    var self = this;
    self.isLoadingFuelTypes(true);
    fuelTypesClient.getAll()
        .done(function(data) {
            self.fuelTypesMap(toIdMap(data));
        })
        .always(function() {
            self.isLoadingFuelTypes(false);
        });
};

var configEmissionReportInstance = null;
export default {
    get: function() {
        if (!configEmissionReportInstance) {
            configEmissionReportInstance = new ConfigEmissionReport();
        }
        return configEmissionReportInstance;
    },
};
