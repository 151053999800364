import template from "./voyage-vessel-event-list.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import emissionReportVesselEventsClient from "Clients/emission-report-vessel-events-client";
import dateHelper from "Utilities/date-helper";
import events from "App/events";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

function findLastVoyageVesselEventByTypes(voyageVesselEvents, types) {
    var found = _.find(voyageVesselEvents,
        function(voyageVesselEvent) {
            var vesselEventType = voyageVesselEvent.vesselEvent().type;
            return _.contains(types, vesselEventType);
        });
    return found;
}

function ViewModel(params) {
    var self = this;
    self.voyageVesselEvents = params.voyageVesselEvents;
    self.isVoyageInformationConfirmed = params.isVoyageInformationConfirmed;
    self.isVoyageDataConfirmed = params.isVoyageDataConfirmed;
    self.departureVoyageVesselEvent = params.departureVoyageVesselEvent;
    self.endOfVoyageVesselEvent = params.endOfVoyageVesselEvent;
    self.arrivalVoyageVesselEvent = params.arrivalVoyageVesselEvent;
    self.requireEndBunkering = params.requireEndBunkering;
    self.requireEndAnchoring = params.requireEndAnchoring;
    self.requireEndDrifting = params.requireEndDrifting;
    self.requireStartBunkering = params.requireStartBunkering;
    self.requireStartAnchoring = params.requireStartAnchoring;
    self.requireStartDrifting = params.requireStartDrifting;

    self.isCreatingVesselEventType = ko.observable();

    self.inputVesselEventDate = ko.observable();
    self.inputVesselEventTime = ko.observable();

    self.plotLineDateSelectedBinding = events.plotLineDateSelected.add(function(selectedDate) {
        self.inputVesselEventDate(dateHelper.getFormatedDateString(selectedDate));
        self.inputVesselEventTime(dateHelper.getFormatedTimeString(selectedDate));
    });

    self.sortedVoyageVesselEvents = ko.pureComputed(function() {
        var voyageVesselEvents = self.voyageVesselEvents();
        return _.sortBy(voyageVesselEvents, function(voyageVesselEvent) {
            return voyageVesselEvent.vesselEvent().timestamp;
        });
    });

    self.sortedVesselEvents = ko.pureComputed(function() {
        var voyageVesselEvents = self.sortedVoyageVesselEvents();
        return _.map(voyageVesselEvents, function(voyageVesselEvent) {
            return voyageVesselEvent.vesselEvent();
        });
    });

    self.isInputTimestampValid = ko.pureComputed(function() {
        var inputVesselEventDate = self.inputVesselEventDate();
        var inputVesselEventTime = self.inputVesselEventTime();
        var vesselEvents = self.sortedVesselEvents();
        var eventType = self.isCreatingVesselEventType();
        var departureVoyageVesselEvent = self.departureVoyageVesselEvent();
        var endOfVoyageVesselEvent = self.endOfVoyageVesselEvent();
        var arrivalVoyageVesselEvent = self.arrivalVoyageVesselEvent();
        var requireStartBunkering = self.requireStartBunkering();
        var requireStartAnchoring = self.requireStartAnchoring();
        var requireStartDrifting = self.requireStartDrifting();

        var timestampMoment = dateHelper.getDateTime(inputVesselEventDate, inputVesselEventTime);
        if (!timestampMoment.isValid()) return false;

        var allowedFromMoment = moment.utc(departureVoyageVesselEvent.vesselEvent().timestamp);

        var allowedToMoment = endOfVoyageVesselEvent ? moment.utc(endOfVoyageVesselEvent.vesselEvent().timestamp) : null;

        if ((eventType === "Arrival" || eventType === "Departure") && vesselEvents.length > 1) {
            var allowedFromVesselEventIndex = endOfVoyageVesselEvent ? vesselEvents.length - 2 : vesselEvents.length - 1;
            var allowedFromVesselEvent = vesselEvents[allowedFromVesselEventIndex];
            allowedFromMoment = moment.utc(allowedFromVesselEvent.timestamp);
        }

        if (eventType !== "Arrival" && eventType !== "Departure" && arrivalVoyageVesselEvent) {
            allowedToMoment = moment.utc(arrivalVoyageVesselEvent.vesselEvent().timestamp);
        }

        if (timestampMoment <= allowedFromMoment) {
            return false;
        }

        if (allowedToMoment && timestampMoment >= allowedToMoment) {
            return false;
        }

        var sameTimestampVesselEvent = _.find(vesselEvents,
            function(vesselEvent) {
                var vesselEventTimestampMoment = moment.utc(vesselEvent.timestamp);
                return vesselEventTimestampMoment.isSame(timestampMoment);
            });

        if (sameTimestampVesselEvent) {
            return false;
        }

        var leftOfTimestampVesselEventIndex = _.findLastIndex(vesselEvents,
            function(vesselEvent) {
                var vesselEventTimestampMoment = moment.utc(vesselEvent.timestamp);
                return vesselEventTimestampMoment < timestampMoment;
            });
        var leftOfTimestampVesselEvent = vesselEvents[leftOfTimestampVesselEventIndex];

        var rightOfTimestampVesselEvent = _.find(vesselEvents,
            function(vesselEvent) {
                var vesselEventTimestampMoment = moment.utc(vesselEvent.timestamp);
                return vesselEventTimestampMoment > timestampMoment;
            });

        var leftEventType = leftOfTimestampVesselEvent.type;
        var rightEventType = rightOfTimestampVesselEvent ? rightOfTimestampVesselEvent.type : null;
        if (eventType === "EndBunkering" && (leftEventType !== "StartBunkering" || rightEventType === "EndBunkering")) {
            return false;
        }

        if (eventType === "EndAnchoring" && (leftEventType !== "StartAnchoring" || rightEventType === "EndAnchoring")) {
            return false;
        }

        if (eventType === "EndDrifting" && (leftEventType !== "StartDrifting" || rightEventType === "EndDrifting")) {
            return false;
        }

        if ((eventType === "StartBunkering" || eventType === "StartAnchoring" || eventType === "StartDrifting") &&
            (leftEventType === "StartBunkering" || leftEventType === "StartAnchoring" || leftEventType === "StartDrifting")) {
            return false;
        }

        if (requireStartBunkering && rightEventType !== "EndBunkering") {
            return false;
        }

        if (requireStartAnchoring && rightEventType !== "EndAnchoring") {
            return false;
        }

        if (requireStartDrifting && rightEventType !== "EndDrifting") {
            return false;
        }

        return true;
    });
}

ViewModel.prototype.dispose = function() {
    this.plotLineDateSelectedBinding.detach();
};

ViewModel.prototype.startCreatingVesselEvent = function(vesselEventType) {
    this.isCreatingVesselEventType(vesselEventType);
    events.dateSelectStarted.dispatch();
};

ViewModel.prototype.startCreatingDeparture = function() {
    this.startCreatingVesselEvent("Departure");
};

ViewModel.prototype.startCreatingArrival = function() {
    this.startCreatingVesselEvent("Arrival");
};

ViewModel.prototype.startCreatingStartBunkering = function() {
    this.startCreatingVesselEvent("StartBunkering");
};

ViewModel.prototype.startCreatingEndBunkering = function() {
    this.startCreatingVesselEvent("EndBunkering");
};

ViewModel.prototype.startCreatingStartAnchoring = function() {
    this.startCreatingVesselEvent("StartAnchoring");
};

ViewModel.prototype.startCreatingEndAnchoring = function() {
    this.startCreatingVesselEvent("EndAnchoring");
};

ViewModel.prototype.startCreatingStartDrifting = function() {
    this.startCreatingVesselEvent("StartDrifting");
};

ViewModel.prototype.startCreatingEndDrifting = function() {
    this.startCreatingVesselEvent("EndDrifting");
};

ViewModel.prototype.cancelCreatingVesselEvent = function() {
    this.isCreatingVesselEventType(null);
    events.dateSelectEnded.dispatch();
};

ViewModel.prototype.createVesselEvent = function() {
    var self = this;
    var vesselEvent = {};
    vesselEvent.vesselId = Vessels.currentVessel.id;
    vesselEvent.timestamp = dateHelper.getSubmitDateString(self.inputVesselEventDate(), self.inputVesselEventTime());
    vesselEvent.type = self.isCreatingVesselEventType();

    emissionReportVesselEventsClient.create(vesselEvent).done(function(createdVesselEvent) {
        self.cancelCreatingVesselEvent();
    });
};

export default { viewModel: ViewModel, template: template };