import ko from "knockout";
import moment from "moment";

ko.bindingHandlers.time = {
    update: function(element, valueAccessor) {
        var date = ko.utils.unwrapObservable(valueAccessor());
        var formattedDate = date ? moment.utc(date).format("HH:mm") : "";

        ko.bindingHandlers.text.update(element, function() { return formattedDate; });
    },
};