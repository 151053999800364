import template from "./long-trend-benchmark-level-icon.html";
import ko from "knockout";
import events from "App/events";

function ViewModel(params) {
    var self = this;
    self.longTrend = params.longTrend;

    self.useManualBenchmarkLevel = ko.pureComputed(function () {
        var longTrend = self.longTrend();
        return longTrend.useManualBenchmarkLevel;
    });

    self.benchmarkLevelTooltipText = ko.pureComputed(function () {
        var longTrend = self.longTrend();
        if (longTrend.useManualBenchmarkLevel) {
            return "Using manual benchmark level: " + longTrend.manualBenchmarkLevel + "%";
        } else {
            return "Using automatic calculated benchmark level";
        }
    });
}

ViewModel.prototype.dispose = function () {
};

ViewModel.prototype.showBenchmarkLevelToopltip = function (vm, e) {
    var element = e.currentTarget;
    events.showTooltip.dispatch("tooltip-text", { text: this.benchmarkLevelTooltipText() }, element);
};

ViewModel.prototype.closeToopltip = function () {
    events.closeTooltip.dispatch();
};

export default { viewModel: ViewModel, template: template };