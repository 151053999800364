import template from "./fuel-type.html";
import ko from "knockout";
import fuelTypesClient from "Clients/fuel-types-client";

function ViewModel(params) {
    var self = this;
    self.fuelType = params.fuelType;
    self.isRemoving = ko.observable(false);
}

ViewModel.prototype.dispose = function() {
};

ViewModel.prototype.remove = function() {
    var self = this;
    self.isRemoving(true);
    fuelTypesClient.remove(self.fuelType).always(function() {
        self.isRemoving(false);
    });
};

export default { viewModel: ViewModel, template: template };