import template from "./curve-edit-graph.html";
import ko from "knockout";
import _ from "underscore";
import highchartsHelper from "Utilities/highcharts-helper";
import curvePredictionHelper from "Utilities/curve-prediction-helper";
import Store from "@/store";

function ViewModel(params) {
    var self = this;

    self.curveMode = ko.utils.unwrapObservable(params.curveMode);
    self.referenceCurveDataPoints = params.referenceCurveDataPoints;
    self.xAxisLogVariable = params.xAxisLogVariable;
    self.yAxisLogVariable = params.yAxisLogVariable;
    self.curveFit = params.curveFit;
    self.regressionOrder = params.regressionOrder;
    self.forceTonDayUnit = self.curveMode === "TimeCharter" || self.curveMode === "SpotMarket";
    self.isKognifai = (Store.getters["User/isKognifai"]) ? true : false;
    self.chart = ko.observable();

    self.chartInfo = ko.pureComputed(function() {
        var curveMode = self.curveMode;
        var xAxisVariable = self.xAxisLogVariable();
        var yAxisVariable = self.yAxisLogVariable();
        var curveFit = self.curveFit().toLowerCase();
        var referenceCurveDataPoints = self.referenceCurveDataPoints();
        var order = self.regressionOrder();
        var series = [];

        if (referenceCurveDataPoints.length > 1 && curveMode !== "SpotMarket") {
            var referenceCurveRegressionData =
                curvePredictionHelper.getRegressionData(referenceCurveDataPoints, curveFit, order);

            var referenceCurveSerieData = curvePredictionHelper.getPredictionSerieData(referenceCurveRegressionData);
            var referenceCurveSerie = highchartsHelper.getRegressionSerie(referenceCurveSerieData,
                {
                    name: "Reference curve",
                    color: "rgba(223, 83, 83, .9)",
                    showInLegend: true,
                });

            var XMin = _.min(referenceCurveDataPoints, function(point) { return point[0]; })[0];
            var XMax = _.max(referenceCurveDataPoints, function(point) { return point[0]; })[0];
            var diff = Math.abs(XMax - XMin);
            XMax += diff / 2;
            XMin -= diff / 2;

            var extendedReferenceCurveSerieData =
                curvePredictionHelper.getPredictionSerieData(referenceCurveRegressionData, XMin, XMax);
            var extendedReferenceCurveSerie = highchartsHelper.getRegressionSerie(extendedReferenceCurveSerieData,
                {
                    name: "Forecast",
                    showInLegend: true,
                    color: "rgba(223, 83, 83, .9)",
                    dashStyle: "dash",
                    lineWidth: 1,
                    zIndex: 0,
                    linkedTo: ":previous",
                });

            series.push(referenceCurveSerie);
            series.push(extendedReferenceCurveSerie);

            var curveDataSerie = highchartsHelper.getScatterDataSerie(referenceCurveDataPoints,
                {
                    name: "Curve points",
                });
            series.push(curveDataSerie);
        }

        var yAxisUnitCaption = !yAxisVariable ? "" : self.forceTonDayUnit ? "ton/day" : yAxisVariable.unit.caption;

        var chartInfo = {
            xAxis: {
                title: {
                    text: xAxisVariable ? xAxisVariable.name + " (" + xAxisVariable.unit.caption + ")" : "",
                },
                type: !xAxisVariable && yAxisVariable ? "datetime" : "",
            },
            yAxis: {
                title: {
                    text: yAxisVariable ? yAxisVariable.name + " (" + yAxisUnitCaption + ")" : "",
                },
                plotLines: [
                    {
                        value: 0,
                        width: 1,
                        color: "#808080",
                    },
                ],
            },
            legend: {
                enabled: true,
                layout: "vertical",
                align: "right",
                verticalAlign: "middle",
                borderWidth: 0,
            },
            series: series,
        };

        return chartInfo;
    });

    self.highchartsOptions = ko.computed(function() {
        var chartInfo = self.chartInfo();
        var options = highchartsHelper.getDefaultGenericLineChartOptions();

        options.title.text = null;
        options.series = chartInfo.series;
        options.xAxis = chartInfo.xAxis;
        options.yAxis = chartInfo.yAxis;
        options.plotLines = chartInfo.plotLines;
        options.legend.enabled = true;

        //set the preview chart heigth to 300 to fit better in the kognifai sidebar design
        if (self.isKognifai)
            options.chart.height = 300;

        return options;
    });
}

export default { viewModel: ViewModel, template: template };
