import "Bindings/fileUpload";
import "Bindings/draggableFile";
import "Bindings/vesselFilesSort";

import template from "./vessel-files.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import vesselFilesClient from "Clients/vessel-files-client";
import stringHelper from "Utilities/string-helper";
import FileVM from "ViewModels/vesselFiles/file";
import FolderVM from "ViewModels/vesselFiles/folder";

function ViewModel(params) {
    var self = this;

    self.vesselId = params.vesselId;
    self.vesselName = params.vesselname;
    //self.vesselFileSizeLimitInMB = params.vesselFileSizeLimitInMB;
    //hardcoded value for now. This is same value as in Web.config
    self.vesselFileSizeLimitInMB = 1000;
    self.newFolderHelpText = "Note: A folder will not be saved until you upload a file to it.";
    self.tooltipHelpText = "Here you can store PDF, Word and Excel documents. Please be aware that files you store here are instantly available to other users that can see this vessel.";

    self.newFolderName = ko.observable();
    self.pdfFileName = ko.observable();
    self.vesselFilePdfUrl = ko.observable();
    self.elementToDelete = ko.observable();
    self.elementToRename = ko.observable();
    self.draggableElement = ko.observable();

    self.isFilesLoading = ko.observable(true);
    self.isValidFile = ko.observable(true);
    self.isUploading = ko.observable(false);
    self.isHelpTooltipOpen = ko.observable(false);
    self.sortAscending = ko.observable(true);
    self.sortColumn = ko.observable("fileName");
    self.errorMessage = ko.observable();

    self.isNewFolderDialogVisible = ko.observable(false);
    self.isPdfViewerVisible = ko.observable(false);
    self.isDeleteDialogVisible = ko.observable(false);
    self.isRenameDialogVisible = ko.observable(false);
    self.hasRenameError = ko.observable(false);
    self.hasNewFolderError = ko.observable(false);

    self.files = ko.observableArray([]);
    self.currentFolder = ko.observable(new FolderVM(self.vesselName, "", false));
    self.foldersInCurrentFolder = ko.observableArray([]);
    self.tempFolders = ko.observableArray([]);

    self.getFiles();

    self.formattedFileSizeLimit = ko.pureComputed(function () {
        var limit = self.vesselFileSizeLimitInMB;
        limit = limit * 1000000;

        return stringHelper.getPrettyFileSize(limit);
    });

    self.totalFileSize = ko.pureComputed(function() {
        var files = self.files();
        var bytes = 0;

        _.each(files, function(file) {
            bytes += file.fileSize();
        });

        return stringHelper.getPrettyFileSize(bytes);
    });

    self.parentFolders = ko.pureComputed(function () {
        var currentFolder = self.currentFolder();
        var folderName = currentFolder.folderName();
        var folderPath = currentFolder.folderPath();
        var parentFolders = [];

        if (folderPath !== "") {
            parentFolders.push(currentFolder);

            var parentFolderPath = folderPath.replace(folderName + "/", "");
            var stop = false;

            if (parentFolderPath !== "") {
                do {
                    var parentFolderNames = parentFolderPath.split("/");
                    var parentFolderName = parentFolderNames[parentFolderNames.length - 2];


                    parentFolders.push(new FolderVM(parentFolderName, parentFolderPath));
                    parentFolderPath = parentFolderPath.replace(parentFolderName + "/", "");

                    stop = parentFolderPath === "";
                } while (!stop);
            }
        }

        parentFolders.push(new FolderVM(self.vesselName, ""));
        parentFolders.reverse();

        return parentFolders;
    });

    self.filesInCurrentFolder = ko.pureComputed(function () {
        var folderPath = self.currentFolder().folderPath();
        var sortColumn = self.sortColumn();
        var files = self.files();
        var filesInFolder = [];

        _.each(files, function (file) {
            var fileName = file.fileName();
            var isDescendant = fileName.indexOf(folderPath) === 0;

            if (isDescendant) {
                var childFileName = fileName.replace(folderPath, "");
                var fileExistsInFolder = childFileName.indexOf("/") === -1;

                if (fileExistsInFolder) {
                    filesInFolder.push(file);
                }
            }
        });

        self.sortFiles(filesInFolder, sortColumn);

        return filesInFolder;
    });

    self.deleteText = ko.pureComputed(function() {
        var element = self.elementToDelete();

        if (element instanceof FolderVM) {
            return "Are you sure you want to delete this folder and all of its contents?";
        } else {
            return "Are you sure you want to delete this file?";
        }
    });

    self.goToFolder = function (folder) {
        self.currentFolder(folder);
        self.getFolders();
    };

    self.viewPdf = function (file) {
        self.pdfFileName(file.fileNameWithoutPath());

        self.isPdfViewerVisible(true);
        events.viewPdf.dispatch(ko.toJS(file.getFile()));
    };

    self.showDeleteDialog = function (element) {
        self.elementToDelete(element);
        self.isDeleteDialogVisible(true);
        element.isFileMenuVisible(false);
    };

    self.showRenameDialog = function (element) {
        if (element.elementType === "file") {
            element.newName(element.fileNameWithoutExtension());
        }

        self.elementToRename(element);
        self.isRenameDialogVisible(true);
        element.isFileMenuVisible(false);
    };

    self.rename = function () {
        var element = self.elementToRename();
        var files = self.filesInCurrentFolder();
        var folders = self.foldersInCurrentFolder();
        var isFile = element.elementType === "file";

        var elements = isFile ? files : folders;
        var elementName= isFile ? element.newName() + "." + element.fileExtension() : folders;
        elements = _.without(elements, element);

        var isInvalid = _.some(elements, function (value) {
            var sourceName = isFile ? value.fileNameWithoutPath() : value.folderName();

            return elementName === sourceName;
        });

        if (isInvalid) {
            self.hasRenameError(true);
        } else {
            self.hasRenameError(false);

            if (!isFile) {
                self.renameFolder(element);
                self.sortFiles(folders, "folderName");
                self.foldersInCurrentFolder(folders);
            } else {
                element.rename();
            }
            self.isRenameDialogVisible(false);
        }
    };

    self.createNewFolder = function () {
        var folderName = self.newFolderName();
        var folders = self.foldersInCurrentFolder();
        var currentFolder = self.currentFolder();

        var isInvalid = _.some(folders, function (folder) {
            return folder.folderName() === folderName;
        });

        if (isInvalid) {
            self.hasNewFolderError(true);
        } else {
            var folderPath = currentFolder.folderPath() + folderName + "/";
            self.isNewFolderDialogVisible(false);
            self.newFolderName("");
            self.hasNewFolderError(false);

            var folder = new FolderVM(folderName, folderPath, true);
            folders.push(folder);
            self.sortFiles(folders, "folderName");
            self.foldersInCurrentFolder(folders);
            self.tempFolders.push(folder);
        }
    };

    self.deleteElement = function () {
        var element = self.elementToDelete();

        if (element instanceof FolderVM) {
            self.removeFolder(element);
        } else {
            self.removeFile(element);
        }

        self.isDeleteDialogVisible(false);
    };

    self.downloadFile = function (file) {
        events.vesselFileDownload.dispatch(ko.toJS(file.getFile()));
    };

    self.vesselFileUploadedBinding = events.vesselFileUploaded.add(function (file) {
        var sortColumn = self.sortColumn();
        var files = self.files();
        var existingFile = _.find(files, function (fileVM) { return fileVM.id() === file.id; });
        var currentFolder = self.currentFolder();

        if (existingFile) {
            var indexOfExistingFile = _.indexOf(files, existingFile);
            files[indexOfExistingFile] = new FileVM(file);
        } else {
            files.push(new FileVM(file));
        }

        self.tempFolders.remove(currentFolder);

        self.sortFiles(files, sortColumn);
        self.files(files);
    });

    self.closeTooltipBinding = events.closeTooltip.add(function () {
        self.isHelpTooltipOpen(false);
    });
}

ViewModel.prototype.showNewFolderDialog = function () {
    this.isNewFolderDialogVisible(true);
};

ViewModel.prototype.showFileMenu = function (element) {
    this.isFileMenuVisible(true);
};

ViewModel.prototype.removeFile = function (file) {
    var self = this;

    vesselFilesClient.remove(file.id())
        .done(function () {
            self.files.remove(file);
        });
};

ViewModel.prototype.removeFolder = function (folder) {
    var self = this;
    var files = self.files();
    var folderPath = folder.folderPath();
    _.each(files, function (file) {
        var fileName = file.fileName();
        var startsWith = fileName.indexOf(folderPath) === 0;

        if (startsWith) {
            self.removeFile(file);
            self.foldersInCurrentFolder.remove(folder);
        }
    });

    self.tempFolders.remove(folder);
};

ViewModel.prototype.renameFolder = function (folder) {
    var self = this;

    var files = self.files();
    var currentFolderPath = self.currentFolder().folderPath();
    var folderName = folder.folderName();
    var folderPath = folder.folderPath();
    var newName = folder.newName();

    _.each(files, function (file) {
        var fileName = file.fileName();
        var fileNameStartsWithPath = fileName.indexOf(folderPath) === 0;

        if (fileNameStartsWithPath) {
            var newFolderPath = currentFolderPath === ""
                ? newName + "/"
                : currentFolderPath + newName + "/";

            fileName = fileName.replace(folderPath, newFolderPath);
            file.fileName(fileName);

            vesselFilesClient.update(file.getFile());
        }
    });

    folder.folderName(newName);
    folder.folderPath(folderPath.replace(folderName, newName));
};

ViewModel.prototype.toggleHelpTooltip = function (vm, e) {
    var isHelpTooltipOpen = this.isHelpTooltipOpen();
    if (!isHelpTooltipOpen) {
        var element = e.currentTarget;
        events.showTooltip.dispatch("tooltip-text", { text: this.tooltipHelpText, isWide: true }, element);
        this.isHelpTooltipOpen(true);
    } else {
        events.closeTooltip.dispatch();
    }
};

ViewModel.prototype.hideHelpTooltip = function () {
    if (this.isHelpTooltipOpen()) {
        this.isHelpTooltipOpen(false);
        events.closeTooltip.dispatch();
    }
};

ViewModel.prototype.sortFiles = function (files, sortColumn) {
    var self = this;
    var sortAscending = self.sortAscending();

    files.sort(function (a, b) {
        var left = self.getSortData(a, sortColumn);
        var right = self.getSortData(b, sortColumn);

        if (left < right) return sortAscending ? - 1 : 1;
        if (left > right) return sortAscending ? 1 : -1;
        return 0;
    });
};

ViewModel.prototype.getSortData = function(element, column) {
    if (column === "fileName") {
        return element.fileNameWithoutPath().toLowerCase();
    } else if (column === "uploadedTime") {
        return element.uploadedTime();
    } else if (column === "uploadedBy") {
        return element.uploadedBy().toLowerCase();
    } else if (column === "fileSize") {
        return element.fileSize();
    } else if (column === "folderName") {
        return element.folderName().toLowerCase();
    }
};

ViewModel.prototype.closePdfViewer = function () {
    this.isPdfViewerVisible(false);
    events.closePdf.dispatch();
};

ViewModel.prototype.getFolders = function() {
    var self = this;

    var currentFolderPath = self.currentFolder().folderPath();
    var files = self.files();
    var folders = [];
    var tempFolders = self.getTempFoldersInCurrentFolder();

    _.each(files, function (file) {
        var fileName = file.fileName();
        var isDescendant = fileName.indexOf(currentFolderPath) === 0;

        if (isDescendant) {
            var childFolderName = fileName.replace(currentFolderPath, "");
            var folderNames = childFolderName.split("/");
            var folderName = folderNames[0];
            var folderExists = _.some(folders, function (folder) { return folder.folderName() === folderName; });
            var isChildFolder = childFolderName.indexOf("/") !== -1;

            if (!folderExists && isChildFolder) {
                var folderPath = currentFolderPath + folderName + "/";
                folders.push(new FolderVM(folderName, folderPath, false));
            }
        }
    });

    folders = _.union(folders, tempFolders);
    self.sortFiles(folders, "folderName");

    self.foldersInCurrentFolder(folders);
};

ViewModel.prototype.getTempFoldersInCurrentFolder = function () {
    var self = this;
    var folders = self.tempFolders();
    var currentFolderPath = self.currentFolder().folderPath();

    return _.filter(folders, function(folder) {
        return currentFolderPath + folder.folderName() + "/" === folder.folderPath();
    });
};

ViewModel.prototype.getFiles = function () {
    var self = this;

    vesselFilesClient.getAll(self.vesselId).done(function (data) {
        self.files(_.map(data, function (file) { return new FileVM(file); }));
        self.getFolders();
    }).always(function () {
        self.isFilesLoading(false);
    });
};

ViewModel.prototype.dispose = function () {
    this.vesselFileUploadedBinding.detach();
    this.closeTooltipBinding.detach();
};

export default { viewModel: ViewModel, template: template };
