import Vue from "vue";
import { DateFilterDMYT, DateFilterDMY } from "@/filters/date";
import companyNameFilter from "@/filters/companyName";
import { prettyLatitude, prettyLongitude, prettyLogVariable } from "@/filters/vesselHistory";

Vue.filter("dateDMYT", DateFilterDMYT );
Vue.filter("dateDMY", DateFilterDMY );
Vue.filter("companyName", companyNameFilter );
Vue.filter("prettyLatitude", prettyLatitude );
Vue.filter("prettyLongitude", prettyLongitude );
Vue.filter("prettyLogVariable", prettyLogVariable );
