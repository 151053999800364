import template from "./fuel-consumption-summary.html";
import ko from "knockout";
import _ from "underscore";
import configEmissionReportFactory from "App/config-emission-report";

function FuelConsumptionsByFuelType(fuelType, flowMeterFuelConsumptions) {
    var self = this;
    self.fuelTypeName = fuelType.name;

    self.fuelConsumptionInTon = _.reduce(flowMeterFuelConsumptions,
        function(memo, flowMeterFuelConsumption) {
            return memo + flowMeterFuelConsumption.actualFuelConsumptionInTon;
        },
        0);
}

function ViewModel(params) {
    var self = this;
    self.configEmissionReport = configEmissionReportFactory.get();
    self.fuelConsumptions = params.fuelConsumptions;

    self.fuelConsumptionsByFuelTypes = ko.pureComputed(function() {
        var fuelConsumptions = self.fuelConsumptions();
        var fuelTypesMap = self.configEmissionReport.fuelTypesMap();

        var groupedByFuelType = _.groupBy(fuelConsumptions,
            function(fuelConsumption) {
                return fuelConsumption.fuelTypeId;
            });

        var fuelConsumptionsByFuelType = _.map(groupedByFuelType,
            function(group, fuelTypeId) {
                var fuelType = fuelTypesMap[fuelTypeId];
                return new FuelConsumptionsByFuelType(fuelType, group);
            });

        return fuelConsumptionsByFuelType;
    });
}

ViewModel.prototype.dispose = function() {
};

export default { viewModel: ViewModel, template: template };