function getConditionTypeFromKey(targetKey) {
  if (targetKey.match(/^perf-status-observe/))
    targetKey = "perf-status-observe";
  if (targetKey.match(/perf-status-notok.*/))
    targetKey = "perf-status-notok";
  switch (targetKey) {
    case "last-imported-date":
      return "When vessel data is overdue";
    case "perf-status-observe":
      return "When vessel performance status is Observe";
    case "perf-status-notok":
      return "When vessel performance status is Not ok";
    case "meta:fuel-property":
      return "When manual fuel properties has not been updated";
    case "property:draft":
      return "When manual draft has not been updated";
    case "log-data-expression":
      return "When log data matches custom expression";
    case "LogDataMathExpression":
      return "when log data matches mathematical custom expression";
    default:
      return "";
  }
}

export default {
  getConditionTypeFromKey: function (targetKey) {
    return getConditionTypeFromKey(targetKey);
  },
};
