function htmlEncode(text) {
  const el = document.createElement('div');
  el.innerText = text;
  return el.innerHTML;
}

export default {
  htmlEncode: function (text) {
    return htmlEncode(text);
  },
};
