import store from "@/store";

export default function authHeader(): unknown {
  const acessToken = store.getters["Auth/getAccessToken"];
  if (acessToken) {
    return { Authorization: "Bearer " + acessToken };
  } else {
    return {};
  }
}
