import template from "./graph-config-data-interval.html";
import ko from "knockout";

function ViewModel(params) {
  var self = this;

  var intervalLimit = {
    "Raw": daysToMinutes(1),
    "Minute": daysToMinutes(2),
    "Quarter": daysToMinutes(14),
    "Hour": daysToMinutes(90),
  };

  var intervalInMilliseconds = {
    "Minute": minutesToMilliseconds(1),
    "Quarter": minutesToMilliseconds(15),
    "Hour": minutesToMilliseconds(60),
    "Day": minutesToMilliseconds(24 * 60),
  };

  self.selectedDataInterval = params.selectedDataInterval;
  self.selectedTimeInMinutes = params.selectedTimeInMinutes;
  self.variables = params.variables;
  self.selectedVariables = params.selectedVariables;

  self.currentVariables = ko.pureComputed(() => self.variables().filter(v => this.selectedVariables().indexOf(v.id) > -1));

  self.checkIfDataIntervalIsDisabled = function (value) {
    return !self.isDataIntervalAllowedForDataSize(value) || !self.isDataIntervalAllowedForSampleWindow(value);
  };

  self.isDataIntervalAllowedForSampleWindow = function (value) {
    var intervalMs = intervalInMilliseconds[value]
    if (!intervalMs) {
      return true;
    }

    return self.currentVariables().every(v => !v.sampleWindowInMs || intervalMs % v.sampleWindowInMs === 0);
  }

  self.isDataIntervalAllowedForDataSize = function (value) {
    if (!intervalLimit[value]) {
      return true;
    }
    return self.selectedTimeInMinutes() <= intervalLimit[value];
  }

  self.selectedTimeBinding = self.selectedTimeInMinutes.subscribe(function (newValue) {
    updateSelectedDataInterval();
  });

  function updateSelectedDataInterval() {
    var disableSelectedDataInterval = self.checkIfDataIntervalIsDisabled(self.selectedDataInterval());

    if (disableSelectedDataInterval) {
      var valid = false;
      var selected = incrementSelectedDataInterval(self.selectedDataInterval());

      while (!valid) {
        if (!self.checkIfDataIntervalIsDisabled(selected)) {
          self.selectedDataInterval(selected);
          valid = true;
        }
        selected = incrementSelectedDataInterval(selected);
      }
    }
  }

  self.description = function (value) {
    var text = "";

    if (!self.isDataIntervalAllowedForDataSize(value)) {
      return "(Not possible to plot due to the data size)";
    }

    if (!self.isDataIntervalAllowedForSampleWindow(value)) {
      return "(Not possible due to sample window of variables)";
    }

    if (value === "Raw") {
      text = "(every logged value)";
    } else if (value === "Minute") {
      text = "(one averaged plot per minute)";
    } else if (value === "Quarter") {
      text = "(one averaged plot per 15 minutes)";
    } else if (value === "Hour") {
      text = "(one averaged plot per hour)";
    } else if (value === "Day") {
      text = "(one averaged plot per day)";
    }
    return text;
  };


  function incrementSelectedDataInterval(current) {
    switch (current) {
      case "Raw":
        return "Minute";
      case "Minute":
        return "Quarter";
      case "Quarter":
        return "Hour";
      case "Hour":
        return "Day";
      default:
        return "Day";
    }
  }
}

function daysToMinutes(days) {
  return days * 24 * 60;
}

function minutesToMilliseconds(minutes) {
  return minutes * 60 * 1000;
}

ViewModel.prototype.dispose = function () {
  this.selectedTimeBinding.dispose();
};

export default {
  viewModel: ViewModel,
  template: template
};
