import template from "./company-manager.html";
import ko from "knockout";
import koValidation from "knockout.validation";
import events from "App/events";
import CompanyVM from "ViewModels/company/company";
import CompanyListVM from "ViewModels/company/companyList";
import stringHelper from "Utilities/string-helper";
import Router from "@/router";
import Store from "@/store";
import { getModule } from "vuex-module-decorators";
import UsersModule from "@/store/clients/Users.module";
import DashboardModule from "@/store/clients/Dashboard.module";
import VesselsModule from "@/store/clients/Vessels.module";

const Users = getModule(UsersModule, Store);
const Dashboard = getModule(DashboardModule, Store);
const Vessels = getModule(VesselsModule, Store);

function ViewModel() {
    var self = this;

    self.viewModel = null;
    self.dialogState = ko.observable("");
    self.companyList = new CompanyListVM();

    self.error = ko.observable();

    self.companyViewBinding = events.companyView.add(function (company) {
        self.clearError();
        self.viewModel = new CompanyVM(company);
        self.dialogState("VIEW");
    });

    self.companyViewAsBinding = events.companyViewAs.add(function (company) {
        self.clearError();
        self.viewAsCompany(company);
    });

    self.companyEditBinding = events.companyEdit.add(function (company) {
        self.clearError();
        self.viewModel = new CompanyVM(company, self.companyList);
        self.dialogState("EDIT");
    });

    self.companyAddBinding = events.companyAdd.add(function () {
        self.clearError();
        self.viewModel = new CompanyVM(null, self.companyList);
        self.dialogState("ADD");
    });

    self.companyDeleteBinding = events.companyDelete.add(function (company) {
        self.clearError();
        self.viewModel = new CompanyVM(company);
        self.dialogState("REMOVE");
    });
}

ViewModel.prototype.dispose = function () {
    this.companyViewBinding.detach();
    this.companyViewAsBinding.detach();
    this.companyEditBinding.detach();
    this.companyDeleteBinding.detach();
    this.companyAddBinding.detach();
};

ViewModel.prototype.createCompany = function () {
    var self = this;

    self.viewModel.save().done(function (newCompany) {
        events.companyOnCreated.dispatch(newCompany);
        self.closeDialog();
    }).fail(function (error) {
        self.handleError(error);
    });
};

ViewModel.prototype.removeCompany = function () {
    var self = this;

    self.viewModel.remove().done(function() {
            var company = self.viewModel.getOriginalCompany();
            events.companyOnRemoved.dispatch(company);
            self.closeDialog();
        })
        .fail(function(error) {
            self.handleError(error);
        });
};

ViewModel.prototype.editCompany = function () {
    var self = this;
    var originalCompany = self.viewModel.getOriginalCompany();
    self.viewModel.save().done(function(updatedCompany) {
        events.companyOnUpdated.dispatch(originalCompany, updatedCompany);
        self.closeDialog();

        // If the name of the company assigned to the current user changes then we need to know
        // about that so that for example the header on the webpage reflects the new name.
        if (!stringHelper.equalsIgnoreCase(originalCompany.name, updatedCompany.name)) {
            if (self.isCurrentUserInCompany(originalCompany.id)) {
                Store.dispatch("User/getUser");
            }
        }
    }).fail(function(error) {
        self.handleError(error);
    });
};

ViewModel.prototype.viewAsCompany = async function (company) {
    await Users.viewAsCompany(company.id);
    await Router.push("/");
    Router.go(0);
};

ViewModel.prototype.closeDialog = function () {
    this.dialogState("");
};

ViewModel.prototype.clearError = function () {
    this.error(null);
};

ViewModel.prototype.handleError = function (error) {
    if (error && error.responseJSON && error.responseJSON.message) {
        this.error(error.responseJSON.message);
    } else {
        this.error("An error occurred.");
    }
    events.errorEvent.dispatch(error);
};

ViewModel.prototype.isCurrentUserInCompany = function (companyId) {
    return Store.getters["User/userProfile"]?.companyId === companyId;
};

export default { viewModel: ViewModel, template: template };
