import ko from "knockout";
import $ from "jquery";

ko.bindingHandlers.scrollTop = {
    init: function(element, valueAccessor) {
    },
    update: function (element, valueAccessor) {
        var div = $(element);
        var value = ko.utils.unwrapObservable(valueAccessor());

        if (value) {
            var scrollToElement = document.getElementById(value);
            $("html, body").animate({
                scrollTop: $(scrollToElement).offset().top,
            }, 1000);
        } else {
            div.scrollTop(0);
        }
    },
};