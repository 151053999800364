import ko from "knockout";
import $ from "jquery";

//removes all style attributes when the obseved value is true
ko.bindingHandlers.deleteStylesWithin = {
    update: function (element, valueAccessor) {
        var value = ko.utils.unwrapObservable(valueAccessor().deleteStyles);
         
        if (!value)
            return;

        var $elements = $(element).find("[style]");
        $elements.removeAttr( "style" );
    },
};