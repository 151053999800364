import template from "./tree-select.html";
import treeMaker from "Utilities/kognifai-variable-tree";
import ko from "knockout";

/**
 * Context-menu style presentation of variables.
 * @param {Object} variables - ko.observableArray of the variables.
 * @param {Object} selectedId - ko.observable string. Id of selected item
 * @param {Object} selected - ko.pureComputed function that returns currently selected item.
 */
function ViewModel(params) {
    var self = this;
    self.variableTree = ko.observable(treeMaker.makeTree(ko.unwrap(params.variables), "|"));
    self.selectedId = params.selectedId;
    self.selectedItem = ko.observable();
    self.menuOpen = ko.observable(false);
    self.menuVisible = ko.observable(false);
    self.safetyMargin = 30;

    if (params.selected)
        self.selectedItem(ko.unwrap(params.selected));

    self.selectItem = function (data) {
        self.selectedItem(data.payload);
        self.selectedId(data.payload.id);
        self.closeMenu();
    };

    self.selectedItemName = ko.pureComputed(function () {
        if (self.selectedItem())
            return self.selectedItem().name;
        return "select";
    }, this);

    self.closeMenu = function () {
        self.menuOpen(false);
        self.menuVisible(false);
    };

    self.toggleMenu = function (data, event) {
        if (self.menuOpen()) {
            self.menuOpen(false);
            self.menuVisible(false);
        } else {
            self.menuOpen(true);
            self.positionMenu(event);  
        }
    };

    //opens the menu as a context menu, 5px right of the pointer. 
    self.positionMenu = function (event) {
        var $menu = $(event.target).next();
        var menuHeight = $menu.height();
        var windowHeight = $(window).height() - self.safetyMargin;
        var top = event.clientY;
        var left = event.clientX + 5;
    
        //move menu up if it clips out of frame
        if (event.clientY + menuHeight > windowHeight) {
            var difference = (event.clientY + menuHeight) - windowHeight;
            top =  event.clientY - difference;
        }

        var styles = {
            top: top + "px",
            left: left + "px",
        };

        $menu.css(styles);
        //adds class 'visible' to element which animates it in with opacity and transform
        self.menuVisible(true);
    };

    //make sure selected submenu is fully inside viewport. Because the submenus are positioned
    //relative to the tree-select parent and not viewport, we use $el.position() to set new positions.
    self.positionSubMenu = function (data, event) {
        var $el = $(event.target).find( "[data-jqposition]" ).first();
        if (!$el.length)
            return;

        var windowHeight = $(window).height();
        var windowWidth = $(window).width();
        var rect = $el[0].getBoundingClientRect();
        var availableHeight = windowHeight - (self.safetyMargin * 2);
        var availableBottom = windowHeight - self.safetyMargin;

        //Menu is clipping outside of the left side of viewport
        //Move menu to right side
        if (rect.left + rect.width > windowWidth) {
            var styles = {
                right:"100%",
                left: "unset",
            };
            $el.css(styles);
        } 

        //Menu is taller than max allowed height. We set height that fits viewport + a little padding.
        //We set a negative margin-right corresponding to scrollbar-width so we don't break hover on the scrollbar
        if (rect.height > availableHeight) {
            var delta = self.safetyMargin - rect.top;
            var top = $el.position().top + delta;
            var styles = {
                height: availableHeight + "px",
            };
            $el.children().css(styles);
            $el.css("top", top + "px");
            return;
        }

        //Menu is poking below viewport. We move it up so it's bottom aligns with the viewport bottom padding.
        if (rect.bottom > availableBottom) {
            var delta = rect.bottom - availableBottom;
            var top =  $el.position().top - delta;
            $el.css("top", top + "px");
            return;
        } 
    };
}

export default { viewModel: ViewModel, template: template };