import template from "./emission-report-template.html";
import ko from "knockout";
import _ from "underscore";
import flowMetersClient from "Clients/flow-meters-client";
import fuelTanksClient from "Clients/fuel-tanks-client";
import fuelTypesClient from "Clients/fuel-types-client";
import formHelper from "Utilities/form-helper";
import emissionReportClient from "Clients/emission-report-client";

function ViewModel(params) {
    var self = this;

    self.vessel = params.vessel;

    self.isLoadingFlowMeters = ko.observable(true);
    self.isLoadingFuelTanks = ko.observable(true);
    self.isLoadingFuelTypes = ko.observable(true);
    self.flowMeters = ko.observableArray([]);
    self.fuelTanks = ko.observableArray([]);
    self.fuelTypes = ko.observableArray([]);

    flowMetersClient.find(self.vessel().id).done(self.flowMeters)
        .always(function () {
            self.isLoadingFlowMeters(false);
        });

    fuelTanksClient.find(self.vessel().id)
        .done(self.fuelTanks)
        .always(function () {
            self.isLoadingFuelTanks(false);
        });

    fuelTypesClient.getAll()
        .done(self.fuelTypes)
        .always(function () {
            self.isLoadingFuelTypes(false);
        });

    self.isLoading = ko.pureComputed(function () {
        var isLoadingFlowMeters = self.isLoadingFlowMeters();
        var isLoadingFuelTanks = self.isLoadingFuelTanks();
        var isLoadingFuelTypes = self.isLoadingFuelTypes();

        return isLoadingFlowMeters || isLoadingFuelTanks || isLoadingFuelTypes;
    });

    self.manualFlowMeters = ko.pureComputed(function () {
        var flowMeters = self.flowMeters();
        return _.filter(flowMeters,
            function (flowMeter) {
                return !flowMeter.isDeactivated && !flowMeter.fuelMassFlowRateLogVariableId;
            });
    });

    self.activeFuelTanks = ko.pureComputed(function () {
        var fuelTanks = self.fuelTanks();
        return _.filter(fuelTanks,
            function (fuelTank) {
                return !fuelTank.isDeactivated;
            });
    });

    self.activeFuelTypes = ko.pureComputed(function () {
        var fuelTypes = self.fuelTypes();
        return _.filter(fuelTypes,
            function (fuelType) {
                return !fuelType.isDeactivated;
            });
    });

    self.hasFlowMeter = ko.pureComputed(function () {
        var manualFlowMeters = self.manualFlowMeters();
        return !_.isEmpty(manualFlowMeters);
    });

    self.hasFuelTank = ko.pureComputed(function () {
        var activeFuelTanks = self.activeFuelTanks();
        return !_.isEmpty(activeFuelTanks);
    });

    self.hasVesselCargoUnits = ko.pureComputed(function () {
        var vessel = self.vessel();
        return vessel.cargoUnits.length > 0;
    });

    self.isMissingTemplateSettings = ko.pureComputed(function () {
        var hasFuelTank = self.hasFuelTank();
        var hasFlowMeter = self.hasFlowMeter();
        var hasVesselCargoUnits = self.hasVesselCargoUnits();

        return (!hasFuelTank & !hasFlowMeter) || !hasVesselCargoUnits;
    });
}

ViewModel.prototype.generateExcelTemplate = function () {
    var filter = {
        vessel: this.vessel(),
        fuelTypes: this.activeFuelTypes(),
        flowMeters: this.manualFlowMeters(),
        fuelTanks: this.activeFuelTanks(),
        vesselCargoUnits: this.vessel().cargoUnits,
    };

    emissionReportClient.generateExcelTemplate(filter);
};

export default { viewModel: ViewModel, template: template };