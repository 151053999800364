function getHelpText(type) {
  switch (type) {
    case "speed":
      return "This graph shows a long trend history of speed loss compared to the baseline used for the vessel. The baseline for the vessel is typically the power vs. speed design curve from the model tank test. A zero (0%) speed loss means that the vessel is performing according to the design curve." +
        "A normal trend line for a vessel shows decreasing speed loss over time (negative values), primarily due to hull fouling and reduced propeller efficiency. The system calculates a speed loss percentage for each day by taking an average of all the speed loss measurements calculated from the high frequency data (usually 1 or 15 second granularity) within the 24 hour period." +
        "These higher frequency data points are averaged up to a single speed loss percentage for the day which is used to compute a trend line (green line) within the trend period. The system automatically adjusts the calculation for laden and ballast states. Note: the speed loss metric in the Fouling tab may have a different result than in the Diagnostics tab due to different filters applied to the data.";
    case "rpm":
      return "This graph shows a long trend overview of the relationship of the main engine power output to the resulting propeller speed (revolutions per minute (rpm)). The graph shows the deviation (+/- 25 %) of rpm over time compared against the value, at the corresponding power, from the propeller curve (power vs rpm). A zero deviation means that the propeller performs according to the propeller curve. The normal trend over time is, however, that the propeller becomes “heavier” (negative trend line), meaning that the power required to turn the propeller increases. The main causes of a heavy propeller are marine growth or a damaged propeller.";
    case "sfr":
      return "This graph shows a long trend overview on how the main engine(s) specific fuel consumption rate (sfr) changes over time. The graph shows the deviation (+/- 50 %) of the sfr, compared against the reference curve, measured during the factory shop test. A deviation of zero means that the Main engine is performing in line with the original shop test data for power vs sfr. The normal trend line for any given main engine, however, is that the fuel consumption increases over time (positive trend line); primarily due to the effects of engine wear (higher friction, leakages, etc.).";
    default:
      return "";
  }
}

function getBenefitText(type) {
  switch (type) {
    case "speed":
      return "* Estimated change in fuel consumption due to speed change, based on ISO corrected Calorific Value.";
    case "sfr":
      return "* Estimated change in fuel consumption due to SFR change, based on ISO corrected Calorific Value.";
    default:
      return "";
  }
}

function getInvalidConsequenceHelpText(type) {
  switch (type) {
    case "speed":
      return "An increased ship speed and fuel savings are expected after a Trend event";
    case "rpm":
      return "An increased propeller speed is expected after a Trend event";
    case "sfr":
      return "A reduced SFR and fuel savings are expected after a Trend event";
    default:
      return "";
  }
}

function getDiagnosticTypeOrder(type) {
  switch (type) {
    case "speed":
      return 1;
    case "rpm":
      return 2;
    case "sfr":
      return 3;
    default:
      return 4;
  }
}

export default {
  getHelpText: function (diagnosticType) {
    if (!diagnosticType) return "";
    return getHelpText(diagnosticType.name.toLowerCase());
  },
  getBenefitText: function (diagnosticType) {
    if (!diagnosticType) return "";
    return getBenefitText(diagnosticType.name.toLowerCase());
  },
  getInvalidConsequenceHelpText: function (diagnosticTypeName) {
    return getInvalidConsequenceHelpText(diagnosticTypeName.toLowerCase());
  },
  getDiagnosticTypeOrder: function (diagnosticType) {
    return getDiagnosticTypeOrder(diagnosticType.name.toLowerCase());
  },
};
