import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import AnnouncementClient from "Clients/Announcement-client";
import { AnnouncementModel, FilteredAnnouncement } from "@/types/Announcement";

@Module({ namespaced: true, name: "Announcement" })
class Announcement extends VuexModule {
  private _listofAnnouncements: Announcement[] = [];
  private _allAnnoucements: AnnouncementModel[] = [];
  private _readByUser: FilteredAnnouncement[] = [];

  @Mutation
  public SET_ANNOUNCEMENTS(listofAnnouncements: Announcement): void {
    this._listofAnnouncements.push(listofAnnouncements);
  }

  @Mutation
  public SET_ALLANNOUNCEMENTS(allAnnouncement: AnnouncementModel): void {
    this._allAnnoucements.push(allAnnouncement);
  }

  @Mutation
  public SET_READANNOUNCEMENTS(readByUser: FilteredAnnouncement): void {
    this._readByUser.push(readByUser);
  }


  @Action({ rawError: true })
  public async announcementListByUser() {
    try {
      const listofAnnouncements = await AnnouncementClient.findByUser();
      this.context.commit("SET_ANNOUNCEMENTS", listofAnnouncements);
      return listofAnnouncements;
    } catch (error) {
      throw ({ message: "Failed to return response", error });
    }
  }

  @Action({ rawError: true })
  public async announcementReadByUser(filteredAnnouncement: FilteredAnnouncement[]): Promise<FilteredAnnouncement> {
    try {
      const readByUser = await AnnouncementClient.readByUser(filteredAnnouncement);
      this.context.commit("SET_READANNOUNCEMENTS", readByUser);
      return readByUser;
    } catch (error) {
      throw ({ message: "Failed to return response", error });
    }
  }

  @Action({ rawError: true })
  public async fetchAllAnnouncement() {
    try {
      const allAnnouncement = await AnnouncementClient.find();
      this.context.commit("SET_ALLANNOUNCEMENTS", allAnnouncement);
      return allAnnouncement;
    } catch (error) {
      throw ({ message: "Failed to return response", error });
    }
  }

  @Action({ rawError: true })
  public async addNewAnnouncement(newAnnouncement: AnnouncementModel): Promise<Announcement> {
    try {
      const allAnnouncement = await AnnouncementClient.new(newAnnouncement);
      this.context.commit("SET_ALLANNOUNCEMENTS", allAnnouncement);
      return allAnnouncement;
    } catch (error) {
      throw ({ message: "Failed to add new announcement", error });
    }
  }

  @Action({ rawError: true })
  public async deleteAnnouncement(id: number) {
    try {
      const deleteResponse = await AnnouncementClient.deleteAnnounement(id);
      return deleteResponse;
    } catch (error) {
      throw ({ message: "Failed to delete announcement", error });
    }
  }

  @Action({ rawError: true })
  public async sendEmail(newAnnouncement: AnnouncementModel): Promise<Announcement> {
    try {
      const sendEmail = await AnnouncementClient.sendEmail(newAnnouncement);
      return sendEmail;
    } catch (error) {
      throw ({ message: "Failed to send email", error });
    }
  }

  public get getannouncementlistByUser() {
    return this._listofAnnouncements;
  }

  public get getallAnnouncement() {
    return this._allAnnoucements;
  }

  public get getReadAnnouncement() {
    return this._readByUser;
  }
}

export default Announcement;
