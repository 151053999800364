import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import LongTrendPeriodsClient from "Clients/long-trend-periods-client";
import { LongTrendPeriodMeta } from "@/types/longTrendPeriodMeta";
import { ILongTrendPeriod } from "@/types/longTrendPeriod";
import { LongTrendPeriodStatistics } from "@/types/longTrendPeriodStatistics";
import { TrendLimit } from "@/types/trendLimit";


@Module({ namespaced: true, name: "LongTrendPeriod" })
class LongTrendPeriod extends VuexModule {
  private _meta: LongTrendPeriodMeta | null = null;
  private _longTrendPeriod: ILongTrendPeriod | null = null;
  private _statistics: LongTrendPeriodStatistics | null = null;
  private _trendLimits: TrendLimit | null = null;

  @Mutation
  public SET_META(data: LongTrendPeriodMeta): void {
    this._meta = data;
  }

  @Mutation
  public SET_LONG_TREND_PERIOD(data: ILongTrendPeriod): void {
    this._longTrendPeriod = data;
  }

  @Mutation
  public SET_STATISTICS(data: LongTrendPeriodStatistics): void {
    this._statistics = data;
  }

  @Mutation
  public SET_TREND_LIMITS(data: TrendLimit): void {
    this._trendLimits = data;
  }

  @Mutation
  public DELETE_DATA(): void {
    this._meta = null;
    this._longTrendPeriod = null;
    this._statistics = null;
  }

  @Action({ rawError: true })
  public async updateMeta(query: LongTrendPeriodMeta): Promise<void> {
    let data = [];
    try {
      data = await LongTrendPeriodsClient.meta(query);
    } catch (err) {
      console.warn(err);
      return Promise.reject();
    }
    this.context.commit("SET_META", data);
  }

  @Action({ rawError: true })
  public async updateLongTrendPeriod(query: LongTrendPeriodMeta): Promise<void> {
    let data = [];
    try {
      data = await LongTrendPeriodsClient.longTrendPeriods(query);
    } catch (err) {
      console.warn(err);
      return Promise.reject();
    }
    this.context.commit("SET_LONG_TREND_PERIOD", data);
  }

  @Action({ rawError: true })
  public async updateStatistics(query: LongTrendPeriodMeta): Promise<void> {
    let data = [];
    try {
      data = await LongTrendPeriodsClient.statistics(query);
    } catch (err) {
      console.warn(err);
      return Promise.reject();
    }
    this.context.commit("SET_STATISTICS", data);
  }

  @Action({ rawError: true })
  public async updateTrendLimits(id: number): Promise<void> {
    let data = [];
    try {
      data = await LongTrendPeriodsClient.trendLimits(id);
    } catch (err) {
      console.warn(err);
    }
    this.context.commit("SET_TREND_LIMITS", data);
  }

  get meta(): LongTrendPeriodMeta | null {
    return this._meta;
  }

  get longTrendPeriod(): ILongTrendPeriod | null {
    return this._longTrendPeriod;
  }

  get statistics(): LongTrendPeriodStatistics | null {
    return this._statistics;
  }
  get trendLimits(): TrendLimit | null {
    return this._trendLimits;
  }
}

export default LongTrendPeriod;
