function secondsToMinutes(seconds) {
    seconds = Number(seconds);
    return seconds / 60;
}

function secondsToHours(seconds) {
    seconds = Number(seconds);
    return secondsToMinutes(seconds) / 60;
}

function secondsToDays(seconds) {
    seconds = Number(seconds);
    return secondsToMinutes(seconds) / 24;
}

function daysToHours(days) {
    days = Number(days);
    return days * 24;
}

function ticksToHours(ticks) {
    var ticksInSeconds = 10000000;
    return ticks / ticksInSeconds / 60 / 60;
}

export default {
    secondsToHours: function(seconds) {
        return secondsToHours(seconds);
    },
    secondsToDays: function(seconds) {
        return secondsToDays(seconds);
    },
    daysToHours: function(days) {
        return daysToHours(days);
    },
    ticksToHours: function(ticks) {
        return ticksToHours(ticks);
    },
};