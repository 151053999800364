import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { LogVariable } from "@/types/logVariable";
import taxonomyClient from "Clients/taxonomy-client";
import { Category as TaxonomyCategory } from "@/types/taxonomy/category";


@Module({ namespaced: true, name: "Taxonomy" })
class Taxonomy extends VuexModule {
  private _taxonomyLogVariables: LogVariable[] = [];
  private _taxonomyCategories: TaxonomyCategory[] = [];

  @Action({ rawError: true })
  public async getTaxonomyLogVariables(): Promise<LogVariable[] | undefined> {
    try {
      const data = await taxonomyClient.getTaxonomyLogVariables();
      this.context.commit("SET_TAXONOMY_LOG_VARIABLES", data);
      return data;
    } catch (error) {
      console.warn(error);
    }
  }

  @Action({ rawError: true })
  public async getTaxonomyCategories(): Promise<TaxonomyCategory[] | undefined> {
    try {
      const data = await taxonomyClient.getAllTaxa();
      this.context.commit("SET_TAXONOMY_CATEGORIES", data);
      return data;
    } catch (error) {
      throw ({ message: "Failed to get taxonomy categories", error });
    }
  }

  @Action({ rawError: true })
  public downloadTaxonomy(): void {
    try {
      return taxonomyClient.downloadTaxonomy();
    } catch (error) {
      console.warn(error);
    }
  }

  @Action({ rawError: true })
  public async uploadTaxonomy(file: FormData): Promise<void> {
    try {
      await taxonomyClient.uploadTaxonomy(file);
    } catch (error) {
      console.warn(error);
    }
  }

  @Mutation
  public SET_TAXONOMY_LOG_VARIABLES(taxonomyLogVariables: LogVariable[]): void {
    this._taxonomyLogVariables = taxonomyLogVariables;
  }

  @Mutation
  public SET_TAXONOMY_CATEGORIES(taxonomyCategories: TaxonomyCategory[]): void {
    this._taxonomyCategories = taxonomyCategories;
  }

  public get taxonomyList(): LogVariable[] {
    return this._taxonomyLogVariables;
  }

  public get categorizedTaxonomyList(): TaxonomyCategory[] {
    return this._taxonomyCategories;
  }
}

export default Taxonomy;
