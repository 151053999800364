






















































































































































































import AnnouncementClient from "@/Scripts/clients/Announcement-client";
import conditionTypeHelper from "@/Scripts/utilities/conditionTypeHelper";
import { Component, PropSync, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/clients/Auth.module";
import AnnouncementModule from "@/store/clients/Announcement.module";
import store from "@/store";
import SnackbarModule from "@/store/clients/Snackbar.module";
import { Announcement, FilteredAnnouncement } from "@/types/Announcement";
import { Incident, OpenIncident } from "@/types/Incident";
import IncidentsModule from "@/store/clients/Incidents.module";
import moment from "moment";

const Auth = getModule(AuthModule, store);
const Snackbar = getModule(SnackbarModule, store);
const Announcement = getModule(AnnouncementModule, store);
const Incidents = getModule(IncidentsModule, store);

@Component
export default class ShowAnnouncement extends Vue {
  //Two way prop to handle open/close popup both from parent and component
  @PropSync("openPopup", { type: Boolean, default: false }) open!: boolean;

  componentKey = 0;
  allAnnouncements: Announcement[] = [];
  userDetails: any = [];
  myNotifications: OpenIncident[] = [];
  latest: Announcement[] = [];
  archived: Announcement[] = [];
  activeTab = 0;
  refreshTimer!: number;
  MINUTE_IN_MS = 60000;
  myNotificationCount = 0;
  announcementCount = 0;

  items: any[] = [
    { title: "My Notifications", subitems: this.myNotifications },
    { title: "Latest", subitems: this.latest },
    { title: "Archived", subitems: this.archived },
  ];

  get isLoggedIn(): boolean {
    return Auth.isLoggedIn;
  }

  closeModal(): void {
    this.open = false;
  }

  mounted(): void {
    this.$root.$on("fetchAnnouncementData", () => {
      this.fetchData();
    });
  }

  @Watch("isLoggedIn")
  async fetchData(): Promise<void> {
    const response = await Announcement.announcementListByUser();
    this.allAnnouncements = response;
    const openIncidents = await Incidents.getFleetLevelOpenIncidents();
    this.myNotifications = openIncidents;

    this.latest = this.allAnnouncements.filter((item: { isView: boolean }) => item.isView === false);
    this.archived = this.allAnnouncements.filter((item: { isView: boolean }) => item.isView === true);
    this.myNotifications = this.myNotifications.filter(item => item.acknowledged != true);
    this.myNotificationCount = this.myNotifications.length;
    this.announcementCount = this.latest.length;
    this.$root.$emit("announcementlistByUser");
  }

  tabClickHandler(i: number): void {
    this.activeTab = i;
  }

  async created(): Promise<void> {
    await this.fetchData();
    this.startRefreshTimer();
  }

  startRefreshTimer(): void {
    this.refreshTimer = setInterval(() => {
      this.fetchData();
    }, this.MINUTE_IN_MS);
  }

  async MarkAllAsRead(): Promise<void> {
    try {
      const filterdata = this.allAnnouncements.filter((item: { isView: boolean }) => item.isView === false);
      const filteredAnnouncement: FilteredAnnouncement[] = [];

      for (var i in filterdata) {
        filteredAnnouncement.push({
          UserEmail: this.userDetails.email,
          CompanyId: this.userDetails.companyId,
          AnnouncementId: filterdata[i].announcementId,
        });
      }
      await Announcement.announcementReadByUser(filteredAnnouncement);

      Snackbar.showSnackbar({
        text: "You read all the Announcements and archived ",
        color: "success",
      });
      await this.fetchData();
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed" });
    }
  }

  async MarkasRead(Id: number): Promise<void> {
    const filteredAnnouncement: FilteredAnnouncement[] = [];
    filteredAnnouncement.push({
      UserEmail: this.userDetails.email,
      CompanyId: this.userDetails.companyId,
      AnnouncementId: Id,
    });
    await AnnouncementClient.readByUser(filteredAnnouncement);

    Snackbar.showSnackbar({
      text: "Announcement marked as read and archived",
      color: "success",
    });
    await this.fetchData();
  }

  async MarkAsAcknowledged(id: number): Promise<void> {
    const response = await AnnouncementClient.AcknowledgeIncident(id);
    await this.fetchData();
  }

  async MarkAllAsAcknowledged(): Promise<void> {
    const response = await AnnouncementClient.AcknowledgeAllIncidents(this.myNotifications);
    await this.fetchData();
  }

  getTimeAgo(date: any): any {
    var utcDate = new Date(date).toLocaleString("en-US", {
      localeMatcher: "best fit",
      timeZoneName: "short",
    });
    const dateValue = new Date(utcDate + " UTC").valueOf();
    return moment(dateValue).fromNow();
  }

  getConditionType(conditionTargetKey: any): any {
    var conditionType = conditionTypeHelper.getConditionTypeFromKey(conditionTargetKey);
    return conditionType;
  }
}
