import template from "./fm-fuel-consumptions.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";

function ViewModel(params) {
    var self = this;
    self.selectedFlowMeter = params.selectedFlowMeter;
    self.departureVoyageVesselEvent = params.departureVoyageVesselEvent;
    self.endOfVoyageVesselEvent = params.endOfVoyageVesselEvent;
    self.selectedVoyageVesselEvent = params.selectedVoyageVesselEvent;
    self.flowMeterFuelConsumptions = self.selectedVoyageVesselEvent.flowMeterFuelConsumptions;
    self.isVoyageDataConfirmed = params.selectedVoyageVesselEvent.isVoyageDataConfirmed;

    self.missingFlowMeterFuelConsumptions = ko.pureComputed(function() {
        var selectedFlowMeter = self.selectedFlowMeter();
        var vesselEvent = self.selectedVoyageVesselEvent.vesselEvent();

        var flowMeterFuelConsumptions = self.filteredFlowMeterFuelConsumptions();

        var previousVesselEvent = self.selectedVoyageVesselEvent.previousVesselEvent();
        if (!previousVesselEvent) return [];

        var sailingStartAt = moment.utc(previousVesselEvent.timestamp);
        var sailingEndAt = moment.utc(vesselEvent.timestamp);

        var timeRanges = _.map(flowMeterFuelConsumptions,
            function(flowMeterFuelConsumption) {
                return {
                    startAt: flowMeterFuelConsumption.startAt,
                    endAt: flowMeterFuelConsumption.endAt,
                    key: flowMeterFuelConsumption.startAt + flowMeterFuelConsumption.endAt,
                };
            });

        var uniqueTimeRanges = _.unique(timeRanges,
            false,
            function(timeRange) {
                return timeRange.key;
            });


        var missingTimeRanges = [];
        var currentDate = sailingStartAt.clone();
        var orderedUniqueTimeRanges = _.sortBy(uniqueTimeRanges, "startAt");

        _.each(orderedUniqueTimeRanges,
            function(timeRange) {
                var startAt = moment.utc(timeRange.startAt);
                var endAt = moment.utc(timeRange.endAt);

                if (currentDate < startAt) {
                    var missingFlowMeterFuelConsumption = {
                        vesselEventId: vesselEvent.id,
                        startAt: currentDate.toISOString(),
                        endAt: timeRange.startAt,
                    };
                    missingTimeRanges.push(missingFlowMeterFuelConsumption);
                }
                currentDate = endAt;
            });

        if (currentDate < sailingEndAt) {
            var missingTimeRange1 = {
                vesselEventId: vesselEvent.id,
                startAt: currentDate.toISOString(),
                endAt: vesselEvent.timestamp,
            };
            missingTimeRanges.push(missingTimeRange1);
        }

        var missingFlowMeterFuelConsumptions = _.map(missingTimeRanges,
            function(missingTimeRange) {
                var missingFuelConsumption = _.clone(missingTimeRange);
                missingFuelConsumption.flowMeterId = selectedFlowMeter.id;
                missingFuelConsumption.vesselEventId = vesselEvent.id;
                missingFuelConsumption.flowMeterFuelConsumptionSource = "Manual";
                return missingFuelConsumption;
            });

        return missingFlowMeterFuelConsumptions;
    });

    self.filteredFlowMeterFuelConsumptions = ko.pureComputed(function() {
        var selectedFlowMeter = self.selectedFlowMeter();
        var flowMeterFuelConsumptions = self.flowMeterFuelConsumptions();

        return _.filter(flowMeterFuelConsumptions,
            function(flowMeterFuelConsumption) {
                return flowMeterFuelConsumption.flowMeterId === selectedFlowMeter.id;
            });
    });

    self.sortedFlowMeterFuelConsumptions = ko.pureComputed(function() {
        var filteredFlowMeterFuelConsumptions = self.filteredFlowMeterFuelConsumptions();

        return _.sortBy(filteredFlowMeterFuelConsumptions, "startAt");
    });
}

ViewModel.prototype.dispose = function() {
};

export default { viewModel: ViewModel, template: template };