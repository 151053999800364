import net from "App/net";
import events from "App/events";

var Client = function() {

};

Client.prototype.find = function(fuelTankId, fromDate, toDate) {
    var filter = {
        fuelTankId: fuelTankId,
        fromDate: fromDate,
        toDate: toDate,
    };

    return net.get("/FuelTankOperations/Find", filter);
};

Client.prototype.create = function(fuelTankOperation) {
    return net.post("/FuelTankOperations", fuelTankOperation).done(function(data) {
        events.fuelTankOperationCreated.dispatch(data);
    });
};

Client.prototype.remove = function(fuelTankOperation) {
    return net.del("/FuelTankOperations/" + fuelTankOperation.id).done(function() {
        events.fuelTankOperationDeleted.dispatch(fuelTankOperation);
    });
};

export default new Client();