import net from "App/net";

var VesselFilesClient = function() {

};

VesselFilesClient.prototype.remove = function (fileId) {
    return net.del("/VesselFiles/" + fileId);
};

VesselFilesClient.prototype.update = function(file) {
    return net.put("/VesselFiles", file);
};

VesselFilesClient.prototype.getAll = function (vesselId) {
    var filter = {
        vesselId: vesselId,
    };
    return net.get("/VesselFiles", filter);
};

VesselFilesClient.prototype.download = function(fileId) {
    return net.fileDownload("/VesselFiles/" + fileId);
};

VesselFilesClient.prototype.getFileUrl = function (fileId) {
    var filter = {
        onlyUrl: true,
    };

    return net.get("/VesselFiles/" + fileId, filter);
};

export default new VesselFilesClient();