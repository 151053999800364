import template from "./benefits-table.html";
import ko from "knockout";
import _ from "underscore";

function ViewModel(params) {
    var self = this;
    self.hasSFRBenchmarkingError = ko.utils.unwrapObservable(params.hasSFRBenchmarkingError);
    self.benefit = ko.utils.unwrapObservable(params.benefit);
    self.firstConsequence = _.first(self.benefit.consequences);
    self.hasConsequenceResults = self.firstConsequence.results.length > 0;
}

ViewModel.prototype.dispose = function () {
};

export default { viewModel: ViewModel, template: template };