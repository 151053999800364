import template from "./company-form.html";
import ko from "knockout";
import koValidation from "knockout.validation";
import _ from "underscore";

function ViewModel(params) {
    var self = this;
    
    self.company = params.company;
    self.error = params.error;

    self.subscriptionAlternatives = ko.observableArray(["Active", "Suspended", "Terminated"]);
}

export default { viewModel: ViewModel, template: template };