import net from "App/net";
import Store from "@/store";
import _ from "underscore";

var LogDataClient = function () {

};

function getData(filter, url) {
  var deferred = $.Deferred();

  var logData = null;

  net.get(url, filter).done(function(response) {
    var continuationFilter = response.continuationFilter;

    logData = response;
    if (continuationFilter) {
      getData(continuationFilter).done(function(continuedResponse) {
        _.extend(logData.data, continuedResponse.data);
        deferred.resolve(logData);
      });
    } else {
      deferred.resolve(logData);
    }
  }).fail(function() {
    deferred.reject(arguments);
  });

  return deferred.promise();
}

LogDataClient.prototype.findLogData = function (variableId, fromDate, toDate, granularity, dateTimeFormat, 
  unitName, filterMinMax, filterOutliers) {
  var url = Store.getters["User/isKognifai"] ? "/Kognifai/LogData" : "/LogData/Find";

  var filter = {
    logVariableId: variableId,
    granularity: granularity ? granularity : "minute",
    fromDate: fromDate,
    toDate: toDate,
    dateTimeFormat: dateTimeFormat,
    unitName: unitName,
    filterMinMax: filterMinMax,
    filterOutliers: filterOutliers,
  };
  return getData(filter, url);
};

LogDataClient.prototype.batchFindLogData = function (variableIds, fromDate, toDate, granularity, filterMinMax, filterOutliers) {
  var url = "/LogData/BatchFind";

  var filter = {
    logVariableIds: variableIds,
    granularity: granularity ? granularity : "minute",
    fromDate: fromDate,
    toDate: toDate,
    filterMinMax: filterMinMax,
    filterOutliers: filterOutliers,
  };

  return getData(filter, url);
};

LogDataClient.prototype.fetchAccumulatedCalculatedLogData = function(payload) {
  var url = "/LogData/Calculated/Accumulated";
  var filter = {
    logVariableIds: payload.logVariableIds,
    fromDate: payload.fromDate,
    toDate: payload.toDate,
  };

  return getData(filter, url);
};

LogDataClient.prototype.fetchAveragedCalculatedLogData = function(payload) {
  var url = "/LogData/Calculated/Averaged";
  var filter = {
    logVariableIds: payload.logVariableIds,
    fromDate: payload.fromDate,
    toDate: payload.toDate,
  };

  return getData(filter, url);
};

LogDataClient.prototype.fetchShapoliAveraged = function(payload) {
  var url = "/Shapoli/LogData/Average";
  var filter = {
    vesselId: payload.vesselId,
    fromDate: payload.fromDate,
    toDate: payload.toDate,
  };

  return getData(filter, url);
};

LogDataClient.prototype.fetchLastReceivedLogData = function(logVariableIds) {
  var url = "/LogData/LastReceived?logVariableIds=" + logVariableIds;
  var filter = {

  };

  return getData(filter, url);
};

LogDataClient.prototype.fetchLastVoyageAccumulated = function(logVariableIds) {
  var url = "/LogData/Calculated/Accumulated/LastVoyage?logVariableIds=" + logVariableIds;
  var filter = {
    
  };

  return getData(filter, url);
};

LogDataClient.prototype.fetchLastVoyageAveraged = function(logVariableIds) {
  var url = "/LogData/Calculated/Averaged/LastVoyage?logVariableIds=" + logVariableIds;
  var filter = {
    
  };

  return getData(filter, url);
};

export default new LogDataClient();
