import net from "App/net";

var PerformanceStatusWidgetClient = function () {

};

PerformanceStatusWidgetClient.prototype.getAll = function () {
    return net.get("/PerformanceStatusWidgetConfiguration");
};

PerformanceStatusWidgetClient.prototype.get = function (id) {
    return net.get("PerformanceStatusWidgetConfiguration/" + id);
};

PerformanceStatusWidgetClient.prototype.new = function (perfStatWidgetConfiguration) {
    return net.post("/PerformanceStatusWidgetConfiguration", perfStatWidgetConfiguration);
};

PerformanceStatusWidgetClient.prototype.update = function (perfStatWidgetConfiguration) {
    return net.put("/PerformanceStatusWidgetConfiguration", perfStatWidgetConfiguration);
};

PerformanceStatusWidgetClient.prototype.delete = function (perfStatWidgetConfigId) {
    return net.del("/PerformanceStatusWidgetConfiguration/" + perfStatWidgetConfigId);
};

export default new PerformanceStatusWidgetClient();