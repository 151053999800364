import _ from "underscore";
/**
 * Takes a flat array of log variables and groups them in a tree-structure based on the variable-name
 * which follows the Vessel Insight Common Asset Structure naming scheme with the separator
 * set to '|' in kognifaiService.cs
 * @param {Array} items
 * @param {String} separator
 * @example Aux_Engines | 1 | Fuel_Flow will be found in
 * [{
 *   name: "Aux_engines",
 *   children: [
 *     {
 *       name: "1",
 *       children: [
 *         {
 *           name: "fuel_flow",
 *           payload: {
 *             id: "942",
 *             name: "Aux_Engines | 1 | Fuel_Flow",
 *             unit: { name: "todo", caption: "todo" },
 *             vesselId: "938",
 *           },
 *         },
 *       ],
 *     },
 *   ],
 * }]
 */
function makeTree (items, separator) {
  //Just make sure there are no identical variable names, otherwide it will create an infinite recursive loop.
  //This should not happen with normal data anyway but it did in the test data. If it turns out to be a real issue
  //perhaps the duplicate should be included with a modified name (name + id or something)
  var uniqueItems = _.uniq(items, item => item.name);

  //first add an array of levels to each item based on its name
  //if internet explorer suddenly becomes required, use .replace(/ /g, '-') instead
  _.forEach(uniqueItems, function(item) {
    item.levels = item.name.replaceAll(" ", "").split(separator);
  });
  return makeTreeRecursive(uniqueItems);
}

//recursively build our tree structure based on the levels of each item
function makeTreeRecursive (items) {
  var grouped = _.groupBy(items, function(item) {
    if (item.levels.length > 0)
      return item.levels.shift();
  });

  var result = _.map(grouped, function(children, name) {
    if (children.length > 1) {
      var nextlevel = makeTreeRecursive(children);
      return {
        name: name,
        children: nextlevel,
      };
    } else return {
      name: name,
      payload: children[0],
    };
  });
  return result;
}

export default {
  makeTree: function(data, separator) {
    return makeTree(data, separator);
  },
};
