import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { report, reportType } from "@/types/reports";
import reportsClient from "Clients/reports-client";
import reportTypesClient from "Clients/report-types-client";


@Module({ namespaced: true, name: "Reports" })
class Reports extends VuexModule {
  private _reportsExpired = true;
  private _reports: report[] = [];
  private _reportTypes: reportType[] = [];


  @Mutation
  public REFRESH_REPORTS(reports: report[]): void {
    this._reportsExpired = false;
    this._reports = reports;
  }

  @Mutation
  public REFRESH_REPORT_TYPES(reportTypes: reportType[]): void {
    //the old kyma online code filters out 'unknown' so we do the same here
    this._reportTypes = reportTypes.filter(type => {
      return type.reportTypeName.toLowerCase() !== "unknown";
    });
  }

  @Mutation
  public EXPIRE_REPORTS(): void {
    this._reportsExpired = true;
  }

  @Action({ rawError: true })
  public async refreshReports(vesselId: number): Promise<void> {
    let data = [];
    try {
      data = await reportsClient.getAll(vesselId);
    } catch (err) {
      console.warn(err);
    }
    this.context.commit("REFRESH_REPORTS", data);
  }

  @Action({ rawError: true })
  public async getAllReports(vesselId: number): Promise<report[]> {
    if (!this._reportsExpired) {
      return this._reports;
    } else {
      await this.refreshReports(vesselId);
      return this._reports;
    }
  }

  @Action({ rawError: true })
  public async refreshReportTypes(): Promise<void> {
    let data = [];
    try {
      data = await reportTypesClient.getAll();
    } catch (err) {
      console.warn(err);
    }
    this.context.commit("REFRESH_REPORT_TYPES", data);
  }

  @Action({ rawError: true })
  public async getAllReportTypes(): Promise<reportType[]> {
    if (this._reportTypes.length) {
      return this._reportTypes;
    } else {
      await this.refreshReportTypes();
      return this._reportTypes;
    }
  }

  @Action({ rawError: true })
  public async getReportUrl(id: number): Promise<string> {
    let url = "";
    try {
      url = await reportsClient.getReportUrl(id);
    } catch (err) {
      console.warn(err);
    }
    return url;
  }

  get reports(): report[] {
    return this._reports;
  }
  get reportTypes(): reportType[] {
    return this._reportTypes;
  }
}

export default Reports;
