import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { VesselDataWidgetConfig } from "@/types/VesselDataWidgetConfig";
import vesselDataWidgetClient from "Clients/vessel-data-widget-client";

@Module({ namespaced: true, name: "VesselDataWidgetConfiguration" })
class VesselDataWidgetConfiguration extends VuexModule {
  private _vesselDataWidgetConfigsExpired = true;
  private _vesselDataWidgetConfigs: VesselDataWidgetConfig[] = [];

  @Mutation
  public SET_VESSEL_DATA_WIDGET_CONFIGS(vesselDataWidgetConfigs: VesselDataWidgetConfig[]): void {
    this._vesselDataWidgetConfigsExpired = false;
    this._vesselDataWidgetConfigs = vesselDataWidgetConfigs;
  }

  @Mutation
  public SET_CONFIGS_EXPIRED(value: boolean): void {
    this._vesselDataWidgetConfigsExpired = value;
  }

  @Mutation
  public UPDATE_CONFIG_COLUMNS_SEQUENCE(id: number): void {
    const configIndex = this._vesselDataWidgetConfigs.findIndex(config => config.id === id);
    if (configIndex >= 0) {
      this._vesselDataWidgetConfigs[configIndex].columns.forEach((column, i) => column.sequence = i);
    }
  }

  @Action({ rawError: true })
  public async refreshVesselDataWidgetConfigs(): Promise<void> {
    let vesselDataWidgetConfigs = [];
    try {
      vesselDataWidgetConfigs = await vesselDataWidgetClient.getAll();
    } catch (error) {
      throw ({ message: "Failed to refresh widget configs", error });
    }
    this.context.commit("SET_VESSEL_DATA_WIDGET_CONFIGS", vesselDataWidgetConfigs);
  }

  @Action({ rawError: true })
  public async getVesselDataWidgetConfigs(): Promise<VesselDataWidgetConfig[]> {
    if (!this._vesselDataWidgetConfigsExpired) {
      return this._vesselDataWidgetConfigs;
    } else {
      await this.context.dispatch("refreshVesselDataWidgetConfigs");
      return this._vesselDataWidgetConfigs;
    }
  }

  @Action({ rawError: true })
  public async getVesselDataWidgetConfigById(tableWidgetId: number): Promise<VesselDataWidgetConfig> {
    if (this._vesselDataWidgetConfigsExpired) {
      await this.context.dispatch("refreshVesselDataWidgetConfigs");
    }
    const config = this._vesselDataWidgetConfigs.find(config => config.id === tableWidgetId);
    if (config) {
      for (let i = 0; i < config.vessels.length; i++) {
        const vessel = await this.context.dispatch("Vessels/get", config.vessels[i].id, { root: true });
        if (vessel) config.vessels[i] = vessel;
      }
      //  set columns in order they were created
      config.columns = config.columns.sort((a: any, b: any) => a.sequence - b.sequence);

      return config;
    }

    else throw ({ message: "Failed to find widget config" });
  }

  @Action({ rawError: true })
  public updateVesselDataWidgetConfigColumnsSequence(tableWidgetId: number): void {
    this.context.commit("UPDATE_CONFIG_COLUMNS_SEQUENCE", tableWidgetId);
  }

  @Action({ rawError: true })
  public async newEmptyVesselDataWidgetConfig(type: string): Promise<VesselDataWidgetConfig> {
    try {
      const vesselDataWidgetConfig: VesselDataWidgetConfig = {
        id: 0,
        displayType: type,
        configurationName: "New Table",
        vessels: [],
        columns: [],
      };
      const createdVesselDataWidgetConfig = await vesselDataWidgetClient.new(vesselDataWidgetConfig);
      await this.context.dispatch("expireConfig");
      this.context.dispatch("refreshVesselDataWidgetConfigs");
      return createdVesselDataWidgetConfig;

    } catch (error) {
      throw ({ message: "Failed to create new widget config", error });
    }
  }

  @Action({ rawError: true })
  public async newVesselDataWidgetConfig(vesselDataWidgetConfig: VesselDataWidgetConfig): Promise<VesselDataWidgetConfig> {
    try {
      const response = await vesselDataWidgetClient.new(vesselDataWidgetConfig);
      await this.context.dispatch("expireConfig");
      this.context.dispatch("refreshVesselDataWidgetConfigs");
      return response;
    } catch (error) {
      throw ({ message: "Failed to add new widget config", error });
    }
  }

  @Action({ rawError: true })
  public async updateVesselDataWidgetConfig(vesselDataWidgetConfig: VesselDataWidgetConfig): Promise<void> {
    try {
      await vesselDataWidgetClient.update(vesselDataWidgetConfig);
      await this.context.dispatch("expireConfig");
    } catch (error) {
      throw ({ message: "Failed to update widget config", error });
    }
    this.context.dispatch("refreshVesselDataWidgetConfigs");
  }

  @Action({ rawError: true })
  public async deleteVesselDataWidgetConfig(vesselDataWidgetConfigId: number): Promise<void> {
    try {
      await vesselDataWidgetClient.delete(vesselDataWidgetConfigId);
      await this.context.dispatch("expireConfig");
    } catch (error) {
      throw ({ message: "Failed to delete widget config", error });
    }
    this.context.dispatch("refreshVesselDataWidgetConfigs");
  }

  @Action({ rawError: true })
  public expireConfig(): void {
    this.context.commit("SET_CONFIGS_EXPIRED", true);
  }

  get vesselDataWidgetConfigs(): VesselDataWidgetConfig[] {
    return this._vesselDataWidgetConfigs;
  }

  public get getConfigById() {
    return (id: number): VesselDataWidgetConfig | undefined => {
      return this._vesselDataWidgetConfigs.find(config => config.id === id);
    };
  }
}

export default VesselDataWidgetConfiguration;
