import ko from "knockout";
import $ from "jquery";

ko.bindingHandlers.clickOutside = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
        var callback = valueAccessor();
        var namespace = getNamespace(); //Use a namespace to ensure that only the correct click bindings are disposed

        var clickBinding = $(document).on("click." + namespace, function (event) {
            if (!$(event.target).closest(element).length) {
                var argsForHandler = makeArray(arguments);

                viewModel = bindingContext["$data"];
                argsForHandler.unshift(viewModel);
                callback.apply(viewModel, argsForHandler);
            }
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
            clickBinding.off("click." + namespace);
        });

    },
    update: function (element, valueAccessor, allBindingsAccessor) {
    },
};

var getNamespace = function () {
    return Math.random().toString(36).substr(2, 16);
};

function makeArray(arrayLikeObject) {
    var result = [];
    for (var i = 0, j = arrayLikeObject.length; i < j; i++) {
        result.push(arrayLikeObject[i]);
    }
    return result;
}