import template from "./vessel-groups.html";
import ko from "knockout";
import _ from "underscore";
import $ from "jquery";
import events from "App/events";
import vesselGroupsClient from "Clients/vessel-groups-client";
import vesselsClient from "Clients/vessels-client";

function getVesselGroupNameError(name) {
    if (!name) {
        return "Please enter a name for the vessel group.";
    }
    if (name.length > 15) {
        return "The vessel group name cannot exceed 15 characters.";
    }
    return "";
}

function ViewModel() {
    var self = this;
    self.isLoadingVesselGroups = ko.observable(true);
    self.isLoadingVessels = ko.observable(true);
    self.isCreatingVesselGroup = ko.observable(false);
    self.isEditingVesselGroup = ko.observable(false);
    self.vesselGroups = ko.observableArray([]);
    self.vessels = ko.observable();
    self.selectedVesselGroup = ko.observable();
    self.newVesselGroupName = ko.observable();
    self.createVesselGroupError = ko.observable();
    self.editVesselGroupError = ko.observable();
    self.removingVesselGroup = ko.observable();
    self.editingVesselGroup = ko.observable();
    self.editedVesselGroupName = ko.observable();

    self.selectVesselGroup = function(vesselGroup) {
        self.selectedVesselGroup(vesselGroup);
        self.editingVesselGroup(null);
    }.bind(self);

    self.startEditVesselGroup = function(vesselGroup, e) {
        var $container = $(e.target).closest(".vessel-group-list-item");
        self.editVesselGroupError("");
        self.editedVesselGroupName(vesselGroup.name);
        self.editingVesselGroup(vesselGroup);

        var $input = $container.find("input");
        $input.focus();
        $input.select();
    }.bind(self);

    self.doneEditVesselGroup = function() {
        var editedVesselGroup = _.clone(self.editingVesselGroup());
        editedVesselGroup.name = self.editedVesselGroupName();

        self.editVesselGroupError("");
        var vesselGroupNameError = getVesselGroupNameError(editedVesselGroup.name);
        if (vesselGroupNameError) {
            self.editVesselGroupError(vesselGroupNameError);
            return;
        }

        self.isEditingVesselGroup(true);
        vesselGroupsClient.update(editedVesselGroup).always(function() {
            self.isEditingVesselGroup(false);
        }).fail(function (error) {
            self.handleError(error);
        });
    }.bind(self);

    self.cancelEditVesselGroup = function() {
        self.editingVesselGroup(null);
    }.bind(self);

    self.startRemoveVesselGroup = function(vesselGroup) {
        self.removingVesselGroup(vesselGroup);
    }.bind(self);

    self.isLoading = ko.pureComputed(function() {
        var isLoadingVessels = self.isLoadingVessels();
        var isLoadingVesselGroups = self.isLoadingVesselGroups();
        return isLoadingVessels || isLoadingVesselGroups;
    });

    self.addVesselToGroup = function(vessel) {
        var selectedVesselGroup = self.selectedVesselGroup();
        vesselGroupsClient.addVesselToGroup(vessel, selectedVesselGroup);
    }.bind(self);

    self.removeVesselFromGroup = function(vessel) {
        var selectedVesselGroup = self.selectedVesselGroup();
        vesselGroupsClient.removeVesselFromGroup(vessel, selectedVesselGroup);
    }.bind(self);

    self.removeVesselGroup = function() {
        var self = this;
        var vesselGroup = self.removingVesselGroup();
        vesselGroupsClient.remove(vesselGroup).always(function() {
            self.removingVesselGroup(null);
        }).fail(function (error) {
            self.handleError(error);
        });
    }.bind(self);

    self.vesselsInSelectedVesselGroup = ko.pureComputed(function() {
        var selectedVesselGroup = self.selectedVesselGroup();
        var vessels = self.vessels();
        if (!selectedVesselGroup) return [];

        var vesselsInSelectedVesselGroup = _.filter(vessels, function(vessel) {
            return _.contains(selectedVesselGroup.vesselIds, vessel.id);
        });

        return _.sortBy(vesselsInSelectedVesselGroup, "name");
    });

    self.vesselsNotInSelectedVesselGroup = ko.pureComputed(function() {
        var vesselsInSelectedVesselGroup = self.vesselsInSelectedVesselGroup();
        var vessels = self.vessels();

        var vesselsNotInSelectedVesselGroup = _.filter(vessels, function(vessel) {
            return !_.contains(vesselsInSelectedVesselGroup, vessel);
        });

        return _.sortBy(vesselsNotInSelectedVesselGroup, "name");
    });

    self.sortedVesselGroups = ko.pureComputed(function() {
        var vesselGroups = self.vesselGroups();
        return _.sortBy(vesselGroups, "name");
    });

    self.vesselGroupCreatedBinding = events.vesselGroupCreated.add(function(createdVesselGroup) {
        self.vesselGroups.push(createdVesselGroup);
        self.selectedVesselGroup(createdVesselGroup);
    });

    self.vesselGroupUpdatedBinding = events.vesselGroupUpdated.add(function(updatedVesselGroup) {
        var oldVesselGroup = _.find(self.vesselGroups(), function(vesselGroup) {
            return vesselGroup.id === updatedVesselGroup.id;
        });
        self.vesselGroups.replace(oldVesselGroup, updatedVesselGroup);

        var editingVesselGroup = self.editingVesselGroup();
        if (editingVesselGroup && editingVesselGroup.id === updatedVesselGroup.id) {
            self.editingVesselGroup(null);
        }

        var selectedVesselGroup = self.selectedVesselGroup();
        if (selectedVesselGroup && selectedVesselGroup.id === updatedVesselGroup.id) {
            self.selectedVesselGroup(updatedVesselGroup);
        }
    });

    self.vesselGroupDeletedBinding = events.vesselGroupDeleted.add(function(deletedVesselGroup) {
        self.vesselGroups.remove(deletedVesselGroup);
        if (self.selectedVesselGroup() === deletedVesselGroup) {
            self.selectedVesselGroup(null);
        }
    });

    self.load();
}

ViewModel.prototype.dispose = function() {
    this.vesselGroupCreatedBinding.detach();
    this.vesselGroupUpdatedBinding.detach();
    this.vesselGroupDeletedBinding.detach();
};

ViewModel.prototype.load = function() {
    this.loadVessels();
    this.loadVesselGroups();
};

ViewModel.prototype.loadVessels = function() {
    var self = this;
    self.isLoadingVessels(true);
    vesselsClient.getVessels().done(function(data) {
        self.vessels(data);
    }).always(function() {
        self.isLoadingVessels(false);
    });
};

ViewModel.prototype.loadVesselGroups = function() {
    var self = this;
    self.isLoadingVesselGroups(true);
    vesselGroupsClient.getAll().done(function(data) {
        self.vesselGroups(data);
    }).always(function() {
        self.isLoadingVesselGroups(false);
    });
};

ViewModel.prototype.createVesselGroup = function() {
    var self = this;
    var newVesselGroup = {
        name: self.newVesselGroupName(),
    };

    self.createVesselGroupError("");
    var vesselGroupNameError = getVesselGroupNameError(newVesselGroup.name);
    if (vesselGroupNameError) {
        self.createVesselGroupError(vesselGroupNameError);
        return;
    }

    self.isCreatingVesselGroup(true);
    vesselGroupsClient.create(newVesselGroup).done(function() {
        self.newVesselGroupName("");
    }).always(function() {
        self.isCreatingVesselGroup(false);
    }).fail(function (error) {
        self.handleError(error);
    });
};

ViewModel.prototype.handleError = function (error) {
    if (error && error.responseJSON && error.responseJSON.message) {
        this.error(error.responseJSON.message);
    } else {
        this.error("An error occurred.");
    }
    events.errorEvent.dispatch(error);
};

export default { viewModel: ViewModel, template: template };
