import template from "./compare-vessels-long-trends-details-table-row.html";
import ko from "knockout";
import _ from "underscore";
import colorHelper from "Utilities/color-helper";

function getMrcConsequenceIndexFromMcrPercent(mcrPercent) {
    switch (parseInt(mcrPercent)) {
    case 40:
        return 0;
    case 50:
        return 1;
    case 75:
        return 2;
    case 90:
        return 3;
    default:
        throw "Unexpected MCR percent: " + mcrPercent;
    }
}

function ViewModel(params) {
    var self = this;
    self.vesselCompareRowData = params.vesselCompareRowData;
    self.showLastTrendPeriodOnly = params.showLastTrendPeriodOnly;
    self.vesselSortParameter = params.vesselSortParameter;
    self.showLastTrendPeriodOnly = params.showLastTrendPeriodOnly;
    self.inputMcrPercent = params.inputMcrPercent;
    self.consequenceHeaders = ko.utils.unwrapObservable(params.consequenceHeaders);
    self.consequencesMap = ko.utils.unwrapObservable(params.consequencesMap);
    self.vesselColor = colorHelper.getDistinctColor(self.vesselCompareRowData.vesselIndex);
    self.performanceStatus = ko.observable(self.vesselCompareRowData.lastLongTrendPeriod.status);

    self.consequences = ko.observable(self.consequencesMap[self.vesselCompareRowData.vesselLongTrend.id]);

    self.mcrConsequenceIndex = ko.pureComputed(function() {
        var inputMcrPercent = self.inputMcrPercent();
        return getMrcConsequenceIndexFromMcrPercent(inputMcrPercent);
    });

    self.consequence = ko.pureComputed(function() {
        var consequences = self.consequences();
        var mcrConsequenceIndex = self.mcrConsequenceIndex();
        if (!consequences) return null;
        return consequences[mcrConsequenceIndex];
    });

    self.consequenceValues = ko.pureComputed(function() {
        var consequenceValues = [];
        var consequence = self.consequence();
        var consequenceResults = consequence ? consequence.results : [];

        _.each(self.consequenceHeaders, function(consequenceHeader) {
            if (self.vesselCompareRowData.isBenchmarking) {
                consequenceValues.push("n/a");
            } else if (consequenceHeader === "MCR") {
                consequenceValues.push(consequence.mcrDisplay);
            } else {
                var consequenceResult = _.find(consequenceResults, function(consequenceResult) {
                    return consequenceResult.headerName === consequenceHeader;
                });
                if (!consequenceResult) {
                    consequenceValues.push("n/a");
                } else {
                    consequenceValues.push(consequenceResult.displayValue);
                }
            }
        });

        return consequenceValues;
    });
}

export default { viewModel: ViewModel, template: template };