import $ from "jquery";
import Router from "@/router";
import Store from "@/store";

//TODO: handle multiple parallel requests with invalid token
$.ajaxPrefilter(function (opts, originalOpts, jqXHR) {
  // you could pass this option in on a "retry" so that it doesn't
  // get all recursive on you.
  if (opts.refreshRequest) {
    return;
  }

  // our own deferred object to handle done/fail callbacks
  const dfd = $.Deferred();

  // if the request works, return normally
  jqXHR.done(dfd.resolve);

  // if the request fails, do something else
  // yet still resolve
  jqXHR.fail(function () {
    const args = Array.prototype.slice.call(arguments);
    if (jqXHR.status === 401) {
      // Check if access token is expired.
      const expiryDate = Store.getters["Auth/getAccessTokenExpiryDate"];
      if (!expiryDate || new Date(expiryDate) < new Date()) {
        dfd.rejectWith(jqXHR, args);
        Store.dispatch("Auth/signOut");
        if (Router.history.current.name === "login") return;
        Router.push("/login");
        return;
      }
    } else {
      dfd.rejectWith(jqXHR, args);
    }
  });
  return dfd.promise(jqXHR);
});
