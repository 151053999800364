import net from "App/net";
import events from "App/events";

var Client = function() {

};

Client.prototype.find = function(fuelTankId, fromDate, toDate) {
    var filter = {
        fuelTankId: fuelTankId,
        fromDate: fromDate,
        toDate: toDate,
    };

    return net.get("/FuelTankSoundings/Find", filter);
};

Client.prototype.create = function(fuelTankSounding) {
    return net.post("/FuelTankSoundings", fuelTankSounding);
};

Client.prototype.remove = function(fuelTankSounding) {
    return net.del("/FuelTankSoundings/" + fuelTankSounding.id).done(function() {
        events.fuelTankSoundingDeleted.dispatch(fuelTankSounding);
    });
};

export default new Client();