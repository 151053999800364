
















import { Component, Watch, Vue } from "vue-property-decorator";
import { Route } from "vue-router";
import { ExtendedVessel } from "@/types/Vessel";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import VesselsModule from "@/store/clients/Vessels.module";
import performanceStatusHelper from "Utilities/performance-status-helper";

const Vessels = getModule(VesselsModule, store);

@Component
export default class PerformanceStatusIcon extends Vue {
  cssClassProp?: string = "loading";
  iconImageSrcProp?: string = "";
  displayTextProp?: string = "";

  @Watch("$route")
  routeChanged(to: Route, from: Route): void {
    if (to.params.vesselId !== from.params.vesselId) {
      this.setStatusIconProps(this.currentVessel?.performance?.performanceStatus);
    }
  }

  @Watch("currentVessel", { deep: true })
  currentVesselDataChanged(): void {
    this.setStatusIconProps(this.currentVessel?.performance?.performanceStatus);
  }

  get currentVessel(): ExtendedVessel | null {
    return Vessels.currentVessel;
  }

  created(): void {
    this.setStatusIconProps(this.currentVessel?.performance?.performanceStatus);
  }

  setStatusIconProps(status: string | undefined): void {
    if (!status) return;
    this.cssClass = performanceStatusHelper.getPerformanceStatusCssClass(status);
    this.iconImageSrc = performanceStatusHelper.getPerformanceStatusImageSrc(status);
    this.displayText = performanceStatusHelper.getPerformanceStatusText(status);
  }

  set cssClass(cssClass: string | undefined) {
    this.cssClassProp = cssClass;
  }
  get cssClass(): string | undefined {
    return this.cssClassProp;
  }
  set iconImageSrc(src: string | undefined) {
    this.iconImageSrcProp = src;
  }
  get iconImageSrc(): string | undefined {
    return this.iconImageSrcProp;
  }
  set displayText(text: string | undefined) {
    this.displayTextProp = text;
  }
  get displayText(): string | undefined {
    return this.displayTextProp;
  }
  //TODO: figure out when to show this
  get showText(): boolean {
    return false;
  }
}
