import template from "./ft-operation.html";
import ko from "knockout";
import fuelTankOperationsClient from "Clients/ft-operations-client";
import configEmissionReportFactory from "App/config-emission-report";

function ViewModel(params) {
    var self = this;
    self.configEmissionReport = configEmissionReportFactory.get();
    self.fuelTankOperation = params.fuelTankOperation;
    self.isVoyageDataConfirmed = params.isVoyageDataConfirmed;

    var fuelTypesMap = self.configEmissionReport.fuelTypesMap();
    self.fuelTypeName = fuelTypesMap[self.fuelTankOperation.fuelTypeId].name;

    self.isRemoving = ko.observable(false);
}

ViewModel.prototype.dispose = function() {
};

ViewModel.prototype.remove = function() {
    var self = this;
    self.isRemoving(true);
    fuelTankOperationsClient.remove(self.fuelTankOperation).always(function() {
        self.isRemoving(false);
    });
};

export default { viewModel: ViewModel, template: template };