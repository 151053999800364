import ko from "knockout";
import _ from "underscore";
import moment from "moment";

ko.bindingHandlers.humanizeDuration = {
    update: function(element, valueAccessor) {
        var options = ko.utils.unwrapObservable(valueAccessor());
        var from = moment.utc(options.from);
        var to = options.to ? moment.utc(options.to) : moment.utc();

        var humanizedDuration = moment.duration(to.diff(from)).humanize();
        ko.bindingHandlers.text.update(element, function() { return humanizedDuration; });
    },
};