import { VuexModule, Module } from "vuex-module-decorators";
import { VesselStatusIcons } from "@/types/vesselStatusIcons";

@Module({ namespaced: true, name: "MapWidget" })
class MapWidgetData extends VuexModule {
  private _performanceStatuses = [
    {
      title: "Ok",
      value: "Ok",
      icon: VesselStatusIcons.Ok,
    },
    {
      title: "Observe",
      value: "Observe",
      icon: VesselStatusIcons.Observe,
    },
    {
      title: "Not ok",
      value: "NotOk",
      icon: VesselStatusIcons.NotOk,
    },
    {
      title: "Benchmarking",
      value: "BenchMarking",
      icon: VesselStatusIcons.BenchMarking,
    },
    {
      title: "Performance status N/A",
      value: "notAvailable",
      icon: VesselStatusIcons.notAvailable,
    },
  ]

  get performanceStatuses(): { title: string, value: string, icon: VesselStatusIcons }[] {
    return this._performanceStatuses;
  }
}

export default MapWidgetData;
