import ko from "knockout";
import $ from "jquery";

ko.bindingHandlers.slideToggle = {
    init: function (element, valueAccessor) {
        var value = valueAccessor();
        var isVisible = ko.unwrap(value.isVisible);
        isVisible ? $(element).show() : $(element).hide();
    },
    update: function (element, valueAccessor) {
        var value = valueAccessor();
        var isVisible = ko.unwrap(value.isVisible);
        if (value.isSliding) {
            value.isSliding(true);
        }

        var duration = ko.unwrap(value.duration) || 400;
        var complete = function () {
            if (value.isSliding) {
                value.isSliding(false);
            }
            if (value.slideComplete) {
                value.slideComplete.call(value.callbackContext);
            }
        };

        if (value.slideStart) {
            value.slideStart.call(value.callbackContext);
        }
        isVisible ? $(element).slideDown(duration, complete) : $(element).slideUp(duration, complete);
    },
};