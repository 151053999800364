import template from "./vessel-settings.html";
import ko from "knockout";
import vesselsAdministrationsClient from "Clients/vessels-administrations-client";
import logVariablesClient from "Clients/log-variables-client";

import Store from "@/store";

var Tab = function(name, componentName) {
    this.name = name;
    this.componentName = componentName;
};

function ViewModel(params) {
    var self = this;
    self.tabs = [
        new Tab("General", "general-settings"),
        new Tab("Flow meters", "flow-meters"),
        new Tab("Fuel tanks", "fuel-tanks"),
        new Tab("Fuel types", "fuel-types"),
        new Tab("Veracity key", "veracity-key"),
    ];
    self.selectedTab = ko.observable(self.tabs[0]);

    self.selectTab = function(tab) {
        self.selectedTab(tab);
    }.bind(self);

    self.vessel = ko.observable();
    self.isLoadingVessel = ko.observable(true);
    vesselsAdministrationsClient.get(Store.getters["Vessels/currentVessel"].id)
        .done(self.vessel)
        .always(function() {
            self.isLoadingVessel(false);
        });

    self.speedLogVariables = ko.observable();
    self.isLoadingSpeedLogVariables = ko.observable(true);
    logVariablesClient.findSpeedVariables(Store.getters["Vessels/currentVessel"].id)
        .done(self.speedLogVariables)
        .always(function() {
            self.isLoadingSpeedLogVariables(false);
        });

    self.isLoading = ko.pureComputed(function() {
        var isLoadingVessel = self.isLoadingVessel();
        var isLoadingSpeedLogVariables = self.isLoadingSpeedLogVariables();
        return isLoadingVessel || isLoadingSpeedLogVariables;
    });
}

ViewModel.prototype.dispose = function() {
};

export default { viewModel: ViewModel, template: template };