import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { Widget } from "@/types/widget";
import { DashboardI } from "@/types/dashboard";
import DashboardClient from "Clients/dashboard-client";
import Vue from "vue";

@Module({ namespaced: true, name: "Dashboard" })
class Dashboard extends VuexModule {
  private _dashboards: DashboardI[] = [];
  private _activeDashboardId!: number;
  private _activeDashboardIndex = 0;
  //note2: the user can only edit widgets on the active dashboard

  @Mutation
  public SET_DASHBOARDS(dashboards: DashboardI[]): void {
    this._dashboards = dashboards;
  }

  @Mutation
  public SET_ACTIVE_DASHBOARD(dashboardId: number): void {
    this._activeDashboardId = dashboardId || parseInt(Vue.$cookies.get("activeDashboardId")) || this._dashboards[0].id;
    this._activeDashboardIndex = this._dashboards.findIndex(dashboard => dashboard.id === this._activeDashboardId);
    if (this._activeDashboardIndex < 0) this._activeDashboardIndex = 0;
    Vue.$cookies.set("activeDashboardId", this._activeDashboardId, "1y");
  }

  @Mutation
  public UPDATE_DASHBOARD(dashboard: DashboardI): void {
    const index = this._dashboards.findIndex(dash => dash.id === dashboard.id);
    Vue.set(this._dashboards, index, dashboard);
  }

  @Mutation
  public DELETE_DASHBOARD(dashboardId: number): void {
    const index = this._dashboards.findIndex(dashboard => dashboard.id === dashboardId);
    this._dashboards.splice(index, 1);
    if (this._activeDashboardIndex !== 0) this._activeDashboardIndex -= 1;
  }

  @Mutation
  public UPDATE_DASHBOARD_LAYOUT(dashboard: DashboardI): void {
    this._dashboards[this._activeDashboardIndex].widgets = dashboard.widgets;
  }

  @Mutation
  public ADD_WIDGET(widget: Widget): void {
    this._dashboards[this._activeDashboardIndex].widgets.push(widget);
  }

  @Mutation
  public DELETE_WIDGET(index: number): void {
    this._dashboards[this._activeDashboardIndex].widgets.splice(index, 1);
  }

  @Mutation
  public UPDATE_WIDGET(data: { index: number; widgetData: Partial<Widget> }): void {
    Object.assign(this._dashboards[this._activeDashboardIndex].widgets[data.index], data.widgetData);
  }

  @Action({ rawError: true })
  public setActiveDashboard(dashboardId: number): void {
    this.context.commit("SET_ACTIVE_DASHBOARD", dashboardId);
  }

  @Action({ rawError: true })
  public async deleteDashboard(dashboardId: number): Promise<void> {
    try {
      await DashboardClient.delete(dashboardId);
      this.context.commit("DELETE_DASHBOARD", dashboardId);
    } catch (error) {
      throw ({ message: "Failed to delete widget", error });
    }
  }

  @Action({ rawError: true })
  public updateDashboardsSequence(sequence: { id: number, sequence: number }[]): void {
    const userId = this.context.rootGetters["User/userId"];
    let updatedDashboardsSequence: DashboardI[] = [];

    this._dashboards.forEach(dashboard => {
      if (dashboard.userId === userId) {
        dashboard.sequence = sequence.find(item => item.id === dashboard.id)?.sequence;
      }
      updatedDashboardsSequence.push(dashboard);
    });
    updatedDashboardsSequence = updatedDashboardsSequence.sort((a: any, b: any) => a.sequence - b.sequence);

    //  save personal dashboard sequence
    const stringifiedDashboardListSequence: string | null = localStorage.getItem("dashboardListSequence");
    if (stringifiedDashboardListSequence == null) {
      const dashboardListSequence = updatedDashboardsSequence.filter(d => d.userId === userId).map((d, i) => ({ id: d.id, sequence: i }));
      localStorage.setItem("dashboardListSequence", JSON.stringify(dashboardListSequence));
    }
    //  need to commit changes to trigger view update
    this.context.commit("SET_DASHBOARDS", updatedDashboardsSequence);
  }

  @Action({ rawError: true })
  public async refreshDashboards(): Promise<void> {
    try {
      const dashboards: DashboardI[] = await DashboardClient.getAll();
      //assigning the correct type object to each widget, as they only save the type-template-name in the database
      for (let i = 0; i < dashboards.length; i++) {
        for (let j = 0; j < dashboards[i].widgets.length; j++) {
          if (dashboards[i].widgets[j].typeTemplate === "TableWidget") {
            var typeTemplate = this.context.rootGetters["WidgetTypes/getTypeByTemplateName"]("VesselDataTable");
          } else if (dashboards[i].widgets[j].typeTemplate === "GraphWidget") {
            var typeTemplate = this.context.rootGetters["WidgetTypes/getTypeByTemplateName"]("VesselDataGraph");
          } else {
            var typeTemplate = this.context.rootGetters["WidgetTypes/getTypeByTemplateName"](dashboards[i].widgets[j].typeTemplate);
          }
          Vue.set(dashboards[i].widgets[j], "type", typeTemplate);
        }
      }

      this.context.commit("SET_DASHBOARDS", dashboards);

    } catch (error) {
      throw ({ message: "Failed to refresh dashboards", error });
    }
  }

  @Action({ rawError: true })
  public async createNewDashboard(name: string): Promise<DashboardI> {
    const newDashboard = {
      id: 0,
      name: name || "New Dashboard",
      widgets: [],
    };

    try {
      const createdDashboard = await DashboardClient.new(newDashboard);
      await this.context.dispatch("refreshDashboards");
      return createdDashboard;
    } catch (error) {
      throw ({ message: "Failed to add dashboard", error });
    }
  }

  @Action({ rawError: true })
  public async updateDashboard(dashboard: DashboardI): Promise<void> {
    try {
      await DashboardClient.update(dashboard);
      this.context.commit("UPDATE_DASHBOARD", dashboard);
    } catch (error) {
      throw ({ message: "Failed to update dashboard", error });
    }
  }

  @Action({ rawError: true })
  public async updateDashboardLayout(dashboard: DashboardI): Promise<void> {
    try {
      await DashboardClient.update(dashboard);
      this.context.commit("UPDATE_DASHBOARD_LAYOUT", dashboard);
    } catch (error) {
      throw ({ message: "Failed to save updated dashboard layout", error });
    }
  }

  @Action({ rawError: true })
  public async addWidget(widget: Widget): Promise<DashboardI> {
    const dashboard = this.activeDashboard;
    dashboard.widgets.push(widget);

    try {
      const updatedDashboard = await DashboardClient.update(dashboard);
      this.context.commit("ADD_WIDGET", widget);

      return updatedDashboard;
    } catch (error) {
      throw ({ message: "Failed to add new widget", error });
    }
  }

  @Action({ rawError: true })
  public async updateWidget(widgetData: Partial<Widget>): Promise<void> {
    const index = this.activeDashboard.widgets.findIndex(widget => widget.id === widgetData.id);

    if (index >= 0) {
      const dashboard = this.activeDashboard;
      dashboard.widgets[index] = Object.assign(dashboard.widgets[index], widgetData);

      try {
        await DashboardClient.update(dashboard);
        this.context.commit("UPDATE_WIDGET", { index, widgetData });
      } catch (error) {
        throw ({ message: "Failed to update widget", error });
      }
    } else {
      throw ({ message: "Could not find widget to update" });
    }
  }

  @Action({ rawError: true })
  public async deleteWidget(id: number): Promise<void> {
    const index = this.activeDashboard.widgets.findIndex(widget => widget.id === id);

    if (index >= 0) {
      const dashboard = this.activeDashboard;
      dashboard.widgets.splice(index, 1);

      try {
        await DashboardClient.update(dashboard);
        this.context.commit("DELETE_WIDGET", index);
      } catch (error) {
        throw ({ message: "Failed to delete widget", error });
      }
    } else {
      throw ({ message: "Could not find widget to delete" });
    }
  }

  //If there are no dashboards, create one
  @Action({ rawError: true })
  public async initializeDashboard(): Promise<void> {
    try {
      await this.context.dispatch("refreshDashboards");

      if (!this._dashboards.length) {
        await this.context.dispatch("createNewDashboard");
      }

      this.context.commit("SET_ACTIVE_DASHBOARD");
    } catch (error) {
      throw ({ message: "Failed to initialize dashboard", error });
    }
  }

  @Action({ rawError: true })
  public getWidgetsOfSelectedType(typeName: string): Widget[] {
    return this._dashboards[this._activeDashboardIndex].widgets.filter(widget => widget.type?.defaultName === typeName);
  }

  get activeDashboardWidgets(): Widget[] {
    return this._dashboards[this._activeDashboardIndex]?.widgets;
  }

  get activeWidgets(): Widget[] {
    return this._dashboards[this._activeDashboardIndex].widgets.filter(widget => widget.active);
  }

  get dashboards(): DashboardI[] {
    return this._dashboards;
  }

  get activeDashboard(): DashboardI {
    //using JSON to create unlinked, deep copy of the active dashboard.
    return JSON.parse(JSON.stringify(this._dashboards[this._activeDashboardIndex]));
  }

  get dashboardById() {
    return (id: number): DashboardI => {
      const dashboard = this._dashboards.find(dashboard => dashboard.id === id);

      if (!dashboard) throw new Error("Found no dashboard with id " + id);
      else return dashboard;
    };
  }
}

export default Dashboard;
