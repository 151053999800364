import ko from "knockout";
import $ from "jquery";

ko.bindingHandlers.submitForm = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var isUploading = valueAccessor().isUploading;

        $(element).change(function(something) {
            isUploading(true);
            this.form.submit();
        });
    },
};