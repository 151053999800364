import template from "./vessel-admin.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import vesselsAdministrationsClient from "Clients/vessels-administrations-client";
import companiesClient from "Clients/companies-client";
import Vessel from "ViewModels/vesselsAdministration/vessel";

function ViewModel() {
    var self = this;

    self.editVessel = ko.observable();
    self.previousMenuItem = ko.observable();
    self.menuItem = ko.observable("unassignedVessels");
    self.isUnasssignedVesselsLoading = ko.observable(true);
    self.isCompaniesLoading = ko.observable(true);
    self.isLoadError = ko.observable(false);
    self.unassignedVessels = ko.observableArray([]);
    self.assignedVessels = ko.observableArray([]);
    self.archivedVessels = ko.observableArray([]);
    self.companies = ko.observableArray([]);

    self.getVessels();
    self.getCompanies();

    self.vessels = ko.pureComputed(function () {
        var menuItem = self.menuItem();
        if (menuItem === "assignedVessels") {
            return self.assignedVessels();
        } else if (menuItem === "archivedVessels") {
            return self.archivedVessels();
        }
        return self.unassignedVessels();
    });

    self.visibleView = ko.pureComputed(function () {
        return self.menuItem();
    });

    self.arraySortedEvent = events.vesselArraySorted.add(function(array) {
        var menuItem = self.menuItem();
        if (menuItem === "assignedVessels") {
            self.assignedVessels(array);
        } else if (menuItem === "archivedVessels") {
            self.archivedVessels(array);
        } else {
            self.unassignedVessels(array);
        }
    });

    self.menuItemClickEvent = events.menuItemClick.add(function (menuItem, previousMenuItem, vessel) {
        self.menuItem(menuItem);
        self.previousMenuItem(previousMenuItem);
        if (vessel) {
            self.editVessel(vessel);
        } else {
            self.editVessel(new Vessel());
        }

        if (menuItem === "editVessel") {
            events.findDataReceiver.dispatch();
        }

        if (previousMenuItem === "editVessel") {
            self.getVessels();
        }
    });

    self.vesselUpdatedEvent = events.vesselAdministrationUpdated.add(function(updateType) {
        self.getVessels();

        if (updateType === "editVessel" && self.menuItem() === "editVessel") {
            events.menuItemClick.dispatch(self.previousMenuItem(), self.menuItem());
        }
    });

    self.vesselDeletedEvent = events.vesselDeleted.add(function() {
        self.getVessels();
    });
}

ViewModel.prototype.getVessels = function () {
    var self = this;
    vesselsAdministrationsClient.getVessels().done(function (data) {
        self.mapVessels(data);
    }).fail(function () {
        self.isLoadError(true);
    }).always(function () {
        self.isUnasssignedVesselsLoading(false);
    });
};

ViewModel.prototype.mapVessels = function(vessels) {
    var self = this;
    var unassignedVessels = [];
    var assignedVessels = [];
    var archivedVessels = [];
    _.each(vessels, function (vessel) {
        var activeStatus = vessel.activeStatus;
        if (activeStatus === "Unassigned") {
            unassignedVessels.push(new Vessel(vessel, self.companies()));
        } else if (activeStatus === "Active" || activeStatus === "Assigned") {
            assignedVessels.push(new Vessel(vessel, self.companies()));
        } else if (activeStatus === "Archived") {
            archivedVessels.push(new Vessel(vessel, self.companies()));
        }
    });

    self.unassignedVessels(unassignedVessels);
    self.assignedVessels(assignedVessels);
    self.archivedVessels(archivedVessels);
};

ViewModel.prototype.getCompanies = function () {
    var self = this;
    companiesClient.getAll().done(function (data) {
        self.companies(data);
    }).fail(function() {
        self.isLoadError(true);
    }).always(function() {
        self.isCompaniesLoading(false);
    });
};

ViewModel.prototype.dispose = function () {
    this.menuItemClickEvent.detach();
    this.vesselUpdatedEvent.detach();
    this.vesselDeletedEvent.detach();
    this.arraySortedEvent.detach();
};

export default { viewModel: ViewModel, template: template };
