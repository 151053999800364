import template from "./user-info.html";
import ko from "knockout";
import koValidation from "knockout.validation";
import _ from "underscore";

function ViewModel(params) {
    var self = this;

    self.user = params.user;
}

export default { viewModel: ViewModel, template: template };