import net from "App/net";
import events from "App/events";

var LongTrendsClient = function () {

};

LongTrendsClient.prototype.findLongTrendVariables = function (vesselId) {
    var filter = {
        vesselId: vesselId,
        isDiagnosticType: false,
    };

    return net.get("/LongTrends/Find", filter);
};

LongTrendsClient.prototype.findLongTrends = function (vesselId) {
    var filter = {
        vesselId: vesselId,
        isDiagnosticType: true,
    };

    return net.get("/LongTrends/Find", filter);
};

LongTrendsClient.prototype.update = function (longTrend) {
    return net.put("/LongTrends", longTrend).done(function (data) {
        events.longTrendUpdated.dispatch(data);
    });
};

LongTrendsClient.prototype.getLongTrendData = function (longTrendIds) {
  var filter = {
    longTrendIds: longTrendIds,
  };

  return net.get("/LongTrends/Data", filter);
};

LongTrendsClient.prototype.downloadExcel = function(vesselId) {
  net.fileDownload("/LongTrends/Excel?vesselId=" + vesselId, "GET");
};

export default new LongTrendsClient();
