import net from "App/net";

var CompaniesClient = function () {

};

CompaniesClient.prototype.getAll = function () {
    return net.get("/Companies");
};

CompaniesClient.prototype.create = function (company) {
    return net.post("/Companies", company);
};

CompaniesClient.prototype.update = function (company) {
    return net.put("/Companies/" + company.id, company);
};

CompaniesClient.prototype.remove = function (company) {
    return net.del("/Companies/" + company.id);
};


export default new CompaniesClient();