import template from "./tooltip.html";
import ko from "knockout";
import _ from "underscore";
import $ from "jquery";
import events from "App/events";
import "jquery-ui/ui/widgets/tooltip";

function ViewModel(params) {
    var self = this;
    self.params = ko.observable();
    self.component = ko.observable();
    self.anchorElement = ko.observable();
    self.visible = ko.observable(false);
    self.tooltipPosition = ko.observable();

    var timeoutHandles = [];

    self.showTooltipBinding = events.showTooltip.add(function(component, params, anchorElement) {
        self.component(component);
        self.params(params);
        self.anchorElement(anchorElement);
        self.tooltipPosition(params.tooltipPosition);
    });

    //Don't know why, but the tooltip component places the box opposite
    //of what it's told so I use a switch to "make it right again".
    self.position = ko.pureComputed(function() {
        switch (self.tooltipPosition()) {
            case "right":
                return "left";
            case "left": 
                return "right";
            case "bottom":
                return "top";
            default:
                return "bottom";
        }
    });

    //Positions the tooltip so that the arrow tip does not overlap the subject.
    self.positionOffset = ko.pureComputed(function() {
        switch (self.tooltipPosition()) {
            case "right":
                return "right+15";
            case "left": 
                return "right-25";
            case "bottom":
                return "top+25";
            default:
                return "top-10";
        }
    });
    
    var positionTooltip = function(element) {
        var timeoutHandle = setTimeout(function() {
            self.visible(true);
            $("#tooltip").position({
                my: self.position(),
                at: self.positionOffset(),
                collision: "fit",
                of: element,
            });
        }, 100);
        timeoutHandles.push(timeoutHandle);
    };

    self.anchorElementSubscription = self.anchorElement.subscribe(function(element) {
        positionTooltip(element);
    });

    self.closeTooltipBinding = events.closeTooltip.add(function() {
        self.visible(false);
        self.params(null);
        self.component(null);

        var clearedTimeoutHandles = [];
        _.each(timeoutHandles, function(timeoutHandle) {
            clearTimeout(timeoutHandle);
            clearedTimeoutHandles.push(timeoutHandle);
        });
        timeoutHandles = _.difference(timeoutHandles, clearedTimeoutHandles);
    });

    self.repositionTooltipBinding = events.repositionTooltip.add(function() {
        var anchorElement = self.anchorElement();
        if (!anchorElement) return;
        positionTooltip(anchorElement);
    });
}

ViewModel.prototype.getPosition = function () {
    return this.tooltipPosition() ? this.tooltipPosition() : "top";
};

ViewModel.prototype.dispose = function() {
    this.showTooltipBinding.detach();
    this.closeTooltipBinding.detach();
    this.anchorElementSubscription.dispose();
};

export default { viewModel: ViewModel, template: template };