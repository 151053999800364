import template from "./flow-meter-management.html";
import ko from "knockout";
import _ from "underscore";

var Tab = function(name, componentName) {
    this.name = name;
    this.componentName = componentName;
};

function ViewModel(params) {
    var self = this;
    self.departureVoyageVesselEvent = params.departureVoyageVesselEvent;
    self.endOfVoyageVesselEvent = params.endOfVoyageVesselEvent;
    self.selectedVoyageVesselEvent = params.selectedVoyageVesselEvent;
    self.voyageVesselEventData = params.selectedVoyageVesselEvent.voyageVesselEventData;
    self.flowMeters = self.selectedVoyageVesselEvent.activeOrInUseFlowMeters;

    self.selectedFlowMeter = ko.observable(self.flowMeters()[0]);

    self.tabs = [
        new Tab("Fuel consumption", "fm-fuel-consumptions"),
        new Tab("Operations", "fm-operations"),
    ];
    self.selectedTab = ko.observable(self.tabs[0]);

    self.selectTab = function(tab) {
        self.selectedTab(tab);
    }.bind(self);
}

ViewModel.prototype.dispose = function() {
};

export default { viewModel: ViewModel, template: template };