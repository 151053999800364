import net from "App/net";

var VesselDataWidgetClient = function () {

};

VesselDataWidgetClient.prototype.getAll = function () {
    return net.get("/TableWidgetConfiguration");
};

VesselDataWidgetClient.prototype.new = function (vesselDataWidgetConfiguration) {
    return net.post("/TableWidgetConfiguration", vesselDataWidgetConfiguration);
};

VesselDataWidgetClient.prototype.update = function (vesselDataWidgetConfiguration) {
    return net.put("/TableWidgetConfiguration/" + vesselDataWidgetConfiguration.id, vesselDataWidgetConfiguration);
};

VesselDataWidgetClient.prototype.delete = function (vesselDataWidgetConfigId) {
    return net.del("/TableWidgetConfiguration/" + vesselDataWidgetConfigId);
};

export default new VesselDataWidgetClient();
