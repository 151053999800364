import net from "App/net";
import events from "App/events";

var Client = function() {

};

Client.prototype.find = function(vesselId) {
    var filter = {
        vesselId: vesselId,
    };

    return net.get("/FlowMeters/Find", filter);
};

Client.prototype.create = function(flowMeter) {
    return net.post("/FlowMeters", flowMeter);
};

Client.prototype.remove = function(flowMeter) {
    return net.del("/FlowMeters/" + flowMeter.id).done(function() {
        events.flowMeterDeleted.dispatch(flowMeter);
    });
};

export default new Client();