import net from "App/net";

var Client = function() {

};

Client.prototype.find = function(query, page, limit) {
    var filter = {
        query: query,
    };

    if (page) {
        filter.page = page;
    }

    if (limit) {
        filter.limit = limit;
    }

    return net.get("/Ports/Find", filter);
};

export default new Client();