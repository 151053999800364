import { VuexModule, Module, Mutation, Action } from "vuex-module-decorators";
import { VesselAdmin } from "@/types/vesselAdmin";
import vesselsAdminClient from "Clients/vessels-administrations-client";


@Module({ namespaced: true, name: "VesselsAdmin" })
class VesselsAdmin extends VuexModule {
  private _vesselsExpired = true;
  private _vesselsAdminAll: VesselAdmin[] = [];
  private _vesselsAdminUnassigned: VesselAdmin[] = [];
  private _vesselsAdminActive: VesselAdmin[] = [];
  private _vesselsAdminInactive: VesselAdmin[] = [];


  @Mutation
  public REFRESH_VESSELS(vessels: VesselAdmin[]): void {
    this._vesselsExpired = false;
    this._vesselsAdminAll = vessels;
    this._vesselsAdminUnassigned = [];
    this._vesselsAdminActive = [];
    this._vesselsAdminInactive = [];

    vessels.forEach(vessel => {
      const activeStatus = vessel.activeStatus;
      switch (activeStatus) {
        case "Unassigned":
          this._vesselsAdminUnassigned.push(vessel);
          break;
        case "Active":
          this._vesselsAdminActive.push(vessel);
          break;
        case "Assigned":
          this._vesselsAdminInactive.push(vessel);
          break;
      }
    });
  }

  @Mutation
  public EXPIRE_DATA(): void {
    this._vesselsExpired = true;
  }

  @Action({ rawError: true })
  public async refreshAllVessels(): Promise<void> {
    let data = [];
    try {
      data = await vesselsAdminClient.getVessels();
    } catch (error) {
      console.warn(error);
    }
    this.context.commit("REFRESH_VESSELS", data);
  }

  @Action({ rawError: true })
  public async getAll(): Promise<VesselAdmin[]> {
    if (!this._vesselsExpired) {
      return this._vesselsAdminAll;
    } else {
      await this.refreshAllVessels();
      return this._vesselsAdminAll;
    }
  }

  get vesselsAdminAll(): VesselAdmin[] {
    return this._vesselsAdminAll;
  }
  get vesselsAdminUnassigned(): VesselAdmin[] {
    return this._vesselsAdminUnassigned;
  }
  get vesselsAdminActive(): VesselAdmin[] {
    return this._vesselsAdminActive;
  }
  get vesselsAdminInactive(): VesselAdmin[] {
    return this._vesselsAdminInactive;
  }
  get UnassignedVesselsCount(): number {
    return this._vesselsAdminUnassigned.length;
  }
  get ActiveVesselsCount(): number {
    return this._vesselsAdminActive.length;
  }
  get InactiveVesselsCount(): number {
    return this._vesselsAdminInactive.length;
  }
}

export default VesselsAdmin;
