import template from "./long-trend-benchmark-level-settings.html";
import ko from "knockout";
import _ from "underscore";
import store from "@/store";
import longTrendsClient from "Clients/long-trends-client";

function ViewModel(params) {
    var self = this;
    self.isUpdating = ko.observable(false);
    self.longTrend = params.longTrend;
    self.useInVesselStatus = ko.observable();
    self.useManualBenchmarkLevel = ko.observable();
    self.manualBenchmarkLevel = ko.observable();
    self.updateError = ko.observable();

    self.validationMessages = ko.pureComputed(function () {
        var minManualBenchmarkLevel = -40;
        var maxManualBenchmarkLevel = 40;
        var manualBenchmarkLevel = parseFloat(self.manualBenchmarkLevel());

        var validationMessages = [];
        if (!_.isFinite(manualBenchmarkLevel) || manualBenchmarkLevel < minManualBenchmarkLevel || manualBenchmarkLevel > maxManualBenchmarkLevel) {
            validationMessages.push("Manual benchmark level must be a number between " + minManualBenchmarkLevel + " and " + maxManualBenchmarkLevel);
        }
        return validationMessages;
    });

    self.initialize();
}

ViewModel.prototype.initialize = function () {
    var longTrend = this.longTrend();
    this.useInVesselStatus(longTrend.useInVesselStatus);
    this.useManualBenchmarkLevel(longTrend.useManualBenchmarkLevel);
    this.manualBenchmarkLevel(longTrend.manualBenchmarkLevel);
};

ViewModel.prototype.submit = function () {
    var self = this;
    if (self.validationMessages().length > 0) {
        return;
    }
    self.updateError(null);

    var longTrend = _.clone(self.longTrend());
    longTrend.useManualBenchmarkLevel = self.useManualBenchmarkLevel();
    longTrend.manualBenchmarkLevel = parseFloat(self.manualBenchmarkLevel());

    self.isUpdating(true);
    longTrendsClient.update(longTrend).done(function (data) {
        self.longTrend(data);
        store.dispatch("Vessels/getVesselPerformanceStatus", data.vesselId);
    }).fail(function (e) {
        self.updateError("An error occured during update");
    }).always(function () {
        self.initialize();
        self.isUpdating(false);
    });
};

ViewModel.prototype.dispose = function () {
};

export default { viewModel: ViewModel, template: template };
