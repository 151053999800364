import template from "./voyage-vessel-event-distance.html";
import ko from "knockout";
import _ from "underscore";
import emissionReportVesselEventsClient from "Clients/emission-report-vessel-events-client";

function ViewModel(params) {
    var self = this;
    self.voyageVesselEvent = params.voyageVesselEvent;
    self.voyageVesselEventData = self.voyageVesselEvent.voyageVesselEventData;
    self.vesselEvent = params.voyageVesselEvent.vesselEvent;
    self.isVoyageDataConfirmed = self.voyageVesselEvent.isVoyageDataConfirmed;
    self.distance = self.voyageVesselEventData.distance;
    self.hasCorrectedDistance = self.voyageVesselEventData.hasCorrectedDistance;
    self.totalDistanceByGPS = self.voyageVesselEventData.totalDistanceByGPS;
    self.currentDistance = self.voyageVesselEventData.currentDistance;
    self.inputManualDistanceByGPS = ko.observable();
    self.isSaving = ko.observable(false);

    var setInitialInputManualDistanceByGPS = function() {
        var currentDistance = self.currentDistance();
        self.inputManualDistanceByGPS(currentDistance);
    };

    setInitialInputManualDistanceByGPS();
    self.distanceBinding = self.distance.subscribe(function() {
        setInitialInputManualDistanceByGPS();
    });

    self.isEditing = ko.observable(false);
}

ViewModel.prototype.dispose = function() {
    this.distanceBinding.dispose();
};

ViewModel.prototype.startEditing = function(vm, e) {
    var self = this;
    self.isEditing(true);

    var $input = $(e.currentTarget).closest(".edit-wrapper").find("input");
    $input.focus();
    $input.select();

    $input.off("keyup");
    $input.on("keyup",
        function(e) {
            if (e.keyCode === 27) { // escape key
                self.cancelEditing();
            }
        });
};

ViewModel.prototype.cancelEditing = function(vm, e) {
    this.isEditing(false);
};

ViewModel.prototype.save = function() {
    var self = this;
    var vesselEvent = _.clone(self.vesselEvent());
    vesselEvent.distanceSailed = vesselEvent.distanceSailed || {};
    vesselEvent.distanceSailed.manualDistanceByGPS = parseFloat(self.inputManualDistanceByGPS());

    self.isSaving(true);
    emissionReportVesselEventsClient.update(vesselEvent).done(function(updatedVesselEvent) {
        self.vesselEvent(updatedVesselEvent);
        self.cancelEditing();
    }).always(function() {
        self.isSaving(false);
    });
};

export default { viewModel: ViewModel, template: template };