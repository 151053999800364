import template from "./voyage-vessel-event-location.html";
import ko from "knockout";
import _ from "underscore";
import moment from "moment";
import vesselEventsClient from "Clients/vessel-events-client";
import vesselLocationsClient from "Clients/vessel-locations-client";
import dateHelper from "Utilities/date-helper";
import events from "App/events";

function ViewModel(params) {
    var self = this;
    self.voyageVesselEvent = params.voyageVesselEvent;
    self.vesselEvent = params.voyageVesselEvent.vesselEvent;
    self.nearestLocation = ko.observable();
    self.inputGeoCoordinate = ko.observable();
    self.isLoading = ko.observable(false);
    self.isEditing = ko.observable(false);
    self.loadingFailed = ko.observable(false);
    self.initialize();
}

ViewModel.prototype.initialize = function() {
    var self = this;
    var vesselEvent = self.vesselEvent();
    var vesselLocations = [];
    var fromDate = moment.utc(vesselEvent.timestamp).add(-2, "hours");
    var toDate = moment.utc(vesselEvent.timestamp).add(2, "hours");
    var queryFromDate = dateHelper.getSubmitDateString(dateHelper.getFormatedDateString(fromDate), dateHelper.getFormatedTimeString(fromDate));
    var queryToDate = dateHelper.getSubmitDateString(dateHelper.getFormatedDateString(toDate), dateHelper.getFormatedTimeString(toDate));

    self.nearestLocation(null);
    self.isLoading(true);

    var vesselEventTimestamp = moment.utc(vesselEvent.timestamp);
    vesselLocationsClient.find(vesselEvent.vesselId, queryFromDate, queryToDate).done(function(data) {
        vesselLocations = data;

        var sortedByNearness = _.sortBy(vesselLocations,
            function(vesselLocation) {
                return Math.abs(moment.utc(vesselLocation.timestamp).diff(vesselEventTimestamp));
            });

        var firstNearestLocation = sortedByNearness[0];
        var equallyNearVesselLocations = _.filter(sortedByNearness,
            function(vesselLocation) {
                return vesselLocation.timestamp === firstNearestLocation.timestamp;
            });

        // Location from vessel event is the preferred source
        var vesselEventLocation = _.find(equallyNearVesselLocations,
            function(vesselLocation) {
                return vesselLocation.source === "VesselEvent";
            });
        if (vesselEventLocation)
        {
            self.nearestLocation(vesselEventLocation);
            return;
        }

        // Location from log data is then the preferred source
        var logDataLocation = _.find(equallyNearVesselLocations,
            function(vesselLocation) {
                return vesselLocation.source === "LogData";
            });
        if (logDataLocation)
        {
            self.nearestLocation(logDataLocation);
            return;
        }
        // Location from vessel history is the third source and last option
        self.nearestLocation(firstNearestLocation);
    }).fail(function(xhr) {
        self.loadingFailed(true);
        console.error("Error loading vessel locations.", xhr);
    }).always(function() {
        self.isLoading(false);
    });
};

ViewModel.prototype.startEditing = function() {
    this.isEditing(true);
};

ViewModel.prototype.cancelEditing = function() {
    events.closeTooltip.dispatch();
    this.isEditing(false);
};

ViewModel.prototype.save = function() {
    var self = this;
    var vesselEvent = _.clone(self.vesselEvent());
    vesselEvent.location = self.inputGeoCoordinate();

    vesselEventsClient.update(vesselEvent).done(function() {
        self.initialize();
        self.cancelEditing();
    });
};

export default { viewModel: ViewModel, template: template };