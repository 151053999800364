import ko from "knockout";
import _ from "underscore";

ko.bindingHandlers.curveTableSort = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
        var asc = true;
        valueAccessor().array.sort(function (a, b) { return a.startDate - b.startDate; });
        element.onclick = function() {
            var array = valueAccessor().array;
            var column = valueAccessor().column;
            var parentId = getParentId(element);
            asc = !asc;

            updateSortIcons(element, asc);

            array.sort(function (a, b) {
                var dataValues = getDataValues(a, b, column, parentId, valueAccessor);
                var left = dataValues.left;
                var right = dataValues.right;

                return left === right ? compareValues(asc, a.startDate, b.startDate) : compareValues(asc, left, right);
            });

            highlightColumn(element);
        };
    },
};

function getParentId(element) {
    var table = $(element).closest("table");
    return table[0].id;
}

function getDataValues(a, b, column, parentId, valueAccessor) {
    var values;
    if (parentId === "curveDataTable") {
        values = getCurveDataValues(a, b, column, valueAccessor);
    } else if (parentId === "cpModuleTable") {
        values = getCpModuleValues(a, b, column);
    }
    return values;
}

function getCurveDataValues(a, b, column, valueAccessor) {
    var left = ko.utils.unwrapObservable(a[column]);
    var right = ko.utils.unwrapObservable(b[column]);

    if (column === "deviationFromReference") {
        left = a.getDeviationFromReferenceYAxis();
        right = b.getDeviationFromReferenceYAxis();
    } else if (column === "xAxisVariable" || column === "yAxisVariable") {
        left = a[column]().average;
        right = b[column]().average;
    } else if (column === "additionalVariable") {
        var id = valueAccessor().id;
        left = a.getAverageValueOfVariable(id);
        right = b.getAverageValueOfVariable(id);
    }

    return { left: left, right: right };
}

function getCpModuleValues(a, b, column) {
    var left = ko.utils.unwrapObservable(a[column]);
    var right = ko.utils.unwrapObservable(b[column]);
    a = a.cpModule();
    b = b.cpModule();
    if (column === "fuelConsumption") {
        left = a.actualFuelConsumption();
        right = b.actualFuelConsumption();
    } else if (column === "referenceFuelConsumption") {
        left = a.referenceFuelConsumption();
        right = b.referenceFuelConsumption();
    } else if (column === "cpDifference") {
        left = a.differenceBetweenConsumptions();
        right = b.differenceBetweenConsumptions();
    } else if (column === "cpDistance") {
        left = a.distanceTravelled();
        right = b.distanceTravelled();
    }

    return { left: left, right: right };
}

function highlightColumn(element) {
    var tableRows = document.querySelectorAll(".curveDataTable tr");
    var elementIndex = _.indexOf(tableRows[0].children, element);

    _.each(tableRows, function (row, index) {
        var child = row.children[elementIndex];
        if (index === 0) {
            child.className = "highlightedColumnFirst";
        } else if (index % 2 === 0) {
            child.className = "highlightedColumnEven";
        } else {
            child.className = "highlightedColumnOdd";
        }

        removeHighlighted(row, elementIndex);
    });
}

function removeHighlighted(row, currentIndex) {
    _.each(row.children, function(child, index) {
        if (index != currentIndex) {
            if (child.className !== "") {
                child.className = "";
            }
        }
    });
}

function updateSortIcons(element, asc) {
    var icon = element.getElementsByTagName("i")[0];
    var otherIcons = document.getElementsByClassName("curveTableSortIcon");
    var className = asc ? " fa fa-sort-asc" : " fa fa-sort-desc";

    _.each(otherIcons, function (icon) {
        icon.className = "curveTableSortIcon";
    });

    icon.className += className;
}

function compareValues(asc, left, right) {
    if (!isFinite(left - right)) {
        return !isFinite(left) ? 1 : -1;
    } else {
        if (left === right) {
                
        }
        return asc ? left - right : right - left;
    }
}