import template from "./company-table.html";
import ko from "knockout";
import events from "App/events";
import _ from "underscore";
import cookie from "js-cookie";

function ViewModel(params) {
    var self = this;

    self.companyList = params.companyList.getList();
    self.isLoadingCompanyList = params.companyList.isLoading();

    var initialCompanySortParameter = cookie.get("companySortParameter");
    if (!initialCompanySortParameter) {
        cookie.set("companySortParameter", "company", { expires: 20 * 365 });
        initialCompanySortParameter = "company";
    }

    var initialCompanySortDirection = cookie.get("companySortDirection");
    if (!initialCompanySortDirection) {
        cookie.set("companySortDirection", "asc", { expires: 20 * 365 });
        initialCompanySortDirection = "asc";
    }

    self.companySortParameter = ko.observable(initialCompanySortParameter);
    self.companySortDirection = ko.observable(initialCompanySortDirection);

    self.sortedCompanyList = ko.pureComputed(function () {
        var companyList = self.companyList();
        var companySortParameter = self.companySortParameter();
        var companySortDirection = self.companySortDirection();

        var sorted = _.sortBy(companyList, function (company) {
            var value = self.getCompanySortValue(company, companySortParameter);

            // 'Case insensitive' sorting
            if (typeof value === "string" || value instanceof String) {
                value = value.toUpperCase();
            }

            return value;
        });
        return companySortDirection === "asc" ? sorted : sorted.reverse();
    });
}

ViewModel.prototype.getCompanySortValue = function(company, sortParameter) {
    switch (sortParameter) {
        case "company":
            return company.name;
        case "created":
            return company.createdDate;
        case "vessels":
            return company.vesselCount;
        case "subscription":
            return company.subscriptionStatus;
        default:
            throw "Unexpected sort parameter: " + sortParameter;
    }
};

ViewModel.prototype.getHeaderSortCssClass = function (parameter) {
    if (parameter !== this.companySortParameter()) {
        return "sortable";
    }
    return this.companySortDirection() === "asc" ? "sortable sorted up" : "sortable sorted down";
};

ViewModel.prototype.sortBy = function (sortParameter) {
    var companySortParameter = this.companySortParameter();
    if (companySortParameter !== sortParameter) {
        this.companySortParameter(sortParameter);
        cookie.set("companySortParameter", sortParameter, { expires: 20 * 365 });
    } else {
        this.toggleSortDirection();
    }
};

ViewModel.prototype.toggleSortDirection = function () {
    var newCompanySortDirection = this.companySortDirection() === "asc" ? "desc" : "asc";
    this.companySortDirection(newCompanySortDirection);
    cookie.set("companySortDirection", newCompanySortDirection, { expires: 20 * 365 });
};

ViewModel.prototype.viewCompanyClick = function(company) {
    events.companyView.dispatch(company);
};

ViewModel.prototype.viewAsCompanyClick = function(company) {
    events.companyViewAs.dispatch(company);
};

ViewModel.prototype.editCompanyClick = function(company) {
    events.companyEdit.dispatch(company);
};

ViewModel.prototype.deleteCompanyClick = function(company) {
    events.companyDelete.dispatch(company);
};

ViewModel.prototype.addCompanyClick = function() {
    events.companyAdd.dispatch();
};

export default { viewModel: ViewModel, template: template };