import template from "./compare-vessels.html";
import ko from "knockout";
import _ from "underscore";
import events from "App/events";
import longTrendPeriodsClient from "Clients/long-trend-periods-client";
import diagnosticTypesHelper from "Utilities/diagnostic-types-helper";

function findDiagnosticLongTrends(vessels) {
  var allLongTrends = _.flatten(_.pluck(vessels, "longTrends"));
  return _.filter(allLongTrends, function (longTrend) {
    return longTrend.diagnosticTypeId;
  });
}

function findCommonLongTrendNames(longTrends, vesselCount) {
  var commonDiagnosticLongTrendNames = [];
  var longTrendsGroupedByName = _.groupBy(longTrends, "name");
  _.each(longTrendsGroupedByName, function (group, name) {
    if (group.length === vesselCount) {
      commonDiagnosticLongTrendNames.push(name);
    }
  });
  return commonDiagnosticLongTrendNames;
}

function CompareGroup(vessels, longTrendName, longTrends, longTrendPeriods) {
  this.vessels = vessels;
  this.longTrendName = longTrendName;
  this.longTrends = longTrends;
  this.longTrendPeriods = longTrendPeriods;
  this.sortOrder = diagnosticTypesHelper.getDiagnosticTypeOrder(longTrends[0].diagnosticType);
}

function ViewModel(params) {
  var self = this;
  self.vessels = ko.observable(params.vessels);
  self.isLoadingLongTrendPeriods = ko.observable(true);
  self.longTrendPeriods = ko.observable();
  self.compareGroups = ko.observable();
  self.selectedCompareGroup = ko.observable();

  self.diagnosticLongTrends = [];
  self.commonDiagnosticLongTrendNames = [];

  self.isLoading = ko.pureComputed(function () {
    var isLoadingLongTrendPeriods = self.isLoadingLongTrendPeriods();
    return isLoadingLongTrendPeriods;
  });

  self.vesselsSubscription = self.vessels.subscribe(function (vessels) {
    self.diagnosticLongTrends = findDiagnosticLongTrends(vessels);
    self.commonDiagnosticLongTrendNames = findCommonLongTrendNames(self.diagnosticLongTrends, vessels.length);

    var allLongTrendIds = [];
    _.each(self.commonDiagnosticLongTrendNames, function (longTrendName) {
      var commonLongTrends = _.filter(self.diagnosticLongTrends, function (longTrend) {
        return longTrend.name === longTrendName;
      });

      allLongTrendIds = _.union(allLongTrendIds, _.pluck(commonLongTrends, "id"));
    });
    self.loadLongTrendPeriods(allLongTrendIds);
  });

  self.longTrendPeriodsBinding = self.longTrendPeriods.subscribe(function (longTrendPeriods) {
    var compareGroups = [];
    _.each(self.commonDiagnosticLongTrendNames, function (longTrendName) {
      var commonLongTrends = _.filter(self.diagnosticLongTrends, function (longTrend) {
        return longTrend.name === longTrendName;
      });
      var commonLongTrendIds = _.pluck(commonLongTrends, "id");

      var commonLongTrendPeriods = _.filter(longTrendPeriods, function (longTrendPeriod) {
        return _.contains(commonLongTrendIds, longTrendPeriod.longTrendId);
      });
      compareGroups.push(new CompareGroup(self.vessels(), longTrendName, commonLongTrends, commonLongTrendPeriods));
    });
    self.compareGroups(_.sortBy(compareGroups, "sortOrder"));
  });

  self.compareGroupClickedBinding = events.compareGroupClicked.add(function (compareGroup) {
    self.selectedCompareGroup(compareGroup);
  });

  self.load();
}

ViewModel.prototype.dispose = function () {
  this.compareGroupClickedBinding.detach();
};

ViewModel.prototype.load = function () {
  var self = this;
  var vessels = self.vessels();
  self.diagnosticLongTrends = findDiagnosticLongTrends(vessels);
  self.commonDiagnosticLongTrendNames = findCommonLongTrendNames(self.diagnosticLongTrends, vessels.length);

  var allLongTrendIds = [];
  _.each(self.commonDiagnosticLongTrendNames, function (longTrendName) {
    var commonLongTrends = _.filter(self.diagnosticLongTrends, function (longTrend) {
      return longTrend.name === longTrendName;
    });

    allLongTrendIds = _.union(allLongTrendIds, _.pluck(commonLongTrends, "id"));
  });
  self.loadLongTrendPeriods(allLongTrendIds);
};

ViewModel.prototype.deselectCompareGroup = function () {
  this.selectedCompareGroup(null);
};

ViewModel.prototype.navigateHome = function () {
  events.navigatedHome.dispatch();
};

ViewModel.prototype.loadLongTrendPeriods = function (longTrendIds) {
  var self = this;
  longTrendPeriodsClient.findMany(longTrendIds).done(function (data) {
    self.longTrendPeriods(data);
  })
    .always(function () {
      self.isLoadingLongTrendPeriods(false);
    });
};

export default { viewModel: ViewModel, template: template };
