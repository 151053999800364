import template from "./curves-about.html";
import ko from "knockout";

function ViewModel() {
    var self = this;
    self.rendered = ko.observable(false);
    setTimeout(function() {
        self.rendered(true);
    }, 0);
}

export default { viewModel: ViewModel, template: template };
