import Component from "vue-class-component";

// Register the router hooks with their names,
// See https://github.com/vuejs/vue-class-component section about
// router hooks for more information on why this must be done
Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);
