import template from "./ft-fuel-consumption.html";
import configEmissionReportFactory from "App/config-emission-report";

function ViewModel(params) {
    var self = this;
    self.configEmissionReport = configEmissionReportFactory.get();
    self.fuelTankFuelConsumption = params.fuelTankFuelConsumption;
    var fuelTypesMap = self.configEmissionReport.fuelTypesMap();
    self.fuelTypeName = fuelTypesMap[self.fuelTankFuelConsumption.fuelTypeId].name;
}

ViewModel.prototype.dispose = function() {
};

export default { viewModel: ViewModel, template: template };