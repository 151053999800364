import { VuexModule, Module, Action } from "vuex-module-decorators";
import logDataClient from "Clients/log-data-client";
import { CalculatedLogDataQuery } from "@/types/calculatedLogDataQuery";

@Module({ namespaced: true, name: "LogData" })
class LogData extends VuexModule {

  @Action({ rawError: true })
  public async fetchAccumulatedCalculatedLogData(payload: CalculatedLogDataQuery): Promise<void> {
    try {
      const data = await logDataClient.fetchAccumulatedCalculatedLogData(payload);
      return data;
    } catch (error) {
      console.warn(error);
    }
  }

  @Action({ rawError: true })
  public async fetchAveragedCalculatedLogData(payload: CalculatedLogDataQuery): Promise<void> {
    try {
      const data = await logDataClient.fetchAveragedCalculatedLogData(payload);
      return data;
    } catch (error) {
      console.warn(error);
    }
  }

  @Action({ rawError: true })
  public async fetchLastReceivedLogData(payload: CalculatedLogDataQuery): Promise<void> {
    try {
      const data = await logDataClient.fetchLastReceivedLogData(payload.logVariableIds);
      return data;
    } catch (error) {
      console.warn(error);
    }
  }

  @Action({ rawError: true })
  public async fetchLastVoyageAccumulated(payload: CalculatedLogDataQuery): Promise<void> {
    try {
      const data = await logDataClient.fetchLastVoyageAccumulated(payload.logVariableIds);
      return data;
    } catch (error) {
      console.warn(error);
    }
  }

  @Action({ rawError: true })
  public async fetchLastVoyageAveraged(payload: CalculatedLogDataQuery): Promise<void> {
    try {
      const data = await logDataClient.fetchLastVoyageAveraged(payload.logVariableIds);
      return data;
    } catch (error) {
      console.warn(error);
    }
  }

  @Action({ rawError: true })
  public async fetchShapoliAveraged(payload: { vesselId: number; fromDate: string; toDate: string }): Promise<void> {
    try {
      const data = await logDataClient.fetchShapoliAveraged(payload);
      return data;
    } catch (error) {
      console.warn(error);
    }
  }
}

export default LogData;
