import ko from "knockout";
import _ from "underscore";
import VoyageVesselEventData from "ViewModels/emission-report/voyageVesselEventData";
import emissionReportVesselEventsClient from "Clients/emission-report-vessel-events-client";
import flowMeterFuelConsumptionsClient from "Clients/fm-fuel-consumptions-client";
import configEmissionReportFactory from "App/config-emission-report";
import events from "App/events";

function validateVoyageDetails(vesselEvent, isEndOfVoyage) {
    var validationErrorMessages = [];
    if (vesselEvent.type === "Departure" && !isEndOfVoyage) {
        if (!vesselEvent.voyage || !vesselEvent.voyage.name) {
            validationErrorMessages.push("Please enter voyage information");
        }
    }
    return validationErrorMessages;
}

function validatePortDetails(vesselEvent, isEndOfVoyage) {
    var validationErrorMessages = [];
    if ((vesselEvent.type === "Departure" && !isEndOfVoyage) || vesselEvent.type === "Arrival") {
        if (!vesselEvent.port || !vesselEvent.port.name) {
            validationErrorMessages.push("Please enter port information");
        }
    }
    return validationErrorMessages;
}

var ViewModel = function(vessel, vesselEvent, vesselEvents, isStartOfVoyage, isEndOfVoyage, isVoyageInformationConfirmed, isVoyageDataConfirmed) {
    var self = this;
    self.configEmissionReport = configEmissionReportFactory.get();
    self.vessel = vessel;
    self.id = vesselEvent.id;
    self.isStartOfVoyage = isStartOfVoyage;
    self.isSelected = ko.observable(isStartOfVoyage);
    self.isEndOfVoyage = isEndOfVoyage;
    self.vesselEvents = vesselEvents;
    self.vesselEvent = ko.observable(vesselEvent);
    self.isVoyageInformationConfirmed = isVoyageInformationConfirmed;
    self.isVoyageDataConfirmed = isVoyageDataConfirmed;
    self.activeFuelTanks = self.configEmissionReport.activeFuelTanks;

    self.validationErrorMessages = ko.pureComputed(function() {
        var vesselEvent = self.vesselEvent();
        var vessel = self.vessel();

        var voyageValidationErrorMessages = validateVoyageDetails(vesselEvent, self.isEndOfVoyage);
        var portValidationErrorMessages = validatePortDetails(vesselEvent, self.isEndOfVoyage);

        return _.union(voyageValidationErrorMessages, portValidationErrorMessages);
    });

    self.hasCompleteInformation = ko.pureComputed(function() {
        var validationErrorMessages = self.validationErrorMessages();
        if (!validationErrorMessages) return false;
        return validationErrorMessages.length === 0;
    });

    self.vesselEventSelectedBinding = events.vesselEventSelected.add(function(vesselEvent) {
        self.isSelected(vesselEvent.id === self.id);
    });

    self.isConfirmed = ko.pureComputed(function() {
        var vesselEvent = self.vesselEvent();
        return vesselEvent.isConfirmed;
    });

    self.canDelete = ko.pureComputed(function() {
        var vesselEvent = self.vesselEvent();

        return !vesselEvent.isConfirmed && vesselEvent.type !== "Departure";
    });

    self.canUnconfirm = ko.pureComputed(function() {
        var isVoyageInformationConfirmed = self.isVoyageInformationConfirmed();
        var isConfirmed = self.isConfirmed();
        var previousDeparture = self.previousDeparture();
        var vesselEvent = self.vesselEvent();

        var isPreviousDepartureLocked = previousDeparture && previousDeparture.voyage && previousDeparture.voyage.informationConfirmedAt;
        var isEndOfVoyageAndLocked = vesselEvent.voyage && vesselEvent.voyage.informationConfirmedAt;

        return !isVoyageInformationConfirmed && isConfirmed && !isPreviousDepartureLocked && !isEndOfVoyageAndLocked;
    });

    self.isConfirming = ko.observable(false);
    self.isUnconfirming = ko.observable(false);
    self.isDeleting = ko.observable(false);

    self.vesselEventUpdatedBinding = events.vesselEventUpdated.add(function(updatedVesselEvent) {
        if (self.id === updatedVesselEvent.id) {
            self.vesselEvent(updatedVesselEvent);
            events.voyageVesselEventUpdated.dispatch();
        }
    });

    self.flowMeterFuelConsumptions = ko.observableArray([]);
    self.isLoadingFlowMeterFuelConsumptions = ko.observable(false);

    if (self.isVoyageInformationConfirmed()) {
        self.loadFlowMeterFuelConsumptions();
    }

    self.isVoyageInformationConfirmedBinding = self.isVoyageInformationConfirmed.subscribe(function(isVoyageInformationConfirmed) {
        if (isVoyageInformationConfirmed) {
            self.loadFlowMeterFuelConsumptions();
        } else {
            self.flowMeterFuelConsumptions.removeAll();
        }
    });

    self.activeOrInUseFlowMeters = ko.pureComputed(function() {
        var flowMeterFuelConsumptions = self.flowMeterFuelConsumptions();
        var flowMetersMap = self.configEmissionReport.flowMetersMap();
        return _.filter(flowMetersMap,
            function(flowMeter) {
                var isActive = !flowMeter.isDeactivated;
                var isInUse = _.some(flowMeterFuelConsumptions, function(flowMeterFuelConsumption) {
                    return flowMeterFuelConsumption.flowMeterId === flowMeter.id;
                });
                return isActive || isInUse;
            });
    });


    self.thisVesselEventIndex = ko.pureComputed(function() {
        var vesselEvents = self.vesselEvents();
        return _.findIndex(vesselEvents, function(vesselEvent) { return vesselEvent.id === self.id; });
    });

    self.previousVesselEvent = ko.pureComputed(function() {
        var vesselEvents = self.vesselEvents();
        var thisVesselEventIndex = self.thisVesselEventIndex();
        var previousVesselEventIndex = thisVesselEventIndex - 1;
        return previousVesselEventIndex >= 0 ? vesselEvents[previousVesselEventIndex] : null;
    });

    self.nextVesselEvent = ko.pureComputed(function() {
        var vesselEvents = self.vesselEvents();
        var thisVesselEventIndex = self.thisVesselEventIndex();
        var nextVesselEventIndex = thisVesselEventIndex + 1;
        return nextVesselEventIndex <= (vesselEvents.length - 1) ? vesselEvents[nextVesselEventIndex] : null;
    });

    self.previousDeparture = ko.pureComputed(function() {
        var vesselEvents = self.vesselEvents();
        var timestamp = self.vesselEvent().timestamp;

        var departureIndex = _.findLastIndex(vesselEvents, function(vesselEvent) {
            return vesselEvent.type === "Departure" && vesselEvent.timestamp < timestamp;
        });

        return departureIndex >= 0 ? vesselEvents[departureIndex] : null;
    });

    self.flowMeterFuelConsumptionUpdatedBinding = events.flowMeterFuelConsumptionUpdated.add(function(updatedFuelConsumption) {
        if (self.id !== updatedFuelConsumption.vesselEventId) return;

        var flowMeterFuelConsumptions = self.flowMeterFuelConsumptions();
        var oldFuelConsumption = _.find(flowMeterFuelConsumptions,
            function(flowMeterFuelConsumption) {
                return flowMeterFuelConsumption.id === updatedFuelConsumption.id;
            });
        if (oldFuelConsumption) {
            self.flowMeterFuelConsumptions.replace(oldFuelConsumption, updatedFuelConsumption);
        } else {
            self.flowMeterFuelConsumptions.push(updatedFuelConsumption);
        }
    });

    self.flowMeterFuelConsumptionDeletedBinding = events.flowMeterFuelConsumptionDeleted.add(function(deletedFuelConsumption) {
        if (self.id !== deletedFuelConsumption.vesselEventId) return;

        self.flowMeterFuelConsumptions.remove(deletedFuelConsumption);
    });

    self.voyageVesselEventData = new VoyageVesselEventData(self);
};

ViewModel.prototype.dispose = function(newCurve) {
    this.vesselEventSelectedBinding.detach();
    this.vesselEventUpdatedBinding.detach();
    this.isVoyageInformationConfirmedBinding.dispose();
    this.flowMeterFuelConsumptionUpdatedBinding.detach();
    this.flowMeterFuelConsumptionDeletedBinding.detach();
};

ViewModel.prototype.confirm = function() {
    var self = this;
    var vesselEvent = _.clone(self.vesselEvent());
    vesselEvent.isConfirmed = true;

    self.isConfirming(true);
    emissionReportVesselEventsClient.update(vesselEvent).always(function() {
        self.isConfirming(false);
    });
};

ViewModel.prototype.unconfirm = function() {
    var self = this;
    var vesselEvent = _.clone(self.vesselEvent());
    vesselEvent.isConfirmed = false;

    self.isUnconfirming(true);
    emissionReportVesselEventsClient.update(vesselEvent).always(function() {
        self.isUnconfirming(false);
    });
};

ViewModel.prototype.deleteVesselEvent = function() {
    var self = this;
    var vesselEvent = self.vesselEvent();

    self.isDeleting(true);
    emissionReportVesselEventsClient.remove(vesselEvent).fail(function() {
        self.isDeleting(false);
    });
};

ViewModel.prototype.loadFlowMeterFuelConsumptions = function() {
    var self = this;
    self.isLoadingFlowMeterFuelConsumptions(true);
    return flowMeterFuelConsumptionsClient.find(self.id)
        .done(self.flowMeterFuelConsumptions)
        .always(function() {
            self.isLoadingFlowMeterFuelConsumptions(false);
        });
};

export default ViewModel;