import net from "App/net";

var LongTrendConfigurationsClient = function () {

};

LongTrendConfigurationsClient.prototype.getByVesselId = function(vesselId) {
    var filter = {
        vesselId: vesselId,
    };

    var deferred = $.Deferred();
    net.get("/LongTrendConfigurations/Find", filter).done(function (response) {
        deferred.resolve(response[0]);
    }).fail(function () {
        deferred.reject(arguments);
    });
    return deferred.promise();
};

LongTrendConfigurationsClient.prototype.update = function(configuration) {
    return net.put("/LongTrendConfigurations", configuration);
};

export default new LongTrendConfigurationsClient();