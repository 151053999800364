import { VuexModule, Module, Action, Mutation } from "vuex-module-decorators";
import ShapoliClient from "Clients/shapoli-client";
import { ShapoliBreach } from "@/types/ShapoliBreach";
import { ShapoliEvent } from "@/types/ShapoliEvent";


@Module({ namespaced: true, name: "Shapoli" })
class Shapoli extends VuexModule {
  private _events: ShapoliEvent[] | null = null;
  private _breaches: ShapoliBreach[] | null = null;

  //  @Getters
  public get events(): ShapoliEvent[] | null {
    return this._events;
  }

  public get breaches(): ShapoliBreach[] | null {
    return this._breaches;
  }

  //  @Actions
  @Action({ rawError: true })
  public async fetchBreaches(query: { vesselId: string | number; year: number | null; }): Promise<void> {
    try {
      const breaches = await ShapoliClient.getBreaches(query);
      this.context.commit("SET_BREACHES", breaches);
    } catch (error) {
      throw ({ message: "Failed to fetch breaches", error });
    }
  }

  @Action({ rawError: true })
  public async fetchBreachDetails(query: { vesselId: string | number; fromDate: string; toDate: string; }): Promise<any[]> {
    try {
      return await ShapoliClient.getBreachDetails(query);
    } catch (error) {
      throw ({ message: "Failed to fetch breach details", error });
    }
  }

  @Action({ rawError: true })
  public async fetchEvents(query: { vesselId: string | number; year: number | null; }): Promise<void> {
    try {
      const events = await ShapoliClient.getEvents(query);
      this.context.commit("SET_EVENTS", events);
    } catch (error) {
      throw ({ message: "Failed to fetch events", error });
    }
  }

  //  @Mutations
  @Mutation
  public SET_EVENTS(events: ShapoliEvent[]): void {
    this._events = events;
  }

  @Mutation
  public SET_BREACHES(breaches: ShapoliBreach[]): void {
    this._breaches = breaches;
  }
}

export default Shapoli;