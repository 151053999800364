import template from "./tooltip-text.html";
import ko from "knockout";
import events from "App/events";

function ViewModel(params) {
    var self = this;
    self.text = ko.isObservable(params.text) ? params.text : ko.observable(params.text);
    self.isWide = params.isWide;
    self.tooltipPosition = ko.observable(params.tooltipPosition); //'top', 'bottom', 'right' or 'left'
    self.customCssClass = ko.observable(params.customCssClass);

    self.cssClass = ko.pureComputed(function () {
        var css = "";
        var customCssClass = self.customCssClass();
        if (customCssClass) {
            css += customCssClass;
        } 

        if (self.isWide) {
            css += " wide";
        }

        return css;
    });

    self.textSubscription = self.text.subscribe(function() {
        events.repositionTooltip.dispatch();
    });
}

ViewModel.dispose = function() {
    this.textSubscription.dispose();
};

export default { viewModel: ViewModel, template: template };