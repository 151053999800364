import signals from "signals";

export default {
        longTrendUpdated: new signals.Signal(),
        showTooltip: new signals.Signal(),
        closeTooltip: new signals.Signal(),
        repositionTooltip: new signals.Signal(),
        vesselEventSelected: new signals.Signal(),
        showModal: new signals.Signal(),
        closeModal: new signals.Signal(),
        modalClosed: new signals.Signal(),
        curveCreated: new signals.Signal(),
        curveUpdated: new signals.Signal(),
        curveDeleted: new signals.Signal(),
        curvePlotCreated: new signals.Signal(),
        curvePlotUpdated: new signals.Signal(),
        curvePlotDeleted: new signals.Signal(),
        curvePlotSelected: new signals.Signal(),
        curvePlotSpecified: new signals.Signal(),
        editCurveStart: new signals.Signal(),
        editCurveDone: new signals.Signal(),
        filterGroupEmpty: new signals.Signal(),
        menuItemClick: new signals.Signal(),
        vesselAdministrationUpdated: new signals.Signal(),
        findDataReceiver: new signals.Signal(),
        vesselDeleted: new signals.Signal(),
        vesselArraySorted: new signals.Signal(),
        retryImport: new signals.Signal(),
        removeImport: new signals.Signal(),
        flowMeterDeleted: new signals.Signal(),
        flowMeterFuelConsumptionUpdated: new signals.Signal(),
        flowMeterFuelConsumptionDeleted: new signals.Signal(),
        flowMeterFuelConsumptionSelected: new signals.Signal(),
        overlappingFuelConsumptionUpdated: new signals.Signal(),
        voyageUpdated: new signals.Signal(),
        voyageCreated: new signals.Signal(),
        plotLineDateSelected: new signals.Signal(),
        dateSelectStarted: new signals.Signal(),
        dateSelectEnded: new signals.Signal(),
        vesselEventCreated: new signals.Signal(),
        vesselEventUpdated: new signals.Signal(),
        vesselEventDeleted: new signals.Signal(),
        voyageVesselEventUpdated: new signals.Signal(),
        vesselEventCargoDetailDeleted: new signals.Signal(),
        fuelTankDeleted: new signals.Signal(),
        fuelTankSoundingDeleted: new signals.Signal(),
        fuelTankOperationCreated: new signals.Signal(),
        fuelTankOperationDeleted: new signals.Signal(),
        flowMeterOperationDeleted: new signals.Signal(),
        departureSelected: new signals.Signal(),
        fuelTypeDeleted: new signals.Signal(),
        viewReport: new signals.Signal(),
        vesselFileUploaded: new signals.Signal(),
        curvePredictionUpdated: new signals.Signal(),
        notificationConditionCreated: new signals.Signal(),
        notificationConditionUpdated: new signals.Signal(),
        notificationConditionRemoved: new signals.Signal(),
        notificationChannelRemoved: new signals.Signal(),
        filteredVesselsChanged: new signals.Signal(),
        vesselGroupCreated: new signals.Signal(),
        vesselGroupUpdated: new signals.Signal(),
        vesselGroupDeleted: new signals.Signal(),
        compareGroupClicked: new signals.Signal(),
        compareVesselsSelected: new signals.Signal(),
        navigatedHome: new signals.Signal(),
        companyAdd: new signals.Signal(),
        companyEdit: new signals.Signal(),
        companyDelete: new signals.Signal(),
        companyView: new signals.Signal(),
        companyViewAs: new signals.Signal(),
        companyOnCreated: new signals.Signal(),
        companyOnUpdated: new signals.Signal(),
        companyOnRemoved: new signals.Signal(),
        vesselAccessGroupAdd: new signals.Signal(),
        vesselAccessGroupEdit: new signals.Signal(),
        vesselAccessGroupRename: new signals.Signal(),
        vesselAccessGroupDelete: new signals.Signal(),
        vesselAccessGroupOnCreated: new signals.Signal(),
        vesselAccessGroupOnUpdated: new signals.Signal(),
        vesselAccessGroupOnDeleted: new signals.Signal(),
        userAdd: new signals.Signal(),
        userEdit: new signals.Signal(),
        userDelete: new signals.Signal(),
        userView: new signals.Signal(),
        userManageApiKey: new signals.Signal(),
        userOnCreated: new signals.Signal(),
        userOnCreatedFailed: new signals.Signal(),
        userOnCreatedAlreadyExist: new signals.Signal(),
        userOnUpdated: new signals.Signal(),
        userOnRemoved: new signals.Signal(),
        userOnFailed: new signals.Signal(),
        viewPdf: new signals.Signal(),
        closePdf: new signals.Signal(),
        vesselFileDownload: new signals.Signal(),
        updateLoaderText: new signals.Signal(),
        presetChanged: new signals.Signal(),
        createEvent: new signals.Signal(),
        editEvent: new signals.Signal(),
        deleteEvent: new signals.Signal(),
        errorEvent: new signals.Signal(),
        updateEventList: new signals.Signal(),
        showDiagnosticsDetails: new signals.Signal(),
};
