import ko from "knockout";
import _ from "underscore";
import $ from "jquery";
import vesselFilesClient from "Clients/vessel-files-client";

function getFilesInFolder(files, folder) {
    var folderPath = folder.folderPath();
    var filesInFolder = [];

    _.each(files, function (file) {
        var fileName = file.fileName();
        var isDescendant = fileName.indexOf(folderPath) === 0;

        if (isDescendant) {
            var childFileName = fileName.replace(folderPath, "");
            var fileExistsInFolder = childFileName.indexOf("/") === -1;

            if (fileExistsInFolder) {
                filesInFolder.push(file);
            }
        }
    });

    return filesInFolder;
}

function updateFile(file, newFileName, tempFolders, folder) {
    file.fileName(newFileName);
    vesselFilesClient.update(file.getFile());
    folder.isTempFolder(false);
    tempFolders.remove(folder);
}

ko.bindingHandlers.draggableFile = {
    init: function (element, valueAccessor, allBindingsAccessor) {
        var isFolder = valueAccessor().isFolder;
        var draggableElement = valueAccessor().draggableElement;
        var data = valueAccessor().data;
        var tempFolders = valueAccessor().tempFolders;
        var files = valueAccessor().files;

        $(element).on("dragstart", function (event) {
            if (!isFolder) {
                draggableElement(data);
            }
        }).on("dragenter dragover", function (event) {
            event.preventDefault();

            if (isFolder) {
                element.className += " dragover";
            }
        }).on("dragleave drop", function (event) {
            if (isFolder) {
                element.className = element.className.replace(/(?:^|\s)dragover(?!\S)/g, "");
            }
        }).on("drop", function (event) {
            event.preventDefault();

            if (isFolder) {
                var file = draggableElement();
                var fileName = file.fileNameWithoutPath();
                var newFileName = data.folderPath() + fileName;
                var filesInFolder = getFilesInFolder(files(), data);
                var moveFile = true;

                var existingFile = _.find(filesInFolder, function (file) {
                    return file.fileNameWithoutPath() === fileName;
                });

                if (existingFile) {
                    if (confirm(fileName + " already exists. Do you want to replace it?")) {
                        moveFile = false;
                        vesselFilesClient.remove(existingFile.id()).done(function () {
                            files.remove(existingFile);
                            updateFile(file, newFileName, tempFolders, data);
                        });
                    }
                } else {
                    updateFile(file, newFileName, tempFolders, data);
                }
            }
        });
    },
    update: function (element, valueAccessor, allBindingsAccessor) {

    },
};