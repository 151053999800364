import template from "./input-dialog.html";
import ko from "knockout";
import _ from "underscore";
import stringHelper from "Utilities/string-helper";

function ViewModel(params) {
    var self = this;

    self.type = params.inputType;
    self.title = params.title;
    self.value = _.isUndefined(params.value) ? ko.observable("") : params.value;
    self.hasError = params.hasError;
    self.errorText = params.errorText;
    self.cancelFunction = params.cancelFunction;
    self.successFunction = params.successFunction;
    self.dialogVisible = params.dialogVisible;
    self.description = params.description;
    self.helpText = params.helpText;

    self.isConfirmButtonEnabled = ko.observable(false);

    self.isTextInputVisible = ko.pureComputed(function() {
        var type = self.type;

        return type === "create" || type === "edit";
    });

    self.successButtonText = ko.pureComputed(function () {
        var type = self.type;

        if (type === "create") {
            return "Create";
        } else if (type === "delete") {
            return "Delete";
        } else if (type === "confirm") {
            return "Confirm";
        }

        return "Ok";
    });

    self.isConfirmButtonDisabled = ko.pureComputed(function () {
        var value = self.value();
        var type = self.type;

        if (type === "delete" || type === "confirm") {
            return false;
        }

        if (stringHelper.isNullOrWhiteSpace(value)) {
            return true;
        }

        return false;
    });

    self.valueBinding = self.value.subscribe(function(value) {
        var hasError = self.hasError();

        if (hasError) {
            self.hasError(false);
        }
    });
}

ViewModel.prototype.cancel = function () {
    var type = this.type;

    this.dialogVisible(false);

    if (!_.isUndefined(this.errorValue)) {
        this.errorValue(false);
    }

    if (type === "create") {
        this.value("");
    }

    if (!_.isUndefined(this.cancelFunction)) {
        this.cancelFunction();
    }
};

ViewModel.prototype.dispose = function() {
    this.valueBinding.dispose();
};

export default { viewModel: ViewModel, template: template };