import template from "./fm-operation.html";
import ko from "knockout";
import _ from "underscore";
import flowMeterOperationsClient from "Clients/fm-operations-client";
import configEmissionReportFactory from "App/config-emission-report";

function ViewModel(params) {
    var self = this;
    self.configEmissionReport = configEmissionReportFactory.get();
    self.flowMeterOperation = params.flowMeterOperation;
    self.isVoyageDataConfirmed = params.isVoyageDataConfirmed;

    var fuelTypesMap = self.configEmissionReport.fuelTypesMap();
    self.fuelTypeName = fuelTypesMap[self.flowMeterOperation.fuelTypeId].name;

    self.isRemoving = ko.observable(false);
}

ViewModel.prototype.dispose = function() {
};

ViewModel.prototype.remove = function() {
    var self = this;
    self.isRemoving(true);
    flowMeterOperationsClient.remove(self.flowMeterOperation).always(function() {
        self.isRemoving(false);
    });
};

export default { viewModel: ViewModel, template: template };
