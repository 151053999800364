import $ from "jquery";
import _ from "underscore";
import L from "leaflet";

var green = require("@/assets/images/vessels/green.png");
var orange = require("@/assets/images/vessels/orange.png");
var red = require("@/assets/images/vessels/red.png");
var blue = require("@/assets/images/vessels/blue.png");
var gray = require("@/assets/images/vessels/gray.png");
var gpsUp = require("@/assets/images/gps-up.png");

var icons = {
  ok: L.icon({
    iconUrl: green,
    iconSize: [12, 29],
    popupAnchor: [0, -20],
    className: "pointercursor",
  }),
  observe: L.icon({
    iconUrl: orange,
    iconSize: [12, 29],
    popupAnchor: [0, -20],
    className: "pointercursor",
  }),
  notok: L.icon({
    iconUrl: red,
    iconSize: [12, 29],
    popupAnchor: [0, -20],
    className: "pointercursor",
  }),
  benchmarking: L.icon({
    iconUrl: blue,
    iconSize: [12, 29],
    popupAnchor: [0, -20],
    className: "pointercursor",
  }),
  gray: L.icon({
    iconUrl: gray,
    iconSize: [12, 29],
    popupAnchor: [0, -20],
    className: "pointercursor",
  }),
  previousPosition: L.icon({
    iconUrl: gpsUp,
    iconSize: [20, 20],
    popupAnchor: [0, -20],
    className: "pointercursor previousPositionMarker",
  }),
};

function getIcon(status) {
  var icon;

  switch (status) {
    case "PreviousPosition":
      icon = icons.previousPosition;
      break;
    case "Ok":
      icon = icons.ok;
      break;
    case "Observe":
      icon = icons.observe;
      break;
    case "NotOk":
      icon = icons.notok;
      break;
    case "BenchMarking":
      icon = icons.benchmarking;
      break;
    default:
      icon = icons.gray;
  }
  return icon;
}

function init() {
  var layer = new L.TileLayer(
    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}.png",
    {
      attribution: "Esri",
    },
  );
  var maxBounds = L.latLngBounds(L.latLng(-85, -200), L.latLng(85, 200));
  var map = L.map("map", {
    layers: layer,
    scrollWheelZoom: true,
    maxZoom: 14,
    minZoom: 2,
    zoom: 5,
    center: [0, 0],
    maxBounds: maxBounds,
    maxBoundsViscosity: 0.8,
  });

  return map;
}

function createMarker(vesselHistory, performanceStatus) {
  var location = vesselHistory.location();

  if (location === null || _.isUndefined(location)) {
    return;
  }

  var vLocation = location.split(",");
  var latSplitted = vLocation[0].split(".");
  var longSplitted = vLocation[1].split(".");

  var latDegrees = parseInt(latSplitted[0]);
  var longDegrees = parseInt(longSplitted[0]);

  var latRangeOk = latDegrees <= 90 && latDegrees >= -90;
  var longRangeOk = longDegrees <= 180 && longDegrees >= -180;

  var locationAvailable = vesselHistory.latitude() != null && vesselHistory.longitude() != null;

  // Make sure location is within bounds, lat: +/-90, long: +/-180
  if (latRangeOk && longRangeOk && locationAvailable) {
    var course = vesselHistory.course();
    var popup = new L.Popup();

    var bubbleContent =
      "<table>" +
      "<tr><td>Voyage" +
      "</td><td>" +
      vesselHistory.voyage().voyageName +
      "</td></tr>" +
      "<tr><td>Latitude" +
      "</td><td>" +
      vesselHistory.prettyLatitude() +
      "</td></tr>" +
      "<tr><td>Longitude" +
      "</td><td>" +
      vesselHistory.prettyLongitude() +
      "</td></tr>" +
      "<tr><td>Log Speed" +
      "</td><td>" +
      vesselHistory.prettyLogSpeed() +
      "</td></tr>" +
      "<tr><td>GPS Speed" +
      "</td><td>" +
      vesselHistory.prettyGpsSpeed() +
      "</td></tr>" +
      "<tr><td>Course" +
      "</td><td>" +
      vesselHistory.prettyCourse() +
      "</td></tr>" +
      "<tr><td>Draft mean" +
      "</td><td>" +
      vesselHistory.prettyDraftMean() +
      "</td></tr>" +
      "<tr><td>Trim" +
      "</td><td>" +
      vesselHistory.prettyTrim() +
      "</td></tr>" +
      "</table>";

    popup.setContent(
      "<div class=\"vesselLabel bubble\"><strong>" +
        vesselHistory.prettyExportDate() +
        "</strong><div class=\"vesselMapPopup\">" +
        bubbleContent +
        " </div></div>",
    );

    var marker = L.rotatedMarker(vLocation, {
      icon: getIcon(performanceStatus),
      angle: Math.round(course),
    }).bindPopup(popup);

    marker.on("mouseover", function(e) {
      this.openPopup();
    });
    marker.on("mouseout", function(e) {
      this.closePopup();
    });

    return marker;
  } else {
    return null;
  }
}
function createFrontPageMarker(
  vesselHistory,
  performanceStatus,
  url,
  imoNumber,
  vesselName,
  initialIsPopupOpen,
) {
  var location = vesselHistory.location();

  if (location === null || _.isUndefined(location)) {
    return;
  }

  var vLocation = location.split(",");
  var latSplitted = vLocation[0].split(".");
  var longSplitted = vLocation[1].split(".");

  var latDegrees = parseInt(latSplitted[0]);
  var longDegrees = parseInt(longSplitted[0]);

  var latRangeOk = latDegrees <= 90 && latDegrees >= -90;
  var longRangeOk = longDegrees <= 180 && longDegrees >= -180;

  var locationAvailable = vesselHistory.latitude() != null && vesselHistory.longitude() != null;

  // Make sure location is within bounds, lat: +/-90, long: +/-180
  if (latRangeOk && longRangeOk && locationAvailable) {
    var course = vesselHistory.course();
    var popup = new L.Popup({
      closeButton: true,
      closeOnClick: false,
      autoClose: false,
    });

    var domelem = document.createElement("div");
    var bubbleContent =
      "<table>" +
      "<tr><td>Current voyage" +
      "</td><td>" +
      vesselHistory.voyage().voyageName +
      "</td></tr>" +
      "<tr><td>Latitude" +
      "</td><td>" +
      vesselHistory.prettyLatitude() +
      "</td></tr>" +
      "<tr><td>Longitude" +
      "</td><td>" +
      vesselHistory.prettyLongitude() +
      "</td></tr>" +
      "<tr><td>Log Speed" +
      "</td><td>" +
      vesselHistory.prettyLogSpeed() +
      "</td></tr>" +
      "<tr><td>GPS Speed" +
      "</td><td>" +
      vesselHistory.prettyGpsSpeed() +
      "</td></tr>" +
      "<tr><td>Course" +
      "</td><td>" +
      vesselHistory.prettyCourse() +
      "</td></tr>" +
      "<tr><td>Draft mean" +
      "</td><td>" +
      vesselHistory.prettyDraftMean() +
      "</td></tr>" +
      "<tr><td>Trim" +
      "</td><td>" +
      vesselHistory.prettyTrim() +
      "</td></tr>" +
      "</table>";
    domelem.innerHTML =
      "<div id=\"" +
      imoNumber +
      "\" class=\"vesselLabel bubble\">" +
      vesselName +
      "<div class=\"moreInfo\">" +
      bubbleContent +
      "<a href=\"" +
      url +
      "\">More info</a></div></div>";
    domelem.onclick = function() {
      $(this)
        .find(".vesselLabel")
        .toggleClass("active");
    };
    popup.setContent(domelem);

    var marker = L.rotatedMarker(vLocation, {
      icon: getIcon(performanceStatus),
      angle: Math.round(course),
    }).bindPopup(popup);

    // Remove default click event
    marker.removeEventListener();

    marker.on("click", function(e) {
      var markerPopup = this.getPopup();
      var isOpen = markerPopup.isOpen();
      if (!isOpen) {
        this.openPopup();
      } else {
        $(markerPopup.getElement())
          .find(".vesselLabel")
          .toggleClass("active");
      }
    });

    return marker;
  } else {
    return null;
  }
}

function findMarkerBounds(markers) {
  var n, e, s, w, sw, ne;
  var marker, lat, lng;

  for (var i = 0; i < markers.length; i++) {
    marker = markers[i];
    lat = marker.getLatLng().lat;
    lng = marker.getLatLng().lng;

    // find lowest and highest latitude
    if (lat > n || i == 0) n = lat;
    if (lat < s || i == 0) s = lat;

    // find lowest and highest longitude
    if (lng > e || i == 0) e = lng;
    if (lng < w || i == 0) w = lng;
  }

  sw = new L.LatLng(s, w);
  ne = new L.LatLng(n, e);

  return new L.LatLngBounds(sw, ne);
}

function getLatLngs(location) {
  var vLocation = location.split(",");
  var latSplitted = vLocation[0].split(".");
  var longSplitted = vLocation[1].split(".");

  var latDegrees = parseFloat(latSplitted[0]);
  var longDegrees = parseFloat(longSplitted[0]);

  return { latDegrees: latDegrees, longDegrees: longDegrees };
}

// Finds the max realistic number of longitude degrees a vessel can sail in one day.
// This number of degrees is found by imagining a vessel sailing at 30 knots for one day
// which gives 720 nautical miles. 1 nautical mile at 0 degrees latitude is about
// 60 nautical miles. 720 nm / 60 nm = 12. So 12 degrees of longitude at the equator
// is about 720 nautical miles in distance.
// But further towards the poles, the longitude lines converge, and so 1 degree is less
// and less distance. By using a tool to meassure how many degrees of longitude equals
// our max distance at different latitudes (e.g. at 80 degrees latitude that is about
// 74 degrees of longitude), and then using polynomal regression, we get a formula to
// gives us the max longitude degrees at a given latitude.
function findMaxDiffLongitude(latitude) {
  // polynomial 5th degree formula
  var x5 = Math.pow(latitude, 5);
  var x4 = Math.pow(latitude, 4);
  var x3 = Math.pow(latitude, 3);
  var x2 = Math.pow(latitude, 2);
  var x = latitude;
  return (
    2.691884215 * 1e-7 * x5 -
    4.32827545 * 1e-5 * x4 +
    2.492331022 * 1e-3 * x3 -
    5.671012308 * 1e-2 * x2 +
    4.950207984 * 1e-1 * x +
    11.11453809
  );
}

function createPolylines(vesselHistories) {
  var latLngLists = [];
  var latLngs = [];
  var prevLatitude = null;
  var prevLongitude = null;
  var maxDiffLatitude = 12; //12 degrees of latitude is always about 720 nautical miles.

  _.each(vesselHistories, function(vesselHistory) {
    var latitude = vesselHistory.latitude();
    var longitude = vesselHistory.longitude();

    if (prevLatitude !== null && prevLongitude !== null) {
      var diffLatitude = Math.abs(latitude - prevLatitude);
      var diffLongitude = Math.abs(longitude - prevLongitude);

      // Since the vessel is not travelling horizontally, we'll
      // use the average latitude to find the allowed difference in longitude degrees
      var averageLatitude = Math.abs(latitude + prevLatitude) / 2;
      var maxDiffLongitude = findMaxDiffLongitude(averageLatitude);

      if (diffLatitude > maxDiffLatitude || diffLongitude > maxDiffLongitude) {
        latLngLists.push(latLngs);
        latLngs = [];
      }
    }

    if (latitude != null && longitude != null) {
      latLngs.push([latitude, longitude]);
      prevLatitude = latitude;
      prevLongitude = longitude;
    }
  });

  latLngLists.push(latLngs);

  var latLngListsWithAtLeastTwoItems = _.filter(latLngLists, function(latLngList) {
    return latLngList.length > 1;
  });

  return _.map(latLngListsWithAtLeastTwoItems, function(latLngList) {
    return L.polyline(latLngList, {
      color: "black",
      smoothFactor: 0,
      weight: 1,
      opacity: 0.5,
    });
  });
}

function createPolylinesForMapWidget(vesselHistories) {
  var latLngLists = [];
  var latLngs = [];
  var prevLatitude = null;
  var prevLongitude = null;
  var maxDiffLatitude = 12; //12 degrees of latitude is always about 720 nautical miles.

  _.each(vesselHistories, function(vesselHistory) {
    var latitude = vesselHistory.latitude;
    var longitude = vesselHistory.longitude;

    if (prevLatitude !== null && prevLongitude !== null) {
      var diffLatitude = Math.abs(latitude - prevLatitude);
      var diffLongitude = Math.abs(longitude - prevLongitude);

      // Since the vessel is not travelling horizontally, we'll
      // use the average latitude to find the allowed difference in longitude degrees
      var averageLatitude = Math.abs(latitude + prevLatitude) / 2;
      var maxDiffLongitude = findMaxDiffLongitude(averageLatitude);

      if (diffLatitude > maxDiffLatitude || diffLongitude > maxDiffLongitude) {
        latLngLists.push(latLngs);
        latLngs = [];
      }
    }

    if (latitude != null && longitude != null) {
      latLngs.push([latitude, longitude]);
      prevLatitude = latitude;
      prevLongitude = longitude;
    }
  });

  latLngLists.push(latLngs);

  var latLngListsWithAtLeastTwoItems = _.filter(latLngLists, function(latLngList) {
    return latLngList.length > 1;
  });

  return latLngListsWithAtLeastTwoItems;
}

export default {
  init: function() {
    return init();
  },
  createMarker: function(vesselHistory, performanceStatus) {
    return createMarker(vesselHistory, performanceStatus);
  },
  createFrontPageMarker: function(
    vesselHistory,
    performanceStatus,
    url,
    imoNumber,
    vesselName,
    initialIsPopupOpen,
  ) {
    return createFrontPageMarker(
      vesselHistory,
      performanceStatus,
      url,
      imoNumber,
      vesselName,
      initialIsPopupOpen,
    );
  },
  findMarkerBounds: function(markers) {
    return findMarkerBounds(markers);
  },
  createPolylines: function(vesselHistories) {
    return createPolylines(vesselHistories);
  },
  createPolylinesForMapWidget: function(vesselHistories) {
    return createPolylinesForMapWidget(vesselHistories);
  },
};
