import net from "App/net";

var VersionClient = function() {

};

VersionClient.prototype.getVersion = function() {
    return net.get("/Version");
};

export default new VersionClient();
