import net from "App/net";
// This should be called Vessel Access Groups, but since it is still
// called Vessel Categories in the backend I did not rename the client.

var Client = function () {

};

Client.prototype.getAll = function () {
    return net.get("/VesselCategories");
};

Client.prototype.create = function (category) {
    return net.post("/VesselCategories", category);
};

Client.prototype.update = function (category) {
    return net.put("/VesselCategories/" + category.id, category);
};

Client.prototype.delete = function (category) {
    return net.del("/VesselCategories/" + category.id);
};

Client.prototype.addVesselToCategory = function(category, vessel) {
    var cmd = {
        vesselId: vessel.id,
        vesselCategoryId: category.id,
    };

    return net.post("/VesselCategories/AddVessel", cmd);
};

Client.prototype.removeVesselFromCategory = function(category, vessel) {
    var cmd = {
        vesselId: vessel.id,
        vesselCategoryId: category.id,
    };

    return net.post("/VesselCategories/RemoveVessel", cmd);
};

Client.prototype.addUserToCategory = function (category, user) {
    var cmd = {
        userId: user.id,
        vesselCategoryId: category.id,
    };

    return net.post("/VesselCategories/AddUser", cmd);
};

Client.prototype.removeUserFromCategory = function (category, user) {
    var cmd = {
        userId: user.id,
        vesselCategoryId: category.id,
    };

    return net.post("/VesselCategories/RemoveUser", cmd);
};

export default new Client();