import ko from "knockout";
import moment from "moment";

ko.bindingHandlers.dateFormat = {
    update: function (element, valueAccessor) {
        var options = ko.utils.unwrapObservable(valueAccessor());
        var date = ko.utils.unwrapObservable(options.date);
        var format = ko.utils.unwrapObservable(options.format) || ko.bindingHandlers.dateFormat.defaultFormat;
        var formattedDate = date ? moment.utc(date).format(format) : "";

        ko.bindingHandlers.text.update(element, function () { return formattedDate; });
    },
    defaultFormat: "DD. MMM, YYYY",
};