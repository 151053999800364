import template from "./compare-vessels-long-trends-details-table.html";
import ko from "knockout";
import _ from "underscore";
import VesselCompareRowData from "ViewModels/compare-vessels/vessel-compare-row-data";
import consequencesClient from "Clients/consequences-client";

function getSortValue(vesselCompareRowData, sortParameter) {
    switch (sortParameter) {
    case "name":
        return vesselCompareRowData.vessel.name;
    case "lastPeriodStartDate":
        return vesselCompareRowData.lastPeriodStartDate;
    case "changePerYear":
        return vesselCompareRowData.changePerYear;
    case "benchmarkPointValue":
        return vesselCompareRowData.benchmarkPointValue;
    case "deviationFromBenchmark":
        return vesselCompareRowData.deviationFromBenchmark;
    default:
        throw "Unexpected sort parameter: " + sortParameter;
    }
}

function ViewModel(params) {
    var self = this;
    self.compareGroup = params.compareGroup;
    self.showLastTrendPeriodOnly = params.showLastTrendPeriodOnly;
    self.vessels = self.compareGroup.vessels;
    self.longTrends = self.compareGroup.longTrends;
    self.longTrendPeriods = self.compareGroup.longTrendPeriods;

    self.inputMcrPercent = ko.observable();

    self.vesselSortParameter = ko.observable("name");
    self.vesselSortDirection = ko.observable("asc");

    self.vesselLongTrendRowViewModels = _.map(self.vessels, function(vessel) {
        return new VesselCompareRowData(vessel, self.compareGroup);
    });

    self.sortedVesselCompareRowDatas = ko.pureComputed(function() {
        var vesselSortParameter = self.vesselSortParameter();
        var vesselSortDirection = self.vesselSortDirection();

        var sorted = _.sortBy(self.vesselLongTrendRowViewModels, function(vesselLongTrendRowViewModel) {
            return getSortValue(vesselLongTrendRowViewModel, vesselSortParameter);
        });
        return vesselSortDirection === "asc" ? sorted : sorted.reverse();
    });

    self.isLoadingConsequences = ko.observable(true);
    self.consequencesMap = ko.observable();

    self.consequenceHeaders = ko.pureComputed(function() {
        var consequencesMap = self.consequencesMap();
        var allConsequences = _.flatten(_.values(consequencesMap));
        var allConsequenceResults = _.flatten(_.pluck(allConsequences, "results"));
        var distinctHeaderNames = _.uniq(_.pluck(allConsequenceResults, "headerName"));
        return _.union(["MCR"], distinctHeaderNames);
    });

    self.loadConsequences();
}

ViewModel.prototype.loadConsequences = function() {
    var self = this;
    self.isLoadingConsequences(true);
    var longTrendIds = _.pluck(self.longTrends, "id");
    consequencesClient.findLatest(longTrendIds).done(function(data) {
        self.consequencesMap(data);
    }).always(function() {
        self.isLoadingConsequences(false);
    });
};

export default { viewModel: ViewModel, template: template };