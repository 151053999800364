import template from "./data-pie.html";
import ko from "knockout";
import _ from "underscore";
import highchartsHelper from "Utilities/highcharts-helper";

function ViewModel(params) {
    var self = this;
    self.chart = ko.observable();
    self.name = params.name;
    self.pieSlices = params.pieSlices;

    self.highchartsOptions = ko.pureComputed(function() {
        var pieSlices = self.pieSlices();
        var pieChartOptions = highchartsHelper.getDefaultGenericPieChartOptions(self.name, pieSlices);
        pieChartOptions.chart.height = 300;
        return pieChartOptions;
    });
}

ViewModel.prototype.dispose = function() {
};

export default { viewModel: ViewModel, template: template };